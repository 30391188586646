module.exports = [
    {
        path: '/inventory/report/purchase',
        name: 'purchase.report',
        component: require('../views/inventory/report/purchaseReport.vue').default
    },
    {
        path: '/inventory/report/actualpurchase',
        name: 'actualpurchase.report',
        component: require('../views/inventory/report/actualPurchaseReport.vue').default
    },
    {
        path: '/inventory/report/sale',
        name: 'sale.report',
        component: require('../views/inventory/report/saleReport.vue').default
    },
    {
        path: '/inventory/report/product',
        name: 'product.report',
        component: require('../views/inventory/report/productReport.vue').default
    },
    {
        path: '/inventory/report/warehouse',
        name: 'warehouse.report',
        component: require('../views/inventory/report/warehouseReport.vue').default
    },
    {
        path: '/expense/report/expense',
        name: 'expense.report',
        component: require('../views/expense/report/expenseReport.vue').default
    },
    {
        path: '/income/report/income',
        name: 'income.report',
        component: require('../views/income/report/incomeReport.vue').default
    },
    {
        path: '/expense/report/overall',
        name: 'overall.report',
        component: require('../views/inventory/report/overallReport.vue').default
    },
    {
        path: '/inventory/report/customer',
        name: 'customer.report',
        component: require('../views/inventory/report/customerReport.vue').default
    },
    {
        path: '/inventory/report/supplier',
        name: 'supplier.report',
        component: require('../views/inventory/report/supplierReport.vue').default
    },
    {
        path:'/inventory/report/saleReturn',
        name:'saleReturn.ledger',
        component: require('../views/inventory/report/saleReturnReport.vue').default
    },
    {
        path:'/inventory/report/purchaseReturn',
        name:'purchaseReturn.ledger',
        component: require('../views/inventory/report/purchaseReturnReport.vue').default
    }
];