import {Errors} from './Errors';

export class Forms {
    constructor(data) {
        this.originalData = data;
        for (let field in data) {
            this[field] = data;
        }
        // calling error class
        this.errors = new Errors();
    }

    // data should be dynamic, we can not call the static
    data() {
        let data = Object.assign({}, this);
        delete data.originalData; //delete original data object
        delete data.errors; //delete original data object
        return data;
    }

    post(data) {
        let type = ['post', 'patch', 'delete', 'get'];
        return type.includes(data.toLowerCase()) ? data.toLowerCase() : '';
    }

    submit(requestType, url, reset = true, apps = null) {

        return new Promise((resolve, reject) => {
            axios[this.post(requestType)](url, this.data())
                .then(response => {
                    this.onSuccess(response.data, reset, apps);
                    resolve(response.data);
                })
                .catch(error => {
                    this.onError(error.response.data);
                    reject(error.response.data);
                });
            // .catch(this.onError.bind(this));
        });
    }

    onSuccess(data, reset, apps = null) {
        if (reset) {
            if (data.type !== 'error') {
                this.reset();
            }
        }
        this.errors.errors = {};
    }

    onError(error) {
        this.errors.record(error);

        // this.errors.record(error.response.data);
    }

    reset() {
        // return null;
        for (let field in this.data()) {
            if (typeof this[field] === 'object') {
                this[field] = []
            } else if (typeof this[field] === 'number') {
                this[field] = 0
            } else {
                this[field] = ''
            }
        }
    }

    fieldReset() {
        for (let field in this.data()) {
            delete this[field]
        }
    }

}
