<template>
  <v-card>
    <v-card-title></v-card-title>
    <v-card-text v-if="!_.isEmpty(item)">
      <v-row>
        <v-col md="3" cols="12">
          name: {{ item.name }}
        </v-col>
        <v-col md="3" cols="12">
          code: {{ item.code }}
        </v-col>
        <v-col md="3" cols="12">
          purchase price: {{ item.buying_price }}
        </v-col>
        <v-col md="3" cols="12">
          selling price: {{ item.price }}
        </v-col>
        <v-col md="3" cols="12">
          brand: {{ item.brands.name }}
        </v-col>
        <v-col md="3" cols="12">
          type: {{ item.bom ? 'composite' : 'simple' }}
        </v-col>
        <v-col md="3" cols="12">
          categories: <span v-for="category in item.categories">{{ category.name }}, </span>
        </v-col>
        <v-col md="3" cols="12">
          base unit: {{ item.unit.key }}
        </v-col>
        <v-col md="3" cols="12">
          note: {{ item.note }}
        </v-col>
        <v-col md="3" cols="12">
          has part number: {{item.manufacture_part_number?'true':'false'}}
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props : {
    form_id: null
  },
  data() {
    return {
      item: {
        unit  : {},
        brands: {
          name: ''
        }
      }
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {

      axios.get('/api/inventory/products/' + this.form_id)
           .then(res => {
             this.item = res.data
           })
    }
  }
}
</script>

<style scoped>

</style>