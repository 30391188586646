export default {
    warehouse   : 'mdi-home-heart',
    product     : {
        product        : 'mdi-cube-scan',
        purchase_create: 'mdi-cart-arrow-down',
        sale_create    : 'mdi-cart-arrow-up',
        warranty_create: 'mdi-police-badge',
        damage_create  : 'mdi-image-broken-variant'
    },
    parts       : 'mdi-tools',
    brand       : 'mdi-label-multiple-outline',
    category    : 'mdi-file-tree',
    customer    : 'mdi-account-heart-outline',
    supplier    : 'mdi-nature-people',
    unit        : 'mdi-weight-kilogram',
    unit_mapping: 'mdi-lan-pending',
    quotation   : 'mdi-clipboard-list-outline',
    order       : 'mdi-bookmark-outline',
    expense     : 'mdi-currency-usd-off',
    income      : 'mdi-cash-usd-outline',
    payroll     : 'mdi-hand-heart',
    settings    : 'mdi-cog',
    report      : 'mdi-file-chart',
    utility     : 'mdi-timeline-help-outline',
    create      : 'mdi-plus',
    list        : 'mdi-table-of-contents',
    delete      : 'mdi-delete',
    edit        : 'mdi-pencil',
    return      : 'mdi-keyboard-return',
    google      : 'mdi-google',
    key         : 'mdi-key',
    email       : 'mdi-email',
    accountLock : 'mdi-account-lock',
    employee    : 'mdi-account-tie',
    department  : 'mdi-account-group',
    group       : 'mdi-group',
    user        : 'mdi-account',
    bank        : 'mdi-bank',
    ledger      : 'mdi-book-account',
    accounts    : 'mdi-calculator-variant',
    print       : 'mdi-printer',
    dense_vertical:'mdi-arrow-collapse-vertical',
    expand_vertical:'mdi-arrow-expand-vertical',
    expand:'',
    submitButton: {
        new  : 'mdi-plus',
        view : 'mdi-eye',
        print: 'mdi-printer'
    },
    reload      : 'mdi-reload',
    convert     : 'mdi-axis-z-arrow'
}