import _ from 'lodash'
import CustomerCreate from '../../../customer/create'
import PurchaseOrder from '../../purchase/order/create.vue'

export default {
    components: {
        'purchase-order' : PurchaseOrder,
        'customer-create': CustomerCreate,
    },
    props     : {
        modelId: {
            type   : Number,
            default: null
        },
        value  : {
            type   : Boolean,
            default: false
        },
    },
    data() {
        return {
            tab                  : null,
            order_no             : 0,
            units                : [],
            url                  : this.$getUrl('sale-orders', this.value, this.modelId),
            axiosMethod          : this.$getAxiosMethod(this.value, this.modelId),
            forms                : this.$root.$data.forms,
            items                : [],
            menu1                : false,
            menu2                : false,
            modal                : false,
            dialog               : false,
            orders               : {},
            drawer               : null,
            model10              : [],
            loading              : false,
            expanded             : [],
            settings             : [],
            products             : [],
            tooltipId            : "conversionTooltip",
            customers            : [],
            warehouses           : [],
            backOrders           : [],
            product_id           : '',
            productCard          : {},
            sale_profit          : false,
            extra_weight         : 0,
            payment_type         : 1,
            computedDate         : null,
            toBackOrder          : {},
            salePrintData        : null,
            searchProduct        : null,
            computedDate1        : null,
            customerModal        : false,
            payment_status       : 1,
            purchaseOrderModal   : false,
            profitPercentageField: false,
            headers              : [
                {text: '#', value: 'item_number', sortable: false},
                {text: 'product name', value: 'pname', sortable: false},
                {text: 'warehouse', value: 'warehouse', sortable: false},
                {text: 'unit', value: 'unit', sortable: false},
                {text: this.$store.state.quantity_label, value: 'quantity', sortable: false},
                {text: 'price', value: 'price', sortable: false},
                {text: 'discount', value: 'discount', sortable: false},
                {text: 'total', value: 'total', sortable: false},
                {text: 'weight T', value: 'weight_total', sortable: false},
                {text: 'action', value: 'action', sortable: false}
            ]
        }
    },
    beforeCreate() {
        this.$root.$data.forms.fieldReset();
    },
    watch     : {
        purchaseOrderModal(val) {
            if(!val) {
                this.toBackOrder = {}
            }
        },
        searchProduct(val) {
            if(val) {
                this.getProducts(val)
                val = null
            }
        },
        '$route.fullPath'(val) {
//reset data
            this.forms.reset()
            this.orders     = {}
            this.product_id = ''
            this.items      = []
        },
    },

    computed: {
        isLoaded() {
            return !_.isEmpty(this.warehouses) || !_.isEmpty(this.customers) || !_.isEmpty(this.products) || !_.isEmpty(this.units)
        },
        total() {
            let total         = 0
            let shipping_cost = 0
            let discount      = 0
            shipping_cost     = !this.forms.shipping_cost ? 0 : this.forms.shipping_cost
            if(this.items.length > 0) {
                for (let i = 0; i < this.items.length; i++) {
                    total += this.items[i].price
                }
            }
            total    = parseFloat(total) + parseFloat(shipping_cost)
            discount = this.forms.overall_discount ? total * parseFloat(this.forms.overall_discount) / 100 : 0
            total -= discount
            this.getTotalWeight()
            return total.toFixed(3)
        },
    },
    created() {
        this.getData()
    },
    methods : {
        getData() {
            this.$set(this.forms, 'total_weight', 0)
            this.loading = true
            this.$root.$data.erp.request.get(this.url, this, (data) => {
                this.settings          = data.settings
                this.customers         = data.customers
                this.products          = data.products
                this.warehouses        = data.warehouses
                this.units             = data.units
                this.forms.customer_id = this.$root.getDefault_id(this.customers)
                this.order_no          = data.latest_so
                this.$set(this.forms, 'order_date', new Date())
                if(this.axiosMethod === 'patch') {
                    this.orders           = data.order
                    this.forms.order_date = moment(this.orders.order_date, this.$root.settings.settings.date_format).format("YYYY-MM-DD")
                    this.$root.$set(this.forms, 'overall_discount', this.orders.overall_discount);
                    this.$root.$set(this.forms, 'shipping_cost', this.orders.shipping_cost);
                    this.forms.customer_id       = parseInt(this.orders.customer_id)
                    this.forms.customer_order_no = this.orders.customer_order_no
                    this.order_no                = this.orders.order_no

                    if(this.orders.expected_shipping_date) {
                        this.forms.expected_shipping_date = moment(this.orders.expected_shipping_date, this.$root.settings.settings.date_format + " HH:mm:ss").format("YYYY-MM-DD HH:mm:ss")
                        this.computedDate1                = moment(this.forms.expected_shipping_date, "YYYY-MM-DD HH:mm:ss").format(this.$root.settings.settings.date_format + " HH:mm:ss")
                    }

                    this.forms.note = this.orders.note
                    for (let i = 0; i < this.orders.products.length; i++) {
                        let product = this.orders.products[i];
                        this.items.push({
                                            product_id    : product.id,
                                            pname         : product.name,
                                            unit_price    : parseFloat(product.pivot.price).toFixed(4),
                                            warehouse     : parseInt(product.pivot.warehouse_id),
                                            unit          : parseInt(product.pivot.unit_id),
                                            units         : product.units,
                                            discount      : parseFloat(product.pivot.discount).toFixed(4),
                                            quantity      : parseFloat(product.pivot.quantity).toFixed(4),
                                            fromUnit      : product.pivot.unit_id,
                                            baseUnit      : product.base_unit_id,
                                            price         : 0,
                                            warehouses    : product.warehouses,
                                            productInStock: this.getAvailableProductCount(product.warehouses),
                                            weight        : product.pivot.weight,
                                            weight_total  : product.pivot.weight_total
                                        });
                    }
                    if((!_.has(data, 'order'))) {
                        Swal.fire({
                                      timer            : 3000,
                                      icon             : data.type,
                                      text             : data.message,
                                      showCancelButton : false,
                                      showConfirmButton: false,
                                  })
                            .then((result) => {
                                this.$router.push({name: 'saleOrder.index'})
                            })
                    }
                    this.getTotalWeight()
                    this.extra_weight = this.orders.total_weight - this.forms.total_weight
                }
                this.loading = false
            });
        },
        remainingQuantity(product, item) {
            let remainingQuantity = 0;
            product.warehouse     = _.find(product.product.warehouses, {
                id: product.warehouse_id,
            });

            if(!item.quantity) {
                return product.warehouse.pivot.quantity
            }

            return Number(
                _.find(product.product.warehouses, {id: product.warehouse_id}).pivot.quantity
            ) - Number(item.quantity) * Number(product.quantity)

        },
        fromBackOrder(val) {
            if(val) {
                this.backOrders.push(val)
                this.purchaseOrderModal = false
            }
        },
        viewProduct(product) {
            this.productCard = {}
            this.productCard = product
            if(!_.isEmpty(this.productCard)) {
                this.drawer = !this.drawer
            }
        },
        backorderBom(bom, item) {
            if(!_.isEmpty(bom)) {
                this.toBackOrder.product_code = bom.product.code
                this.toBackOrder.unit         = bom.unit_id
                this.toBackOrder.warehouse    = bom.warehouse_id
                this.toBackOrder.quantity     = this.remainingQuantity(bom, item) < 0 ? Number(-this.remainingQuantity(bom, item)) : 0
                this.purchaseOrderModal       = true
            }
        },
        backorder(item) {
// product_code, quantity, unit, warehouse
            if(!_.isEmpty(item)) {
                this.toBackOrder.product_code = item.product_code
                this.toBackOrder.quantity     = item.quantity
                this.toBackOrder.unit         = item.unit
                this.toBackOrder.warehouse    = item.warehouse
                this.purchaseOrderModal       = true
            }
        },
        checkQuantity(warehouse_id, item) {
            if(!warehouse_id) return 0
            let value = item.warehouses.filter(val => {
                return val.id === warehouse_id
            })[0]

            if(value.pivot === undefined) {
                return item.remaining_quantity = 0
            }

            let warehouseQuantity   = value.pivot.quantity !== undefined ? value.pivot.quantity : 0
            item.base_unit          = value.pivot.unit_id
            item.remaining_quantity = warehouseQuantity

            if(item.unit === item.base_unit && item.quantity) {
                item.remaining_quantity = item.remaining_quantity - item.quantity
            }

            if(item.unit !== item.base_unit) {
                item.remaining_quantity = item.remaining_quantity / item.conversion_factor
                if(item.quantity) {
                    item.remaining_quantity = item.remaining_quantity - item.quantity
                }
            }
            return item.remaining_quantity
        },
        getTotalWeight() {
            this.forms.total_weight = 0
            this.items.forEach(item => {
                if(!item.weight_total) {
                    item.weight_total = 0
                }
                this.forms.total_weight += Number(parseFloat(item.weight_total).toFixed(3))
            })
        },
        findWeight(product_id) {
            let product = this.getProduct(product_id)
            let units   = product.units
            if(units.length > 0) {
                let weight = units.find((unit) => {
                    return unit.id === product.base_unit_id
                }).pivot.weight
                return weight ? weight : 0;
            } else {
                return 0;
            }
// console.log(_.find(this.getProduct(product_id).units, { id: this.getProduct(product_id).base_unit_id
// }));
// return _.find(this.getProduct(this.product_id).units, { id:
// this.getProduct(this.product_id).base_unit_id }).pivot.weight
        },
        itemTotalWeight(index) {
            this.items[index].weight_total = this.items[index].quantity
                ? this.items[index].weight * this.items[index].quantity
                : 0

            return parseFloat(this.items[index].weight_total).toFixed(3)
        },
        postOrder() {
            this.loading          = true
            this.forms.items      = this.items
            this.forms.total      = this.total
            this.forms.backorders = this.backOrders
            this.forms.order_no   = this.order_no
            this.forms.order_date = moment(this.forms.order_date).format("YYYY-MM-DD HH:mm:ss")
            if(this.forms.expected_shipping_date) {
                this.forms.expected_shipping_date = moment(this.forms.expected_shipping_date).format("YYYY-MM-DD HH:mm:ss")
            }
            this.forms.extra_weight = this.extra_weight
            this.forms.submit(
                this.axiosMethod,
                this.$getUrlForm(this.url),
                false,
                this.$root
            )
                .then(data => {
                    this.forms.order_date        = moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
                    this.items                   = []
                    this.loading                 = false
                    this.forms.customer_order_no = ''
                    Swal.fire({
                                  timer            : 3000,
                                  icon             : data.type,
                                  text             : data.message,
                                  showCancelButton : false,
                                  showConfirmButton: false,
                              })
                        .then((result) => {
                            if(!this.value) {
                                if(this.axiosMethod === 'patch') {
                                    this.$router.push({name: 'sale.order.index'})
                                } else {
                                    this.getData()
                                }
                            }

                            if(this.value) {
                                this.$emit('input', false)
                            }
                        })
                })
                .catch(error => {
                    this.loading = false
                });


        },
// product Sale section
        addProduct() {
            if(!this.product_id) return null;
            this.items.push({
                                purchase_price: this.getProduct(this.product_id).buying_price,
                                product_code  : this.getProduct(this.product_id).code,
                                product_id    : this.product_id,
                                pname         : this.getProduct(this.product_id).name,
                                unit_price    : parseFloat(this.getProduct(this.product_id).price).toFixed(4),
                                price         : 0,
                                warehouses    : this.getWarehouse(this.getProduct(this.product_id).warehouses),
                                warehouse     : null,
                                productInStock: this.getAvailableProductCount(this.getProduct(this.product_id).warehouses),
                                fromUnit      : this.getProduct(this.product_id).base_unit_id,
                                baseUnit      : this.getProduct(this.product_id).base_unit_id,
                                units         : this.getProduct(this.product_id).units,
                                unit          : this.$root.getPrimary_id(this.getProduct(this.product_id).units),
                                weight        : this.findWeight(this.product_id),
                                bom           : _.filter(this.products, {id: this.product_id})[0].bom
                            })
            if(this.items.length > 0) {
                this.product_id    = null
                this.searchProduct = null
            }
        },
        getWarehouse(item) {
            return _.isEmpty(item) ? this.warehouses : item;
        },
        select_default_warehouse(warehouses) {
            if(_.isEmpty(warehouses)) {
                return this.$root.getDefault_id(this.warehouses)
            }
            let datas = null
            warehouses.filter(data => {
                if(data.is_default) {
                    datas = data.id
                }
            })
            return !datas ? warehouses[0].id : datas;
        },
        uniqueItem(id) {
            return this.items.find((item) => {
                return item.product_id === id
            })
        },

        getProduct(id) {
            return this.products.find((product) => {
                return product.id === this.product_id
            })
        },
        getProductById(id) {
            return this.products.find((product) => {
                return product.id === id
            })
        },
        removeProduct(index) {
            this.items.splice(index, 1);
        },
        getTotalPrice(item) {
            let totalPrice = 0
            if(isNaN(item.quantity)) {
                return 'total: ' + totalPrice
                item.price = totalPrice
            } else {
                totalPrice = isNaN(item.discount)
                    ? (item.quantity * item.unit_price)
                    : (item.quantity * item.unit_price) - item.discount;

                item.price = totalPrice
                return 'total: ' + totalPrice
            }
        },
        getPrice(unit, index) {
            let fromUnit               = this.items[index].fromUnit
            this.items[index].fromUnit = unit
            if(fromUnit === unit) {
                return
            }

            let url = '/api/inventory/unitconversions/' + unit + '/' + fromUnit + '/1'
            axios.post(url)
                 .then(response => {
                     let conversion                      = response.data.conversion
                     this.items[index].conversion_factor = conversion.conversion_factor
                     this.items[index].unit_price        = parseFloat(response.data.quantity * this.items[index].unit_price).toFixed(3)

                 })
        },
        getAvailableProductCount(warehouses) {
            let quantity = 0
            for (var i = 0; i < warehouses.length; i++) {
                let warehouse = warehouses[i]
                quantity += Number(parseFloat(warehouse.pivot.quantity).toFixed(3))
            }
            return quantity
        },
        getNewCustomer(customerDetails) {
            this.forms.order_date     = moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
            this.forms.payment_status = 3
            this.forms.status         = 1
            this.customers.push(customerDetails);
            this.forms.customer_id = customerDetails.id;
            this.dialog            = false
        },
        onCancel() {
            this.$router.push({name: "sale.index"})
        },
        getProducts: _.debounce(function (query) {
            if(query !== '') {
                axios.post('/api/inventory/products/getProducts', {val: query, isPurchase: true})
                     .then(res => {
                         this.products = res.data.products
                         if(this.products.length === 1) {
                             this.product_id = this.products[0].id
                             this.addProduct()
                             this.searchProduct = null
                         }
                         this.loading = false
                     })
                     .catch(error => {
                         alert(error.message)
                     })
            } else {
                this.loading  = true;
                this.products = []
                return null
            }
        }, 1000),
// getProducts(query) {
//     if (query !== '') {
//         setTimeout(() => {
//             axios.post('/api/inventory/products/getProducts', {val: query, isSale: true})
//                 .then(response => {
//                     this.products = response.data.products
//                     this.loading = false;
//                 })
//                 .catch(error => {
//                     alert(error.message)
//                 })
//         }, 200);
//     } else {
//         this.loading = true;
//         this.products = []
//         return null
//     }
// },
        getInputField(event) {
            this.profitPercentageField = event.target.checked;
        },
        initPreview() {
            Erp.salePurchase.initialPreviewSetup(this)
        },
        closeDatePicker() {
            let currentTime       = moment(new Date()).format("HH:mm:ss")
            let orderDate         = this.forms.order_date
            let selectedDate      = moment(orderDate).format(this.$root.settings.settings.date_format)
// console.log(selectedDate + ' ' + currentTime)
            this.computedDate     = moment(selectedDate + ' ' + currentTime, this.$root.settings.settings.date_format + " HH:mm:ss").format(this.$root.settings.settings.date_format + " HH:mm:ss")
            this.forms.order_date = moment(this.forms.order_date + ' ' + currentTime).format("YYYY-MM-DD HH:mm:ss")
            this.menu1            = false
        },
        closeDatePicker1() {
            let currentTime                   = moment(new Date()).format("HH:mm:ss")
            let expected_shipping_date        = this.forms.expected_shipping_date
            let selectedDate                  = moment(expected_shipping_date).format(this.$root.settings.settings.date_format)
// console.log(selectedDate + ' ' + currentTime)
            this.computedDate1                = moment(selectedDate + ' ' + currentTime, this.$root.settings.settings.date_format + " HH:mm:ss").format(this.$root.settings.settings.date_format + " HH:mm:ss")
            this.forms.expected_shipping_date = moment(this.forms.expected_shipping_date + ' ' + currentTime).format("YYYY-MM-DD HH:mm:ss")
            this.menu1                        = false
        }
    }
}