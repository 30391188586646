module.exports = [
    {
        path: '/inventory/tables',
        name: 'sale.table',
        component: require('../views/inventory/resturant/table/salestable.vue').default
    },
    {
        path: '/inventory/sales/index',
        name: 'sale.index',
        component: require('../views/inventory/product/sales/index.vue').default
    },
    {
        path:'/inventory/sales/create',
        name:'sale.create',
        component: require('../views/inventory/product/sales/create.vue').default
    },
    {
        path: '/inventory/sales/:id',
        name: 'sale.show',
        component: require('../views/inventory/product/sales/show.vue').default
    },
    {
        path: '/inventory/sales/:id/edit',
        name: 'sale.edit',
        component: require('../views/inventory/product/sales/create.vue').default
    },
    {
        path: '/inventory/sales/:id/return',
        name: 'sale.return',
        component: require('../views/inventory/product/sales/salesReturn.vue').default
    },
    {
        path:'/inventory/sale-quotations/create',
        name:'saleQuotation.create',
        component: require('../views/inventory/product/sales/quotation/create.vue').default
    },
    {
        path:'/inventory/sale-quotations/index',
        name:'saleQuotation.index',
        component: require('../views/inventory/product/sales/quotation/index.vue').default
    },
    {
        path:'/inventory/sale-quotations/:id',
        name:'saleQuotation.show',
        component: require('../views/inventory/product/sales/quotation/show.vue').default
    },
    {
        path:'/inventory/sale-quotations/:id/edit',
        name:'saleQuotation.edit',
        component: require('../views/inventory/product/sales/quotation/create.vue').default
    },
    {
        path:'/inventory/sale-quotations-convert/:id',
        name:'saleQuotation.convert',
        component: require('../views/inventory/product/sales/quotation/convert.vue').default
    },
    {
        path:'/inventory/sale-orders/create',
        name:'sale.order.create',
        component: require('../views/inventory/product/sales/order/create.vue').default
    },
    {
        path:'/inventory/sale-orders/:id/edit',
        name:'sale.order.edit',
        component: require('../views/inventory/product/sales/order/create.vue').default
    },
    {
        path:'/inventory/sale-orders/index',
        name:'sale.order.index',
        component: require('../views/inventory/product/sales/order/index.vue').default
    },
    {
        path:'/inventory/sale-orders/:id',
        name:'sale.order.show',
        component: require('../views/inventory/product/sales/order/show.vue').default
    },
    {
        path:'/inventory/sale-orders-full/:id',
        name:'sale.order.full',
        component: require('../views/inventory/product/sales/order/full-show.vue').default
    },
    {
        path:'/inventory/sale-orders-convert/:id',
        name:'sale.order.convert',
        component: require('../views/inventory/product/sales/order/convert.vue').default
    },
    {
        path:'/inventory/sale-orders-shipment',
        name:'sale.order.shipment',
        component: require('../views/inventory/product/sales/order/shipment.vue').default
    }
];