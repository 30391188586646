import FormActions from '~/form-actions.vue'

export default {
    components: {
        FormActions
    },
    props     : {
        modelId: {
            type   : Number,
            default: null
        },
        value  : {
            type   : Boolean,
            default: false
        },
    },
    data() {
        return {
            banks             : [],
            url               : this.$getUrl('salary', this.value, this.modelId, 'payroll'),
            axiosMethod       : this.$getAxiosMethod(this.value, this.modelId),
            forms             : {
                amount        : 0,
                festival_bonus: 0,
                payment_method: 1,
                other_bonus   : 0,
                deduction     : 0,
                errors        : {}
            },
            modal             : false,
            menu1             : false,
            menu2             : false,
            salary            : [],
            loading           : false,
            employees         : [],
            payment_types     : ['cash'],
            computedSalaryDate: null,
        }
    },

    watch: {
        '$route.fullPath'(val) {
            this.salary = []
        }
    },


    computed: {
        totalAmount() {
            let amount = 0
            amount     = this.$root.ownParse(this.forms.amount) +
                this.$root.ownParse(this.forms.festival_bonus) +
                this.$root.ownParse(this.forms.other_bonus) -
                this.$root.ownParse(this.forms.deduction)
            return amount ? amount.toFixed(2) : 0
        },
    },

    created() {
        this.forms.salary_date  = moment(new Date()).format('YYYY-MM-DD')
        this.computedSalaryDate = moment(this.forms.salary_date, "YYYY-MM-DD").format(this.$root.settings.settings.date_format)

        this.forms.salary_month   = moment(new Date()).format('YYYY-MM')
        this.forms.payment_method = 1
        this.loading              = true
        axios.get(this.url)
             .then(res => {
                 this.employees = res.data.employees
                 this.banks     = res.data.banks
                 if(this.axiosMethod === 'patch') {
                     this.salary               = res.data.salary
                     this.forms.id             = res.data.salary.id
                     this.forms.salary_date    = moment(res.data.salary.salary_date, this.$root.settings.settings.date_format).format("YYYY-MM-DD")
                     this.computedSalaryDate   = moment(this.forms.salary_date, "YYYY-MM-DD").format(this.$root.settings.settings.date_format)
                     this.forms.employee_id    = res.data.salary.employee_id
                     this.forms.payment_method = res.data.salary.payment_method
                     this.forms.salary_month   = res.data.salary.salary_month ? moment(new Date(res.data.salary.salary_month)).format('YYYY-MM') : ''
                     this.forms.festival_bonus = res.data.salary.festival_bonus
                     this.forms.other_bonus    = res.data.salary.other_bonus
                     this.forms.deduction      = res.data.salary.deduction
                     this.forms.note           = res.data.salary.note
                     this.forms.amount         = res.data.salary.current_salary
                 }
                 this.loading = false
             })
        // this.$root.$data.erp.request.get(url, this, (data) => {
        //     this.employees = data.employees
        //     this.banks     = data.banks
        //     if(this.method === 'Edit') {
        //         this.salary   = data.salary
        //         this.forms.id = this.salary.id
        //         // this.forms.salary_date    = this.salary.salary_date ? moment(new
        //         // Date(this.salary.salary_date)).format('YYYY-MM-DD') : ''
        //         this.$set(this.forms, 'amount', parseFloat(this.salary.amount).toFixed(2))
        //         this.$set(this.forms, 'festival_bonus', parseFloat(this.salary.festival_bonus).toFixed(2))
        //         this.$set(this.forms, 'other_bonus', parseFloat(this.salary.other_bonus).toFixed(2))
        //         this.$set(this.forms, 'deduction', parseFloat(this.salary.deduction).toFixed(2))
        //         this.forms.note = this.salary.note
        //     }
        // });

    },

    methods: {
        postSalary($e) {
            let submitter           = $e.submitter.value
            this.forms.errors       = {}
            this.loading            = true
            this.forms.salary_date  = this.forms.salary_date ?
                moment(this.forms.salary_date).format("YYYY-MM-DD HH:mm:ss") : ''
            this.forms.salary_month = this.forms.salary_month ?
                moment(this.forms.salary_month).format("YYYY-MM-DD") : ''


            axios
                [this.axiosMethod](
                this.$getUrlForm(this.url),
                this.forms)
                .then(res => {
                    if(this.value) {
                        if(submitter === 'submit-close' || submitter === 'update-close') {
                            this.$emit('input', false)
                        }
                        this.onCancel()
                    }
                    this.$axiosPostDialog(res.data)
                })
                .catch(error => {
                    this.loading = false;
                    if(error.response && error.response.status === 422) {
                        let err = error.response.data.errors;
                        Object.keys(err).forEach((key) => {
                            this.forms.errors[key] = err[key][0];
                        });
                    }
                })
            // this.forms.submit(requestMethod, url, true, this.$root)
            //     .then(data => {
            //         this.loading = false
            //         Swal.fire({
            //                       icon             : data.type,
            //                       timer            : 2000,
            //                       text             : data.message,
            //                       showCancelButton : false,
            //                       showConfirmButton: false,
            //                   })
            //         this.$router.push({name: 'payroll.salary.index'})
            //     }).catch(err => {
            //     this.loading = false
            // })
        },
        onCancel() {
            this.reset()
        },
        reset() {
            this.forms = {
                amount        : 0,
                festival_bonus: 0,
                payment_method: 1,
                other_bonus   : 0,
                deduction     : 0,
                errors        : {}
            }
        },
        getSalary() {
            let currentEmployee = this.employees.filter(data => this.forms.employee_id === data.id)
            this.$set(this.forms, 'amount', parseInt(currentEmployee.length ? currentEmployee[0].salary : 0))
            this.$set(this.forms, 'current_salary', parseInt(currentEmployee.length ? currentEmployee[0].salary : 0))
        },
        closeDatePicker() {
            let currentTime         = moment(new Date()).format("HH:mm:ss")
            let salary_date         = this.forms.salary_date
            let selectedDate        = moment(salary_date).format(this.$root.settings.settings.date_format)
            this.computedSalaryDate = moment(selectedDate + ' ' + currentTime, this.$root.settings.settings.date_format).format(this.$root.settings.settings.date_format)
            this.forms.salary_date  = moment(this.forms.salary_date).format("YYYY-MM-DD")
            this.menu1              = false
        }
    }
}