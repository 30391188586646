<template>
  <div>
    <v-card>
      <v-card-title>
        Quotation Ledger PQ-{{ quotations.id }}
        <v-spacer></v-spacer>
        <v-btn
            fab
            icon
            text
            small
            color="success"
            v-if="!quotations.purchase_id"
            :to="{name:'purchaseQuotation.edit',param:{id:quotations.id}}"
        >
          <v-icon>edit</v-icon>
        </v-btn>
        <v-btn
            fab
            icon
            text
            small
            color="success"
            v-if="!quotations.purchase_id"
            :to="{name:'purchaseQuotation.convert',param:{id:quotations.id}}"
        >
          <v-icon>mdi-play</v-icon>
        </v-btn>
        <v-btn
            fab
            text
            small
            color="primary"
            v-if="quotations.purchase_id"
            :to="{name:'purchase.show',params:{id:quotations.purchase_id}}"
        >
          <v-icon
          >
            mdi-eye
          </v-icon>
        </v-btn>
        <v-btn class="float-right"
               icon
               @click="$htmlToPaper('quotation_print')"
        >
          <v-icon>mdi-printer</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col md="4" cols="12">
            <p class="text-subtitle-1">Customer Information</p>
            <p>name: {{ quotations.supplier.name }}</p>
            <p>email: {{ quotations.supplier.email }}</p>
            <p v-if="quotations.supplier.phone">phone: {{ quotations.supplier.phone }}</p>
            <p v-if="quotations.supplier.address">address: {{ quotations.supplier.address }}</p>
          </v-col>
          <v-col md="4" cols="12">
            <p class="text-subtitle-1">Shop Information</p>
            <p>Company name: {{ company.name }}</p>
            <p>Address Detail: {{ company.address1 }}</p>
            <p>
              mobile number:
              <span v-for="(phone, index) in company.contact_phone">
                            {{ phone }}{{ company.contact_phone.length - 1 == index ? '' : ',' }}
                        </span>
            </p>
          </v-col>
          <v-col md="4" cols="12">
            <p class="text-subtitle-1">Quotations Information</p>
            <p>Quotation no: PQ-{{ quotations.id }}</p>
            <p>Quotations Date: {{ quotations.quotation_date | removeTimeFromDate }}</p>
            <p>created By: {{ quotations.created_by.name }}</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12" cols="12">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th>No.</th>
                  <th>Code</th>
                  <th>Name</th>
                  <th>{{ $store.state.quantity_label }}</th>
                  <th>Unit Cost</th>
                  <th>Discount</th>
                  <th>Total</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(product, index) in quotations.products" :index="index">
                  <td>
                    {{ index + 1 }}
                  </td>
                  <td>{{ product.code }}</td>
                  <td>{{ product.name }}</td>
                  <td>{{ parseFloat(product.pivot.quantity).toFixed(4) }}
                    {{ product.units | findFromArrayById(product.pivot.unit_id, 'key') }}
                  </td>
                  <td>{{ product.pivot.price }}</td>
                  <td>{{ product.pivot.discount }}</td>
                  <td>{{ product.pivot.subtotal }}</td>
                </tr>
                </tbody>
                <tfoot>
                <tr class="strong">
                  <td colspan="6" align="right">
                    Product Total
                  </td>
                  <td colspan="2">
                    {{ product_total }}
                  </td>
                </tr>
                <tr class="strong">
                  <td colspan="6" class="text-right">{{ $root.shippingLabel }}</td>
                  <td>{{ quotations.shipping_cost }}</td>
                </tr>
                <tr>
                  <td colspan="6" class="text-right">
                    Sub Total
                  </td>
                  <td>
                    {{ sub_total }}
                  </td>
                </tr>

                <tr class="strong">
                  <td colspan="6" class="text-right">Overall Discount</td>
                  <td v-if="quotations.overall_discount">{{ quotations.overall_discount }}%({{ discounted_amount }})</td>
                  <td v-else>0</td>
                </tr>

                <tr class="strong">
                  <td colspan="6" class="text-right">Total</td>
                  <td>{{ quotations.total }}</td>
                </tr>
                </tfoot>
              </template>
            </v-simple-table>
          </v-col>
          <v-col md="6" cols="12" v-if="quotations.note">
            Note: {{ quotations.note }}
          </v-col>
          <v-col md="6" cols="12"></v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <quotation-print
        :quotations="quotations"
        :company="company"
        style="visibility: collapse"
    ></quotation-print>
  </div>
</template>
<script>
import QuotationPrint from './quotation-print.vue'
export default {
  components:{
    QuotationPrint
  },
  data() {
    return {
      quotations: {
        ref       : '',
        created_by: {},
        supplier  : {}
      },
      company   : {},
    }
  },
  computed  : {
    product_total() {
      if(!_.isEmpty(this.quotations) && !_.isEmpty(this.quotations.products)) {
        return this.quotations.products.reduce((acc, obj) => {
          return acc + obj.pivot.subtotal
        }, 0)
      }
      return 0
    },
    sub_total() {
      return this.product_total + this.quotations.shipping_cost
    },
    discounted_amount() {
      return (this.sub_total * (this.quotations.overall_discount / 100)).toFixed(2)
    }
  },
  created() {
    this.$root.$data.erp.request.get('/api' + window.location.pathname, this, (data) => {
      this.quotations = data.quotations
      this.company    = data.company
    });
  }
}

</script>