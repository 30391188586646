<template>
  <v-autocomplete
      solo
      v-model="user"
      :items="users"
      item-text="name"
      item-value="id"
      v-bind:dense="dense"
      v-bind:clearable="clearable"
      :search-input.sync="searchUser"
      v-bind:return-object="returnObject"
      :label="`${'please type ' + moduleType + ' name, or id'}`"
  >
    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title v-text="item.name"/>
        <v-list-item-subtitle v-text="item.email"/>
      </v-list-item-content>
      <v-list-item-action-text>
<!--        <v-list-item-title v-text="item.company" v-if="moduleType==='supplier'"/>-->
<!--        <v-list-item-subtitle v-text="item.phone"/>-->
      </v-list-item-action-text>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name : "user-dropdown-autocomplete",
  props: {
    label       : {
      type   : String,
      default: 'name_id'
    },
    moduleType  : {
      type    : String,
      required: true,
      enum    : ['customer', 'supplier', 'otheruser', 'employee'],
      default : 'customer'
    },
    dense       : {
      type   : Boolean,
      default: false
    },
    clearable   : {
      type   : Boolean,
      default: false
    },
    returnObject: {
      type   : Boolean,
      default: false
    },
    gate_type   : {
      type   : String,
      enum   : ['or', 'and'],
      default: 'or'
    },
    columns     : {
      type    : Array,
      required: true,
      default : ['name']
    }
  },
  data() {
    return {
      user      : {},
      users     : [],
      searchUser: null
    }
  },

  watch  : {
    searchUser(val) {
      val && val !== this.user && this.query(val)
    },
    user(val) {
      this.$emit('input', val)
      this.$emit('changeAutocompleteDropdown', val)
    },
  },
  methods: {
    check(val){
      console.log(val)
    },
    query: _.debounce(function (val) {
      if(val !== '') {
        axios.get('/api/get_any_user_by_dynamic_column_and_model', {
          params: {
            columns     : this.columns,
            model_type  : this.moduleType,
            gate_type   : this.gate_type, // this could 'and' or 'or'
            query_string: val
          }
        })
             .then(res => {
               this.users = res.data
             })
      }
    }, 400)
  }
}
</script>