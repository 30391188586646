<template>
  <div>
    <v-row v-if="!_.isEmpty(item)" class="text-capitalize">
      <v-col md="2" cols="12">Product Name: {{ item.product_name }}</v-col>
      <v-col md="2" cols="12">Unit Name: {{ item.unit_key }}</v-col>
      <v-col md="1" cols="12">Quantity: {{ item.quantity }}</v-col>
      <v-col md="1" cols="12">Warehouse In: {{ item.warehouse_name }}</v-col>
      <v-col md="2" cols="12">material Cost per unit: {{ item.mcpu }}</v-col>
      <v-col md="2" cols="12">expense total: {{ item.expense_total }}</v-col>
      <v-col md="2" cols="12">Total Cost: {{ item.total_cost }}</v-col>
    </v-row>
    <hr />
    <v-row>
      <v-col
        md="8"
        cols="12"
        v-if="
          item.material_detail !== undefined && !_.isEmpty(item.material_detail)
        "
      >
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <td>name</td>
                <td>Warehouse</td>
                <td>quantity</td>
                <td>Total Price</td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(product, index) in item.material_detail" :key="index">
                <td>{{ product.product.name }}</td>
                <td>{{ product.warehouse.name }}</td>
                <td>
                  {{ product.quantity | tofix(2) }} {{ product.unit.key }}
                </td>
                <td>
                  {{ product.product | totalProductPrice(product.quantity) }}
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="3" class="text-right text-capitalize">
                  total material cost/unit:
                </td>
                <td>{{ item.mcpu }}</td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </v-col>
      <v-col
        md="4"
        cols="12"
        v-if="
          item.other_expenses !== undefined && !_.isEmpty(item.other_expenses)
        "
      >
        <v-card-title primary-title outline>
          Estimated Expense Details
        </v-card-title>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th>label</th>
                <th>value</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(bom, index) in item.other_expenses"
                :key="'b' + index"
              >
                <td>{{ bom.label }}</td>
                <td>{{ bom.value }}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td class="text-right">Total Expenses:</td>
                <td>{{ item.expense_total }}</td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: ["item"],
  methods: {},
};
</script>