var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("\n      Warehouses\n      "),_c('v-spacer'),_vm._v(" "),_c('back-button'),_vm._v(" "),(_vm.$options.filters.checkPermission(['warehouse', 'create']))?_c('tooltip-button',{attrs:{"icon":"","dark":"","color":"primary","position":"bottom","data-text":"Create Warehouse","icon-name":_vm.$root.appIcons.create},on:{"click":function($event){_vm.createOrUpdateDialog = true}}}):_vm._e(),_vm._v(" "),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$htmlToPaper('list_print_bootstrap')}}},[_c('v-icon',[_vm._v("mdi-printer")])],1)],1),_vm._v(" "),(_vm.$options.filters.checkPermission(['warehouse', 'index']))?_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"loading":_vm.loading,"items":_vm.items.data,"options":_vm.options,"server-items-length":_vm.items.total,"loading-text":"Loading... Please wait","footer-props":{
          itemsPerPageOptions: _vm.$store.state.itemsPerPageOptions,
        }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header.code",fn:function({ header }){return [_c('v-text-field',{attrs:{"solo":"","dense":"","clearable":"","label":"code","hide-details":""},on:{"click":function($event){$event.stopPropagation();}},model:{value:(_vm.options.code),callback:function ($$v) {_vm.$set(_vm.options, "code", $$v)},expression:"options.code"}})]}},{key:"header.name",fn:function({ header }){return [_c('v-text-field',{attrs:{"solo":"","dense":"","clearable":"","label":"name","hide-details":""},on:{"click":function($event){$event.stopPropagation();}},model:{value:(_vm.options.name),callback:function ($$v) {_vm.$set(_vm.options, "name", $$v)},expression:"options.name"}})]}},{key:`item.is_default`,fn:function({ item }){return [_vm._v("\n          "+_vm._s(_vm._f("booleanToString")(item.is_default))+"\n        ")]}},(
            _vm.$options.filters.checkPermission(['warehouse', 'edit']) ||
            _vm.$options.filters.checkPermission(['warehouse', 'delete'])
          )?{key:"item.action",fn:function({ item }){return [_c('v-menu',{attrs:{"top":"","close-on-content-click":_vm.closeOnContentClick},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"dark":"","x-small":"","color":"primary"}},on),[_vm._v(" action ")])]}}],null,true)},[_vm._v(" "),_c('v-list',[_c('v-list-item',[_c('tooltip-button',{attrs:{"icon":true,"size":"small","color":"success","data-text":"Parts Location","icon-name":"mdi-package-variant"},on:{"click":function($event){(_vm.edit_id=item.id), (_vm.showProductsLocationListDialog=true)}}})],1),_vm._v(" "),_c('v-list-item',[_c('tooltip-button',{attrs:{"icon":true,"size":"small","color":"primary","data-text":"View","icon-name":"mdi-eye","to":{ name: 'warehouse.show', params: { id: item.id } }}})],1),_vm._v(" "),(_vm.$options.filters.checkPermission(['warehouse', 'edit']))?_c('v-list-item',[_c('tooltip-button',{attrs:{"icon":true,"size":"small","color":"primary","icon-name":"edit","data-text":"Edit"},on:{"click":function($event){(_vm.edit_id = item.id), (_vm.createOrUpdateDialog = true)}}})],1):_vm._e(),_vm._v(" "),(
                  _vm.$options.filters.checkPermission(['warehouse', 'delete'])
                )?_c('v-list-item',[_c('tooltip-button',{attrs:{"icon":true,"size":"small","color":"red","icon-name":"delete","data-text":"Delete"},on:{"click":function($event){return _vm.deleteItem(item.id)}}})],1):_vm._e(),_vm._v(" "),(_vm.$options.filters.checkPermission(['warehouse', 'edit']))?_c('v-list-item',[_c('tooltip-button',{attrs:{"icon":true,"size":"small","color":"primary","icon-name":"mdi-tools","data-text":"Config"},on:{"click":function($event){(_vm.edit_id = item.id), (_vm.warehouseConfigDialog = true)}}})],1):_vm._e()],1)],1)]}}:null],null,true)})],1):_vm._e()],1),_vm._v(" "),(_vm.$options.filters.checkPermission(['warehouse', 'index']))?_c('list-print-bootstrap',{staticStyle:{"visibility":"collapse"},attrs:{"title":'warehouse List',"columns":_vm.headers,"rows":_vm.items.data}}):_vm._e(),_vm._v(" "),_c('v-dialog',{attrs:{"width":"1200"},model:{value:(_vm.createOrUpdateDialog),callback:function ($$v) {_vm.createOrUpdateDialog=$$v},expression:"createOrUpdateDialog"}},[(_vm.createOrUpdateDialog)?_c('warehouse-create',{attrs:{"model-id":_vm.edit_id},model:{value:(_vm.createOrUpdateDialog),callback:function ($$v) {_vm.createOrUpdateDialog=$$v},expression:"createOrUpdateDialog"}}):_vm._e()],1),_vm._v(" "),(_vm.warehouseConfigDialog)?_c('warehouse-configure',{attrs:{"warehouse_id":_vm.edit_id},model:{value:(_vm.warehouseConfigDialog),callback:function ($$v) {_vm.warehouseConfigDialog=$$v},expression:"warehouseConfigDialog"}}):_vm._e(),_vm._v(" "),(_vm.showProductsLocationListDialog)?_c('v-dialog',{attrs:{"width":"900"},model:{value:(_vm.showProductsLocationListDialog),callback:function ($$v) {_vm.showProductsLocationListDialog=$$v},expression:"showProductsLocationListDialog"}},[_c('all-products-in-a-warehouse',{attrs:{"warehouse-id":_vm.edit_id}})],1):_vm._e(),_vm._v(" "),(!_vm.$options.filters.checkPermission(['warehouse', 'index']))?_c('unauthorize-message'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }