export default {
    props   : ['transferData', 'dialogModal'],
    data() {
        return {
            menu        : false,
            dialog      : this.dialogModal,
            banks       : [],
            banks2      : [],
            computedDate: null,
            forms       : {
                date:moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                amount   : null,
                bank_to  : null,
                bank_from: null,
                errors   : {
                    date:'',
                    amount   : '',
                    bank_to  : '',
                    bank_from: '',
                }
            }
        }
    },
    computed: {
        from_running_balance() {
            if((this.forms.bank_from && this.forms.bank_to) && this.forms.amount) {
                let running_balance  = parseFloat(_.find(this.banks, {id: this.forms.bank_from}).running_balance).toFixed(2)
                let running_balance2 = parseFloat(_.find(this.banks, {id: this.forms.bank_to}).running_balance).toFixed(2)
                let amount           = parseFloat(this.forms.amount).toFixed(2)
                return [
                    Number(running_balance) - Number(amount),
                    Number(running_balance2) + Number(amount)
                ]
            } else {
                return [0, 0]
            }
        }
    },
    watch   : {
        dialogModal(val) {
            this.dialog = val
        },
        dialog(val) {
            if(!val) {
                this.$emit('transfer', true)
            }
        },
        'forms.bank_from'(val) {
            if(!this.forms.id) {
                this.forms.bank_to = null
                this.forms.amount  = null
            }
            this.generateBank2(val)
        },
        'forms.amount'(val) {
            if(val && this.forms.bank_from) {
                // let running_balance = _.find(this.banks, {id: this.forms.bank_from}).running_balance
                // if(val > running_balance && this.transferData.method !== 'edit') {
                //     Swal.fire({
                //                   icon           : 'warning',
                //                   text           : 'Running balance limit exceeded',
                //                   timer          : 3000,
                //                   showCloseButton: true
                //               })
                //         .then((result) => {
                //             this.forms.amount = running_balance
                //         })
                // }
            }
        },
    },
    created() {
        this.forms.date   = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
        this.computedDate = moment(this.forms.date, "YYYY-MM-DD HH:mm:ss").format(
            this.$root.settings.settings.date_format + " HH:mm:ss"
        )
        this.getBank()
    },
    methods : {
        closeDatePicker() {
            let currentTime   = moment(new Date()).format("HH:mm:ss");
            let date          = this.forms.date;
            let selectedDate  = moment(date).format(
                this.$root.settings.settings.date_format
            )
            // console.log(selectedDate + ' ' + currentTime)
            this.computedDate = moment(
                selectedDate + " " + currentTime,
                this.$root.settings.settings.date_format + " HH:mm:ss"
            ).format(this.$root.settings.settings.date_format + " HH:mm:ss")

            this.forms.date = moment(this.forms.date + " " + currentTime).format(
                "YYYY-MM-DD HH:mm:ss"
            )

            this.menu = false;
        },
        getBank() {
            axios.get('/api/get-bank', {params: {with_running_balance: true}})
                 .then(res => {
                     this.banks = res.data
                     if(this.transferData.id !== undefined) {
                         this.$nextTick(() => {
                             this.resetForms()
                             this.forms.id        = this.transferData.id
                             this.forms.amount    = this.transferData.amount
                             this.forms.bank_to   = this.transferData.bank_to
                             this.forms.bank_from = this.transferData.bank_from
                             this.forms.note      = this.transferData.note
                             this.forms.date = moment(this.transferData.date, this.$root.settings.settings.date_format + " HH:mm:ss").format("YYYY-MM-DD HH:mm:ss")
                             this.computedDate        = moment(this.forms.date, "YYYY-MM-DD HH:mm:ss").format(this.$root.settings.settings.date_format + " HH:mm:ss")
                             this.generateBank2(this.forms.bank_from)
                         })
                     }
                     if(!this.banks.length > 0) {
                         Swal.fire({
                                       type : 'error',
                                       text : 'There is no bank',
                                       timer: 3000
                                   }).then((result) => {
                             this.dialog = false
                             this.$emit('transfer', true)
                         })
                     }
                 })
        },
        generateBank2(val) {
            let banks = []
            this.banks.forEach(val => banks.push(val))
            let index = _.findIndex(this.banks, (item) => {
                return item.id === val
            }, 0)
            banks.splice(index, 1)
            this.banks2 = banks
        },
        resetForms() {
            this.forms.id               = null
            this.forms.bank_from        = null
            this.forms.bank_to          = null
            this.forms.amount           = null
            this.forms.errors.amount    = ''
            this.forms.errors.bank_to   = ''
            this.forms.errors.bank_from = ''
        },
        submit() {
            let timePermission = this.$options.filters.getTimePermission(['transaction', 'create'])
            if(timePermission && timePermission.hours) {
                let now      = moment(new Date())
                let end      = moment(this.forms.date)
                let duration = moment.duration(now.diff(end))
                let hours    = duration.asHours()
                if(hours >= timePermission.hours && timePermission.hours !== null) {
                    this.forms.errors.date = ['you don\'t have permission to create transaction']
                    return null
                }
            }
            axios.post('/api/bank-transfer', this.forms)
                 .then(res => {
                     Swal.fire({
                                   icon : res.data.type,
                                   text : res.data.message,
                                   timer: 3000
                               }).then((result) => {
                         this.dialog = false
                         this.$emit('transfer', true)
                     })
                 })
                 .catch(error => {
                     this.loading = false
                     if(error.response.status === 422) {
                         let err = error.response.data.errors
                         Object.keys(err).forEach(key => {
                             this.forms.errors[key] = err[key][0]
                         })
                     } else {
                         this.dialog = false
                         Swal.fire({
                                       'icon': 'error',
                                       'text': error.response.message
                                   })
                     }
                 })
        }
    }
}