<template>
  <v-card>
    <v-card-title>
      Location list of Warehouse {{ warehouse.name }} {{ warehouse.code }}
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th>Product Name</th>
            <th>Location</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(product, index) in products">
            <td>{{ product.name }}</td>
            <td>
              <p v-for="(location, key) in product.pivot.location">
                location Id: {{key}}, Quantity: {{location.quantity}}, weight: {{location.weight}}
              </p>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>

    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name   : "all-products-in-a-warehouse",
  props  : {
    warehouseId: {
      type   : Number,
      default: null
    }
  },
  data() {
    return {
      products : [],
      warehouse: {
        name: '',
        code: ''
      }
    }
  },
  created() {
    this.getLocationList()
  },
  methods: {
    getLocationList: _.debounce(function () {
      axios.get('/api/inventory/warehouse-config', {
        params: {
          product_all : true,
          warehouse_id: this.warehouseId
        }
      })
           .then(res => {
             let data            = res.data.locations
             this.warehouse.name = data.name
             this.warehouse.code = data.code
             if(!_.isEmpty(data) && _.has(data, 'products')) {
               this.products = data.products
             }
           })
    }, 200)
  }
}
</script>

<style scoped>

</style>