<template>
    <v-row>
        <v-col md="3" cols="12">
            <v-select
                    v-model="format"
                    small-chips
                    :items="[
                        {value:'CODE128A', text:'CODE128A'},
                        {value:'CODE128B', text:'CODE128B'},
                        {value:'CODE128C', text:'CODE128C'},
                        {value:'codebar', text:'codabar'},
                        ]">

            </v-select>
        </v-col>
        <v-col md="3" cols="12">
            <v-text-field
            v-model="barcodeValue"
            >
            </v-text-field>
        </v-col>
        <v-col cols="12" md="3">
            <v-btn @click="generateBarcode()">
                Generate
            </v-btn>
        </v-col>
        <v-col cols="12" md="12">
            <svg id="barcode"></svg>
        </v-col>
<!--        <input type="number" min="1" class="input-item" v-model="print_items">-->
<!--        <button type="button" class="btn warning s printBtn right"-->
<!--                @click="pos_print_barcode()">-->
<!--            Print With Pos-->
<!--        </button>-->
<!--        <button type="button" class="btn info s printBtn right"-->
<!--                @click="print_barcode()">-->
<!--            Print-->
<!--        </button>-->

<!--        <barcode-print-->
<!--                style="visibility: collapse"-->
<!--                :svgData="svgData"-->
<!--                :print_items="parseInt(print_items)"-->
<!--        >-->

<!--        </barcode-print>-->
<!--        <barcode-print-for-pos-->
<!--                style="visibility: collapse"-->
<!--                :svgData="svgData"-->
<!--                :print_items="parseInt(print_items)"-->
<!--        >-->

<!--        </barcode-print-for-pos>-->
    </v-row>
</template>

<script>
    const JsBarcode = require('jsbarcode')
    const Canvas = require('canvas')
    export default {
        name: "barcode",
        props: ['generateCode'],
        data() {
            return {
                barcodeValue: '1254er56464',
                format: 'CODE128B',
                print_items: 4,
                svgData: 'asdfsdf'
            }
        },
        created() {

        },
        watch: {
            generateCode() {
                this.barcodeValue = this.generateCode ? this.generateCode : 123454678
            }
        },
        mounted() {
            if (this.$store.state.requestedBarcode) {
                this.barcodeValue = this.$store.state.requestedBarcode
                window.setTimeout(() => {
                    this.generateBarcode()
                }, 200)
            }
            if (!_.isEmpty(this.$route.query)) {
                if (this.$route.query.hasOwnProperty('q')) {
                    this.barcodeValue = this.$route.query.q
                    window.setTimeout(() => {
                        this.generateBarcode()
                    }, 200)
                }
            } else {
                window.setTimeout(() => {
                    JsBarcode('#barcode', '1254er56464', {
                        width: 1,
                        height: 40,
                    })
                }, 50)
            }

        },

        methods: {
            print_barcode() {
                this.svgData = document.getElementById('barcode').innerHTML
                setTimeout(() => {
                    printJS({printable: 'barcode_print', type: 'html', showModal: false})
                }, 1000)

            },
            pos_print_barcode() {
                this.svgData = document.getElementById('barcode').innerHTML
                setTimeout(() => {
                    printJS({printable: 'barcode_pos_print', type: 'html', showModal: false})
                }, 1000)
            },
            generateBarcode() {
                try {
                    JsBarcode('#barcode', this.barcodeValue, {
                        format: this.format,
                        width: 1,
                        height: 40,
                    })
                } catch (e) {
                    Swal.fire({
                        type: 'error',
                        timer: 5000,
                        text: e
                    })
                }
            }
        }

    }
</script>