<template>
  <tooltip-button
      icon
      dark
      position="bottom"
      :icon-name="$root.appIcons.return"
      data-text="Back"
      color="secondary"
      @click="$router.back()"
  />
<!--  <v-btn @click="$router.back()" icon>-->
<!--    <v-icon>{{ $root.appIcons.return }}</v-icon>-->
<!--  </v-btn>-->
</template>

<script>
export default {
  name: "back-button"
}
</script>