import BankTransfer  from './bank-transfer.vue'
import TransactionIn from './transaction-in.vue'
import TransactionExport from './transaction-export.vue'

export default {
    name      : "transaction",
    components: {
        BankTransfer,
        TransactionIn,
        TransactionExport,
        // TransactionOut
    },
    data() {
        return {
            table_dense:true,
            exportDialog      : false,
            quickItem         : {},
            quickDialog       : false,
            menu              : false,
            items             : {
                payment: {},
                returns: [],
            },
            banks             : [],
            loading           : false,
            options           : {
                itemsPerPage: this.$store.state.itemsPerPage,
                sortBy      : ['created_at'],
                sortDesc    : [true],
            },
            transferData      : {},
            transferDataDialog: false,
            transaction_in    : false,
            transaction_out   : false,
            forms             : {
                errors: {}
            },
            // Bank --- Date --- Method --- Ref. No. --- Name --- ID --- Account --- Note --- Withdrawal --- Deposit ---
            // Created --- Actions
            headers           : [
                {text: 'bank', value: 'bank.name', sortable: false},
                {text: 'date', value: 'date', sortable: true},
                {text: 'method', value: 'payment_method', sortable: true},
                {text: 'ref no.', value: 'ref_no', sortable: true},
                {text: 'trans.no.', value: 'transaction_number', sortable: true},
                {text: 'name-id-type', value: 'userable.name_id_type', sortable: false},
                {text: 'account', value: 'account_name', sortable: true},
                {text: 'note', value: 'note', sortable: true},
                {text: 'withdraw', value: 'withdraw', sortable: true},
                {text: 'deposit', value: 'deposit', sortable: true},
                {text: 'created at', value: 'created_at', sortable: true},
                {text: 'action', value: 'action', sortable: false},
            ],
            closeOnContentClick:false
        }
    },
    watch     : {
        options: {
            deep: true,
            handler() {
                this.loading = true
                this.getData()
            }
        },

    },

    created() {
        if(!_.isEmpty(this.$route.params)) {
            if(this.$route.params.id) {
                this.options.id = this.$route.params.id
            }
        }
    },

    methods: {
        chequeStatus(value){
           if(!value){
               return ['uncleared', 'error']
           }
           return ['cleared', 'success']
        },
        uploadFile() {
            let formData = new FormData()
            let file     = this.$refs.inputUpload.files[0]
            formData.append('file', file)
            axios.post('/api/inventory/transaction-import', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                 .then(res => {
                     Swal.fire({
                                   icon: 'success',
                                   text: 'file has been uploaded successfully'
                               }).then((result)=>{
                         this.getData()
                     })
                 })
                 .catch(error => {
                     Swal.fire({
                                   icon: 'error',
                                   html: 'status ' + error.response.status + '.<br>' + error.response.statusText,
                               })
                 })
        },
        closeDialogExport(val) {
            if(val === 200) {
                this.exportDialog = false
            }
        },
        resetQuery() {
            this.options = {
                itemsPerPage: this.$store.state.itemsPerPage,
                sortBy      : ['created_at'],
                sortDesc    : [true]
            }
        },
        activeModule(item) {
            if(item.action === 'bank_transaction_debit') {
                this.bank_transactionDialog = true
            }
            if(item.action === 'bank_transfer') {
                this.transferDataDialog = true
            }
        },
        showEditButton(item) {
            if(!_.isEmpty(item.payment)) {
                if(item.payment.paymentable_type !== undefined && item.payment.paymentable_type === 'App\\Expense\\Expense') {
                    return false
                }
            }
            if(!_.isEmpty(item.payment)) {
                if(item.payment.paymentable_type !== undefined && item.payment.paymentable_type === 'App\\Income\\Income') {
                    return false
                }
            }

            if((item.account_id || item.transfer_id) && this.$options.filters.checkTimeAndPermission(['transaction', 'edit'], item.date)) {
                return true
            }
        },
        showDeleteButton(item) {
            if(!_.isEmpty(item.payment)) {
                if(item.payment.paymentable_type !== undefined && item.payment.paymentable_type === 'App\\Expense\\Expense') {
                    return false
                }
            }
            if(!_.isEmpty(item.payment)) {
                if(item.payment.paymentable_type !== undefined && item.payment.paymentable_type === 'App\\Income\\Income') {
                    return false
                }
            }
            if((item.account_id || item.payment_method === 'transfer') && this.$options.filters.checkTimeAndPermission(['transaction', 'delete'], item.date)) {
                return true
            }

        },
        showViewButton(item) {
            return true
            if(!_.isEmpty(item.payment)) {
                return true
            }

            if(!_.isEmpty(item.returns)) {
                return true
            }

            if(item.buildevent_id) {
                return true
            }
            if(item.income_id) {
                return true
            }

            if(item.salary_id) {
                return true
            }

            return false
        },
        view(item) {
            if(!_.isEmpty(item.payment)) {
                this.viewPayment(item.payment)
            }
            if(!_.isEmpty(item.returns)) {
                this.viewReturns(item.returns[0])
            }
            if(item.buildevent_id) {
                this.$router.push({name: 'product.composite.event', params: {id: item.buildevent_id}})
            }
            if(item.salary_id) {
                this.$router.push({name: 'payroll.salary.index', params: {id: item.salary_id}})
            } else {
                this.quickItem = item
                this.getBankDetail(item.bank_id)
            }
        },
        getBankDetail(bank_id) {
            axios.get(api_bank + '/' +bank_id, {params: {total_debit_credit: true}}).then(res => {
                this.quickItem.available_balance = res.data.bankTransactionTotal_debit_credit.running_balance
                this.quickDialog                 = true
            })
        },
        viewReturns(item) {
            if(item.returnable_type === 'App\\Inventory\\Purchase') {
                this.$router.push({name: 'purchaseReturn.ledger', params: {ref: item.returnable.id}})
            }
            if(item.returnable_type === 'App\\Inventory\\Sale') {
                this.$router.push({name: 'saleReturn.ledger', params: {ref: item.returnable.ref}})
            }
        },
        viewPayment(item) {
            if(item.paymentable_type !== undefined && item.paymentable_type === 'App\\Inventory\\Purchase') {
                this.$router.push({name: 'purchase.show', params: {id: item.paymentable_id}})
            }
            if(item.paymentable_type !== undefined && item.paymentable_type === 'App\\Inventory\\Sale') {
                this.$router.push({name: 'sale.show', params: {id: item.paymentable_id}})
            }
            if(item.paymentable_type !== undefined && item.paymentable_type === 'App\\Expense\\Expense') {
                this.$router.push({name: 'expense.index', params: {id: item.paymentable_id}})
            }
            if(item.paymentable_type !== undefined && item.paymentable_type === 'App\\Income\\Income') {
                this.$router.push({name: 'income.index', params: {id: item.paymentable_id}})
            }
        },
        editItem(item) {
            this.forms        = {}
            this.forms.errors = {}
            if(item.transfer) {
                this.transferData.id        = item.id
                this.transferData.bank_to   = item.bank_id
                this.transferData.amount    = item.amount
                this.transferData.bank_from = item.transfer_id
                this.transferData.note      = item.note
                this.transferData.date      = item.date
                this.transferData.method    = 'edit'
                this.transferDataDialog     = true
                return true
            }
            this.forms        = item
            this.forms.errors = {}
            if(item.account_id) {
                axios.get('/api/get-any-user/' + item.userable_id, {
                    params: {
                        model: item.userable_model
                    }
                })
                     .then(res => {
                         this.forms.userable_id = res.data
                         this.transaction_in    = true
                     })
            }
        },
        deleteItem(id) {
            if(confirm('are you sure')) {
                axios.delete('/api/transaction/' + id)
                     .then(res => {
                         Swal.fire({
                                       icon : res.data.type,
                                       timer: 3000,
                                       text : res.data.message,
                                   })
                             .then((result) => {
                                 this.getData()
                             })
                     })
                     .catch(error => {
                         this.loading = false
                         this.dialog  = false
                         Swal.fire({
                                       'icon'   : 'error',
                                       'message': error.response.message
                                   })
                     })
            }
        },
        getData() {
            axios.get('/api/transaction', {params: this.options})
                 .then(res => {
                     this.items   = res.data.transactions
                     this.banks   = res.data.banks
                     this.loading = false
                 })
        },
        transaction(val) {
            if(val) {
                this.transaction_in  = false
                this.transaction_out = false
                this.forms           = {}
                this.forms.errors    = {}
                this.getData()
            }
        },
        transfer(val) {
            if(val) {
                this.getData()
                this.transferData       = {}
                this.transferDataDialog = false
            }
        },
    }
}