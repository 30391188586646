<template>
  <v-card outlined>
    <v-row justify="end" class="pa-2">
      <v-col md="6">
        <v-row>
          <v-col>
            <v-select
                solo
                v-model="type"
                label="select type"
                :items="['Year', 'Month', 'Day']"
            />
          </v-col>
          <v-col>
            <template v-if="type==='Year'">
              <v-select
                  solo
                  :items="years"
                  label="select year"
                  v-model="selectedType"
              />
            </template>
            <template v-if="type==='Month'">
              <v-menu
                  offset-y
                  ref="menu"
                  v-model="menu"
                  min-width="auto"
                  max-width="290px"
                  transition="scale-transition"
                  :close-on-content-click="false"
                  :return-value.sync="selectedType"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      solo
                      readonly
                      v-on="on"
                      v-bind="attrs"
                      label="Select Month"
                      v-model="selectedType"
                      prepend-icon="mdi-calendar"
                  />
                </template>
                <v-date-picker
                    no-title
                    scrollable
                    show-current
                    type="month"
                    :min="minDate"
                    :max="maxDate"
                    v-model="selectedType"
                    @input="$refs.menu.save(selectedType), menu=false"
                />
              </v-menu>
            </template>
            <template v-if="type==='Day'">
              <v-menu
                  offset-y
                  ref="menu"
                  v-model="menu"
                  min-width="auto"
                  max-width="290px"
                  transition="scale-transition"
                  :close-on-content-click="false"
                  :return-value.sync="selectedType"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      solo
                      readonly
                      v-on="on"
                      v-bind="attrs"
                      label="Select Date"
                      v-model="selectedType"
                      prepend-icon="mdi-calendar"
                  />
                </template>
                <v-date-picker
                    no-title
                    scrollable
                    show-current
                    :min="minDate"
                    :max="maxDate"
                    v-model="selectedType"
                    @input="$refs.menu.save(selectedType), menu=false"
                />
              </v-menu>
            </template>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-card-title class="justify-center">
      {{ company_detail.user.company.name }}
    </v-card-title>
    <v-card-subtitle class="d-flex flex-column align-center">
      <span>
        CASH FLOW STATEMENT
      </span>
      <span v-if="triggerReq && !_.isEmpty(response_data.date_between)">
        For Period (<strong>from: {{ response_data.date_between[0] }}</strong>,
        <strong>to: {{ response_data.date_between[1] }}</strong>)
      </span>
      <span>
        Contact:
      <template v-for="(phone, index) in company_detail.user.company.contact_phone">
        {{ phone }}
      </template>
      </span>
      <span>Address: {{ company_detail.user.company.address1 }}</span>
      <br>
      <v-btn outlined text small>
        Print
        <v-icon>mdi-printer</v-icon>
      </v-btn>
    </v-card-subtitle>
    <v-card-text>
      <v-skeleton-loader
          v-if="loading"
          type="table-heading, list-item-two-line, table-tfoot"
      />
      <v-simple-table v-else>
        <template v-slot:default>
          <thead>
          <tr>
            <th>Particular</th>
            <th>Amount</th>
          </tr>
          </thead>

          <tbody>
          <tr>
            <td class="text-right">Opening Balance</td>
            <td>{{ opening_balance }}</td>
          </tr>
          <tr>
            <td>Account Payable</td>
            <td>{{ account_payable }}</td>
          </tr>
          <tr>
            <td>Account Receivable</td>
            <td>{{ account_receivable }}</td>
          </tr>
          <tr>
            <td>Expense</td>
            <td>{{ expense }}</td>
          </tr>
          <tr>
            <td>Payroll</td>
            <td>{{ payroll }}</td>
          </tr>
          <tr>
            <td>Income</td>
            <td>{{ income }}</td>
          </tr>
          <tr>
            <td>Asset</td>
            <td>{{ asset }}</td>
          </tr>
          <tr>
            <td>Liability</td>
            <td>{{ liability }}</td>
          </tr>
          <tr>
            <td>Equity</td>
            <td>{{ equity }}</td>
          </tr>
          <tr>
            <td class="text-right">Closing Balance</td>
            <td>{{ response_data.total_sum }}</td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
const offset  = new Date().getTimezoneOffset()
const nowDate = new Date(new Date().getTime() - (offset * 60 * 1000))
export default {
  name    : "cash-flow",
  data() {
    return {
      company_detail: auth,
      response_data : [],
      type          : 'Year',
      years         : [],
      selectedType  : new Date().toISOString().substr(0, 7),
      menu          : false,
      loading       : true,
      startTime     : auth.user.company.created_at
    }
  },
  created() {
    for (
        let i = new Date(this.company_detail.user.company.created_at).getFullYear();
        i <= new Date().getFullYear();
        i++
    ) {
      this.years.push(i)
    }
  },
  watch   : {
    type: {
      immediate: true,
      handler(val) {
        if(val === 'Year') this.selectedType = nowDate.getFullYear()
        if(val === 'Month') this.selectedType = nowDate.getFullYear() + '-' + Number(nowDate.getMonth() + 1)
        if(val === 'Day') this.selectedType = nowDate.toISOString().split('T')[0]
      }
    }
  },
  computed: {
    triggerReq() {
      if(this.selectedType && this.type) {
        this.loading = true
        this.getData()
        return true
      }
      return false
    },
    minDate() {
      if(this.type === 'Month')
        return new Date(this.startTime).getFullYear() + '-' + new Date(this.startTime).getMonth()
      if(this.type === 'Day')
        return new Date(this.startTime).toISOString().split('T')[0]
    },
    maxDate() {
      let month = Number(nowDate.getMonth()) + 1
      if(this.type === 'Month')
        return nowDate.getFullYear() + '-' + month
      if(this.type === 'Day')
        return nowDate.toISOString().split('T')[0]
    },
    opening_balance(){
      return this.response_data['opening_balance'] || 0
    },
    account_receivable() {
      let total = this.response_data['sale_cash'] + this.response_data['return_purchase_cash']
      return total || 0
    },
    account_payable() {
      return this.response_data['purchase_cash'] + this.response_data['return_sale_cash'] || 0
    },
    payroll() {
      return this.response_data['salary_cash'] || 0
    },
    income() {
      return !_.isEmpty(this.response_data['final_account'])
          ? this.response_data['final_account']['Income'] : 0;
    },
    expense() {
      return !_.isEmpty(this.response_data['final_account'])
          ? - this.response_data['final_account']['Expense'] : 0
    },
    asset() {
      return !_.isEmpty(this.response_data['final_account'])
          ? - this.response_data['final_account']['Asset'] : 0
    },
    liability() {
      return !_.isEmpty(this.response_data['final_account'])
          ? this.response_data['final_account']['Liability'] : 0
    },
    equity() {
      return !_.isEmpty(this.response_data['final_account'])
          ? this.response_data['final_account']['Equity'] : 0
    },
  },
  methods : {
    getData: _.debounce(function () {
      axios.get(api_accounts + '/cash-flow',
                {
                  params: {
                    type              : this.type,
                    selectedType      : this.selectedType,
                    company_created_at: this.startTime
                  }
                })
           .then(res => {
             this.response_data = res.data
             this.loading       = false
           })
    }, 200)
  }
}
</script>