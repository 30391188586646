import _ from "lodash";

export default {
    name    : "create",
    props   : {
        form_id: null
    },
    data() {
        return {
            banks        : [],
            forms        : this.$root.$data.forms,
            incomes      : [],
            warehouses   : [],
            pay_later    : false,
            // categories   : [],
            method       : '',
            loading      : false,
            modal        : false,
            menu1        : false,
            menu2        : false,
            computedDate : null,
            computedDate1: null,
            users        : [],
            searchItems  : null,
            accounts     : []
        }
    },
    beforeCreate() {
        this.$root.$data.forms.fieldReset();
    },
    watch   : {
        searchItems: {
            immediate: true,
            handler(val) {
                this.getUsers(val)
            }
        },
        '$route.fullPath'(val) {
            //reset data
            this.forms.reset();
            this.incomes    = []
            this.method     = 'Create'
            this.forms.type = 'debit'
        }
    },
    computed: {
        isLoaded() {
            return this.method === 'Edit' ? !_.isEmpty(this.incomes) : true
        }
    },
    created() {
        this.loading = true
        this.$set(this.forms, 'payment_method', 3)
        this.forms.type    = 'debit'
        this.forms.date    = moment(new Date()).format('YYYY-MM-DD')
        this.computedDate1 = moment(this.forms.date, "YYYY-MM-DD").format(this.$root.settings.settings.date_format)

        this.method = this.$root.getMethodAction();
        let url     = '/api/income/incomes/create';
        if(this.form_id) {
            this.method = 'Edit'
            url         = '/api/income/incomes/' + this.form_id + '/edit'
        }
        this.$root.$data.erp.request.get(url, this, (data) => {
            this.warehouses = data.warehouses
            this.$set(this.forms, 'income_date', new Date())
            if(this.warehouses.length === 1) {
                this.forms.warehouse_id = this.warehouses[0].id
            }
            // this.categories = data.categories
            this.banks    = data.banks
            this.accounts = data.accounts
            this.getUsers()
            if(this.method === 'Edit') {
                this.incomes  = data.incomes
                this.forms.id = this.incomes.id

                this.forms.income_date = moment(this.incomes.income_date, this.$root.settings.settings.date_format).format("YYYY-MM-DD")

                this.forms.warehouse_id = parseInt(this.incomes.warehouse_id)

                this.forms.payment_method = parseInt(this.incomes.payment_method)
                this.forms.ref            = this.incomes.ref
                this.forms.amount         = this.incomes.amount
                this.forms.note           = this.incomes.note
                if(data.incomes.account_id) {
                    this.forms.account_id = this.incomes.account_id
                    axios.get('/api/get-any-user/' + this.incomes.userable_id, {
                        params: {
                            model: this.incomes.userable_model
                        }
                    })
                         .then(res => {
                             this.forms.userable_id = res.data
                         })
                }
            }
            this.loading = false
        });

    },
    methods : {
        getUsers: _.debounce(function (query) {
            axios
                .get("/api/get-any-user", {
                    params: {name: query},
                })
                .then((result) => {
                    this.users = result.data;
                })
        }, 200),
        onCancel() {
            this.forms.reset()
            this.forms.type = 'debit'
        },
        postIncome() {
            this.forms.income_date = moment(this.forms.income_date).format("YYYY-MM-DD HH:mm:ss")
            let requestMethod      = 'post'
            let url                = '/api/income/incomes'
            if(this.method === 'Edit') {
                requestMethod = 'patch'
                url           = '/api/income/incomes/' + this.forms.id
            }
            this.forms.submit(requestMethod, url, true, this.$root)
                .then(data => {
                    this.loading = false
                    Swal.fire({
                                  toast            : true,
                                  position         : 'top',
                                  icon             : data.type,
                                  timer            : 1000,
                                  text             : data.message,
                                  showCancelButton : false,
                                  showConfirmButton: false,
                              })
                    this.forms.income_date         = moment(new Date()).format('YYYY-MM-DD')
                    this.computedDate              = moment(this.forms.income_date, "YYYY-MM-DD").format(this.$root.settings.settings.date_format)
                    data['show_payment_component'] = !(this.form_id || this.pay_later);
                    this.$emit('fromCreate', data)
                })
                .catch(err => {
                    this.loading = false
                })
        },
        closeDatePicker() {
            let currentTime        = moment(new Date()).format("HH:mm:ss")
            let income_date        = this.forms.income_date
            let selectedDate       = moment(income_date).format(this.$root.settings.settings.date_format)
            // console.log(selectedDate + ' ' + currentTime)
            this.computedDate      = moment(selectedDate + ' ' + currentTime, this.$root.settings.settings.date_format).format(this.$root.settings.settings.date_format)
            this.forms.income_date = moment(this.forms.income_date).format("YYYY-MM-DD")
            this.menu1             = false
        },
        closeDatePicker1() {
            let currentTime    = moment(new Date()).format("HH:mm:ss")
            let date           = this.forms.date
            let selectedDate   = moment(date).format(this.$root.settings.settings.date_format)
            // console.log(selectedDate + ' ' + currentTime)
            this.computedDate1 = moment(selectedDate + ' ' + currentTime, this.$root.settings.settings.date_format).format(this.$root.settings.settings.date_format)
            this.forms.date    = moment(this.forms.date).format("YYYY-MM-DD")
            this.menu2         = false
        }
    }
}