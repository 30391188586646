<!-- https://www.retaildogma.com/retailer-balance-sheet-explained/ -->
<template>
  <v-card outlined>
    <v-row justify="end" class="pa-2">
      <v-col md="4">
<!--        <v-menu-->
<!--            offset-y-->
<!--            v-model="menu"-->
<!--            min-width="290px"-->
<!--            :nudge-right="40"-->
<!--            transition="scale-transition"-->
<!--            :close-on-content-click="false"-->
<!--        >-->
<!--          <template v-slot:activator="{ on, attrs }">-->
<!--            <v-text-field-->
<!--                solo-->
<!--                dense-->
<!--                readonly-->
<!--                clearable-->
<!--                v-on="on"-->
<!--                hide-details-->
<!--                v-bind="attrs"-->
<!--                v-model="date"-->
<!--                label="Date Between"-->
<!--            >-->
<!--            </v-text-field>-->
<!--          </template>-->
<!--          <v-date-picker-->
<!--              range-->
<!--              v-model="date"-->
<!--              @input="date.length>1?menu = false:null"-->
<!--          >-->
<!--          </v-date-picker>-->
<!--        </v-menu>-->
      </v-col>
    </v-row>
    <v-card-title class="justify-center">
      {{ company_detail.user.company.name }}
    </v-card-title>
    <v-card-subtitle class="d-flex flex-column align-center">
      <span>
        Balance Sheet
      </span>
      <span v-if="triggerReq && !_.isEmpty(response_data.date_between)">
        For Period (<strong>from: {{ response_data.date_between[0] }}</strong>,
        <strong>to: {{ response_data.date_between[1] }}</strong>)
      </span>
      <span>
        Contact:
      <template v-for="(phone, index) in company_detail.user.company.contact_phone">
        {{ phone }}
      </template>
      </span>
      <span>Address: {{ company_detail.user.company.address1 }}</span>
      <br>
      <v-btn outlined text small @click="$htmlToPaper('myPrint')">
        Print
        <v-icon>mdi-printer</v-icon>
      </v-btn>
    </v-card-subtitle>
    <v-card-text>
      <v-skeleton-loader
          v-if="loading"
          type="table-heading, list-item-two-line, table-tfoot"
      />
      <v-simple-table dense v-else>
        <template v-slot:default>
          <thead>
          <tr>
            <th>Particular</th>
            <th>Amount</th>
            <th>Amount</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td colspan="3" class="font-weight-black">
              Assets
            </td>
          </tr>
          <!--Fixed Assets-->
          <tr>
            <td>
              <span class="ml-2 font-weight-black">1. Fixed Assets</span>
            </td>
            <td colspan="2">{{ response_data.fixed_asset }}</td>
          </tr>
          <tr>
            <td class="text-right">
              <strong>Total Fixed Assets</strong>
            </td>
            <td colspan="2" class="text-center">
              <strong>{{ response_data.fixed_asset }}</strong>
            </td>
          </tr>

          <!--Current Assets-->
          <tr>
            <td colspan="3">
              <span class="ml-2 font-weight-black">2. Current Assets</span>
            </td>
          </tr>
          <tr>
            <td>
              <span class="ml-6">Cash in Hand</span>
            </td>
            <td colspan="2">{{ response_data.cash_in_hand }}</td>
          </tr>
          <tr>
            <td>
              <span class="ml-6">Cash in Bank</span>
            </td>
            <td colspan="2">{{ response_data.cash_in_bank }}</td>
          </tr>
          <tr>
            <td>
              <span class="ml-6">Merchandise Inventory</span>
            </td>
            <td colspan="2">{{ response_data.merchandise_inventory }}</td>
          </tr>
          <tr>
            <td>
              <span class="ml-6">Account Receivable</span>
            </td>
            <td colspan="2">{{ response_data.account_receivable }}</td>
          </tr>
          <tr>
            <td class="text-right">
              <strong>Total Current Asset</strong>
            </td>
            <td colspan="2" class="text-center">
              <strong>{{ response_data.total_current_asset }}</strong>
            </td>
          </tr>

          <tr>
            <td class="text-right">
              <strong>Total Assets</strong>
            </td>
            <td colspan="2" class="text-center">
              <strong>{{ response_data.total_assets }}</strong>
            </td>
          </tr>


          <tr>
            <td colspan="3" class="font-weight-black">Liabilities and Owners Equity</td>
          </tr>
          <tr>
            <td colspan="3" class="font-weight-black">
              <span class="ml-2">3. Current Liabilities</span>
            </td>
          </tr>
          <tr>
            <td>
              <span class="ml-6">Accounts Payable</span>
            </td>
            <td colspan="2">{{ response_data.account_payable }}</td>
          </tr>
          <tr>
            <td class="font-weight-black" colspan="3">
              <span class="ml-2">4. Owner's Equity</span>
            </td>
          </tr>
          <tr>
            <td>
              <span class="ml-6">
                Owner's Investment
              </span>
            </td>
            <td colspan="2">
              {{
                response_data.owner_investment
              }}
            </td>

          </tr>
          <tr>
            <td>
              <span class="ml-6">
                Retained Earnings
              </span>
            </td>
            <td colspan="2">
              {{
              response_data.profit
              }}
            </td>
          </tr>

          <tr>
            <td class="text-right">
              <strong>Total Liability and Owner Equity</strong>
            </td>
            <td colspan="2" class="text-center">
              <strong>
                {{
                  response_data.total_liabilities
                  +
                  response_data.total_owner_equity
                }}
              </strong>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
    <balance-sheet-print
        :client-data="{response_data, company_detail}"
        style="visibility: collapse"
    ></balance-sheet-print>
  </v-card>
</template>

<script>
import BalanceSheetPrint from 'g~/print/balance-sheet-print.vue'
export default {
  name    : "balance-sheet",
  components: {
    BalanceSheetPrint
  },
  data() {
    return {
      menu          : false,
      date          : [],
      company_detail: auth,
      response_data : {
        'cash_in_hand'         : 0,
        'cash_in_bank'         : 0,
        'merchandise_inventory': 0
      },
      loading       : false,
    }
  },
  computed: {
    triggerReq() {
      // if(this.date.length < 2) return false
      this.loading = true
      this.getData()
    }
  },
  methods : {
    getData: _.debounce(function () {
      axios.get(api_accounts + '/balance-sheet', {
        params: {
          date: this.date
        }
      })
           .then(res => {
             this.response_data = res.data
             this.loading       = false
           })
    }, 200)
  }

}
</script>

<style scoped>

</style>