<template>

  <div class="with-border pd-9" v-if="isLoaded">
    <div class="row with-border bg-default">
      <span class="pd-9">Ledger Report of ref {{ expenses.ref }}</span>
      <!--<button type="button" class="btn info s printBtn right"-->
      <!--onclick="printJS({printable: 'myPrint', type: 'html', showModal: false  })">-->
      <!--Print-->
      <!--</button>-->

    </div>
    <div class="row">


      <div class="col-1 pdr-0">
        <div class="card no-hover h-100">
          <div class="header bg-default">
            <span class="dark">Expense Information</span>
          </div>
          <div class="body">
            <div class="purchaseInfo">
              <Icon type="arrow-return-left" class="fs-30"></Icon>
              <p>Ref: {{ expenses.ref }}</p>
              <p>Expense Date: {{ $root.timeFormat(expenses.expense_date) }}</p>
              <p>created At: {{ $root.timeFormat(expenses.created_at) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-9 pdl-0">
        <hr>
        <h5 class="text-center">Expense Detail</h5>
        <table class="table">
          <thead>
          <tr class="bg-default">
            <td>No.</td>
            <td>Warhouse name</td>
            <td>Category name</td>
            <td>Amount</td>
            <td>Note</td>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>1</td>

            <td>{{ expenses.warehouse ? expenses.warehouse.name : '' }}</td>
            <td>{{ expenses.category ? expenses.category.name : '' }}</td>
            <td class="bg-warning-lt strong">{{ expenses.amount }}</td>
            <td>{{ expenses.note }}</td>
          </tr>
          </tbody>
          <tfoot>

          <tr class="strong">
            <td colspan="3" class="text-right">Total</td>
            <td colspan="0" class="text-left bg-info-lt">{{ expenses.amount }}</td>
            <td></td>
          </tr>
          </tfoot>
        </table>
      </div>

    </div>

  </div>

</template>
<script>
export default {
  data() {
    return {
      expenses: {}
    }
  },
  computed: {
    isLoaded() {
      return !_.isEmpty(this.expenses);
    }
  }
  ,
  created() {
    this.$root.$data.erp.request.get('/api' + window.location.pathname, this, (data) => {
      this.expenses = data;
    });
  }
}

</script>

<style>
.card.h-100 {
  height: 100%;
}
</style>