import FormActions from '~/form-actions.vue'

export default {
    components: {
        FormActions
    },
    props     : {
        modelId: {
            type   : Number,
            default: null
        },
        value  : {
            type   : Boolean,
            default: false
        },
    },
    data() {
        return {
            url        : this.$getUrl('units', this.value, this.modelId),
            axiosMethod: this.$getAxiosMethod(this.value, this.modelId),
            forms      : this.$root.$data.forms,
            units      : [],
            loading    : false,
        }
    },

    beforeCreate() {
        this.$root.$data.forms.fieldReset();
    },

    watch  : {
        '$route.fullPath'(val) {
            this.forms.reset();
            this.units = []
        }
    },
    created() {
        if(this.axiosMethod === 'patch' || this.modelId)
            this.getData()
    },
    methods: {
        getData() {
            this.loading = true
            this.$root.$data.erp.request.get(this.url, this, (data) => {
                this.units             = data.units
                this.forms.id          = this.units.id
                this.forms.key         = this.units.key
                this.forms.description = this.units.description
                this.forms.is_primary  = this.units.is_primary == 0 ? 0 : 1
                this.loading           = false
            })
        },
        postUnit($e) {
            let submitter = $e.submitter.value
            // return null
            this.loading  = true
            this.forms.submit(
                this.axiosMethod,
                this.$getUrlForm(this.url),
                true,
                this.$root)
                .then(data => {
                    this.loading = false
                    if(this.value) {
                        if(submitter === 'submit-close' || submitter === 'update-close') {
                            this.$emit('input', false)
                        }
                    }
                    this.$axiosPostDialog(data)
                }).catch(error => {
                this.loading = false
            });
        },
        onCancel() {
            this.forms.reset();
        }

    }
}