module.exports = [{
        path: '/inventory/warehouses/create',
        name: 'warehouse.create',
        component: require('../views/inventory/warehouse/create.vue').default
    },
    {
        path: '/inventory/warehouses/index',
        name: 'warehouse.index',
        component: require('../views/inventory/warehouse/index.vue').default
    },
    {
        path: '/inventory/warehouses/:id',
        name: 'warehouse.show',
        component: require('../views/inventory/warehouse/show.vue').default
    },
    {
        path: '/inventory/warehouses/:id/edit',
        name: 'warehouse.edit',
        component: require('../views/inventory/warehouse/create.vue').default
    },
    {
        path: '/inventory/brands/create',
        name: 'brand.create',
        component: require('../views/inventory/brand/create.vue').default
    },
    {
        path: '/inventory/brands/index',
        name: 'brand.index',
        component: require('../views/inventory/brand/index.vue').default
    },
    {
        path: '/inventory/brands/:id/edit',
        name: 'brand.edit',
        component: require('../views/inventory/brand/create.vue').default
    },
    {
        path: '/inventory/category/create',
        name: 'category.create',
        component: require('../views/inventory/category/create.vue').default
    },
    {
        path: '/inventory/category/index',
        name: 'category.index',
        component: require('../views/inventory/category/index.vue').default
    },
    {
        path: '/home/settings',
        name: 'setting.index',
        component: require('../views/inventory/settings.vue').default
    },
    {
        path: '/home/typescript-test',
        name: 'typescript.test',
        component: require('../views/inventory/typescript-test.vue').default
    },
    {
        path: '/home/warehouse-new-module-mockup',
        name: 'warehouse.mockup',
        component: require('../views/inventory/mockup/warehouse.vue').default
    },
];