<template>
  <div>
    <v-text-field
      type="number"
      name="amount"
      label="amount"
      v-model="forms.paid"
      :readonly="!_.isEmpty(readonly) && readonly.paid!==undefined?readonly.paid:false"
      :error-messages="forms.errors['paid']"
    ></v-text-field>
    <v-select
      item-value="id"
      item-text="name"
      name="payment_type"
      label="payment method"
      v-model="forms.payment_type"
      :items="$store.state.payment_methods"
      :readonly="!_.isEmpty(readonly) && readonly.payment_type!==undefined?readonly.payment_type:false"
      :error-messages="forms.errors['payment_type']"
    >
    </v-select>
    <v-select
      :items="banks"
      item-value="id"
      item-text="name"
      label="select bank"
      v-model="forms.bank_id"
      v-if="forms.payment_type == 2 || forms.payment_type == 3"
      :error-messages="forms.errors['bank_id']"
    ></v-select>
    <v-text-field
      label="transaction number"
      v-model="forms.transaction_number"
      v-if="forms.payment_type == 2 || forms.payment_type == 3"
    ></v-text-field>
    <v-text-field
      label="cheque number"
      v-model="forms.cheque_number"
      v-if="forms.payment_type == 3"
    ></v-text-field>
  </div>
</template>

<script>
export default {
  props: ["forms", "readonly"],
  data: () => ({
    banks: [],
  }),
  created() {
    axios
      .get("/api/get-bank")
      .then((result) => {
        this.banks = result.data;
      })
      .catch((err) => {});
  },
};
</script>