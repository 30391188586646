import _ from 'lodash'
import WarehouseCreate from 'i#/warehouse/create.vue'
import WarehouseConfigure from 'i#/warehouse/warehouse-configure.vue'
import AllProductsInAWarehouse from 'g~/all-products-in-a-warehouse.vue'
export default {
    components: {
        WarehouseCreate,
        WarehouseConfigure,
        AllProductsInAWarehouse
    },
    data() {
        return {
            locationInput: '',
            name: '',
            code: '',
            warehouses: [],
            createOrUpdateDialog: false,
            warehouseConfigDialog: false,
            showProductsLocationListDialog:false,
            edit_id: null,
            headers: [{
                    text: 'name',
                    value: 'name',
                    sortable: true
                },
                {
                    text: 'code',
                    value: 'code',
                    sortable: true
                },
                {
                    text: 'phone',
                    value: 'phone',
                    sortable: true
                },
                {
                    text: 'email',
                    value: 'email',
                    sortable: true
                },
                {
                    text: 'address',
                    value: 'address',
                    sortable: true
                },
                {
                    text: 'action',
                    value: 'action',
                    sortable: false
                },
            ],
            items: {},
            loading: false,
            options: {
                itemsPerPage: this.$store.state.itemsPerPage,
            },
            closeOnContentClick: false
        }
    },

    computed: {
        queryString() {
            return '&name=' + this.name + '&code=' + this.code
        }
    },

    watch: {
        options: {
            deep: true,
            handler() {
                this.getDataWithLoading()
            }
        },
        createOrUpdateDialog(val) {
            if (!val) {
                this.getDataWithLoading()
                this.edit_id = null
            }
        }
    },

    methods: {
        deleteItem(id) {
            this.loading = true
            this.$deleteWithConfirmation({
                    text: 'Are you sure you want delete this warehouse?',
                    url: '/api/inventory/warehouses/' + id
                })
                .then(data => {
                    this.getData()
                })
                .catch(error => {
                    this.loading = false
                })
        },
        getData: _.debounce(function () {
            axios.get('/api/inventory/warehouses', {
                    params: this.options
                })
                .then(res => {
                    this.items = res.data
                    this.loading = false
                })
        }, 400),
        getWarehouseList(query, callback) {
            axios.get('/api/inventory/warehouses?dropdown=true').then(response => {
                this.warehouses = response.data;
                callback(query);
            }).catch(error => {

            })
        },
        getDataWithLoading() {
            this.loading = true
            this.getData()
        }
    }
}