import PurchaseConvert from '../../purchase/order/convert.vue'

export default {
    name      : "show",
    components: {
        PurchaseConvert
    },
    data() {
        return {
            dialog  : false,
            expanded: [],
            headers : [
                {text: 'name', value: 'name', sortable: false},
                {text: 'code', value: 'code', sortable: false},
                {text: this.$store.state.quantity_label, value: 'quantity', sortable: false},
                {text: 'unit cost', value: 'pivot.price', sortable: false},
                {text: 'discount', value: 'pivot.discount', sortable: false},
                {text: 'total', value: 'pivot.subtotal', sortable: false}
            ],
            mainData: {},
            item    : {
                customer: {
                    name: ''
                },
                user    : {}
            },
            company : {}
        }
    },
    created() {
        this.getData()
    },
    methods   : {
        getData() {
            axios.get('/api/inventory/sale-orders/' + this.$route.params.id)
                 .then(res => {
                     this.mainData = res.data.orders
                     this.item     = res.data.orders
                     this.item.products.forEach(item => {
                         this.checkQuantity(item.pivot.warehouse_id, item)
                     })
                     this.company = res.data.company
                 })
        },
        backorderConvert(value) {
            if(value) {
                this.dialog = false
                this.getData()
            }
        },
        checkShippingStatus(item) {
            // console.log(item)
        },
        checkQuantity(warehouse_id, item) {
            if(!warehouse_id) {
                return 0
            }
            let value = item.warehouses.filter(val => {
                return val.id === warehouse_id
            })[0]

            if(!value) {
                return item.remaining_quantity = 0
            }

            if(value.pivot === undefined) {
                return item.remaining_quantity = 0
            }

            let warehouseQuantity   = value.pivot.quantity !== undefined ? value.pivot.quantity : 0
            item.base_unit          = value.pivot.unit_id
            item.remaining_quantity = warehouseQuantity

            if(item.pivot.unit_id === item.base_unit && item.pivot.quantity) {
                item.remaining_quantity = item.remaining_quantity - item.pivot.quantity
            }

            if(item.pivot.unit_id !== item.base_unit) {
                item.remaining_quantity = item.remaining_quantity / item.conversion_factor
                if(item.quantity) {
                    item.remaining_quantity = item.remaining_quantity - item.quantity
                }
            }
            return item.remaining_quantity
        },
        backorderProduct(item) {
            let product

            if(!_.isEmpty(this.mainData.orderpurchases)) {
                this.mainData.orderpurchases.forEach(val => {
                    product = _.find(val.products, {id: item.id})
                })
            }

            return !_.isEmpty(product) ? product : null
        },
        backorder(product) {
            // product_id, unit_id, warehouse_id, quantity
        },
        findUnitName(unit, id) {
            return _.find(unit, { id: Number(id) });
        },
        find_bom_type_from_product(item) {
            return !!item.bill_of_materials.length;
        },
        findCanbeBuildWith(totalQuantity, required) {
            return Math.floor(Number(totalQuantity) / Number(required));
        },
    }
}