import CustomerCreate from '../../../customer/create'
import _ from 'lodash'

export default {

    components: {
        'customer-create': CustomerCreate
    },
    props     : {
        modelId: {
            type   : Number,
            default: null
        },
        value  : {
            type   : Boolean,
            default: false
        },
    },
    data() {
        return {
            sale_profit          : false,
            settings             : [],
            model10              : [],
            customers            : [],
            units                : [],
            products             : [],
            product_id           : '',
            url                  : this.$getUrl('sale-quotations', this.value, this.modelId),
            axiosMethod          : this.$getAxiosMethod(this.value, this.modelId),
            forms                : this.$root.$data.forms,
            extra_weight         : 0,
            items                : [],
            payment_status       : 1,
            payment_type         : 1,
            loading              : false,
            menu1                : false,
            modal                : false,
            profitPercentageField: false,
            computedDate         : null,
            tooltipId            : "conversionTooltip",
            salePrintData        : null,
            customerModal        : false,
            searchProduct        : null,
            dialog               : false,
            quotations           : {},
            method               : 'Create'
        }
    },

    beforeCreate() {
        this.$root.$data.forms.fieldReset();
    },
    watch: {
        searchProduct(val) {
            if(val) {
                this.getProducts(val)
                val = null
            }
        },
        '$route.fullPath'(val) {
            //reset data

            this.forms.reset()
            this.quotations = {}
            this.product_id = ''
            this.items      = []
        },
    },

    computed: {
        isLoaded() {
            return !_.isEmpty(this.customers) || !_.isEmpty(this.products) || !_.isEmpty(this.units)
        },
        total() {
            let total         = 0
            let shipping_cost = 0
            let discount      = 0
            shipping_cost     = !this.forms.shipping_cost ? 0 : this.forms.shipping_cost
            if(this.items.length > 0) {
                for (let i = 0; i < this.items.length; i++) {
                    total += this.items[i].price
                }
            }
            total    = parseFloat(total) + parseFloat(shipping_cost)
            discount = this.forms.overall_discount ? total * parseFloat(this.forms.overall_discount) / 100 : 0
            total -= discount
            this.getTotalWeight()
            return total.toFixed(4)
        },

    },

    created() {
        this.getData()
    },
    methods: {
        getData() {
            this.$set(this.forms, 'total_weight', 0)
            this.loading = true
            this.$root.$data.erp.request.get(this.url, this, (data) => {
                this.settings   = data.settings
                this.customers  = data.customers
                this.products   = data.products
                this.units      = data.units

                this.$set(this.forms, 'quotation_date', new Date())
                this.forms.quotation_no = data.latest_sq
                this.forms.customer_id  = this.$root.getDefault_id(this.customers)
                if(this.axiosMethod === 'patch') {
                    this.quotations           = data.quotation
                    this.forms.quotation_no   = data.quotation.quotation_no
                    this.forms.quotation_date = moment(this.quotations.quotation_date, this.$root.settings.settings.date_format).format("YYYY-MM-DD")
                    this.$root.$set(this.forms, 'overall_discount', this.quotations.overall_discount);
                    this.$root.$set(this.forms, 'shipping_cost', this.quotations.shipping_cost);
                    this.forms.customer_id = parseInt(this.quotations.customer_id)
                    for (let i = 0; i < this.quotations.products.length; i++) {
                        let product = this.quotations.products[i];
                        this.items.push({
                                            product_id    : product.id,
                                            pname         : product.name,
                                            unit_price    : parseFloat(product.pivot.price).toFixed(4),
                                            unit          : parseInt(product.pivot.unit_id),
                                            units         : product.units,
                                            discount      : parseFloat(product.pivot.discount).toFixed(4),
                                            quantity      : parseFloat(product.pivot.quantity).toFixed(4),
                                            fromUnit      : product.pivot.unit_id,
                                            baseUnit      : product.base_unit_id,
                                            price         : 0,
                                            productInStock: this.getAvailableProductCount(product.warehouses),
                                            weight        : product.pivot.weight,
                                            weight_total  : product.pivot.weight_total
                                        });
                    }
                    if((!_.has(data, 'quotation'))) {
                        Swal.fire({
                                      timer            : 3000,
                                      icon             : data.type,
                                      text             : data.message,
                                      showCancelButton : false,
                                      showConfirmButton: false,
                                  })
                            .then((result) => {
                                this.$router.push({name: 'saleQuotation.index'})
                            })
                    }
                    this.getTotalWeight()
                    this.extra_weight = this.quotations.total_weight - this.forms.total_weight
                }
                this.loading = false
            });
        },
        getTotalWeight() {
            this.forms.total_weight = 0
            this.items.forEach(item => {
                if(!item.weight_total) {
                    item.weight_total = 0
                }
                this.forms.total_weight += item.weight_total
            })
        },
        findWeight(product_id) {
            let product = this.getProduct(product_id)
            let units   = product.units
            if(units.length > 0) {
                let weight = units.find((unit) => {
                    return unit.id === product.base_unit_id
                }).pivot.weight
                if(weight) {
                    return weight
                } else {
                    return 0;
                }
            } else {
                return 0;
            }
            // console.log(_.find(this.getProduct(product_id).units, { id: this.getProduct(product_id).base_unit_id
            // }));
            // return _.find(this.getProduct(this.product_id).units, { id:
            // this.getProduct(this.product_id).base_unit_id }).pivot.weight
        },
        itemTotalWeight(index) {
            this.items[index].weight_total = this.items[index].quantity
                ? this.items[index].weight * this.items[index].quantity
                : 0

            return this.items[index].weight_total
        },
        postQuotation() {
            this.loading              = true
            this.forms.items          = this.items
            this.forms.total          = this.total
            this.forms.quotation_date = moment(this.forms.quotation_date).format("YYYY-MM-DD HH:mm:ss")
            this.forms.extra_weight   = this.extra_weight

            this.forms.submit(
                this.axiosMethod,
                this.$getUrlForm(this.url),
                false,
                this.$root
            )
                .then(data => {
                    if(this.forms.selectedButton == 'pos-print') {
                        this.salePrintData = data.sale.original
                        setTimeout(function () {
                            document.getElementById('posPrintButton').click()

                        }, 2000)
                    }
                    if(this.forms.selectedButton === 'print') {
                        this.salePrintData = data.sale.original
                        setTimeout(function () {
                            document.getElementById('printButton').click()

                        }, 2000)
                    }
                    this.forms.quotation_date = moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
                    this.items                = []
                    this.loading              = false
                    Swal.fire({
                                  timer            : 3000,
                                  icon             : data.type,
                                  text             : data.message,
                                  showCancelButton : false,
                                  showConfirmButton: false,
                              })
                        .then((result) => {
                            if(!this.value){
                                if(this.method === 'Edit') {
                                    this.$router.push({name: 'saleQuotation.index'})
                                } else {
                                    this.getData()
                                }
                            }

                            if(this.value){
                                this.$emit('input', false)
                            }

                        })
                })
                .catch(error => {
                    this.loading = false
                });


        },
        // product Sale section
        addProduct() {
            if(!this.product_id) return null;
            this.items.push({
                                product_id    : this.product_id,
                                pname         : this.getProduct(this.product_id).name,
                                unit_price    : parseFloat(this.getProduct(this.product_id).price).toFixed(4),
                                price         : 0,
                                productInStock: this.getAvailableProductCount(this.getProduct(this.product_id).warehouses),
                                fromUnit      : this.getProduct(this.product_id).base_unit_id,
                                baseUnit      : this.getProduct(this.product_id).base_unit_id,
                                units         : this.getProduct(this.product_id).units,
                                unit          : this.$root.getPrimary_id(this.getProduct(this.product_id).units),
                                weight        : this.findWeight(this.product_id)
                            });

            if(this.items.length > 0) {
                this.product_id    = null
                this.searchProduct = null
            }
        },
        select_default_warehouse(warehouses) {
            let datas = null
            warehouses.filter(data => {
                if(data.is_default) {
                    datas = data.id
                }
            })
            if(!datas) {
                return warehouses[0].id
            } else {
                return datas
            }
        },
        uniqueItem(id) {
            return this.items.find((item) => {
                return item.product_id === id
            })
        },

        getProduct(id) {
            return this.products.find((product) => {
                return product.id === this.product_id
            })
        },
        getProductById(id) {
            return this.products.find((product) => {
                return product.id === id
            })
        },
        removeProduct(index) {
            this.items.splice(index, 1);
        },
        getTotalPrice(item) {
            let totalPrice = 0
            if(isNaN(item.quantity)) {
                return 'total: ' + totalPrice
                item.price = totalPrice
            } else {
                if(isNaN(item.discount)) {
                    totalPrice = (item.quantity * item.unit_price)
                } else {
                    totalPrice = (item.quantity * item.unit_price) - item.discount
                }
                item.price = totalPrice
                return 'total: ' + totalPrice
            }
        },
        getPrice(unit, index) {
            let fromUnit               = this.items[index].fromUnit
            this.items[index].fromUnit = unit
            if(fromUnit === unit) {
                return
            }

            let url = '/api/inventory/unitconversions/' + unit + '/' + fromUnit + '/1'
            axios.post(url)
                 .then(response => {
                     let conversion               = response.data.conversion
                     this.items[index].unit_price = parseFloat(response.data.quantity * this.items[index].unit_price).toFixed(4)

                 })
        },
        getAvailableProductCount(warehouses) {
            let quantity = 0
            for (var i = 0; i < warehouses.length; i++) {
                let warehouse = warehouses[i]
                quantity += parseFloat(warehouse.pivot.quantity)

            }
            return quantity
        },
        getNewCustomer(customerDetails) {
            this.forms.quotation_date = moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
            this.forms.payment_status = 3
            this.forms.status         = 1
            this.customers.push(customerDetails);
            this.forms.customer_id = customerDetails.id;
            this.dialog            = false
        },
        onCancel() {
            this.$router.push({name: "sale.index"})

        },
        getProducts: _.debounce(function (query) {
            if(query !== '') {
                axios.post('/api/inventory/products/getProducts', {val: query, isSale: true})
                     .then(response => {
                         this.products = response.data.products
                         if(this.products.length === 1) {
                             this.product_id = this.products[0].id
                             this.addProduct()
                             this.searchProduct = null
                         }
                         this.loading = false
                     })
                     .catch(error => {
                         alert(error.message)
                     })
            } else {
                this.loading  = true;
                this.products = []
                return null
            }
        }, 1000),
        // getProducts(query) {
        //     if (query !== '') {
        //         setTimeout(() => {
        //             axios.post('/api/inventory/products/getProducts', {val: query, isSale: true})
        //                 .then(response => {
        //                     this.products = response.data.products
        //                     this.loading = false;
        //                 })
        //                 .catch(error => {
        //                     alert(error.message)
        //                 })
        //         }, 200);
        //     } else {
        //         this.loading = true;
        //         this.products = []
        //         return null
        //     }
        // },
        getInputField(event) {
            this.profitPercentageField = event.target.checked;

        },
        initPreview() {
            Erp.salePurchase.initialPreviewSetup(this)
        },
        closeDatePicker() {
            let currentTime           = moment(new Date()).format("HH:mm:ss")
            let quotationDate         = this.forms.quotation_date
            let selectedDate          = moment(quotationDate).format(this.$root.settings.settings.date_format)
            // console.log(selectedDate + ' ' + currentTime)
            this.computedDate         = moment(selectedDate + ' ' + currentTime, this.$root.settings.settings.date_format + " HH:mm:ss").format(this.$root.settings.settings.date_format + " HH:mm:ss")
            this.forms.quotation_date = moment(this.forms.quotation_date + ' ' + currentTime).format("YYYY-MM-DD HH:mm:ss")
            this.menu1                = false
        }
    }
}