<template>
  <v-card-actions v-if="show_only_submit_button">
    <v-btn
        dark
        tabindex="7"
        type="submit"
        color="success"
        :loading="loading"
        value="submit-close"
    >
      Submit
    </v-btn>
  </v-card-actions>
  <v-card-actions v-else>
    <v-btn
        dark
        tabindex="7"
        type="submit"
        color="success"
        v-if="forms.id"
        :loading="loading"
        value="update-close"
    >
      Update
    </v-btn>
    <v-btn
        dark
        tabindex="7"
        type="submit"
        color="success"
        value="submit-new"
        :loading="loading"
        v-if="!value && !forms.id"
    >
      Submit
    </v-btn>
    <v-btn
        dark
        type="submit"
        tabindex="8"
        color="success"
        value="submit-new"
        :loading="loading"
        v-if="value && !forms.id"
    >
      Submit & New
    </v-btn>
    <v-btn
        dark
        tabindex="9"
        type="submit"
        color="success"
        :loading="loading"
        value="submit-close"
        v-if="value && !forms.id"
    >
      Submit & Close
    </v-btn>
    <v-btn
        tabindex="10"
        color="warning"
        v-if="!forms.id"
        @click="$emit('onCancel')"
    >
      Reset
    </v-btn>
    <v-btn
        tabindex="11"
        v-if="!value"
        color="primary"
        @click="$router.back()"
    >
      back
    </v-btn>
    <v-spacer/>
    <v-btn
        text
        v-if="value"
        tabindex="12"
        @click="$emit('close-modal', false)"
    >
      Close
    </v-btn>
  </v-card-actions>
</template>

<script>
export default {
  name    : "form-actions",
  props   : {
    forms  : {
      type: Object
    },
    value  : {
      type   : Boolean,
      default: false
    },
    loading: {
      type: Boolean
    }
  },
  computed: {
    show_only_submit_button() {
      return this.$route.name === 'purchase.create' || this.$route.name === 'sale.create'
    }
  }
}
</script>

<style scoped>

</style>