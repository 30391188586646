<template>
  <v-autocomplete
      clearable
      cache-items
      hide-details
      :items="items"
      item-value="id"
      item-text="name"
      v-model="select"
      label="brand name"
      v-bind:solo="solo"
      v-bind:dense="dense"
      :search-input.sync="searchValue"
  />
</template>

<script>
export default {
  name : "search-brand",
  props: {
    value: {
      type   : Number,
      default: null
    },
    dense: {
      type   : Boolean,
      default: false
    },
    solo : {
      type   : Boolean,
      default: false
    },
  },
  watch: {
    searchValue(val) {
      axios.get('/api/inventory/brands', {
        params: {
          name    : val,
          dropdown: true
        }
      })
           .then(res => {
             this.items = res.data
           })
    },
    select(val) {
      this.$emit('input', val)
    }
  },
  data() {
    return {
      searchValue: null,
      items      : [],
      select     : null
    }
  },

}
</script>

<style scoped>

</style>