<template>
  <div>
    <qrcode-scanner
        :qrbox="250"
        :fps="10"
        style="width: 500px;"
        @result="onScan"
    />

    <p>{{ barcode_scan }}</p>
    <br>
    assume we have warehouse name, location, code field at the top, and then we will have things below
    <br>
    <br>
    <p>Warehouse Configuration</p>
    <v-select :items="['picking', 'storage']" label="select warehouse type" v-model="warehouse_type"></v-select>
    <template v-if="warehouse_type==='picking'">
      <br>
      <br>
      <template v-for="(pd, index) in picking_details">
        <div style="background-color: white">
          <p>
            {{ index + 1 }}
          </p>
          <v-btn @click="deletePickingDetail()">delete</v-btn>
          <v-text-field v-model="pd.location" label="Location"></v-text-field>
          <v-select :items="picking_types" v-model="pd.type" label="select picking location type"></v-select>
          <p>barcode: {{ index }}</p>
        </div>
      </template>
      <br>
      <v-btn @click="addPickingDetail()">Add more</v-btn>
      <hr>
      <br>

    </template>

    <template v-if="warehouse_type==='storage'">
      <v-row>
        <v-col>
          <v-autocomplete :items="warehouse_data" return-object v-model="selected_warehouse" label="select warehouse"
                          item-text="name" item-value="id"></v-autocomplete>
        </v-col>
        <v-col>
          <v-combobox chips :items="filteredIsle" return-object v-model="selected_isle" label="select old or type new Isle" item-text="name"
                      item-value="id"></v-combobox>
        </v-col>
        <v-col>
          <v-combobox chips multiple :items="filteredBin" return-object v-model="selected_bin" label="select old or type new bin" item-text="name"
                      item-value="id" persistent-hint hint="if you want to create new, type and press Enter" ></v-combobox>
        </v-col>
      </v-row>
      <v-autocomplete :items="warehouse_data" return-object v-model="selected_warehouse" label="select warehouse"
                      item-text="name" item-value="id"></v-autocomplete>
      <v-combobox chips :items="filteredIsle" return-object v-model="selected_isle" label="select old or type new Isle" item-text="name"
                  item-value="id"></v-combobox>
      <v-combobox chips multiple :items="filteredBin" return-object v-model="selected_bin" label="select old or type new bin" item-text="name"
                  item-value="id" persistent-hint="if you want to create new, type and press Enter"></v-combobox>
      <br>
      <br>
      <template v-for="(sd, index) in storage_details">
        <div style="background-color: white">
          <v-select v-model="storage_details[index].type" :items="storage_types" label="select type">
            <template v-slot:append-outer v-if="storage_details[index].type==='storage'">
              <v-btn @click="addIsle(index)">add isle</v-btn>
            </template>
          </v-select>
          <template v-if="storage_details[index].type==='staging' || storage_details[index].type==='both'">
            offer field
            is default
          </template>
          <template v-if="storage_details[index].type==='storage'">
            <template v-for="(is, isIndex) in storage_details[index]['isle']">

              <v-text-field label="type isle"
                            v-model="storage_details[index]['isle'][isIndex].name"
              >
                <template v-slot:append-outer>
                  <v-btn @click="addRack(index, isIndex)">add rack</v-btn>
                </template>
              </v-text-field>
              <template v-for="(rack, raIndex) in storage_details[index]['isle'][isIndex].rack"
                        v-if="storage_details[index]['isle'][isIndex].rack!==undefined && storage_details[index]['isle'][isIndex].rack.length>0"
              >
                <v-text-field
                    label="type rack"
                    v-model="storage_details[index]['isle'][isIndex].rack[raIndex].name"
                    :prefix="`isle ${storage_details[index]['isle'][isIndex].name}`"
                >
                  <template v-slot:append-outer>
                    <v-btn @click="addBin(index, isIndex, raIndex)">add bin</v-btn>
                  </template>
                </v-text-field>
                <template v-for="(bin, baIndex) in storage_details[index]['isle'][isIndex].rack[raIndex].bin">
                  <v-text-field
                      label="type bin"
                      :prefix="storage_details[index]['isle'][isIndex].rack[raIndex].name"
                      v-model="storage_details[index]['isle'][isIndex].rack[raIndex].bin[baIndex].name"
                  ></v-text-field>
                </template>
              </template>
            </template>
            <!--            <v-text-field-->
            <!--                label="type isle"-->
            <!--                v-model="storage_details[index].isle"-->
            <!--                @input="changeOtherStorageDetail($event, index)"-->
            <!--            >-->
            <!--            </v-text-field>-->

            <!--            <v-text-field v-model="storage_details[index].bin" :prefix="storage_details[index].rack" label="type bin">-->
            <!--            </v-text-field>-->
            <!--            <barcode-->
            <!--                height="35px"-->
            <!--                font-size="10px"-->
            <!--                v-if="storage_details[index].isle"-->
            <!--                :value="`${$root.companySettings.id}-I${storage_details[index].isle}-R${storage_details[index].rack}-B${storage_details[index].bin}`"-->
            <!--            />-->
            <!--            barcode-->
            <!--            {{-->
            <!--              `${$root.companySettings.id}-I${storage_details[index].isle}-R${storage_details[index].rack}-B${storage_details[index].bin}`-->
            <!--            }}-->
          </template>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name    : "warehouse",
  data() {
    return {
      selected_bin      : null,
      selected_isle     : null,
      selected_warehouse: null,
      warehouse_data    : [
        {
          id  : 1, name: 'test',
          isle: [
            {
              id          : 1,
              name        : 'isle 1',
              warehouse_id: 1,
              bin         : [
                {id: 1, name: 'bin 1', isle_id: 1},
                {id: 2, name: 'bin 2', isle_id: 1},
              ]
            },
            {
              id          : 2,
              name        : 'isle 2',
              warehouse_id: 1,
              bin         : [
                {id: 3, name: 'bin 1', isle_id: 2},
                {id: 4, name: 'bin 2', isle_id: 2},
              ]
            },
          ],

        }
      ],
      barcode_scan      : '',
      warehouse_types   : [
        {
          value: 'picking',
          text : 'picking'
        },
        {
          value: 'storage',
          text : 'storage'
        },
      ],
      warehouse_type    : '',

      picking_types        : ['Mobile', 'Fixed'],
      picking_details      : [
        [
          {location: ''},
          {type: ''},
          {barcode: ''}
        ]
      ],
      storage_types        : [
        'storage', 'staging', 'both'
      ],
      storage_details      : [
        {
          isle: [
            {
              name: ''
            }
          ],
        },
        {barcode: ''},
      ],
      created_storage_value: [
        [
          {isle: '1'},
          {rack: '1'},
          {bin: '1'},
          {type: 'storage'},
          {barcode: ''},
        ],
        []
      ],
      items                : [],
      options              : {
        dropdown: true
      },
      loading              : false,

    }
  },
  computed: {
    filteredIsle() {
      console.log(this.selected_warehouse)
      if(this.selected_warehouse) {
        return this.selected_warehouse.isle
      }
    },
    filteredBin() {
      if(!_.isEmpty(this.selected_isle) && _.isObject(this.selected_isle)) {
        return this.selected_isle.bin
      }
    }
  },
  created() {
    axios.get('/api/inventory/warehouses', {
      params: this.options
    })
         .then(res => {
           this.items   = res.data
           this.loading = false
         })
  },
  methods : {
    addPickingDetail() {
      this.picking_details.push({location: '', type: ''})
    },
    deletePickingDetail(index) {
      this.$delete(this.picking_details, index)
    },
    changeOtherStorageDetail($e, index) {
      console.log($e, index)
    },
    addRack(index, isIndex) {
      if(this.storage_details[index]['isle'][isIndex].rack === undefined) {
        this.$set(this.storage_details[index]['isle'][isIndex], 'rack', [{name: ''}])
      } else {
        this.storage_details[index]['isle'][isIndex].rack.push({name: ''})
      }

    },
    addIsle(index) {
      this.storage_details[index]['isle'].push({name: ''})
    },
    addBin(index, isIndex, raIndex) {
      if(this.storage_details[index]['isle'][isIndex]['rack'][raIndex].bin === undefined) {
        this.$set(this.storage_details[index]['isle'][isIndex]['rack'][raIndex], 'bin', [{name: ''}])
      } else {
        this.storage_details[index]['isle'][isIndex]['rack'][raIndex].bin.push({name: ''})
      }
    },
    checkObject(val) {
      console.log(val)
    },
    onScan(decodedText, decodedResult) {
      if(decodedText) {
        this.barcode_scan = decodedText
      }
    }
  }
}
</script>
