module.exports = [
    {
        path:'/inventory/purchases/index',
        name:'purchase.index',
        component: require('../views/inventory/product/purchase/index.vue').default
    },
    {
        path:'/inventory/purchases/create',
        name: 'purchase.create',
        component: require('../views/inventory/product/purchase/create.vue').default
    },
    {
        path: '/inventory/purchases/:id',
        name: 'purchase.show',
        component: require('../views/inventory/product/purchase/show.vue').default
    },
    {
        path:'/inventory/purchases/:id/edit',
        name:'purchase.edit',
        component: require('../views/inventory/product/purchase/create.vue').default
    },
    {
        path:'/inventory/purchase-quotations/create',
        name:'purchaseQuotation.create',
        component: require('../views/inventory/product/purchase/quotation/create.vue').default
    },
    {
        path:'/inventory/purchase-quotations/index',
        name:'purchaseQuotation.index',
        component: require('../views/inventory/product/purchase/quotation/index.vue').default
    },
    {
        path:'/inventory/purchase-quotations/:id',
        name:'purchaseQuotation.show',
        component: require('../views/inventory/product/purchase/quotation/show.vue').default
    },
    {
        path:'/inventory/purchase-quotations/:id/edit',
        name:'purchaseQuotation.edit',
        component: require('../views/inventory/product/purchase/quotation/create.vue').default
    },
    {
        path:'/inventory/purchase-quotations-convert/:id',
        name:'purchaseQuotation.convert',
        component: require('../views/inventory/product/purchase/quotation/convert.vue').default
    },
    {
        path:'/inventory/purchase-return/:id',
        name:'purchase.returns',
        component: require('../views/inventory/product/purchase/returns.vue').default
    },
    {
        path:'/inventory/purchase-orders/create',
        name:'purchase.order.create',
        component: require('../views/inventory/product/purchase/order/create.vue').default
    },
    {
        path:'/inventory/purchase-orders/index',
        name:'purchase.order.index',
        component: require('../views/inventory/product/purchase/order/index.vue').default
    },
    {
        path:'/inventory/purchase-orders/:id',
        name:'purchase.order.show',
        component: require('../views/inventory/product/purchase/order/show.vue').default
    },
    {
        path:'/inventory/purchase-orders/:id/edit',
        name:'purchase.order.edit',
        component: require('../views/inventory/product/purchase/order/create.vue').default
    },
    {
        path:'/inventory/purchase-orders-convert/:id',
        name:'purchase.order.convert',
        component: require('../views/inventory/product/purchase/order/convert.vue').default
    },
    {
        path:'/inventory/purchase-orders-shipment',
        name:'purchase.order.shipment',
        component: require('../views/inventory/product/purchase/order/shipment.vue').default
    }
];