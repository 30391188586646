export default {
    data() {
        return {
            values          : [],
            products        : [],
            product_quantity: [],
            expenses        : {}
        }
    },
    computed: {
        dataReady() {
            if(typeof this.values[0] !== 'undefined') {
                return true
            }
        }
    },
    created() {
        let url = '/api/report/home'
        this.$root.$data.erp.request.get(url, this, (data) => {
            this.values   = this.sortDataByLabel(data)
            this.expenses = data.expenses
            this.products[0]         = data.products.product_name
            this.products[1]         = data.products.product_total
            this.product_quantity[0] = data.product_quantity.product_quantity_name
            this.product_quantity[1] = data.product_quantity.product_quantity_total
        })
    },
    methods : {
        sortDataByLabel(data) {
            let purchaseValue = []
            let salesValue    = []
            let labels        = []
            labels            = _.union(data.purchases.purchase_dates.concat(data.sales.sale_dates))
            labels.map(date => {
                //collect Purchase total according to date
                if(_.includes(data.purchases.purchase_dates, date)) {
                    purchaseValue.push(data.purchases.purchase_totals[data.purchases.purchase_dates.indexOf(date)])
                } else {
                    purchaseValue.push(0)
                }

                if(_.includes(data.sales.sale_dates, date)) {
                    salesValue.push(data.sales.sale_totals[data.sales.sale_dates.indexOf(date)])
                } else {
                    salesValue.push(0)
                }
            })
            return [labels, purchaseValue, salesValue]
        }
    }
}