<template>
  <div>
    <v-card outlined>
      <v-btn class="float-right"
             icon
             @click="$htmlToPaper('list_print_bootstrap')"
      >
        <v-icon>mdi-printer</v-icon>
      </v-btn>
      <v-card-text id="list_print_div">
        <v-data-table
            :loading="loading"
            :headers="headers"
            :items="items.data"
            class="elevation-0"
            :options.sync="options"
            :server-items-length="items.total"
            :footer-props="{
          itemsPerPageOptions: $store.state.itemsPerPageOptions,
        }"
        >
          <template v-slot:top>
            <h2 class="pa-3">Unbox Event</h2>
          </template>
        </v-data-table>
      </v-card-text>
      <list-print-bootstrap
          :title="'Unbox Event'"
          :columns="headers"
          :rows="items.data"
          style="visibility: collapse"
          :date_fields="['created_at']"
      >
      </list-print-bootstrap>
    </v-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      menu      : false,
      modal     : false,
      dialog    : false,
      loading   : false,
      itemDetail: null,
      options   : {
        sortBy      : ['created_at'],
        sortDesc    : [true],
        itemsPerPage: this.$store.state.itemsPerPage,
      },
      items     : {},
      headers   : [
        {text: "product name", value: "product_name", sortable: "true"},
        {text: "Quantity", value: "quantity"},
        {text: "unit", value: "unit_key"},
        {text: "warehouse", value: "warehouse_name"},
        {text: "created at", value: "created_at"},
      ],
    }
  },
  watch  : {
    options: {
      deep: true,
      handler() {
        this.loading = true;
        this.getData();
      },
    },
  },
  methods: {
    getData() {
      axios
          .get("/api/inventory/product-composite-unbox", {
            params: this.options,
          })
          .then((result) => {
            this.items   = result.data;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
          });
    },
  },
};
</script>