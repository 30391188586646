import _ from 'lodash'
import SupplierCreate from 'i#/supplier/create.vue'
import SupplierShow from 'i#/supplier/show.vue'

export default {
    components: {
        SupplierCreate,
        SupplierShow
    },
    data() {
        return {
            suppliers           : [],
            items               : {},
            loading             : false,
            options             : {
                sortDesc    : [true],
                itemsPerPage: this.$store.state.itemsPerPage,
            },
            createOrUpdateDialog: false,
            edit_id             : null,
            showDialog:false,
            show_id:null,
            closeOnContentClick    : true,
            headers             : [
                {text: 'company', value: 'company', sortable: true},
                {text: 'id', value: 'code', sortable: true},
                {text: 'name', value: 'name', sortable: true},
                {text: 'email', value: 'email', sortable: true},
                {text: 'phone', value: 'phone', sortable: true},
                {text: 'due', value: 'due', sortable: true},
                // {text: 'created at', value: 'created_at', sortable: true},
                {text: 'action', value: 'action', sortable: false},
            ],
        }
    },

    computed: {
        queryString() {
            return '&user_name=' + this.user_name + '&phone=' + this.phone + '&company=' + this.company + '&user_email=' + this.user_email
        }
    },

    watch: {
        options: {
            deep: true,
            handler() {
                this.loading = true
                this.getDataWithLoading()
            }
        },
        createOrUpdateDialog(val) {
            if(!val) {
                this.getDataWithLoading()
                this.edit_id = null
            }
        },
    },

    methods: {
        deleteItem(id) {
            this.loading = true
            this.$deleteWithConfirmation({
                                             text: 'Are you sure you want delete this supplier?',
                                             url : '/api/inventory/suppliers/' + id
                                         })
                .then(data => {
                    this.getData()
                    this.loading = false
                })
                .catch(error => {
                    this.loading = false
                })

        },
        getData: _.debounce(function () {
            axios.get('/api/inventory/suppliers', {
                params: this.options
            })
                 .then(res => {
                     this.items   = res.data
                     this.loading = false
                 })
        }, 800),

        getSupplierList(query, callback) {
            axios.get('/api/inventory/suppliers?dropdown=true').then(response => {
                this.suppliers = response.data;
                callback(query);
            }).catch(error => {

            })
        },
        getDataWithLoading() {
            this.loading = true
            this.getData()
        }
    }
}