export default {
    name: "warehouse-mapping-select",
    props: {
        value: {
            type: String,
            default: "",
        },
        solo: {
            type: Boolean,
            default: false
        },
        outlined: {
            type: Boolean,
            default: false
        },
        autocomplete:{
            type:Boolean,
            default:false
        },
        productId:{
            type:Number,
            default:null
        }
    },
    data() {
        return {
            search:null,
            isBarcode:false,
            dialog: false,
            type: "storage",
            warehouse_id: null,
            isle: null,
            isle_id: null,
            isle_name: "",
            rack: null,
            rack_id: null,
            rack_name: "",
            bin: null,
            bin_id: null,
            bin_name: "",
            picking: null,
            picking_id: null,
            picking_name: null,
            warehouse_list: [],
            isle_list: [],
            rack_list: [],
            bin_list: [],
            picking_list: [],
            type_list: this.$store.state.warehouseType,
            showFindButton: false,
            location_list:[],
        };
    },
    created() {
        this.getWarehouse();
    },
    computed: {
        findIsle_location() {
            return this.warehouse_id && this.type;
        },
        findRack() {
            return this.warehouse_id && this.type === "storage" && this.isle;
        },
        findBin() {
            return this.findRack && this.rack;
        },
        findPicking() {
            return (this.type === "picking") & this.warehouse_id;
        },
        locationError() {
            if (this.warehouse_id && this.type === 'storage') {
                if (this.isle_id && this.rack_id && this.bin_id) return false
            }
            if (this.warehouse_id && this.type === 'picking') {
                if (this.picking_id) return false
            }
            return true
        },
        generatedLocation: {
            get() {
                // debugger;
                // console.log('from computed', this.search)
                this.showFindButton = false;
                if (this.type === "storage") {
                    if (
                        this.isle_id &&
                        this.rack_id &&
                        this.bin_id &&
                        this.warehouse_id
                    ) {
                        return this.$setStorageLocation(
                            this.warehouse_id,
                            this.isle_id,
                            this.rack_id,
                            this.bin_id
                        )
                    }
                }
                if (this.type === "picking") {
                    if (this.warehouse_id && this.picking_id) {
                        return this.$setPickingLocation(this.warehouse_id, this.picking_id)
                    }
                }
            },
            set(newVal) {
                if (newVal && !this.autocomplete) {
                    this.resetValue()
                    this.showFindButton = true;
                }
                if (!newVal) {
                    this.$emit('input', '')
                }
            },
        },
    },
    watch: {
        search(val){
            if(!val) return null
            let arr = val.split("-");
            this.processScanValue(arr);
        },
        generatedLocation(val) {
            // console.log('from watch computed', val)
            this.$emit('input', val)
        },
        warehouse_id(newVal, oldVal) {
            if (this.showFindButton) return false;
            if (newVal !== oldVal) {
                this.type = "";
                this.isle = null;
                this.isle_id = null;
                this.isle_name = "";
                this.rack = null;
                this.rack_id = null;
                this.rack_name = "";
                this.bin = null;
                this.bin_id = null;
                this.bin_name = "";
                this.isle_list = [];
                this.rack_list = [];
                this.bin_list = [];
                this.picking_list = [];
            }
        },
        isle(val) {
            if (!_.isEmpty(val) && _.isObject(val)) {
                this.isle_id = val.id;
                this.isle_name = val.name;
            } else {
                this.isle_id = null;
                this.isle_name = null;
            }
        },
        rack(val) {
            if (!_.isEmpty(val) && _.isObject(val)) {
                this.rack_id = val.id;
                this.rack_name = val.name;
            }
        },
        bin(val) {
            if (!_.isEmpty(val) && _.isObject(val)) {
                this.bin_id = val.id;
                this.bin_name = val.name;
            } else {
                this.bin_id = null;
                this.bin_name = null;
            }
        },
        picking(val) {
            if (!_.isEmpty(val) && _.isObject(val)) {
                this.picking_id = val.id;
                this.picking_name = val.location;
            } else {
                this.picking_id = null;
                this.picking_name = null;
            }
        },
        findIsle_location(newVal, oldVal) {
            if (newVal !== oldVal) {
                if (newVal && this.type === "storage") {
                    this.processIsle();
                }
                if (newVal && this.type === "picking") {
                    this.processPicking();
                }
            }
        },
        findRack(newVal, oldVal) {
            if (!_.isEmpty(newVal) && _.isObject(newVal)) {
                if (!_.isEqual(newVal, oldVal)) {
                    this.processRack();
                }
            }
        },
        findBin(newVal, oldVal) {
            if (!_.isEmpty(newVal) && _.isObject(newVal)) {
                if (!_.isEqual(newVal, oldVal)) {
                    this.processBin();
                } else {
                    this.bin_list = [];
                    this.bin = null;
                }
            }
        },
        findPicking(newVal, oldVal) {
            if (!_.isEmpty(newVal) && _.isObject(newVal)) {
                if (!_.isEqual(newVal, oldVal)) {
                    this.processPicking();
                }
            }
        },
    },
    methods: {
        onScan(decodedText, decodedResult) {
            this.$emit('input', decodedText)
            if(decodedText) {
                this.processBarcode(decodedText)
            }
        },
        processBarcode:_.debounce(function(decodedText){
            let arr = decodedText.split("-");
            this.processScanValue(arr)
            // this.$emit('input', decodedText)
        }, 200),
        resetValue() {
            this.type = "";
            this.isle = null;
            this.isle_id = null;
            this.isle_name = "";
            this.rack = null;
            this.rack_id = null;
            this.rack_name = "";
            this.bin = null;
            this.bin_id = null;
            this.bin_name = "";
            this.isle_list = [];
            this.rack_list = [];
            this.bin_list = [];
            this.picking_list = [];
            this.warehouse_id = null;
        },
        scanWarehouseOuter() {
            let value = this.$refs.inputFromOutside.$el.querySelector("input").value;
            let arr = value.split("-");
            this.processScanValue(arr);
        },
        scanWarehouse() {
            let value = this.$refs.inputLocation.$el.querySelector("input").value;
            let arr = value.split("-");
            this.processScanValue(arr);
        },
        processScanValue(arr) {
            if (arr.length === 3) {
                this.warehouse_id = parseInt(arr[1]);
                this.$nextTick((n) => {
                    this.type = "picking";
                    this.processPicking();
                    this.picking = _.find(this.picking_list, {
                        id: parseInt(arr[2])
                    });
                });
            }
            if (arr.length === 5) {
                this.warehouse_id = parseInt(arr[1]);
                this.$nextTick(()=>{
                    this.type = "storage";
                    this.processIsle();
                })
                this.$nextTick((n) => {
                    this.isle = _.find(this.isle_list, {
                        id: parseInt(arr[2])
                    });
                    this.processRack();
                });
                this.$nextTick((n) => {
                    this.rack = _.find(this.rack_list, {
                        id: parseInt(arr[3])
                    });
                    this.processBin();
                });
                this.$nextTick((n) => {
                    this.bin = _.find(this.bin_list, {
                        id: parseInt(arr[4])
                    });
                });
            }
        },
        processBin() {
            this.bin_list = this.rack.bins;
        },
        processRack() {
            this.rack_list = this.isle.racks;
        },
        processIsle() {
            let selectedWarehouse = _.find(this.warehouse_list, (w) => {
                return w.id === this.warehouse_id;
            });
            this.isle_list = !_.isEmpty(selectedWarehouse) ? selectedWarehouse.isles : [];

        },
        processPicking() {
            let selectedWarehouse = _.find(this.warehouse_list, (w) => {
                return w.id === this.warehouse_id;
            });
            this.picking_list = selectedWarehouse.pickings;
        },
        findLocation() {
            let selectedWarehouse = _.find(this.warehouse_list, (w) => {
                return w.id === this.warehouse_id;
            });
            this.picking_list = selectedWarehouse.pickings;
        },
        getWarehouse() {
            axios
                .get("/api/inventory/warehouses", {
                    params: {
                        dropdown: true,
                        withMapping: true,
                        product_id:this.productId
                    },
                })
                .then((res) => {
                    this.warehouse_list = res.data;
                    if(!_.isEmpty(this.warehouse_list)){
                        let location_list = []
                        this.warehouse_list.forEach(wl=>{
                            if(!_.isEmpty(wl.location_string)){
                                location_list.push(wl.location_string)
                            }
                        })
                        if(location_list.length){
                            location_list  =_.flatten(location_list)
                            this.location_list = _.sortedUniq(location_list).reverse()
                        }
                    }
                    if (this.value) {
                        let arr = this.value.split('-')
                        this.warehouse_id = parseInt(arr[1])
                        this.processScanValue(arr)
                        if (arr.length === 3) {
                            this.type = 'picking'
                        }
                        if (arr.length === 5) {
                            this.type = 'storage'
                        }
                    }
                });
        }
    },
};