import _ from "lodash";

export default {
    data() {
        return {
            permissionsJson: require("../permission.json"),
            timePermissions: {
                transaction: [
                    {method: "create", value: null, hours: null},
                    {method: "edit", value: null, hours: null},
                    {method: "delete", value: null, hours: null},
                ],
            },
            modules:[
                "warehouse",
                {"supplier":'#e2e2e2'},
                {"purchase":'#e2e2e2'},
                {"purchaseQuotation":'#e2e2e2'},
                {"purchase_order":'#e2e2e2'},
                {"bill_paid":'#e2e2e2'},
                {"shipment_from_supplier":'#e2e2e2'},
                "customer",
                "sales",
                {"category":'#e2e2e2'},
                "brand",
                "saleQuotation",
                "income",
                "sale_order",
                "payment_receive",
                "shipment_to_customer",
                {"product":'#e2e2e2'},
                {"warranty":'#e2e2e2'},
                {"productDamage":'#e2e2e2'},
                "bill_of_material",
                {"unitMapping":'#e2e2e2'},
                {"unit":'#e2e2e2'},
                {"productUnit":'#e2e2e2'},
                "expense",
                "other_user",
                {"employee":'#e2e2e2'},
                {"salary":'#e2e2e2'},
                "bank",
                "transaction",
                "accounts",
                {"purchaseReport":'#e2e2e2'},
                {"saleReport":'#e2e2e2'},
                {"productReport":'#e2e2e2'},
                {"warehouseReport":'#e2e2e2'},
                {"expenseReport":'#e2e2e2'},
                {"incomeReport":'#e2e2e2'},
                {"overallReport":'#e2e2e2'},
                {"supplierReport":'#e2e2e2'},
                {"customerReport":'#e2e2e2'},
                "settings",
                "userRole"
            ],
            headers        : [
                {text: "name", value: "name"},
                {text: "description", value: "description"},
                {text: "created at", value: "created_at"},
                {text: "action", value: "action", sortable: false},
            ],
            options        : {
                itemsPerPage: this.$store.state.itemsPerPage,
            },
            loading        : false,
            forms          : {
                id         : null,
                errors     : {},
                name       : "",
                description: "",
                permissions: [],
            },
            roles          : [],
            url            : "/api/rbac/role",
        };
    },
    watch  : {
        'timePermissions.transaction': {
            deep: true,
            handler(val) {
                val.forEach(v => {
                    if(v.value === null) {
                        delete v.day
                        v.hours = null
                    }
                })
            }
        },
        options                      : {
            deep: true,
            handler() {
                this.loading = true;
                this.getData();
            },
        },
    },
    methods: {
        findRawColor(model){
            let val = _.find(this.modules, model)
            if(val){
                return val[model]
            }
        },
        getLabel(val){
            if(val==='create'){
                return 'Time range allowed to create transaction'
            }
            if(val==='edit'){
                return 'Time range allowed to Edit '
            }
            if(val==='delete'){
                return 'Time range allowed to delete'
            }
            return 'day'
        },
        getMethodName(method) {
            if(method === "index") return "list";
            if(method === "crud") return "Full Access";
            return method;
        },
        deleteItem(item) {
            if(confirm("are you sure")) {
                this.loading = true;
                axios
                    .delete(this.url + "/" + item.id)
                    .then((res) => {
                        Swal.fire({
                                      icon : res.data.type,
                                      text : res.data.message,
                                      timer: 3000,
                                  }).then((result) => {
                            this.getData();
                            this.loading = false;
                        });
                    })
                    .catch((error) => {
                        this.loading = false;
                        Swal.fire({
                                      icon: error.response.data.type,
                                      text: error.response.data.message,
                                  });
                    });
            }
        },
        editItem(item) {
            this.resetForms()
            this.forms.id          = item.id;
            this.forms.name        = item.name;
            this.forms.description = item.description;
            this.forms.permissions = item.permissions;
            this.forms.permissions.forEach((p) => delete p.role_id);
            this.$nextTick(() => this.$refs.name.focus());
            if(!_.isEmpty(item.timepermissionFormatter)) {
                this.timePermissions = item.timepermissionFormatter
            }
        },
        getData: _.debounce(function () {
            this.loading = true;
            axios.get(this.url, {params: this.options}).then((res) => {
                this.loading = false;
                this.roles   = res.data.roles;
            });
        }, 400),
        submitRole() {
            this.forms.timePermissions = this.timePermissions
            !this.forms.id ? this.postRole() : this.updateRole()
        },
        postRole() {
            this.resetForms()
            axios
                .post(this.url, this.forms)
                .then((res) => {
                    Swal.fire({
                                  icon : res.data.type,
                                  text : res.data.message,
                                  timer: 3000,
                              }).then((result) => {
                        this.getData();
                        this.loading = false;
                        this.resetForms();
                        this.$nextTick(() => this.$refs.name2.focus());
                        this.$emit("get-data");
                    });
                })
                .catch((error) => {
                    this.loading      = false;
                    this.forms.errors = error.response.data.errors;
                });
        },
        resetForms() {
            this.forms.id          = null;
            this.forms.name        = "";
            this.forms.description = "";
            this.forms.permissions = [];
            this.forms.errors      = {};
            this.timePermissions   = {
                transaction: [
                    {method: "create", value: null, hours: null},
                    {method: "edit", value: null, hours: null},
                    {method: "delete", value: null, hours: null},
                ],
            }
        },
        updateRole() {
            axios
                .patch(this.url + "/" + this.forms.id, this.forms)
                .then((res) => {
                    Swal.fire({
                                  icon : res.data.type,
                                  text : res.data.message,
                                  timer: 3000,
                              }).then((result) => {
                        this.getData();
                        this.loading = false;
                        this.resetForms();
                        this.$nextTick(() => this.$refs.name2.focus());
                        this.$emit("get-data");
                    });
                })
                .catch((error) => {
                    this.loading = false;
                    if(error.response.status === 422) {
                        this.forms.errors = error.response.data.errors;
                    } else {
                        Swal.fire({
                                      icon: error.response.data.type,
                                      text: error.response.data.message,
                                  });
                    }
                });
        },
    },
};