export default {
    name    : "payable",
    data    : () => ({
        items  : {
            purchases      : [],
            sales          : [],
            purchase_return: {},
            sale_return    : {},
            payroll        : {}
        },
        loading: false,
    }),
    computed: {
        supplierBalance() {
            return this.items.purchases.purchase_total - this.items.purchases.purchase_paid
        },
        customerBalance() {
            return this.items.sales.sale_paid - this.items.sales.sale_total
        },
        totalBalance() {
            return this.supplierBalance
                + this.customerBalance
                + this.items.purchase_return.negative_balance
                + this.items.sale_return.negative_balance
                + this.items.payroll.total_due
        }
    },
    created() {
        this.loading = true
        this.getData()
    },

    methods : {
        getData() {
            axios.get('/api/accounts/payable', {params: this.options})
                 .then(res => {
                     this.items   = res.data
                     this.loading = false
                 })
        }
    }
}