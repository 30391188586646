<template>
  <v-autocomplete
      solo
      clearable
      cache-items
      :items="items"
      item-value="id"
      v-model="select"
      item-text="name"
      label="warehouse"
      :search-input.sync="searchValue"
  />
</template>
<script>
export default {
  name : "search-warehouse",
  props: {
    value: {
      type   : Number,
      default: null
    },
  },
  watch: {
    searchValue(val) {
      axios.get('/api/inventory/warehouses', {
        params: {
          name    : val,
          dropdown: true
        }
      })
           .then(res => {
             this.items = res.data
           })
    },
    select(val) {
      this.$emit('input', val)
    }
  },
  data() {
    return {
      searchValue: null,
      items      : [],
      select     : null
    }
  },
}
</script>