import _ from "lodash";
import CreateProduct from './product_component/create.vue'
import ShowProduct from './product_component/show.vue'
import SearchBrand from '~/local/search-brand.vue'
import SearchWarehouse from '~/local/search-warehouse.vue'
import SearchCategory from '~/local/search-category.vue'
import AProductLocationsList from 'g~/a-product-locations-list.vue'

export default {
    components: {
        CreateProduct,
        ShowProduct,
        SearchBrand,
        SearchWarehouse,
        SearchCategory,
        AProductLocationsList
    },
    data() {
        return {
            form_id                : null,
            show_id                : null,
            location_product_id    : null,
            product_modal          : false,
            product_modal_show     : false,
            loading                : false,
            warehouses             : [],
            searchWarehouse        : null,
            items                  : {},
            product_location_dialog: false,
            options                : {
                sortBy      : ['created_at'],
                sortDesc    : [true],
                itemsPerPage: this.$store.state.itemsPerPage,
            },
            headers                : [
                {text: 'item number', value: 'code', sortable: true},
                {text: 'name', value: 'name', sortable: true},
                {text: 'category', value: 'categories', sortable: false},
                {text: 'cost', value: 'buying_price', sortable: true},
                {text: 'price', value: 'price', sortable: true},
                {text: 'brand', value: 'brands.name', sortable: false},
                {text: 'action', value: 'action', sortable: false},
            ],
            closeOnContentClick    : false
        }
    },
    watch     : {
        product_modal_show(val) {
            if(!val) {
                this.show_id = null
            }
        },
        options: {
            deep: true,
            handler() {
                this.loading = true
                this.getData()
            }
        },
        product_location_dialog(val) {
            if(!val) {
                this.location_product_id = null
            }
        },
    },
    methods   : {
        callCreateProductModal() {
            this.product_modal = true
            this.form_id       = null
        },
        createProduct(val) {
            this.product_modal = false
            if(!_.isEmpty(val)) {
                this.getData()
                if(val.buttonName === 'new') {
                    setTimeout(() => {
                        this.product_modal = true
                    }, 200)
                } else {
                    this.product_modal = false
                    this.form_id       = null
                }
            }
        },
        showProduct(id) {
            this.show_id = id
            this.$nextTick(() => {
                this.product_modal_show = true
            })
        },
        editProduct(id) {
            this.form_id = id
            if(this.form_id) {
                this.product_modal = true
            }
        },
        async deleteItem(id) {
            if(await this.$root.confirmation({
                                                 text: 'Are you sure you want to delete this product?'
                                             })) {
                axios.delete('/api/inventory/products/' + id)
                     .then(res => {
                         this.getData()
                         this.loading = true
                         Swal.fire({
                                       icon             : res.data.type,
                                       text             : res.data.message,
                                       timer            : 3000,
                                       showCancelButton : false,
                                       showConfirmButton: false,
                                   })
                     })
                     .finally(() => {
                         this.loading = false
                     })
            }
        },
        getData: _.debounce(function () {
            this.loading = true
            axios.get('/api/inventory/products', {
                params: this.options
            })
                 .then(res => {
                     this.items   = res.data
                     this.loading = false
                 })
        }, 200),

        filterData(query) {
            if(query !== '') {
                this.loading1 = true;
                setTimeout(() => {
                    this.loading1 = false;
                    const list    = this.warehouses.map(item => {
                        return {
                            value: item.id,
                            label: item.name
                        };
                    });
                    this.options1 = list.filter(item => item.label.toLowerCase().indexOf(query.toLowerCase()) > -1);
                }, 200);
            } else {
                this.options1 = [];
            }
        }
    }
}