<template>
  <div>
    <v-card-title>
      Chart of Accounts
      <v-spacer></v-spacer>
      <v-btn
          color="success"
          small
          dark
          @click="resetForms(), dialog=true" class="pull-right"
      >
        New Account
      </v-btn>
    </v-card-title>
    <v-row>
      <v-col cols="12" md="4">
        <v-text-field solo v-model="search"></v-text-field>
      </v-col>
    </v-row>
    <v-dialog scrollable v-model="dialog" width="800">
      <v-card class="dense-form">
        <v-card-title>
          {{ this.forms.id ? 'Update' : 'create' }} Accounts
          <v-spacer></v-spacer>
          <v-btn
              color="error"
              dark
              small fab icon v-if="forms.id" @click="deleteAccount()">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider/>
        <v-card-text class="fill-height">
          <br>
          <v-row>
            <v-col cols="12" md="6">
              <v-select
                  dense
                  outlined
                  item-value="id"
                  item-text="name"
                  :items="parent_account"
                  v-model="forms.parent_id"
                  label="select parent account"
                  :error-messages="forms.errors['parent_id']"
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                  dense
                  outlined
                  v-model="forms.name"
                  label="type account name"
                  :error-messages="forms.errors['name']"
              ></v-text-field>
            </v-col>
            <!--            <v-col cols="12" md="6">-->
            <!--              <v-select-->
            <!--                  solo-->
            <!--                  persistent-hint-->
            <!--                  hint="transaction type"-->
            <!--                  v-model="forms.transaction_type"-->
            <!--                  :error-messages="forms.errors['transaction_type']"-->
            <!--              ></v-select>-->
            <!--            </v-col>-->
            <v-col cols="12" md="6">
              <v-text-field
                  dense
                  outlined
                  label="type description"
                  v-model="forms.description"
                  :error-messages="forms.errors['description']"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn color="primary" @click="submitAccount()" :loading="loading">
            Submit
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false">
            close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-treeview
        rounded
        open-all
        hoverable
        activatable
        v-if="loaded"
        :search="search"
        :items="accounts"
        :active.sync="selected"
        selection-type="independent"
    >
    </v-treeview>
  </div>
</template>

<script>
export default {
  name    : "chart-of-account",
  data    : () => ({
    search        : null,
    dialog        : false,
    loading       : false,
    selected      : [],
    forms         : {
      errors: {},
    },
    parent_account: [],
    accounts      : [],
  }),
  created() {
    axios.get("/api/accounts/create").then((result) => {
      this.parent_account = result.data["parent_account"];
    });
    this.getData();
  },
  watch   : {
    dialog(val) {
      if(!val) {
        this.resetForms()
      }
    },
    selected(val) {
      if(!_.isEmpty(val) && val.length === 1) {
        axios.get('/api/accounts/' + val[0] + '/edit')
             .then(result => {
               if(!_.isEmpty(result.data.account)) {
                 if(result.data.account.parent_id == 0) {
                   return null
                 }
                 this.forms.id          = result.data.account.id
                 this.forms.name        = result.data.account.name
                 this.forms.parent_id   = result.data.account.parent_id
                 this.forms.description = result.data.account.description
                 if(this.forms.id) {
                   this.dialog = true
                 }
               }
             })
             .catch(error => {

             })
      }
    }
  },
  computed: {
    loaded() {
      return !!!_.isEmpty(this.accounts) && this.accounts.length > 0
    }
  },
  methods : {
    deleteAccount() {
      this.loaded = true
      axios.delete('/api/accounts/' + this.forms.id)
           .then(result => {
             this.dialog = false
             Swal.fire({
                         icon : result.data.type,
                         text : result.data.message,
                         timer: 3000
                       }).then((result) => {
               this.loaded = false
               this.getData()
             })
           })
           .catch(error => {
             Swal.fire({
                         icon: "error",
                         text: error.response.data.message,
                       });
             this.loading = false
           })
    },
    resetForms() {
      this.forms.id          = null
      this.forms.parent_id   = null;
      this.forms.name        = "";
      this.forms.description = "";
      this.forms.errors      = {};
      this.selected          = []
    },
    submitAccount() {
      this.loading      = true;
      this.forms.errors = {};
      let url           = "/api/accounts"
      if(this.forms.id) {
        url = '/api/accounts/' + this.forms.id
      }
      axios[this.forms.id ? 'patch' : 'post'](url, this.forms)
          .then((result) => {
            this.dialog = false;
            Swal.fire({
                        icon : result.data.type,
                        text : result.data.message,
                        timer: 3000,
                      }).then((result) => {
              this.resetForms();
              this.loading = false;
              this.getData();
            });
          })
          .catch((error) => {
            if(error.response.status === 422) {
              this.forms.errors = error.response.data.errors;
              this.loading      = false;
            } else {
              Swal.fire({
                          icon: "error",
                          text: error.response.data.message,
                        });
            }
            this.loading = false;
          });
    },
    getData() {
      axios
          .get("/api/accounts")
          .then((result) => {
            this.accounts = result.data["accounts"];
          })
          .catch((err) => {
          });
    },
  },
};
</script>