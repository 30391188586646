import Vue from 'vue';
import Vuetify from 'vuetify';

Vue.use(Vuetify, {
    iconfont: 'mdi'
})

export default new Vuetify({
                               icon: 'mdi',
                               theme: {
                                   themes: {
                                       light: {
                                           primary: '#007bff',
                                           default: '#d4d4d4',
                                           blue: '#4692f3',
                                           grey_darken_1: '#757575',
                                       },
                                   },
                               },
                           });