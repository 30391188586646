const warehouseType = [{
        text: 'Storage',
        value: 'storage'
    },
    {
        text: 'Picking',
        value: 'picking'
    },
]

const warehousePickingType = [{
        text: 'Fixed',
        value: 'fixed'
    },
    {
        text: 'Mobile',
        value: 'mobile'
    },
]

const warehouseStorageType = [{
        text: 'Storage',
        value: 'storage'
    },
    {
        text: 'Staging',
        value: 'staging'
    },
    {
        text: 'Both',
        value: 'both'
    }
]

export {
    warehouseType,
    warehousePickingType,
    warehouseStorageType
}