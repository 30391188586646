import _ from 'lodash'
import SupplierCreate from 'i#/supplier/create.vue'
import PurchaseShowPrint from './purchase-show-print.vue'

export default {
    components: {
        SupplierCreate,
        PurchaseShowPrint,
    },
    props: {
        modelId: {
            type: Number,
            default: null
        },
        value: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            purchase_date: new Date(),
            due: 0,
            banks: [],
            modal: false,
            menu1: false,
            items: [],
            url: this.$getUrl('purchases', this.value, this.modelId),
            axiosMethod: this.$getAxiosMethod(this.value, this.modelId),
            forms: this.$root.$data.forms,
            units: [],
            model10: [],
            loading: false,
            products: [],
            suppliers: [],
            tooltipId: "conversionTooltip",
            purchases: [],
            warehouses: [],
            product_id: '',
            productName: null,
            extra_weight: 0,
            payment_type: 3,
            computedDate: null,
            settingsValue: settings,
            searchProduct: null,
            loadingMessage: false,
            payment_status: 1,
            productLoading: false,
            supplier_dialog: false,
            unitconversions: [],
            part_number_dialog: false,
            print_Data: {
                supplier: {},
                payments: [],
                returns: []
            },
            print_totalRAmount: 0
        }
    },

    beforeCreate() {
        this.$root.$data.forms.fieldReset();
    },
    watch: {
        items: {
            deep: true,
            handler(val) {
                const unique = []
                const duplicates = val.filter((o, i) => {
                    if (unique.find(i =>
                            i.product_id === o.product_id &&
                            i.quantity === o.quantity &&
                            i.unit === o.unit &&
                            i.location === o.location
                        )) {
                        o.quantity++
                        return true
                    }
                    unique.push(o)
                    return false;
                })
                val.map(v => {
                    if (v.location) {
                        let arr = v.location.split('-')
                        if (arr.length) v.warehouse = parseInt(arr[1])
                    }
                })
            }
        },
        searchProduct(val) {
            this.getProducts(val)
        },
        '$route.fullPath'(val) {
            //reset data
            this.forms.reset()
            this.due = 0
            this.items = []
            this.purchases = []
            this.product_id = ''
            this.payment_type = 3
            this.payment_status = 1
        },

        total(val) {
            if (this.axiosMethod === 'patch') {
                this.calDue()
                return
            }
            // this.forms.paid = val
        },
    },

    computed: {
        isLoaded() {
            return !_.isEmpty(this.warehouses) || !_.isEmpty(this.suppliers) || !_.isEmpty(this.products) || !_.isEmpty(this.units)
        },
        total() {
            let total = 0
            let discount = 0
            let shipping_cost = 0

            shipping_cost = !this.forms.shipping_cost ? 0 : this.forms.shipping_cost;

            if (this.items.length > 0) {
                for (let i = 0; i < this.items.length; i++) {
                    total += this.items[i].price
                }
            }

            total = parseFloat(total) + parseFloat(shipping_cost)

            discount = this.forms.overall_discount ? total * parseFloat(this.forms.overall_discount) / 100 : 0

            total -= discount

            if (this.forms.sum_fields !== undefined) {
                total += _.sumBy(this.forms.sum_fields, item => {
                    return item.active ? Number(item.value) : Number(0);
                })
            }

            this.getTotalWeight()
            return total.toFixed(3)
        }
    },

    created() {
        this.getData()
    },
    methods: {
        getData() {
            this.$set(this.forms, 'removed_ids', [])
            this.$set(this.forms, 'purchase_date', new Date())
            this.$set(this.forms, 'bill_no', '')
            this.payment_type = this.findDefaultPaymentMood()
            this.$set(this.forms, 'total_weight', 0)
            this.forms.extra_weight = 0
            this.loading = true
            this.forms.status = 1
            this.forms.payment_status = 3
            this.getSum_fields()

            // console.log(this.url)

            this.$root.$data.erp.request.get(this.url, this, (data) => {
                this.suppliers = data.suppliers
                this.products = data.products
                this.banks = data.banks
                this.warehouses = data.warehouses
                this.units = data.units
                this.unitconversions = data.unitconversions
                if (this.axiosMethod === 'patch') {
                    let part_numbers = data.purchases.partnumbers
                    this.purchases = data.purchases
                    this.forms.purchase_date = moment(this.purchases.purchase_date_formatted, this.$root.settings.settings.date_format).format("YYYY-MM-DD")
                    this.$root.$set(this.forms, 'overall_discount', this.purchases.overall_discount)
                    this.$root.$set(this.forms, 'shipping_cost', this.purchases.shipping_cost);

                    this.forms.supplier_id = parseInt(this.purchases.supplier_id)
                    this.forms.bill_no = this.purchases.bill_no
                    this.forms.status = this.purchases.status
                    this.forms.note = this.purchases.note
                    this.forms.sum_fields = this.purchases.sum_fields

                    if (this.purchases.payments !== undefined && this.purchases.payments.length > 0) {
                        this.forms.paid = this.purchases.payments[0].paid
                        this.payment_type = this.purchases.payments[0].payment_type
                        if (this.payment_type == 2 || this.payment_type == 3) {
                            this.forms.bank_id = this.purchases.payments[0].transaction.bank_id
                            this.forms.transaction_number = this.purchases.payments[0].transaction.transaction_number
                        }
                    } else {
                        this.forms.paid = 0
                        this.payment_type = this.findDefaultPaymentMood()
                    }

                    for (let i = 0; i < this.purchases.products.length; i++) {
                        let product = this.purchases.products[i];
                        // console.log(parseFloat(product.pivot.quantity).toFixed(3))
                        this.items.push({
                            quantity: Number(product.pivot.quantity).toFixed(3),
                            product_id: product.id,
                            pname: product.name,
                            unit_price: parseFloat(product.pivot.price).toFixed(2),
                            warehouse: parseInt(product.pivot.warehouse_id),
                            unit: parseInt(product.pivot.unit_id),
                            units: product.units,
                            discount: product.pivot.discount,
                            fromUnit: product.pivot.unit_id,
                            baseUnit: product.base_unit_id,
                            price: 0,
                            weight: product.pivot.weight,
                            weight_total: product.pivot.weight_total,
                            adjustment: product.pivot.adjustment,
                            manufacture_part_number: product.manufacture_part_number,
                            location: product.purchased_location,
                            pp_id:product.pivot.pp_id,
                            part_number: this.$options.filters.sortPartNumber(
                                part_numbers,
                                product,
                                parseInt(product.pivot.warehouse_id)
                            )
                        })
                    }
                    this.getTotalWeight()
                    this.extra_weight = this.purchases.total_weight - this.forms.total_weight
                }
                this.loading = false
            });
        },
        getNewSupplier(val) {
            this.suppliers.push(val)
            this.forms.supplier_id = val.id
            this.supplier_dialog = false
        },
        findDefaultPaymentMood() {
            return this.settingsValue.settings.inventory.purchase.default_payment_mood === undefined ?
                1 :
                this.settingsValue.settings.inventory.purchase.default_payment_mood;
        },
        getTotalWeight() {

            this.forms.total_weight = 0
            this.items.forEach(item => {
                if (!item.weight_total) {
                    item.weight_total = 0
                }
                this.forms.total_weight += Number(parseFloat(item.weight_total).toFixed(3))
            })
        },
        getSum_fields() {
            let inventorySettings = this.$root.settings.settings.inventory
            if (inventorySettings.purchase !== undefined && inventorySettings.purchase.sum_fields !== undefined) {
                if (!_.isEmpty(inventorySettings.purchase.sum_fields)) {
                    this.forms.sum_fields = inventorySettings.purchase.sum_fields
                }
            } else {
                this.forms.sum_fields = []
            }
        },
        postPurchase($e) {
            let buttonValue = $e.submitter.value
            // this.loading=true
            this.forms.items = this.items
            this.forms.total = this.total
            this.forms.payment_status = this.payment_status
            this.forms.payment_type = this.payment_type
            this.forms.purchase_date_formatted = moment(this.forms.purchase_date).format("YYYY-MM-DD")
            // this.forms.purchase_date_formatted = moment(this.forms.purchase_date.format("YYYY-MM-DD HH:mm:ss"))
            // console.log(this.forms.purchase_date_formatted)
            this.forms.extra_weight = this.extra_weight
            // console.log(this.forms.payment_type)
            if ((this.forms.payment_type == 3 && this.forms.bank_id) && this.axiosMethod !== 'patch') {
                let bank = _.find(this.banks, {
                    id: this.forms.bank_id
                })
                if (this.forms.paid >= bank.running_balance) {
                    let confirmation = confirm(bank.name + ' has low balance. Do you still want to continue?')
                    if (!confirmation) {
                        return null;
                    }
                }
            }

            this.forms.submit(
                    this.axiosMethod,
                    this.$getUrlForm(this.url),
                    false,
                    this.$root
                )
                .then(data => {
                    if (data.type === 'success') {
                        this.items = []
                    }
                    this.forms.purchase_date = moment(new Date()).format("YYYY-MM-DD")
                    this.loading = false
                    Swal.fire({
                        icon: data.type,
                        timer: 3000,
                        text: data.message,
                        showCancelButton: false,
                        showConfirmButton: false,
                    }).then(result => {
                        if (!this.value) {
                            if (this.axiosMethod === 'patch') {
                                this.$router.push({
                                    name: 'purchase.index'
                                })
                            }
                        }
                        //for modal
                        if (this.value && buttonValue === 'submit-close') {
                            this.$emit('input', false)
                        }
                        if (this.value && buttonValue === 'view') {
                            this.$emit('view', data.purchase.id)
                        }
                        if (this.value && buttonValue === 'update') {
                            this.$emit('input', false)
                        }

                        //common
                        if (buttonValue === 'print') {
                            this.call_print_component(data)
                        }
                        if (buttonValue === 'view') {
                            this.$router.push({
                                name: 'purchase.show',
                                params: {
                                    id: data.purchase.id
                                }
                            })
                        }
                        this.$root.$data.forms.fieldReset();
                        this.resetForm()
                        this.loading = false
                    })
                })
                .catch(error => {
                    this.forms.purchase_date = moment(new Date()).format("YYYY-MM-DD")
                    this.loading = false
                });
        },

        resetForm() {
            this.$set(this.forms, 'removed_ids', [])
            this.$set(this.forms, 'purchase_date', new Date())
            this.$set(this.forms, 'bill_no', '')
            this.payment_type = this.findDefaultPaymentMood()
            this.$set(this.forms, 'total_weight', 0)
            this.forms.extra_weight = 0
            this.loading = true
            this.forms.status = 1
            this.forms.payment_status = 3
            this.getSum_fields()
        },
        call_print_component(data) {
            axios.get('/api/inventory/purchases/' + data.purchase.id)
                .then(res => {
                    this.print_Data = res.data.purchase
                    this.print_totalRAmount = 0
                    if (this.print_Data.returns.length > 0) {
                        for (let i = 0; i < this.print_Data.returns.length; i++) {
                            this.print_totalRAmount += parseFloat(this.print_Data.returns[i].amount)
                        }
                    }
                    this.$nextTick(next => {
                        this.$htmlToPaper('myPrint')
                    })
                })
        },

        getProducts: _.debounce(function (query) {
            if (query !== '') {
                axios.post('/api/inventory/products/getProducts', {
                        val: query,
                        isPurchase: true
                    })
                    .then(response => {
                        this.products = response.data.products
                        if (this.products.length === 1) {
                            this.product_id = this.products[0].id
                            this.addProduct()
                            this.products = []
                        }
                        this.loadingMessage = false;
                    })
                    .catch(error => {
                        alert(error.message)
                    })
            } else {
                this.loadingMessage = true
                this.products = []
                return null
            }
        }, 1000),
        findWeight(product_id) {
            let product = this.getProduct(product_id)
            let units = product.units
            if (units.length > 0) {
                let weight = units.find((unit) => {
                    return unit.id === product.base_unit_id
                }).pivot.weight
                if (weight) {
                    return weight
                } else {
                    return 0;
                }
            } else {
                return 0;
            }
            // console.log(_.find(this.getProduct(product_id).units, { id: this.getProduct(product_id).base_unit_id
            // }));
            // return _.find(this.getProduct(this.product_id).units, { id:
            // this.getProduct(this.product_id).base_unit_id }).pivot.weight
        },
        addProduct() {
            console.log('testing product')
            if (!this.product_id) {
                return null
            }
            // if (this.uniqueItem(this.product_id)) return null;
            this.items.push({
                product_id: this.product_id,
                pname: this.getProduct(this.product_id).name,
                unit_price: parseFloat(this.getProduct(this.product_id).buying_price).toFixed(2),
                price: 0,
                fromUnit: this.getProduct(this.product_id).base_unit_id,
                baseUnit: parseFloat(this.getProduct(this.product_id).base_unit_id),
                units: this.getProduct(this.product_id).units,
                warehouse: this.$root.getDefault_id(this.warehouses),
                unit: this.$root.getPrimary_id(this.getProduct(this.product_id).units),
                weight: this.findWeight(this.product_id),
                manufacture_part_number: this.getProduct(this.product_id).manufacture_part_number,
                part_number: [],
                quantity: 1,
                location: ''
            })
            if (this.items.length > 0) {
                this.$nextTick(() => {
                    this.product_id = null
                })
            }
        },
        uniqueItem(id) {
            return this.items.find((item) => {
                return item.product_id === id
            })
        },
        getPurchasePivot(product, id) {
            return product.purchases.find((purchase) => {
                return purchase.id === id
            })
        },
        getProduct(id) {
            return this.products.find((product) => {
                return product.id === this.product_id
            })
        },
        removeProduct(index) {
            this.forms.removed_ids.push(this.items[index]['product_id'])
            this.items.splice(index, 1);
        },
        itemTotalWeight(index) {
            this.items[index].weight_total = this.items[index].quantity ?
                this.items[index].weight * this.items[index].quantity :
                0

            return Number(parseFloat(this.items[index].weight_total).toFixed(3))
        },
        getTotalPrice(item) {
            let totalPrice = 0

            if (item.unit_price && item.quantity) {
                totalPrice = Number(item.unit_price) * Number(item.quantity)
            }
            if (totalPrice && item.discount) {
                totalPrice -= item.discount
            }
            if (totalPrice && item.adjustment) {
                totalPrice += Number(item.adjustment)
            }
            item.price = Number(parseFloat(totalPrice).toFixed(2))
            return item.price

        },
        calDue() {
            this.due = this.total - this.forms.paid

            if (this.due == 0) {
                this.payment_status = 1 //paid
            }
            if (this.due < this.total && this.due > 0) {
                this.payment_status = 2 //partial
            }
            if (this.due == this.total) {
                this.payment_status = 3 //due
            }
        },
        getPrice(unit, index) {
            let fromUnit = this.items[index].fromUnit
            this.items[index].fromUnit = unit
            this.items[index].weight = _.find(this.items[index].units, {
                id: unit
            }).pivot.weight
            if (fromUnit === unit) {
                return
            }

            let url = '/api/inventory/unitconversions/' + unit + '/' + fromUnit + '/1'
            axios.post(url, {
                    isPurchase: true,
                    productId: this.items[index].product_id
                })
                .then(response => {
                    let conversion = response.data.conversion
                    this.items[index].unit_price = parseFloat(response.data.quantity * this.items[index].unit_price).toFixed(2)
                })
        },
        onCancel() {
            if (!this.value) {
                this.$router.push({
                    name: "purchase.index"
                })
            } else {
                this.forms.reset();
                this.items = []
                this.forms.items = []
            }
        },
    }
}