import _ from "lodash";

export default {
    data(){
        return {
            menu1               : false,
            menu                : false,
            changeDeliveryStatus: {
                errors: {}
            },
            computedDate        : null,
            dialog              : false,
            items               : {},
            loading             : false,
            options             : {
                sortBy      : ['delivered_date'],
                sortDesc    : [true],
                itemsPerPage: this.$store.state.itemsPerPage,
            },
            headers             : [
                {text: 'order no', value: 'order_no'},
                {text: 'delivered date', value: 'delivered_date'},
                {text: 'action', value: 'action', sortable: false},
            ],
        }
    },
    watch  : {
        dialog(val) {
            if(!val) {
                this.changeDeliveryStatus        = {}
                this.changeDeliveryStatus.errors = {}
            }
        },
        options: {
            deep: true,
            handler() {
                this.loading = true
                this.getData()
            }
        }
    },
    methods: {
        postDelivered() {
            this.changeDeliveryStatus.errors = {}
            axios
                .post('/api/inventory/sale-orders-delivered', this.changeDeliveryStatus)
                .then(res => {
                    Swal.fire({
                                  timer            : 3000,
                                  icon             : res.data.type,
                                  text             : res.data.message,
                                  showCancelButton : false,
                                  showConfirmButton: false,
                              })
                        .then((result) => {
                            this.dialog = false
                            this.getData()
                            this.$emit('updateShipping', true)
                        })
                })
                .catch(error => {
                    this.changeDeliveryStatus.errors = error.response.data.errors
                })
        },
        closeDatePicker() {
            let currentTime                                  = moment(new Date()).format("HH:mm:ss")
            let expected_shipping_date                       = this.changeDeliveryStatus.expected_shipping_date
            let selectedDate                                 = moment(expected_shipping_date).format(this.$root.settings.settings.date_format)
            // console.log(selectedDate + ' ' + currentTime)
            this.computedDate                                = moment(selectedDate + ' ' + currentTime, this.$root.settings.settings.date_format + " HH:mm:ss").format(this.$root.settings.settings.date_format + " HH:mm:ss")
            this.changeDeliveryStatus.expected_shipping_date = moment(this.changeDeliveryStatus.expected_shipping_date + ' ' + currentTime).format("YYYY-MM-DD HH:mm:ss")
            this.menu                                        = false
        },
        showMarkDeliveryModal(item) {
            this.changeDeliveryStatus.id                     = item.id
            this.changeDeliveryStatus.expected_shipping_date = item.expected_shipping_date
            this.dialog                                      = true
        },
        getData: _.debounce(function () {
            this.loading = true
            axios.get('/api/inventory/sale-orders-delivered', {
                params: this.options
            })
                 .then(res => {
                     this.items   = res.data
                     this.loading = false
                 })
        }, 200),
    }
}