import CreateOrder from './create.vue'
import ConvertOrder from './convert.vue'

export default {
    name      : "index",
    components: {
        CreateOrder,
        ConvertOrder
    },
    data() {
        return {
            columnMenu          : false,
            menu                : false,
            items               : {},
            loading             : false,
            closeOnContentClick : true,
            options             : {
                sortBy      : ['created_at'],
                sortDesc    : [true],
                itemsPerPage: this.$store.state.itemsPerPage,
            },
            convertDialog       : false,
            convert_id          : null,
            createOrUpdateDialog: false,
            edit_id             : null,
            headers             : [
                {text: 'order no', value: 'order_no', align: ''},
                {text: 'CPO', value: 'customer_order_no', align: ''},
                {text: 'customer-id', value: 'customer_name_id', sortable: false, align: ''},
                {text: 'total', value: 'total', align: ''},
                {text: 'weight', value: 'total_weight', align: ''},
                {text: 'order status', value: 'order_status', align: ''},
                // {text: 'created at', value: 'created_at', align: ''},
                {text: 'order date', value: 'order_date', sortable: true, align: ''},
                {text: 'action', value: 'action', sortable: false, align: ''},
            ]
        }
    },
    watch     : {
        options: {
            deep: true,
            handler() {
                this.loading = true
                this.getData()
            }
        },
        createOrUpdateDialog(val) {
            if(!val) {
                this.getData()
                this.edit_id = null
            }
        }
    },
    methods   : {
        cancelToggle(item) {
            Swal.fire({
                          showCancelButton  : true,
                          cancelButtonColor : '#d33',
                          cancelButtonText  : 'close',
                          confirmButtonColor: '#3085d6',
                          title             : 'Are you sure?',
                          icon              : !item.is_canceled ? 'warning' : 'success',
                          confirmButtonText : !item.is_canceled ? 'Yes, cancel it!' : 'Yes reopen it',
                          text              : !item.is_canceled ? "You won't be able to convert this!" : "You will be able to convert this!",
                      }).then((result) => {
                if(result.isConfirmed) {
                    axios.post('/api/inventory/sale-orders-cancel-toggle', {id: item.id}).then(res => {
                        this.getData()
                        Swal.fire(
                            res.data.type,
                            res.data.message,
                            res.data.type
                        )
                    })
                }
            })
        },
        getData: _.debounce(function () {
            axios
                .get('/api/inventory/sale-orders', {params: this.options})
                .then(res => {
                    this.items = res.data
                    if(!_.isEmpty(this.items) && this.items.data.length > 0) {
                        this.items.data.forEach(i => {
                            i.order_no = 'SO-' + i.order_no
                        })
                    }
                    this.loading = false
                })
        }, 300)
    }
}