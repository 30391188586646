import _ from "lodash";
import WarrantyCreate from 'i#/warranty/create.vue'

export default {
    components: {
        WarrantyCreate
    },
    data() {
        return {
            brands              : [],
            items               : {},
            loading             : false,
            options             : {
                sortBy      : ['updated_at'],
                sortDesc    : [true],
                itemsPerPage: this.$store.state.itemsPerPage,
            },
            createOrUpdateDialog: false,
            closeOnContentClick:false,
            edit_id             : null,
            headers             : [
                {text: 'date', value: 'warranty_date', sortable: true},
                {text: 'customer', value: 'customer_name', sortable: false},
                {text: 'product', value: 'product_name', sortable: false},
                {text: 'quantity', value: 'quantity', sortable: true},
                {text: 'note', value: 'note', sortable: false},
                {text: 'status', value: 'status', sortable: true},
                {text: 'updated at', value: 'updated_at', sortable: true},
                {text: 'action', value: 'action', sortable: false},
            ],
        }
    },
    watch     : {
        options: {
            deep: true,
            handler() {
                this.loading = true
                this.getDataWithLoading()
            }
        },
        createOrUpdateDialog(val) {
            if(!val) {
                this.getDataWithLoading()
                this.edit_id = null
            }
        }
    },
    methods   : {
        deleteItem(id) {
            this.loading = true
            this.$deleteWithConfirmation({
                                             text: 'Are you sure you want delete this warranty?',
                                             url : '/api/inventory/warranty/' + id
                                         })
                .then(data => {
                    this.getData()
                })
                .catch(error => {
                    this.loading = false
                })
        },
        getData: _.debounce(function () {
            axios.get('/api/inventory/warranty', {
                params: this.options
            })
                 .then(res => {
                     this.items   = res.data
                     this.loading = false
                 })
        }, 400),
        getDataWithLoading() {
            this.loading = true
            this.getData()
        }
    }
}