export default {
    data() {
        return {
            forms: this.$root.$data.forms,
            units: [],
            unitConversions: [],
            method: '',
            loading:false,
            items:{},
            options: {
                itemsPerPage: this.$store.state.itemsPerPage,
            },
            headers:[
                {text:'from unit', value:'from_unit.key', sortable:false},
                {text:'to unit', value:'to_unit.key', sortable:false},
                {text:'conversion factor', value:'conversion_factor', sortable:false},
                {text:'date', value:'created_at', sortable:false},
            ],
        }
    },
    beforeCreate() {
        this.$root.$data.forms.fieldReset();
    },
    created() {
        this.method = this.$root.getMethodAction();
        let url = '/api/inventory/unitconversions/create'
        if (this.method === 'Edit') {
            url = '/api/inventory/units/' + this.$route.params.id + '/edit'
        }
        axios.get(url).then(response => {
            this.units = response.data.units
        })
    },
    watch:{
        options:{
            deep:true,
            handler(){
                this.loading=true
                this.getData()
            }
        }
    },
    methods: {
        getData(){
            axios.get('/api/inventory/unitconversions',{
                params:this.options

            }).then(res=>{
                this.items=res.data
                this.loading=false
            })
        },
        postUnitConversion() {
            let requestMethod = 'post'
            let url = '/api/inventory/unitconversions'
            if (this.method === 'Edit') {
                requestMethod = 'patch'
                url = '/api/inventory/unitconversions/' + this.forms.id
            }
            this.loading=true
            this.forms.submit(requestMethod, url)
                .then(data => {
                    this.getData()
                    this.loading =false
                    Swal.fire({
                        icon: data.type,
                        timer: 2000,
                        text: data.message,
                        showCancelButton: false,
                        showConfirmButton: false,
                    }).catch(Swal.noop)
                }).catch(error => {
                    this.loading=false
            });
        }
    }
}