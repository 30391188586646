export default {
    name    : "edit",
    props     : {
        modelId: {
            type   : Number,
            default: null
        },
        value  : {
            type   : Boolean,
            default: false
        },
    },
    data    : () => ({
        loading      : false,
        products     : [],
        tooltipId    : "conversionTooltip",
        warehouses   : [],
        units        : [],
        product_id   : null,
        searchProduct: null,
        categories   : [],
        brands       : [],
        forms        : {
            deletedIds       : [],
            name             : '',
            code             : '',
            categories       : [],
            items            : [],
            total_weight     : 0,
            extra_weight     : 0,
            bill_of_materials: [],
            other_expenses   : [
                {label: 'labor cost', value: null}
            ],
            errors           : {
                name: '',
                code: '',
            }
        }
    }),
    computed: {
        total() {
            let total         = 0
            let shipping_cost = 0

            if(this.forms.items.length > 0) {
                for (let i = 0; i < this.forms.items.length; i++) {
                    total += this.forms.items[i].price
                }
            }
            this.getTotalWeight()
            total = parseFloat(total) + parseFloat(shipping_cost)
            return Number(total.toFixed(2))
        },
        grandTotal() {
            this.forms.buying_price = this.total + _.sumBy(this.forms.other_expenses, item => {
                return item.value > 0 && item.label ? Number(item.value) : Number(0);
            })
            this.forms.price        = this.forms.buying_price
            return this.forms.buying_price
        }
    },
    watch   : {
        searchProduct(val) {
            this.getProducts(val)
        },
        forms:{
            deep:true,
            handler(val){
                val.items.map(v => {
                    if (v.location) {
                        let arr = v.location.split('-')
                        if (arr.length) v.warehouse = parseInt(arr[1])
                    }
                })
            }
        },
    },
    created() {
        axios.get('/api/inventory/product-composite/' + this.modelId + '/edit')
             .then(res => {
                 let data                  = res.data.data
                 this.categories           = data.categories
                 this.units                = data.units
                 this.brands               = data.brands
                 let product               = res.data.product
                 this.forms.name           = product.name
                 this.forms.code           = product.code
                 this.forms.brand          = product.brand_id
                 this.forms.base_unit_id   = product.base_unit_id
                 this.forms.other_expenses = product.bom
                 this.forms.note = product.note
                 this.warehouses           = data.warehouses

                 if(res.data.product.categories.length > 0) {
                     this.forms.categories = res.data.product.categories.map(c => c.id)
                 }
                 this.forms.items = res.data.product.bill_of_materials.map(b => {
                     return {
                         id        : b.id,
                         product_id: b.product_id,
                         location:b.location,
                         pname     : b.product.name,
                         code      : b.product.code,
                         unit_price: parseFloat(b.product.buying_price).toFixed(2),
                         warehouse : b.warehouse_id,
                         unit      : b.unit_id,
                         units     : b.product.units,
                         quantity  : b.material_quantity,
                         fromUnit  : b.unit_id,
                         baseUnit  : b.product.base_unit_id,
                         price     : 0,
                         weight    : b.product.units.find(u => {
                             return u.id === b.unit_id
                         }).pivot.weight
                     }
                 })
                 this.forms.items.forEach(item => {
                     if(!item.weight_total) {
                         item.weight_total = 0
                     }
                     this.forms.total_weight += item.weight * item.quantity
                 })
                 let product_weight      = product.units.find(u => {
                     return product.base_unit_id == u.id
                 }).pivot.weight
                 this.forms.extra_weight = product_weight - this.forms.total_weight
             })
    },
    methods : {
        calculateTotalWeight(total_weight, extra_weight) {
            if(!total_weight || Number(total_weight) < 0) {
                total_weight = 0
            }
            if(!extra_weight || Number(extra_weight) < 0) {
                extra_weight = 0
            }
            let total = Number(total_weight) + Number(extra_weight)

            return total.toFixed(3)
        },
        getTotalWeight() {
            this.forms.total_weight = 0
            this.forms.items.forEach(item => {
                if(!item || item.weight_total < 0) {
                    item.weight_total = 0
                }

                this.forms.total_weight += item.weight_total
            })
        },
        itemTotalWeight(index) {
            this.forms.items[index].weight_total = this.forms.items[index].quantity
                ? this.forms.items[index].weight * this.forms.items[index].quantity
                : 0
            return Number(this.forms.items[index].weight_total.toFixed(2))
        },
        postProduct() {
            this.loading      = true
            this.forms.errors = {}
            axios.patch('/api/inventory/product-composite/' + this.modelId, this.forms)
                 .then(res => {
                     Swal.fire({
                                   icon : res.data.type,
                                   text : res.data.message,
                                   timer: 3000
                               }).then((result) => {
                         this.loading = false
                         if(this.value){
                             this.$emit('reloadData')
                             this.$emit('input', false)
                         }else{
                             this.$router.push({'name': 'product.composite.index'})
                         }

                     })
                 })
                 .catch(error => {
                     if(error.response.status === 422) {
                         this.forms.errors = error.response.data.errors
                     } else {
                         Swal.fire({
                                       icon: 'error',
                                       text: error.response.data.message
                                   })
                     }
                     this.loading = false
                 })
        },
        addProduct() {
            if(!this.product_id) {
                return null
            }
            // if (this.uniqueItem(this.product_id)) return null;
            this.forms.items.push({
                                      product_id: this.product_id,
                                      pname     : this.getProduct(this.product_id).name,
                                      code      : this.getProduct(this.product_id).code,
                                      unit_price: parseFloat(this.getProduct(this.product_id).buying_price).toFixed(2),
                                      price     : 0,
                                      fromUnit  : this.getProduct(this.product_id).base_unit_id,
                                      baseUnit  : parseFloat(this.getProduct(this.product_id).base_unit_id),
                                      units     : this.getProduct(this.product_id).units,
                                      warehouse : this.$root.getDefault_id(this.warehouses),
                                      unit      : this.$root.getPrimary_id(this.getProduct(this.product_id).units),
                                      weight    : this.findWeight(this.product_id).toFixed(2)
                                  })
            if(this.forms.items.length > 0) {
                this.$nextTick(() => {
                    this.product_id = null
                })
            }
        },
        findWeight(product_id) {
            let product = this.getProduct(product_id)
            let units   = product.units
            if(units.length > 0) {
                let weight = units.find((unit) => {
                    return unit.id === product.base_unit_id
                }).pivot.weight
                if(weight) {
                    return weight
                } else {
                    return 0;
                }
            } else {
                return 0;
            }
            // console.log(_.find(this.getProduct(product_id).units, { id: this.getProduct(product_id).base_unit_id
            // }));
            // return _.find(this.getProduct(this.product_id).units, { id:
            // this.getProduct(this.product_id).base_unit_id }).pivot.weight
        },
        removeExpense(index) {
            this.forms.other_expenses.splice(index, 1)
        },
        addExpense() {
            this.forms.other_expenses.push({label: '', value: null})
            let indexValue = this.forms.other_expenses.length - 1
            this.$nextTick(() => this.$refs[`${'exp' + indexValue}`][0].focus())
        },
        getProduct(id) {
            return this.products.find((product) => {
                return product.id === this.product_id
            })
        },
        getProducts: _.debounce(function (query) {
            if(query !== '') {
                axios.post('/api/inventory/products/getProducts', {val: query, isPurchase: true})
                     .then(response => {
                         this.products = response.data.products
                         if(this.products.length === 1) {
                             this.product_id = this.products[0].id
                             this.addProduct()
                             this.products = []
                         }
                     })
                     .catch(error => {
                         alert(error.message)
                     })
            } else {
                this.products = []
                return null
            }
        }, 1000),

        getPrice(unit, index) {
            let fromUnit                     = this.forms.items[index].fromUnit
            this.forms.items[index].fromUnit = unit
            this.forms.items[index].weight   = _.find(this.forms.items[index].units, {id: unit}).pivot.weight
            if(fromUnit === unit) {
                return
            }

            let url = '/api/inventory/unitconversions/' + unit + '/' + fromUnit + '/1'
            axios.post(url, {isPurchase: true, productId: this.forms.items[index].product_id})
                 .then(response => {
                     let conversion                     = response.data.conversion
                     this.forms.items[index].unit_price = parseFloat(response.data.quantity * this.forms.items[index].unit_price).toFixed(2)
                 })
        },
        removeProduct(index) {
            if(this.forms.items[index].id !== undefined) {
                this.forms.deletedIds.push(this.forms.items[index].id)
            }
            this.forms.items.splice(index, 1);
        },
        getTotalPrice(item) {
            if(item.quantity < 0) {
                item.quantity = 0
                return null
            }
            let totalPrice = 0
            let unit_price = parseFloat(item.unit_price).toFixed(2);
            if(isNaN(item.quantity)) {
                return totalPrice
                item.price = totalPrice
            } else {
                totalPrice = isNaN(item.discount)
                    ? (item.quantity * unit_price)
                    : (item.quantity * unit_price) - item.discount
                item.price = totalPrice
                if(item.price < 0) {
                    item.price = 0
                }
                return totalPrice.toFixed(3)
            }
        },
    }
}