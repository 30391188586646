import FormActions from '~/form-actions.vue'

export default {
    components: {
        FormActions
    },
    props     : {
        value: {
            type   : Boolean,
            default: false
        },
    },
    data() {
        return {
            forms     : this.$root.$data.forms,
            categories: [],
            loading   : false,
            typeItems : [
                {text: 'PRODUCT', value: 'PRODUCT'},
                // {text: 'EXPENSE', value: 'EXPENSE'},
                {text: 'INCOME', value: 'INCOME'},
                {text: 'CUSTOMER', value: 'CUSTOMER'},
                {text: 'PRICE', value: 'PRICE'},
            ]
        }
    },
    created() {
        this.resetForm()
        this.getData()
    },
    beforeCreate() {
        this.$root.$data.forms.fieldReset();
    },
    watch     : {
        '$route.fullPath'(val) {
            //reset data
            this.forms.reset();
            this.categories = []
            this.method     = 'Create'
        },
    },
    methods   : {
        resetForm() {
            this.forms.id          = null
            this.forms.name        = null
            this.forms.description = null
            this.forms.parent_id   = 0
            this.forms.type        = null
        },
        getData() {
            this.loading = true
            let url      = '/api/inventory/categories/create'
            this.$root.$data.erp.request.get(url, this, (data) => {
                this.categories = data.categories
                this.loading    = false
            })
        },
        onCancel() {
            this.forms.reset();
            if(this.method == "Edit") {
                this.$router.push({name: "category.index"})
            }
        },
        postCategory($e) {
            let submitter     = $e.submitter.value
            let requestMethod = 'post'
            let url           = '/api/inventory/categories'
            this.forms.submit(requestMethod, url, true, this.$root)
                .then(data => {
                    Swal.fire({
                                  icon             : data.type,
                                  timer            : 3000,
                                  text             : data.message,
                                  showCancelButton : false,
                                  showConfirmButton: false,
                              }).then((result) => {
                        if(data.type === 'success') {
                            this.categories = data.categories;
                            this.resetForm()
                        }
                        if(this.value) {
                            if(submitter === 'submit-close') {
                                this.$emit('input', false)
                            }
                        } else {
                            this.getData()
                        }
                    })
                }).catch(error => {
                this.loading = false
            });
        }
    }
}