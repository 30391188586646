import Vue from 'vue';
import VueRouter from 'vue-router';

import {default as staticRoute} from "../routes/staticRoutes"
import {default as inventoryRoute} from "../routes/inventoryRoutes"
import {default as purchaseRoute} from "../routes/purchaseRoutes"
import {default as saleRoute} from "../routes/saleRoutes"
import {default as expenseRoute} from "../routes/expenseRoutes"
import {default as reportRoute} from "../routes/reportRoutes"
import {default as unitRoute} from "../routes/unitRoutes"
import {default as promotionRoute} from "../routes/promotionRoutes"
import {default as productRoute} from "../routes/productRoutes"
import {default as bomRoute} from "../routes/bomRoutes"
import {default as userRoute} from "../routes/userRoutes"
import {default as utilityRoute} from "../routes/utilityRoutes"
import {default as warrantyRoute} from "../routes/warrantyRoutes"
import {default as incomeRoute} from "../routes/incomeRoutes"
import {default as payrollRoute} from "../routes/payrollRoutes"
import {default as roleRoutes} from "../routes/roleRoutes"
import {default as bankRoutes} from "../routes/bankRoutes"
import {default as AccountRoutes} from "../routes/accountRoutes"

let rout = []

const routes = rout.concat(
    staticRoute,
    inventoryRoute,
    productRoute,
    bomRoute,
    purchaseRoute,
    saleRoute,
    expenseRoute,
    reportRoute,
    unitRoute,
    promotionRoute,
    userRoute,
    utilityRoute,
    warrantyRoute,
    incomeRoute,
    payrollRoute,
    roleRoutes,
    bankRoutes,
    AccountRoutes
)

const router = new VueRouter({
                                 routes,
                                 linkActiveClass: 'is-active',
                                 mode           : 'history',
                                 scrollBehavior(to, from, savedPosition) {
                                     return {x: 0, y: 0}
                                 }
                             })

Vue.use(VueRouter);

export default router;