import _ from 'lodash'

export default {
    props: {
        form_id: null
    },
    data() {
        return {
            pay_later    : false,
            banks        : [],
            forms        : this.$root.$data.forms,
            expenses     : [],
            warehouses   : [],
            // categories   : [],
            method       : '',
            loading      : false,
            modal        : false,
            menu1        : false,
            menu2        : false,
            computedDate : null,
            computedDate1: null,
            users        : [],
            searchItems  : null,
            accounts     : [],
        }
    },
    beforeCreate() {
        this.$root.$data.forms.fieldReset();
    },
    watch: {
        searchItems: {
            immediate: true,
            handler(val) {
                this.getUsers(val)
            }
        },
        '$route.fullPath'(val) {
            //reset data
            this.forms.reset();
            this.expenses = []
            this.method   = 'Create'
        }
    },

    computed: {
        isLoaded() {
            return this.method === 'Edit' ? !_.isEmpty(this.expenses) : true
        }
    },

    created() {
        this.loading = true
        this.$set(this.forms, 'payment_method', 3)
        this.$set(this.forms, 'expense_date', new Date())

        this.forms.date    = moment(new Date()).format('YYYY-MM-DD')
        this.computedDate1 = moment(this.forms.date, "YYYY-MM-DD").format(this.$root.settings.settings.date_format)

        this.method = this.$root.getMethodAction();
        let url     = '/api/expense/expenses/create';
        if(this.form_id) {
            this.method = 'Edit'
            url         = '/api/expense/expenses/' + this.form_id + '/edit'
        }
        this.$root.$data.erp.request.get(url, this, (data) => {
            this.warehouses = data.warehouses
            if(this.warehouses.length === 1) {
                this.forms.warehouse_id = this.warehouses[0].id
            }
            // this.categories = data.categories
            this.banks    = data.banks
            this.accounts = data.accounts
            this.getUsers()
            if(this.method === 'Edit') {
                this.expenses           = data.expenses
                this.forms.id           = this.expenses.id
                // this.forms.expense_date   = moment(new Date(this.expenses.expense_date)).format('YYYY-MM-DD')
                this.forms.expense_date = moment(this.expenses.expense_date, this.$root.settings.settings.date_format).format("YYYY-MM-DD")

                this.forms.warehouse_id   = parseInt(this.expenses.warehouse_id)
                // this.forms.category_id    = parseInt(this.expenses.category_id)
                this.forms.payment_method = parseInt(this.expenses.payment_method)
                this.forms.ref            = this.expenses.ref
                this.forms.amount         = this.expenses.amount
                this.forms.note           = this.expenses.note
                if(data.expenses.account_id) {
                    this.forms.account_id = this.expenses.account_id

                    axios.get('/api/get-any-user/' + this.expenses.userable_id, {
                        params: {
                            model: this.expenses.userable_model
                        }
                    })
                         .then(res => {
                             this.forms.userable_id = res.data
                         })
                }
            }
            this.loading = false
        });

    },

    methods: {
        getUsers: _.debounce(function (query) {
            axios
                .get("/api/get-any-user", {
                    params: {name: query},
                })
                .then((result) => {
                    this.users = result.data;
                })
        }, 200),

        postExpense() {
            this.forms.expense_date = moment(this.forms.expense_date).format("YYYY-MM-DD HH:mm:ss")
            let requestMethod       = 'post'
            let url                 = '/api/expense/expenses'
            if(this.method === 'Edit') {
                requestMethod = 'patch'
                url           = '/api/expense/expenses/' + this.forms.id
            }
            this.forms.submit(requestMethod, url, true, this.$root)
                .then(data => {
                    this.loading = false
                    Swal.fire({
                                  toast            : true,
                                  position         : 'top',
                                  icon             : data.type,
                                  timer            : 1000,
                                  text             : data.message,
                                  showCancelButton : false,
                                  showConfirmButton: false,
                              })
                    this.forms.expense_date        = moment(new Date()).format('YYYY-MM-DD')
                    this.computedDate              = moment(this.forms.expense_date, "YYYY-MM-DD").format(this.$root.settings.settings.date_format)
                    data['show_payment_component'] = !(this.form_id || this.pay_later);
                    if(this.form_id) {
                        data['method'] = 'edit'
                    }
                    this.$emit('fromCreate', data)
                })
                .catch(err => {
                    this.loading = false
                })
        },
        onCancel() {
            this.forms.reset()
        },
        closeDatePicker() {
            let currentTime         = moment(new Date()).format("HH:mm:ss")
            let expense_date        = this.forms.expense_date
            let selectedDate        = moment(expense_date).format(this.$root.settings.settings.date_format)
            // console.log(selectedDate + ' ' + currentTime)
            this.computedDate       = moment(selectedDate + ' ' + currentTime, this.$root.settings.settings.date_format).format(this.$root.settings.settings.date_format)
            this.forms.expense_date = moment(this.forms.expense_date).format("YYYY-MM-DD")
            this.menu1              = false
        },
        closeDatePicker1() {
            let currentTime    = moment(new Date()).format("HH:mm:ss")
            let date           = this.forms.date
            let selectedDate   = moment(date).format(this.$root.settings.settings.date_format)
            // console.log(selectedDate + ' ' + currentTime)
            this.computedDate1 = moment(selectedDate + ' ' + currentTime, this.$root.settings.settings.date_format).format(this.$root.settings.settings.date_format)
            this.forms.date    = moment(this.forms.date).format("YYYY-MM-DD")
            this.menu2         = false
        }
    }
}