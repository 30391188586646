require('./bootstrap')
import Vuetify from './plugins/vuetify.js'
import router from './plugins/inventoryRoutes'
import AppIcons from './ERPFramework/appIcons'
import store from "./store";
import {Forms} from "./ERPFramework/Forms"
import {Erp} from "./ERPFramework/Erp"
import mixins from "./mixin.js";
import './components/global/inventoryGlobalComponents'
import './plugins/vueHtmlToPaper'

require('./filter')
// import VeeValidate from 'vee-validate'


Vue.mixin(mixins)

window.Erp = new Erp()

Vue.prototype._ = _;
new Vue({
            el      : '#app',
            router,
            store,
            vuetify : Vuetify,
            data    : () => ({
                goDark         : false,
                appIcons       : AppIcons,
                dialog         : false,
                drawer         : null,
                settings       : {},
                companySettings: {},
                forms          : new Forms({}),
                erp            : new Erp(),
                previousRoute  : '',
            }),
            filters : {
                capitalize(str) {
                    return _.upperFirst(str);
                },
                replace(str, input, output) {
                    return str.replace(input, output);
                },
            },
            created() {
                if(localStorage.getItem('erptheme') === 'dark') {
                    this.goDark = true
                }
                let settings = document.head.querySelector('meta[name="settings"]')
                if(window.location.pathname !== '/') {
                    this.getCompany()
                    this.getPermissions()
                }

                if(settings) {
                    this.settings = JSON.parse(settings.content)
                }
                store.dispatch('getSettings')
            },
            watch   : {
                goDark: {
                    // immediate: true,
                    handler(val) {
                        if(val === true) {
                            this.$store.state.appColor = {
                                topbar : 'blue-grey darken-3',
                                sidebar: 'blue-grey darken-4'
                            };
                            localStorage.setItem('erptheme', 'dark');
                            return (this.$vuetify.theme.dark = true);
                        } else {
                            this.$store.state.appColor = {
                                topbar : 'purple',
                                sidebar: 'grey lighten-2'
                            };
                            localStorage.setItem('erptheme', 'light');
                            return (this.$vuetify.theme.dark = false);
                        }
                    }
                }
            },
            computed: {
                shippingLabel() {
                    if(this.settings.settings.inventory.shipping_cost_label !== undefined) {
                        if(this.settings.settings.inventory.shipping_cost_label !== null || this.settings.settings.inventory.shipping_cost_label !== '') {
                            return this.settings.settings.inventory.shipping_cost_label
                        } else {
                            return 'Shipping Cost'
                        }
                    } else {
                        return 'Shipping Cost'
                    }
                },
                tableOrder() {
                    if(this.settings.settings.inventory.table_order !== undefined) {
                        if(this.settings.settings.inventory.table_order === 'true') {
                            return true
                        } else {
                            return false
                        }
                    } else {
                        return false
                    }
                }
            },
            methods : {
                async confirmation(obj = {}) {
                    let returnData = false
                    await Swal.fire({
                                        icon             : 'warning',
                                        text             : obj.text,
                                        showCancelButton : true,
                                        showConfirmButton: true
                                    })
                              .then((result) => {
                                  returnData = result.isConfirmed
                              })
                    return returnData;
                },
                getPermissions() {
                    let currentUrl = window.location.pathname
                    if(currentUrl === '/login' || currentUrl === '/' || currentUrl === '/admin/login') return null
                    axios.get('/api/rbac/permission/1')
                         .then(res => {
                             if(res.data['permissions']) {
                                 store.commit('addPermissions', res.data['permissions'])
                             }
                             if(res.data['timePermissions']) {
                                 store.commit('addTimePermissions', res.data['timePermissions'])
                             }

                         })
                },
                getCompany() {
                    axios.get('/api/inventory/get-company').then(res => {
                        this.companySettings = res.data ? res.data : {}
                    })
                },
                hideUser() {
                    document.getElementById("pullNotification").style.display = "none";
                },
                getDefault_id(arrs) {
                    let val = []
                    val     = arrs.filter(data => {
                        if(data.is_default == true) {
                            return data
                        }
                    })
                    return val.length > 0 ? val[0].id : null;
                },
                getPrimary_id(arrs) {
                    let val = []
                    val     = arrs.filter(data => parseInt(data.is_primary) === 1)
                    return val.length > 0 ? val[0].id : null;
                },
                getMethod_and_id() {
                    let id     = this.$route.params;
                    let method = this.$route.name;
                    if(_.includes(method, 'Edit')) method = 'Edit'
                    if(_.includes(method, 'Create')) method = 'Create'
                    if(_.includes(method, 'Delete')) method = 'Delete'
                    if(_.includes(method, 'Show')) method = 'Show'
                    if(_.includes(method, 'Update')) method = 'Update'

                    return [id, method];
                },
                getMethodAction() {
                    let method = this.$route.name
                    if(_.includes(method, 'edit')) method = 'Edit'
                    if(_.includes(method, 'create')) method = 'Create'
                    if(_.includes(method, 'delete')) method = 'Delete'
                    if(_.includes(method, 'show')) method = 'Show'
                    if(_.includes(method, 'update')) method = 'Update'

                    return method;
                },
                timeFormat(value) {
                    return moment(value).format(this.settings.settings.date_format + " HH:mm:ss")
                },

                paymentMethods(value) {
                    if(value == 1) return 'cash'
                    if(value == 2) return 'credit card'
                    if(value == 3) return 'cheque'
                },
                productStatus(value) {
                    if(value == 1) return 'received'
                    if(value == 2) return 'pending'
                    if(value == 3) return 'ordered'
                },

                showSpinner() {
                    this.loading = true;
                },
                hideSpinner() {
                    this.loading = false;
                },
                printMe() {
                    window.print()
                },
                ownParse(val) {
                    return parseFloat(val ? val : 0)
                },
            }
        })


