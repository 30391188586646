module.exports = [
    {
        path:'/rbac/user-crud',
        name:'user.crud',
        component: require('../views/inventory/rbac/user-crud.vue').default
    },
    // {
    //     path: '/rbac/role-crud',
    //     name: 'role.crud',
    //     component: require('../views/inventory/rbac/role-crud.vue').default
    // },
    // {
    //     path: '/rbac/permission-crud',
    //     name: 'permission.crud',
    //     component: require('../views/inventory/rbac/permission-crud.vue').default
    // }
]