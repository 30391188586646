<template>
  <v-card outlined>
    <v-card-title>
      Bank Reconcile
    </v-card-title>
    <v-card-text>

    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name   : "reconcile",
  data() {
    return {
      response_data: []
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      axios.get(api_bank + '/reconcile')
           .then(res => {
             console.log(res.data)
           })
    }
  }
}
</script>