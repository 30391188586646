import FormActions from '~/form-actions.vue'

export default {
    components: {
        FormActions
    },
    props     : {
        modelId: {
            type   : Number,
            default: null
        },
        value  : {
            type   : Boolean,
            default: false
        },
    },
    data() {
        return {
            url        : this.$getUrl('customers', this.value, this.modelId),
            axiosMethod: this.$getAxiosMethod(this.value, this.modelId),
            forms      : this.$root.$data.forms,
            customers  : {},
            loading    : false,
        }
    },

    beforeCreate() {
        this.$root.$data.forms.fieldReset();
    },

    watch: {
        '$route.fullPath'(val) {
            //reset data
            this.forms.reset();
            this.customers = {}
        }
    },

    created() {
        if(settings) {
            if(settings.settings.hasOwnProperty('default_email') && settings.settings.default_email) {
                this.forms.email = settings.settings.default_email
            }
        }
        this.forms.is_default = false

        if(this.axiosMethod === 'patch' || this.modelId)
            this.getData()
    },


    methods: {
        getData() {
            this.loading = true
            this.$root.$data.erp.request.get(this.url, this, (data) => {
                this.customers        = data.customers
                this.forms.id         = this.customers.id
                this.forms.name       = this.customers.name
                this.forms.code       = this.customers.code
                this.forms.phone      = this.customers.phone
                this.forms.email      = this.customers.email
                this.forms.address    = this.customers.address
                this.forms.details    = this.customers.details
                this.forms.is_default = this.customers.is_default
                this.loading          = false
            })
        },
        postCustomer($e) {
            let submitter = $e.submitter.value
            // return null
            this.loading  = true
            this.forms.submit(this.axiosMethod, this.$getUrlForm(this.url), true, this.$root)
                .then(data => {
                    this.loading = false
                    if(this.value) {
                        if(submitter === 'submit-close' || submitter === 'update-close') {
                            this.$emit('customerDetails', data.customer);
                            this.$emit('input', false)
                        }
                    }
                    this.$axiosPostDialog(data)
                }).catch(error => {
                this.loading = false
            });
        },
        onCancel() {
            this.forms.reset();
        },
        initialEmail() {
            if(this.forms.name) {
                var emailExtension = '@' + (this.$root.companySettings.hasOwnProperty('id') ?
                    this.$root.companySettings.settings.site_name.replace(/\s/g, '') :
                    'initial').toLowerCase()
                emailExtension     = emailExtension + '.com'
                var today          = new Date();
                var time           = today.getHours() + today.getMinutes() + today.getSeconds();

                this.forms.email = (this.forms.name.replace(/\s/g, '') + time + emailExtension).toLowerCase()

            } else {
                this.forms.email = ''
            }

        }
    }
}