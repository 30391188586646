<template>
  <v-row>
    <v-col md="6" cols="12">
      <v-card flat>
        <v-card-title>To be Received</v-card-title>
        <v-card-text>
          <to-be-receive ref="shipping" @updateReceived="updateReceived"/>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col md="6" cols="12">
      <v-card flat>
        <v-card-title>Received</v-card-title>
        <v-card-text>
          <received ref="received" @updateShipping="updateShipping"/>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import ToBeReceive from './toBeReceive.vue'
import Received    from './received.vue'

export default {
  name      : "shipment",
  components: {
    ToBeReceive,
    Received
  },
  data() {
    return {
      loading           : false,
      toBeReceiveHeaders: [
        {text: '#', value: 'id'},
        {text: 'expected shipping date', value: 'expected_shipping_date'},
        {text: 'action', value: 'action'},
      ],
      toBeReceiveOptions: {
        itemsPerPage: this.$store.state.itemsPerPage,
      },
      toBeReceive       : {},
      received          : {}
    }
  },
  watch     : {
    toBeReceiveOptions: {
      deep: true,
      handler() {
        this.loading = true
        this.getData()
      }
    }
  },
  created() {

  },
  methods   : {
    updateReceived(val) {
      if(val) {
        this.$refs['received'].getData()
      }
    },
    updateShipping(val) {
      if(val) {
        this.$refs['shipping'].getData()
      }
    }
  }
}
</script>