import _ from "lodash";
import UnitCreate from 'i#/unit/create.vue';
export default {
    components:{
        UnitCreate
    },
    data() {
        return {
            units: [],
            items:{},
            loading:false,
            options: {
                sortBy      : ['key'],
                sortDesc:[false],
                itemsPerPage: this.$store.state.itemsPerPage,
            },
            edit_id:null,
            createOrUpdateDialog:false,
            headers:[
                {text:'key', value:'key', sortable:false},
                {text:'description', value:'description', sortable:false},
                {text:'created at', value:'created_at', sortable:false},
                {text:'action', value:'action', sortable:false},
            ],
            closeOnContentClick:false
        }
    },
    computed: {
        queryString() {
            return '&key=' + this.key
        }
    },
    watch:{
        options:{
            deep:true,
            handler(){
                this.loading=true
                this.getDataWithLoading()
            }
        },
        createOrUpdateDialog(val) {
            if(!val) {
                this.getDataWithLoading()
                this.edit_id = null
            }
        }
    },
    methods: {
        deleteItem(id){
            this.loading = true
            this.$deleteWithConfirmation({
                                             text: 'Are you sure you want delete this unit?',
                                             url : '/api/inventory/units/'+id
                                         })
                .then(data=>{
                    this.loading  = false
                    this.getData()
                })
            this.loading=false
        },
        getData:_.debounce(function () {
            axios.get('/api/inventory/units',{
                params:this.options
            })
                .then(res=>{
                    this.items = res.data
                    this.loading=false
                })
        }, 400),
        getUnitList(query, callback) {
            axios.get('/api/inventory/units?dropdown=true').then(response => {
                this.units = response.data;
                callback(query);
            }).catch(error => {

            })
        },
        getDataWithLoading() {
            this.loading = true
            this.getData()
        }

    }
}