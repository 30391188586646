<template>
  <div id="purchase_order_print">
    <h4>Order Ledger #{{item.order_id?'CPO':'PO'}}-{{ item.order_no }}</h4>

    <div class="row">
      <div class="col-md-4">
        <p class="text-subtitle-1">Customer Information</p>
        <p>name: {{ item.supplier.company }}</p>
        <p>email: {{ item.supplier.email }}</p>
        <p v-if="item.supplier.phone">phone: {{ item.supplier.phone }}</p>
        <p v-if="item.supplier.address">address: {{ item.supplier.address }}</p>
      </div>
      <div class="col-md-4">
        <p class="text-subtitle-1">Shop Information</p>
        <p>Company name: {{ company.name }}</p>
        <p>Address Detail: {{ company.address1 }}</p>
        <p>
          mobile number:
          <span v-for="(phone, index) in company.contact_phone">
              {{ phone }}{{ company.contact_phone.length - 1 == index ? '' : ',' }}
            </span>
        </p>
      </div>
      <div class="col-md-4">
        <p class="text-subtitle-1">Order Information</p>
        <p>Order no: {{item.order_id?'CPO':'PO'}}-{{ item.order_no }}</p>
        <p>Order Date: {{ item.order_date | momentFormatBy($root.settings.settings.date_format)  }}</p>
        <p>created By: {{ item.created_by.name }}</p>
        <barcode
            height="35px"
            font-size="10px"
            :value="`${item.order_id?'CPO':'PO' +'-'+item.order_no}`"
        >
        </barcode>
      </div>
    </div>


    <div class="row">
      <div class="col-md-12">
        <table class="table">
          <thead>
          <tr>
            <th>No.</th>
            <th>Code</th>
            <th>Name</th>
            <th>{{ $store.state.quantity_label }}</th>
            <th>Unit Cost</th>
            <th>Discount</th>
            <th>Total</th>
          </tr>
          </thead>
          <tbody>
          <tr
              :index="index"
              v-for="(product, index) in item.products"
              v-if="!_.isEmpty(item.products)"
          >
            <td>
              {{ index + 1 }}
            </td>
            <td>{{ product.code }}</td>
            <td>{{ product.name }}</td>
            <td>
              {{ parseFloat(product.pivot.quantity).toFixed(4) }}
              {{ product.units | findFromArrayById(product.pivot.unit_id, 'key') }}
            </td>
            <td>{{ product.pivot.price }}</td>
            <td>{{ product.pivot.discount }}</td>
            <td>{{ product.pivot.subtotal }}</td>
          </tr>
          </tbody>
          <tfoot>
          <tr class="strong">
            <td colspan="6" align="right">
              Product Total
            </td>
            <td colspan="2">
              {{ product_total }}
            </td>
          </tr>
          <tr class="strong">
            <td colspan="6" class="text-right">{{ $root.shippingLabel }}</td>
            <td>{{ item.shipping_cost }}</td>
          </tr>
          <tr>
            <td colspan="6" class="text-right">
              Sub Total
            </td>
            <td>
              {{ sub_total }}
            </td>
          </tr>

          <tr class="strong">
            <td colspan="6" class="text-right">Overall Discount</td>
            <td v-if="item.overall_discount">{{ item.overall_discount }}%({{ discounted_amount }})</td>
            <td v-else>0</td>
          </tr>

          <tr class="strong">
            <td colspan="6" class="text-right">Total</td>
            <td>{{ item.total }}</td>
          </tr>
          </tfoot>
        </table>
      </div>
      <div class="col-md-6" v-if="item.note">
        Note: {{ item.note }}
      </div>
      <div class="col-md-6"></div>
    </div>

  </div>
</template>
<script>
export default {
  props:['item', 'company'],
  computed  : {
    product_total() {
      if(!_.isEmpty(this.item) && !_.isEmpty(this.item.products)) {
        return this.item.products.reduce((acc, obj) => {
          return acc + obj.pivot.subtotal
        }, 0)
      }
      return 0
    },
    sub_total() {
      return this.product_total + this.item.shipping_cost
    },
    discounted_amount() {
      return (this.sub_total * (this.item.overall_discount / 100)).toFixed(2)
    }
  },
}
</script>