export default {
    name   : "saleReturnReport",
    data() {
        return {
            items     : {},
            totalPrice: 0.0,
            paid      : 0.0,
            balance   : 0.0,
            loading   : false,
            options   : {
                itemsPerPage: this.$store.state.itemsPerPage,
            },
            headers   : [
                {text: 'ref', value: 'ref', sortable: true},
                {text: 'total(dr)', value: 'total_price', sortable: true},
                {text: 'paid(cr)', value: 'total_return_amount', sortable: true},
                {text: 'balance', value: 'balance', sortable: true},
                {text: 'created at', value: 'created_at', sortable: true},
            ]
        }
    },
    watch  : {
        options: {
            deep: true,
            handler() {
                this.loading = true
                this.getData()
            }
        },
    },
    created() {
        if(!_.isEmpty(this.$route.params)) {
            if(this.$route.params.ref !== undefined && this.$route.params.ref) {
                this.options.ref = this.$route.params.ref
            }
        }
    },
    methods: {
        getData() {
            axios.get('/api/report/sale-return', {params: this.options})
                 .then(res => {
                     this.items      = res.data.saleReturnData
                     this.totalPrice = res.data.totalPrice
                     this.balance    = res.data.balance
                     this.paid       = res.data.paid
                     this.loading    = false
                 })
        }
    }
}