module.exports = [
    {
        path:'/inventory/unit/create',
        name:'unit.create',
        component: require('../views/inventory/unit/create.vue').default
    },
    {
        path: '/inventory/unit/index',
        name:'unit.index',
        component: require('../views/inventory/unit/index.vue').default
    },
    {
        path: '/inventory/unit/:id/edit',
        name: 'unit.edit',
        component: require('../views/inventory/unit/create.vue').default
    },
    {
        path:'/inventory/unitconversions/create',
        name:'unitMapping.create',
        component: require('../views/inventory/unit/mapping.vue').default
    },
    {
        path: '/inventory/productunit',
        name: 'productUnit.create',
        component: require('../views/inventory/unit/productunit.vue').default
    }
];