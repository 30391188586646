<template>
  <v-card outlined>
    <v-card-title>Supplier Ledger {{ `${options.supplier ? ' - ' + options.supplier.name : ''}` }}
      <v-spacer/>
      <user-dropdown-autocomplete
          dense
          returnObject
          module-type="supplier"
          v-model="options.supplier"
          :columns="['name', 'email']"
      />
      <v-btn @click="$router.back()" icon>
        <v-icon>{{ $root.appIcons.return }}</v-icon>
      </v-btn>
      <v-btn icon @click="$htmlToPaper('list_print_bootstrap')">
        <v-icon>mdi-printer</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-col cols="12" md="12" id="list_print_div">
        <v-data-table
            dense
            :headers="headers"
            :loading="loading"
            item-key="item_id"
            :items="items.data"
            class="elevation-0"
            :options.sync="options"
            :server-items-length="items.total"
            loading-text="Loading... Please wait"
            :footer-props="{itemsPerPageOptions: $store.state.itemsPerPageOptions}"
        >
          <template v-slot:top>
            <template v-if="options.date">
              <template v-if="options.date.length<2">
                <v-alert
                    elevation="2"
                    border="bottom"
                    colored-border type="warning"
                >
                  Please select dates
                </v-alert>
              </template>
            </template>
            <template v-if="!options.date">
              <v-alert
                  elevation="2"
                  border="bottom"
                  colored-border type="warning"
              >
                Please select dates
              </v-alert>
            </template>
          </template>
          <template v-slot:header.date="{ header }">
            <v-menu
                offset-y
                v-model="menu"
                min-width="290px"
                :nudge-right="40"
                transition="scale-transition"
                :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    solo
                    dense
                    readonly
                    clearable
                    v-on="on"
                    hide-details
                    label="Dates"
                    v-bind="attrs"
                    v-model="options.date"
                >
                </v-text-field>
              </template>
              <v-date-picker
                  range
                  v-model="options.date"
                  @input="options.date.length>1?menu = false:null"
              >
              </v-date-picker>
            </v-menu>
          </template>

        </v-data-table>
      </v-col>
    </v-card-text>
    <list-print-bootstrap
        :title="[
            `Supplier Ledger ${options.supplier? ' - '+options.supplier.name:''}`,
            `From ${options.date[0]} To ${options.date[1]}`
        ]"
        :columns="headers"
        :rows="items.data"
        style="visibility: collapse"
        :date_fields="['created_at']"
        v-if="options.user && (options.date && options.date.length>1)"
    />
  </v-card>
</template>
<script>

export default {
  name    : "supplier-ledger",
  data() {
    return {
      items      : {},
      user       : null,
      menu       : false,
      loading    : false,
      queryString: {},
      options    : {
        itemsPerPage: this.$store.state.itemsPerPage,
      },
      headers    : [
        {text: 'date', value: 'date', sortable: false},
        {text: 'description', value: 'description', sortable: false},
        {text: 'debit', value: 'debit', sortable: false},
        {text: 'credit', value: 'credit', sortable: false},
        {text: 'balance', value: 'balance', sortable: false},
      ],
    }
  },
  watch   : {
    options: {
      deep: true,
      handler(val) {
        this.options.user = !_.isEmpty(val.supplier) ? val.supplier.id : null;
        if((this.options.date && this.options.date.length > 1) && this.options.user) {
          this.loading = true
          this.getResults()
        }
      }
    },
  },
  computed: {
    user_name() {

    }
  },
  methods : {
    generateDescription(val) {
      let txt = ''
      if(val.purchase_date_formatted && val.purchase_id) {
        txt = 'A purchase(bill no: '
            + val.purchase_bill_no +
            ') has been made from ' +
            this.options.supplier.name
      }
      if(!val.purchase_id && val.ledger_type === 'debit') {
        txt = 'A bill has been paid to '
            + this.options.supplier.name
      }
      if(!val.purchase_id && val.ledger_type === 'credit') {
        txt = 'A deposit/advance has been taken from ' + this.options.supplier.name
      }
      return txt
    },
    getResults() {
      let url = api_accounts + '/supplier-ledger'
      axios.get(url, {params: this.options})
           .then(res => {
             this.items = res.data
             if(this.items.data.length) {
               this.items.data.map((v, i) => {
                 v.date        = v.purchase_date_formatted ? v.purchase_date_formatted : v.date_without_time
                 v.credit      = v.ledger_type === 'credit' ? v.value : null
                 v.debit       = v.ledger_type === 'debit' ? v.value : null
                 v.description = this.generateDescription(v)
               })
             }
           })
           .catch()
           .finally(r => {
             this.loading = false
           })
    }
  }
}
</script>

<style scoped>

</style>