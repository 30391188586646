export default {
    methods: {
        $getAxiosMethod(is_dialog, model_id) {
            return model_id || this.$route.params.id ? 'patch' : 'post';
        },
        $getUrlForm(url) {
            return url.replace(/edit|create/g, '').replace(/\/$/, "")
        },
        $setStorageLocation(warehouse_id, isle_id, rack_id, bin_id) {
            return `${auth.user.company_id}-${warehouse_id}-${isle_id}-${rack_id}-${bin_id}`
        },
        $setPickingLocation(warehouse_id, picking_id) {
            return `${auth.user.company_id}-${warehouse_id}-${picking_id}`
        },
        $getUrl(model_name, is_dialog, model_id, module_name = null) {

            let base_url = api_base_url + '/' + model_name

            if (module_name) {
                base_url = '/api/' + module_name + '/' + model_name
            }

            let url = base_url + '/create'

            if (is_dialog && model_id) {
                // console.log(1)
                url = base_url + '/' + this.modelId + '/edit'
            }

            if (!is_dialog && this.$route.params.id) {
                // console.log(2, model_id, is_dialog)
                url = base_url + '/' + this.$route.params.id + '/edit'
            }
            return url
        },
        $axiosPostDialog(data) {
            Swal.fire({
                    icon: data.type,
                    timer: 2000,
                    text: data.message,
                    showCancelButton: false,
                    showConfirmButton: false,
                })
                .catch(Swal.noop)
        },
        async $deleteWithConfirmation(obj) {
            let returnData = false
            await Swal.fire({
                    icon: "warning",
                    text: obj.text,
                    showCancelButton: true,
                    showConfirmButton: true
                })
                .then(async (result) => {
                    if (result.isConfirmed) {
                        await axios.delete(obj.url)
                            .then(res => {
                                Swal.fire({
                                    icon: res.data.type,
                                    timer: 3000,
                                    text: res.data.message,
                                    showCancelButton: false,
                                    showConfirmButton: false
                                })
                                returnData = result.isConfirmed
                            })
                    }
                })
            return new Promise((res, rej) => {
                if (returnData) {
                    res(returnData)
                } else {
                    rej(returnData)
                }
            })
        }

    }
}