module.exports = [
    {
        path: '/inventory/suppliers/index',
        name: 'supplier.index',
        component: require('../views/inventory/supplier/index.vue').default
    },
    {
        path: '/inventory/suppliers/create',
        name: 'supplier.create',
        component: require('../views/inventory/supplier/create.vue').default
    },
    {
        path: '/inventory/suppliers/:id/edit',
        name: 'supplier.edit',
        component: require('../views/inventory/supplier/create.vue').default
    },
    {
        path: '/inventory/suppliers/:id',
        name: 'supplier.show',
        component: require('../views/inventory/supplier/show.vue').default
    },
    {
        path: '/inventory/customers/create',
        name:'customer.create',
        component: require('../views/inventory/customer/create.vue').default
    },
    {
        path: '/inventory/customers/index',
        name: 'customer.index',
        component: require('../views/inventory/customer/index.vue').default
    },
    {
        path: '/inventory/customers/:id/edit',
        name: 'customer.edit',
        component: require('../views/inventory/customer/create.vue').default
    },
    {
        path:'/inventory/otherusers/index',
        name:'otheruser.index',
        component: require('../views/inventory/otheruser/index.vue').default
    },
    {
        path:'/user/activity-log',
        name:'activitylog',
        component: require('../views/activitylog.vue').default
    },
    {
        path:'/inventory/payment-received/:id',
        name: 'payment.received.show',
        component: require('../views/inventory/payment/payment-received-show.vue').default
    },
    {
        path:'/inventory/payment-received',
        name: 'payment.received',
        component: require('../views/inventory/payment/payment-received.vue').default
    },
    {
        path:'/inventory/bill-paid/:id',
        name: 'bill.paid.show',
        component: require('../views/inventory/payment/bill-paid-show.vue').default
    },
    {
        path:'/inventory/bill-paid',
        name: 'bill.paid',
        component: require('../views/inventory/payment/bill-paid.vue').default
    },

];