export default {
    name   : "show",
    props     : {
        modelId: {
            type   : Number,
            default: null
        },
        value  : {
            type   : Boolean,
            default: false
        },
    },
    data() {
        return {
            payment_crud_method    : 'create',
            payment_crud_model_id  : null,
            payment_crud_dialog    : false,
            payment_crud_payment_id: null,
            payment_list_dialog    : false,
            menu                   : false,
            selected               : [],
            supplier               : {},
            loading                : false,
            items                  : {},
            multiple_ids           : [],
            disabledCount          : 0,
            options                : {
                sortBy      : ['created_at'],
                sortDesc    : [true],
                itemsPerPage: this.$store.state.itemsPerPage,
            },
            headers                : [
                {text: 'bill no', value: 'bill_no', sortable: true},
                {text: 'amount', value: 'total', sortable: true},
                {text: 'total paid', value: 'total_paid', sortable: true},
                {text: 'due', value: 'due', sortable: true},
                {text: 'created at', value: 'created_at', sortable: true},
                {text: 'bill date', value: 'purchase_date', sortable: true},
            ],
        }
    },
    watch  : {
        selected(val) {
            this.multiple_ids = []
            if(val.length > 0) {
                val.forEach(v => {
                    this.multiple_ids.push(v.id)
                })
            }
        },
        payment_crud_dialog(val) {
            if(!val) {
                this.resetPaymentComponent()
            }
        },
        options: {
            deep: true,
            handler() {
                this.loading = true
                this.getData()
            }
        },
    },
    created() {
        this.getSupplier()
    },
    computed:{
        view_id(){
            return this.modelId ? this.modelId : this.$route.params.id;
        }
    },
    methods: {
        selectAllToggle(props) {
            if(this.selected.length != this.items.length - this.disabledCount) {
                this.selected = [];
                const self    = this;
                props.items.forEach(item => {
                    if(!item.due <= 0) {
                        self.selected.push(item);
                    }
                })
            } else {
                this.selected = []
            }
        },
        resetPaymentComponent() {
            this.payment_crud_method     = 'create'
            this.payment_crud_model_id   = null
            this.payment_crud_payment_id = null
        },
        createBill() {
            this.resetPaymentComponent()
            this.payment_crud_dialog = true
        },
        paymentSuccess(val) {
            if(val) {
                this.getData()
                this.payment_crud_dialog = false
                this.multiple_ids        = []
                this.selected            = []
                this.$emit('reload-index', true)
            }
        },
        getData() {
            if(this.view_id) {
                this.options.supplier_id = this.view_id
                axios
                    .get('/api/inventory/purchases', {params: this.options})
                    .then(res => {
                        this.items   = res.data
                        this.loading = false
                    })
            }
        },
        getSupplier() {
            if(this.view_id)
                axios
                    .get('/api/inventory/suppliers/' + this.view_id)
                    .then(res => {
                        this.supplier = res.data
                        this.loading  = false
                    })
        }
    }
}