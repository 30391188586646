import _ from "lodash";
import CustomerCreate from 'i#/customer/create.vue'
export default {
    components: {
        CustomerCreate
    },
    data() {
        return {
            customers: [],
            loading  : false,
            items    : {},
            options  : {
                sortBy      : ['created_at'],
                sortDesc:[true],
                itemsPerPage: this.$store.state.itemsPerPage,
                user_email  : null,
                user_name   : null
            },
            createOrUpdateDialog: false,
            edit_id             : null,
            headers  : [
                {text: 'name', value: 'name', sortable: true},
                {text: 'id', value: 'code', sortable: true},
                {text: 'phone', value: 'phone', sortable: true},
                {text: 'email', value: 'email', sortable: true},
                {text: 'address', value: 'address', sortable: false},
                {text: 'details', value: 'details', sortable: false},
                {text: 'created at', value: 'created_at', sortable: true},
                {text: 'action', value: 'action', sortable: false},
            ],
            closeOnContentClick:false
        }
    },
    watch  : {
        options: {
            deep: true,
            handler() {
                this.getDataWithLoading()
            }
        },
        createOrUpdateDialog(val) {
            if(!val) {
                this.getDataWithLoading()
                this.edit_id = null
            }
        }
    },
    methods: {
        deleteItem(id) {
            Swal.fire({
                          icon             : 'warning',
                          text             : 'Are you sure you want to delete this customer?',
                          showCancelButton : true,
                          showConfirmButton: true
                      })
                .then((result)=>{
                    if(result.isConfirmed){
                        this.loading = true
                        this.$deleteWithConfirmation({
                                                         text: 'Are you sure you want delete this customer?',
                                                         url : '/api/inventory/customers/' + id
                                                     })
                            .then(data => {
                                this.loading = false
                                this.getData()
                            })
                            .catch(error => {
                                this.loading = false
                            })
                    }
                })
                .finally(()=>{
                    this.loading = false
                })

        },
        getData: _.debounce(function () {
            axios.get('/api/inventory/customers', {
                params: this.options
            })
                 .then(res => {
                     this.items   = res.data
                     this.loading = false
                 })
        }, 800),
        getCustomerList(query, callback) {
            axios.get('/api/inventory/customers?dropdown=true').then(response => {
                this.customers = response.data;
                callback(query);
            })
                 .catch(error => {

                 })
        },
        getDataWithLoading() {
            this.loading = true
            this.getData()
        }
    }
}