import PurchaseShowPrint from './purchase-show-print.vue'

export default {
    components: {
        PurchaseShowPrint
    },
    data() {
        return {
            payment_crud_method    : 'create',
            payment_crud_dialog    : false,
            payment_crud_model_id  : null,
            payment_crud_payment_id: null,
            dialog                 : false,
            forms                  : {},
            purchase               : {
                supplier: {},
                payments: [],
                returns : []
            },
            company                : {
                name: {}
            },
            totalRAmount           : 0,
        }
    },
    created() {
        this.getData()
    },
    watch     : {
        payment_crud_dialog(val) {
            if(!val) {
                this.resetPaymentComponent()
            }
        }
    },
    computed  : {
        product_total() {
            if(!_.isEmpty(this.purchase) && !_.isEmpty(this.purchase.products)) {
                return this.purchase.products.reduce((acc, obj) => {
                    return acc + obj.pivot.subtotal
                }, 0)
            }
            return 0
        },
        sub_total() {
            return this.product_total + this.purchase.shipping_cost
        },
        discounted_amount() {
            return (this.sub_total * (this.purchase.overall_discount / 100)).toFixed(4)
        }
    },
    methods   : {
        createBill() {
            this.resetPaymentComponent()
            this.payment_crud_model_id = this.purchase.id
            this.payment_crud_dialog   = true
        },
        editBill(id) {
            this.payment_crud_payment_id = id
            this.payment_crud_method     = 'edit'
            this.payment_crud_dialog     = true
            this.payment_list_dialog     = false
        },
        resetPaymentComponent() {
            this.payment_crud_method     = 'create'
            this.payment_crud_model_id   = null
            this.payment_crud_payment_id = null
        },
        paymentSuccess(val) {
            if(val) {
                this.getData()
                this.payment_crud_dialog = false
            }
        },
        updateReturn() {
            axios.post('/api/inventory/purchase-return/', this.forms)
                 .then(res => {
                     if(res.status === 200) {
                         Swal.fire({
                                       icon : res.data.type,
                                       text : res.data.message,
                                       timer: 3000
                                   })
                             .then((result) => {
                                 this.getData()
                                 this.dialog = false
                             })
                     }
                 })
                 .catch(error => {
                     Swal.fire(error.response.data.message)
                 })
        },
        getData() {
            this.$root.$data.erp.request.get('/api' + window.location.pathname, this, (data) => {
                this.purchase     = data.purchase
                this.company      = data.company
                this.totalRAmount = 0
                if(this.purchase.returns.length > 0) {
                    for (let i = 0; i < this.purchase.returns.length; i++) {
                        this.totalRAmount += parseFloat(this.purchase.returns[i].amount)
                    }
                }
            })
        },
        showAmountEdit(item, index) {
            this.forms       = item
            this.forms.price = item.product.buying_price
            this.dialog      = true
        },
    }
}