<template>
  <div>
    <v-card outlined>
      <v-card-title>
        Order Ledger #{{item.order_id?'CPO':'PO'}}-{{ item.order_no }}
        <v-spacer/>
        <v-btn @click="$router.back()" icon>
          <v-icon>{{$root.appIcons.return}}</v-icon>
        </v-btn>
        &nbsp;
        <v-btn
            fab
            dark
            small
            color="success"
            :to="{name:'purchase.order.convert',param:{id:item.id}}"
            v-if="!item.purchase_id && ($options.filters.checkPermission(['purchase', 'create']))"
        >
          <v-icon small>mdi-play</v-icon>
        </v-btn>
        <v-btn
               icon
               @click="$htmlToPaper('purchase_order_print')"
        >
          <v-icon>mdi-printer</v-icon>
        </v-btn>
        <!--      <v-btn-->
        <!--          fab-->
        <!--          icon-->
        <!--          small-->
        <!--          color="success"-->
        <!--          :to="{name:'purchaseOrder.edit',param:{id:item.id}}"-->
        <!--          v-if="!item.purchase_id && $options.filters.checkPermission(['purchaseOrder', 'edit'])"-->
        <!--      >-->
        <!--        <v-icon small>edit</v-icon>-->
        <!--      </v-btn>-->
        <!--      <v-btn-->
        <!--          fab-->
        <!--          text-->
        <!--          small-->
        <!--          color="primary"-->
        <!--          :to="{name:'purchase.show',params:{id:item.purchase_id}}"-->
        <!--          v-if="item.purchase_id && $options.filters.checkPermission(['purchases', 'show'])"-->

        <!--      >-->
        <!--        <v-icon-->
        <!--        >-->
        <!--          mdi-eye-->
        <!--        </v-icon>-->
        <!--      </v-btn>-->
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col md="4" cols="12">
            <p class="text-subtitle-1">Customer Information</p>
            <p>name: {{ item.supplier.company }}</p>
            <p>email: {{ item.supplier.email }}</p>
            <p v-if="item.supplier.phone">phone: {{ item.supplier.phone }}</p>
            <p v-if="item.supplier.address">address: {{ item.supplier.address }}</p>
          </v-col>
          <v-col md="4" cols="12">
            <p class="text-subtitle-1">Shop Information</p>
            <p>Company name: {{ company.name }}</p>
            <p>Address Detail: {{ company.address1 }}</p>
            <p>
              mobile number:
              <span v-for="(phone, index) in company.contact_phone">
              {{ phone }}{{ company.contact_phone.length - 1 == index ? '' : ',' }}
            </span>
            </p>
          </v-col>
          <v-col md="4" cols="12">
            <p class="text-subtitle-1">Order Information</p>
            <p>Order no: {{item.order_id?'CPO':'PO'}}-{{ item.order_no }}</p>
            <p>Order Date: {{ item.order_date | momentFormatBy($root.settings.settings.date_format) }}</p>
            <p>created By: {{ item.created_by.name }}</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col md="12" cols="12">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th>No.</th>
                  <th>Code</th>
                  <th>Name</th>
                  <th>{{ $store.state.quantity_label }}</th>
                  <th>Unit Cost</th>
                  <th>Discount</th>
                  <th>Total</th>
                </tr>
                </thead>
                <tbody>
                <tr
                    :index="index"
                    v-for="(product, index) in item.products"
                    v-if="!_.isEmpty(item.products)"
                >
                  <td>
                    {{ index + 1 }}
                  </td>
                  <td>{{ product.code }}</td>
                  <td>{{ product.name }}</td>
                  <td>
                    {{ parseFloat(product.pivot.quantity).toFixed(4) }}
                    {{ product.units | findFromArrayById(product.pivot.unit_id, 'key') }}
                  </td>
                  <td>{{ product.pivot.price }}</td>
                  <td>{{ product.pivot.discount }}</td>
                  <td>{{ product.pivot.subtotal }}</td>
                </tr>
                </tbody>
                <tfoot>
                <tr class="strong">
                  <td colspan="6" align="right">
                    Product Total
                  </td>
                  <td colspan="2">
                    {{ product_total }}
                  </td>
                </tr>
                <tr class="strong">
                  <td colspan="6" class="text-right">{{ $root.shippingLabel }}</td>
                  <td>{{ item.shipping_cost }}</td>
                </tr>
                <tr>
                  <td colspan="6" class="text-right">
                    Sub Total
                  </td>
                  <td>
                    {{ sub_total }}
                  </td>
                </tr>

                <tr class="strong">
                  <td colspan="6" class="text-right">Overall Discount</td>
                  <td v-if="item.overall_discount">{{ item.overall_discount }}%({{ discounted_amount }})</td>
                  <td v-else>0</td>
                </tr>

                <tr class="strong">
                  <td colspan="6" class="text-right">Total</td>
                  <td>{{ item.total }}</td>
                </tr>
                </tfoot>
              </template>
            </v-simple-table>
          </v-col>
          <v-col md="6" cols="12" v-if="item.note">
            Note: {{ item.note }}
          </v-col>
          <v-col md="6" cols="12"></v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <purchase-order-print
        :item="item"
        :company="company"
        style="visibility: collapse"
    ></purchase-order-print>
  </div>
</template>

<script>
import PurchaseOrderPrint from './purchase-order-print.vue'
export default {
  components:{
    PurchaseOrderPrint
  },
  name   : "show",
  data   : () => ({
    item   : {
      supplier  : {
        company: ''
      },
      created_by: {}
    },
    company: {}
  }),
  created() {
    axios.get('/api' + window.location.pathname)
         .then(res => {
           this.item = res.data.orders
           this.item.products.forEach(item => {
             this.checkQuantity(item.pivot.warehouse_id, item)
           })
           this.company = res.data.company
         })
  },
  computed  : {
    product_total() {
      if(!_.isEmpty(this.item) && !_.isEmpty(this.item.products)) {
        return this.item.products.reduce((acc, obj) => {
          return acc + obj.pivot.subtotal
        }, 0)
      }
      return 0
    },
    sub_total() {
      return this.product_total + this.item.shipping_cost
    },
    discounted_amount() {
      return (this.sub_total * (this.item.overall_discount / 100)).toFixed(2)
    }
  },
  methods: {
    checkQuantity(warehouse_id, item) {
      let value = item.warehouses.filter(val => {
        return val.id === warehouse_id
      })[0]

      if(value === undefined || value.pivot === undefined) {
        item.remaining_quantity = false
        return null
      }
      let warehouseQuantity   = value.pivot.quantity !== undefined ? value.pivot.quantity : 0
      item.lowest_unit        = value.pivot.unit_id
      item.remaining_quantity = warehouseQuantity
      if(item.pivot.unit_id === item.base_unit_id && item.pivot.quantity) {
        item.remaining_quantity = item.remaining_quantity >= item.pivot.quantity
        return null
      }
      if(item.pivot.unit_id !== item.base_unit_id) {
        warehouseQuantity = warehouseQuantity / item.conversion_factor
        if(item.quantity) {
          item.remaining_quantity = item.remaining_quantity >= item.pivot.quantity
        }
      }
    },
    backorder(product) {
      // product_id, unit_id, warehouse_id, quantity

    },
  }
}
</script>