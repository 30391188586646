import CreateOrder from './create.vue'
import ConvertOrder from './convert.vue'

export default {
    name      : "index",
    components: {
        CreateOrder,
        ConvertOrder
    },
    data() {
        return {
            columnMenu          : false,
            menu                : false,
            items               : {},
            loading             : false,
            closeOnContentClick : true,
            options             : {
                sortBy      : ['created_at'],
                sortDesc    : [true],
                itemsPerPage: this.$store.state.itemsPerPage,
            },
            convertDialog: false,
            convert_id: null,
            createOrUpdateDialog: false,
            edit_id             : null,
            headers             : [
                {text: 'order date', value: 'order_date_formatted', sortable: false, align: ''},
                {text: 'order no', value: 'order_no', align: ''},
                {text: 'supplier-id', value: 'supplier_name_id', sortable: false, align: ''},
                {text: 'total', value: 'total', align: ''},
                {text: 'weight', value: 'total_weight', align: ''},
                {text: 'order status', value: 'order_status', sortable: false, align: ''},
                {text: 'created at', value: 'created_at', align: ''},
                {text: 'action', value: 'action', sortable: false, align: ''},
            ]
        }
    },
    watch     : {
        options: {
            deep: true,
            handler() {
                this.loading = true
                this.getData()
            }
        },
        createOrUpdateDialog(val) {
            if(!val) {
                this.getData()
                this.edit_id = null
            }
        },
        convertDialog(val){
            if(!val){
                this.convert_id = null
            }
        }
    },
    methods   : {
        getData: _.debounce(function () {
            axios
                .get('/api/inventory/purchase-orders', {params: this.options})
                .then(res => {
                    this.items = res.data
                    if(!_.isEmpty(this.items) && this.items.data.length > 0) {
                        this.items.data.forEach(i => {
                            if(i.purchase_id) {
                                i.order_status = 'closed'
                            } else {
                                i.order_status = 'confirmed'
                            }
                        })
                    }
                    this.loading = false
                })
        }, 300)
    }
}