import _ from "lodash";
import CreateQuotation from './create.vue'
import ConvertQuotation from './convert.vue'

export default {
    components: {
        CreateQuotation,
        ConvertQuotation
    },
    data() {
        return {
            columnMenu          : false,
            items               : {},
            loading             : false,
            options             : {
                sortBy      : ['quotation_date'],
                sortDesc    : [true],
                itemsPerPage: this.$store.state.itemsPerPage,
            },
            convertDialog       : false,
            convert_id          : null,
            createOrUpdateDialog: false,
            edit_id             : null,
            headers             : [
                {text: 'quotation no', value: 'quotation_no', align: ''},
                {text: 'created by', value: 'created_by.name', sortable: false, align: ''},
                {text: 'customer', value: 'customer_name', sortable: false, align: ''},
                {text: 'conversion id', value: 'sale_id', align: ''},
                {text: 'total', value: 'total', align: ''},
                {text: 'quotation date', value: 'quotation_date', align: ''},
                {text: 'created at', value: 'created_at', align: ''},
                {text: 'action', value: 'action', sortable: false, align: ''},
            ],
            closeOnContentClick : true
        }
    },
    watch     : {
        options: {
            deep: true,
            handler() {
                this.loading = true
                this.getData()
            }
        },
        createOrUpdateDialog(val) {
            if(!val) {
                this.getData()
                this.edit_id = null
            }
        },
        convertDialog(val){
            if(!val){
                this.convert_id = null
            }
        }
    },
    methods   : {
        deleteItem(id) {
            if(!confirm("are you sure?")) {
                return false;
            }
            this.loading = true
            axios.delete('/api/inventory/sale-quotations/' + id)
                 .then(res => {
                     Swal.fire({
                                   icon             : res.data.type,
                                   timer            : 3000,
                                   text             : res.data.message,
                                   showCancelButton : false,
                                   showConfirmButton: false
                               })
                         .then((result) => {
                             this.getData()
                             this.loading = false
                         })
                 })
                 .catch(err => {
                     this.loading = false
                 })
        },
        getData: _.debounce(function () {
            this.loading = true
            axios.get('/api/inventory/sale-quotations', {
                params: this.options
            })
                 .then(res => {
                     this.items = res.data
                     if(!_.isEmpty(this.items) && this.items.data.length > 0) {
                         this.items.data.forEach(i => {
                             i.customer_name = i.customer.name
                         })
                     }
                     this.loading = false
                 })
                 .catch(err => {
                     this.loading = false
                 })
        }, 200),
    }
}