<template>
  <div>
    <v-dialog
        max-width="600"
        v-model="manageReportButton"
        v-if="$store.state.permissions.length===0"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-row class="mt-5">
          <v-col md="2" cols="12">
            <v-btn
                v-on="on"
                v-bind="attrs"
                color="success"
            >
              manage report
            </v-btn>
          </v-col>
          <v-col md="4" cols="12">
            <v-autocomplete
                clearable
                label="Search"
                v-model="search"
                append-icon="mdi-magnify"
                :items="child_accounts_list"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </template>
      <v-card>
        <v-card-title>
          Manage reports
        </v-card-title>
        <v-card-text>
          <v-menu
              offset-y
              v-model="menu"
              min-width="290px"
              :nudge-right="40"
              transition="scale-transition"
              :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  readonly
                  v-on="on"
                  clearable
                  v-bind="attrs"
                  v-model="range"
                  label="date range"
                  prepend-icon="event"
              >
              </v-text-field>
            </template>
            <v-date-picker
                range
                v-model="range"
                @input="range.length>1?menu = false:null"
            >
            </v-date-picker>
          </v-menu>
          <v-btn @click="addRange()">
            add range
          </v-btn>

          <v-list>
            <v-list-item v-for="(r, index) in ranges" :key="'ec'+index">
              <v-list-item-content>
                {{ string_to_time(r.toString()) }}
              </v-list-item-content>
              <v-list-item-action>
                <v-row>
                  <v-btn icon text>
                    <v-icon
                        x-small
                        color="success"
                        @click="edit(index)"
                    >
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                  <v-btn icon text>
                    <v-icon
                        x-small
                        color="error"
                        @click="del(index)"
                    >
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </v-row>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-row class="align-center">
            <span>Hide Column: &nbsp;</span>
            <v-checkbox
                :value="c"
                :label="c"
                :key="'c'+index"
                v-model="showColumns"
                v-for="(c, index) in columns"
            ></v-checkbox>
          </v-row>
          <v-card-actions>
            <v-btn
                color="success"
                @click="manageReportButton=false"
            >
              Close
            </v-btn>
          </v-card-actions>

        </v-card-text>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col md="12" cols="12" class="mt-4">

        <v-simple-table fixed-header height="100vh">
          <template v-slot:default>
            <thead>
            <tr>
              <th
                  class="text-center"
                  :key="'range'+index"
                  v-for="(r, index) in ranges"
              >
                {{ string_to_time(r.toString()) }}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td v-for="(r, index) in ranges">
                <draggable v-model="child_accounts">
                  <dynamic-ledger
                      :date="r"
                      :item="c"
                      class="mb-6"
                      :key="'dl'+index"
                      :columns.sync="showColumns"
                      v-for="(c, index) in child_accounts"
                  ></dynamic-ledger>
                </draggable>
                <!--                <template v-for="(c, index) in child_accounts">-->
                <!--                  <dynamic-ledger-->
                <!--                      :date="r"-->
                <!--                      :item="c"-->
                <!--                      class="mb-6"-->
                <!--                      :columns.sync="showColumns"-->
                <!--                  ></dynamic-ledger>-->
                <!--                </template>-->
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import DynamicLedger from './components/comparison.vue'

export default {
  name      : "dynamic-ledger-comparison",
  components: {
    DynamicLedger
  },
  data() {
    return {
      menu               : false,
      items              : [],
      range              : [],
      ranges             : [],
      search             : null,
      loading            : false,
      accounts           : [],
      selected           : [],
      editIndex          : null,
      showColumns        : [],
      child_accounts     : [],
      manageReportButton : false,
      child_accounts_list: [],
      columns            : [
        'debit', 'credit'
      ]
    }
  },
  computed  : {
    loaded() {
      return !!!_.isEmpty(this.accounts) && this.accounts.length > 0
    }
  },
  created() {
    this.getAccounts()
    this.getSession()
  },
  watch     : {
    search(val) {
      if(val) {
        this.child_accounts = _.filter(this.child_accounts, ca => {
          return _.some(ca, s => {
            return _.startsWith(s, val);
          });
        })
      } else {
        this.child_accounts = []
        this.getAccounts()
      }
    },
    selected(val) {
      if(val.length) {
        axios.get('/api/accounts/' + val[0], {
          params: {with_total: true}
        })
             .then(res => {
               if(res.data.parent_id) {
                 this.items.push(res.data)
               }
             })
      }
    }
  },
  methods   : {
    string_to_time(val) {
      let dateConvert = []
      let value       = val.split(",")
      value.forEach(v => {
        dateConvert.push(this.$options.filters.momentFormatBy(v, this.$root.settings.settings.date_format))
      })
      return 'From ' + dateConvert[0] + ' To ' + dateConvert[1]
    },
    getSession() {
      axios.get('/comparison-date')
           .then(res => {
             if(res.data) {
               this.ranges = res.data
             }
           })
    },
    edit(index) {
      this.editIndex = index
      this.range     = this.ranges[index]
      this.ranges.splice(index, 1)
    },
    del(index) {
      this.ranges.splice(index, 1)
      if(!this.$store.state.permissions.length > 0) {
        axios.post('/comparison-date', {comparisonData: this.ranges})
      }
    },
    addRange() {
      if(this.range.length > 0) {
        this.ranges.push(this.range)
        this.range = null
        if(!this.$store.state.permissions.length > 0) {
          axios.post('/comparison-date', {comparisonData: this.ranges})
        }
      }
    },
    getAccounts() {
      this.child_accounts_list = []
      axios
          .get('/api/accounts')
          .then(res => {
            this.accounts = res.data.accounts
            this.accounts.forEach(a => {
              if(a.children.length > 0) {
                a.children.forEach(b => {
                  this.child_accounts.push(b)
                  this.child_accounts_list.push(b.name)
                })
              }
            })
          })
    }
  }
}
</script>