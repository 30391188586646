import FormActions from '~/form-actions.vue'

export default {
    components: {
        FormActions
    },
    props     : {
        modelId: {
            type   : Number,
            default: null
        },
        value  : {
            type   : Boolean,
            default: false
        },
    },
    data() {
        return {
            url           : this.$getUrl('productdamages', this.value, this.modelId),
            axiosMethod   : this.$getAxiosMethod(this.value, this.modelId),
            forms         : this.$root.$data.forms,
            productDamages: [],
            warehouses    : [],
            units         : [],
            products      : [],
            loading       : false,
            placeholder   : "Select product"
        }
    },

    beforeCreate() {
        this.$root.$data.forms.fieldReset();
    },

    watch: {
        '$route.fullPath'(val) {
            //reset data
            this.forms.reset();
            this.productDamages = []
        }
    },
    created() {
        this.getData()
    },

    methods: {
        getData() {
            this.loading = true
            this.$root.$data.erp.request.get(this.url, this, (data) => {
                this.warehouses = data.warehouses;
                this.products   = data.products
                this.units      = data.units
                if(this.axiosMethod === 'patch') {
                    this.productDamages     = data.productdamages
                    this.products           = data.products
                    this.units              = data.units
                    this.forms.id           = this.productDamages.id
                    this.forms.product_id   = parseInt(this.productDamages.product_id)
                    this.forms.warehouse_id = parseInt(this.productDamages.warehouse_id)
                    this.forms.quantity     = this.productDamages.quantity
                    this.forms.unit_id      = parseInt(this.productDamages.unit_id)
                    this.forms.sale_value   = this.productDamages.sale_value
                }
                this.loading = false
            });
        },
        postProductDamage($e) {
            let submitter = $e.submitter.value
            // return null
            this.loading  = true
            this.forms.submit(
                this.axiosMethod,
                this.$getUrlForm(this.url),
                true,
                this.$root)
                .then(data => {
                    this.loading = false
                    if(this.value) {
                        if(submitter === 'submit-close' || submitter === 'update-close') {
                            this.$emit('input', false)
                        }
                    }
                    this.$axiosPostDialog(data)
                }).catch(error => {
                this.loading = false
            });
        },
        onCancel() {
            this.forms.reset();
        },
        getProducts() {
            this.placeholder = "Loading...";
            axios.post('/api/inventory/products/getProducts', {
                warehouseId    : this.forms.warehouse_id,
                filterWarehouse: true
            })
                 .then(response => {
                     this.products    = response.data.products
                     this.placeholder = "Select product";
                 })
                 .catch(error => {
                     alert(error.message)
                 })
        },
        getUnits() {
            this.placeholder = "Loading...";
            axios.post('/api/inventory/units/getUnits', {
                productId: this.forms.product_id
            })
                 .then(response => {
                     this.units       = response.data.units
                     this.placeholder = "Select Unit";
                 })
                 .catch(error => {
                     alert(error.message)
                 })
        }
    }
}