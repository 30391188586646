<template>
  <div id="qr-code-full-region"></div>
</template>

<script>
import {Html5QrcodeScanner} from "html5-qrcode";

export default {
  name : "qrcode-scanner",
  props: {
    qrbox: {
      type   : Number,
      default: 250
    },
    fps  : {
      type   : Number,
      default: 10
    },
  },
  mounted () {
    const config = {
      fps: this.fps,
      qrbox: this.qrbox,
    };
    const html5QrcodeScanner = new Html5QrcodeScanner('qr-code-full-region', false, config);
    html5QrcodeScanner.render(this.onScanSuccess);
  },
  methods: {
    onScanSuccess (decodedText, decodedResult) {
      this.$emit('result', decodedText, decodedResult);
    }
  }
}
</script>
<style>
#html5-qrcode-select-camera.html5-qrcode-element{
  border-bottom: 1px solid !important;
  margin: 6px 0 !important;
}
#html5-qrcode-button-camera-start.html5-qrcode-element{
  border-bottom: 1px solid !important;
  margin: 6px 0 !important;
}
#html5-qrcode-button-camera-stop.html5-qrcode-element{
  border-bottom: 1px solid !important;
  margin: 6px 0 !important;
}
#html5-qrcode-button-camera-permission.html5-qrcode-element{
  border-bottom: 1px solid !important;
  margin: 6px 0 !important;
}
</style>