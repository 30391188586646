<template>
  <v-card outlined>
    <v-card-title>Sale Ledger
      <v-spacer/>
      <v-btn @click="$router.back()" icon>
        <v-icon>{{ $root.appIcons.return }}</v-icon>
      </v-btn>
      <v-btn icon @click="$htmlToPaper('list_print_bootstrap')">
        <v-icon>mdi-printer</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="12" id="list_print_div">
          <v-data-table
              dense
              :headers="headers"
              :loading="loading"
              item-key="item_id"
              :items="items.data"
              class="elevation-0"
              :options.sync="options"
              :server-items-length="items.total"
              loading-text="Loading... Please wait"
              :footer-props="{itemsPerPageOptions: $store.state.itemsPerPageOptions}"
          >
            <template v-slot:top>
              <template v-if="options.date">
                <template v-if="options.date.length<2">
                  <v-alert
                      elevation="2"
                      border="bottom"
                      colored-border type="warning"
                  >
                    Please select dates
                  </v-alert>
                </template>
              </template>
              <template v-if="!options.date">
                <v-alert
                    elevation="2"
                    border="bottom"
                    colored-border type="warning"
                >
                  Please select dates
                </v-alert>
              </template>
            </template>
            <template v-slot:header.date="{ header }">
              <v-menu
                  offset-y
                  v-model="menu"
                  min-width="290px"
                  :nudge-right="40"
                  transition="scale-transition"
                  :close-on-content-click="false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      solo
                      dense
                      readonly
                      clearable
                      v-on="on"
                      hide-details
                      v-bind="attrs"
                      label="Dates"
                      v-model="options.date"
                  >
                  </v-text-field>
                </template>
                <v-date-picker
                    range
                    v-model="options.date"
                    @input="options.date.length>1?menu = false:null"
                >
                </v-date-picker>
              </v-menu>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <list-print-bootstrap
        :title="[
            'Sale Ledger',
            `From ${options.date[0]} To ${options.date[1]}`
        ]"
        :columns="headers"
        :rows="items.data"
        style="visibility: collapse"
        :date_fields="['created_at']"
        v-if="options.date && options.date.length>1"
    />
  </v-card>
</template>
<style scoped>
::v-deep th {
  padding: 0 3px !important;
}
</style>
<script>
export default {
  name: "sale-ledger",
  data() {
    return {
      items      : {},
      menu       : false,
      loading    : false,
      queryString: {},
      options    : {
        itemsPerPage: this.$store.state.itemsPerPage,
      },
      headers    : [
        {text: 'date', value: 'date', sortable: false},
        {text: 'description', value: 'description', sortable: false},
        {text: 'debit', value: 'debit', sortable: false},
        {text: 'credit', value: 'credit', sortable: false},
        {text: 'balance', value: 'balance', sortable: false},
      ],
    }
  },

  watch  : {
    options: {
      deep: true,
      handler() {
        if(this.options.date && this.options.date.length > 1) {
          this.loading = true
          this.getResults()
        }
      }
    }
  },
  methods: {
    generateDescription(val) {
      let txt = ''
      if(val.sales_date_formatted) {
        let c = 'A sale(ref no: '
            + val.ref +
            ') has been made to ' +
            val.customer_name
            + ' by '

        let b = val.activities && val.activities.length ? val.activities[0].causer.name : '(not available)'
        txt   = c + ' ' + b
      }
      if(val.payment_date_formatted) {
        let c = 'A bill has been received from '
            + val.customer_name + ' by '
        let b = val.activities && val.activities.length ? val.activities[0].causer.name : '(not available)'
        txt   = c + ' ' + b
      }
      return txt
    },
    getResults() {
      let url = '/api/accounts/sale-ledger'
      axios.get(url, {params: this.options})
           .then(res => {
             this.items = res.data
             if(this.items.data.length) {
               this.items.data.map((v, i) => {
                 v.item_id     = i
                 v.date        = v.sales_date_formatted ? v.sales_date_formatted : v.payment_date_formatted
                 v.description = this.generateDescription(v)
               })
             }
           })
           .catch()
           .finally(r => {
             this.loading = false
           })
    }
  }
}
</script>