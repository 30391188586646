import _ from 'lodash'

export default {
    props: {
        fromSaleOrder: {
            type: Object
        },
        modelId      : {
            type   : Number,
            default: function () {
                return this.$route.params.id ? Number(this.$route.params.id) : null;
            }
        },
        value        : {
            type   : Boolean,
            default: false
        }
    },
    data() {
        return {
            extra_weight      : 0,
            banks             : [],
            part_number_dialog: false,
            settingsValue     : settings,
            loadingMessage    : false,
            productName       : null,
            model10           : [],
            purchases         : {},
            warehouses        : [],
            suppliers         : [],
            units             : [],
            unitconversions   : [],
            products          : [],
            product_id        : '',
            forms             : this.$root.$data.forms,
            items             : [],
            due               : 0,
            initialPaid       : 0,
            payment_status    : 1,
            payment_type      : 1,
            tooltipId         : "conversionTooltip",
            loading           : false,
            modal             : false,
            menu1             : false,
            productLoading    : false,
            searchProduct     : null,
            computedDate      : null,
        }
    },

    beforeCreate() {
        this.$root.$data.forms.fieldReset();

    },

    watch: {
        searchProduct(val) {
            this.getProducts(val)
        },
        '$route.fullPath'(val) {
            //reset data
            this.forms.reset()
            this.purchases      = []
            this.product_id     = ''
            this.items          = []
            this.due            = 0
            this.payment_status = 1
            this.payment_type   = 1
        },

        total(val) {
            this.calDue()
            // if(this.method === 'Edit') {
            //     return
            // }
            this.forms.paid = parseFloat(val).toFixed(4)
        }
    },

    computed: {
        isLoaded() {
            return !_.isEmpty(this.warehouses) || !_.isEmpty(this.suppliers) || !_.isEmpty(this.products) || !_.isEmpty(this.units)
        },

        total() {
            let total         = 0
            let shipping_cost = 0
            let discount      = 0
            shipping_cost     = !this.forms.shipping_cost ? 0 : this.forms.shipping_cost;

            if(this.items.length > 0) {
                for (let i = 0; i < this.items.length; i++) {
                    total += this.items[i].price
                }
            }

            total    = parseFloat(total) + parseFloat(shipping_cost)
            discount = this.forms.overall_discount ? total * parseFloat(this.forms.overall_discount) / 100 : 0

            total -= discount
            this.getTotalWeight()
            return total.toFixed(4)

        }
    },

    created() {

        this.loading             = true
        this.forms.status        = 1
        this.forms.purchase_date = moment(new Date()).format("YYYY-MM-DD")
        this.computedDate        = moment(this.forms.purchase_date, "YYYY-MM-DD").format(this.$root.settings.settings.date_format)
        // console.log(this.forms.purchase_date)
        // this.method              = this.$root.getMethodAction();
        let url = "/api/inventory/purchases/create"
        if(this.modelId) {
            url = "/api/inventory/purchase-orders/" + this.modelId + "/edit"
        }
        if(!_.isEmpty(this.fromSaleOrder)) {
            url = "/api/inventory/purchase-orders/" + this.fromSaleOrder.id + "/edit"
        }
        this.$root.$data.erp.request.get(url, this, (data) => {
            if(!_.has(data, 'order')) {
                Swal.fire({
                              timer            : 3000,
                              icon             : data.type,
                              text             : data.message,
                              showCancelButton : false,
                              showConfirmButton: false,
                          })
                    .then((result) => {
                        this.$router.push({name: 'purchaseQuotation.index'})
                    })
            }
            this.suppliers           = data.suppliers
            this.products            = data.products
            this.warehouses          = data.warehouses
            this.units               = data.units
            this.banks               = data.banks
            this.forms.purchase_date = moment(new Date()).format("YYYY-MM-DD")
            this.computedDate        = moment(this.forms.purchase_date, "YYYY-MM-DD").format(this.$root.settings.settings.date_format)
            if(this.$route.params.id || this.modelId) {
                this.purchases = data.order
                this.$root.$set(this.forms, 'overall_discount', this.purchases.overall_discount)
                this.$root.$set(this.forms, 'shipping_cost', this.purchases.shipping_cost)
                this.$root.$set(this.forms, 'payment_type', 3)
                this.forms.supplier_id = parseInt(this.purchases.supplier_id)
                this.forms.note        = this.purchases.note
                this.forms.order       = this.purchases.id
                this.forms.order_no    = this.purchases.order_no
                this.forms.order_id    = this.purchases.order_id
                for (let i = 0; i < this.purchases.products.length; i++) {
                    let product = this.purchases.products[i];
                    this.items.push({
                                        product_id             : product.id,
                                        pname                  : product.name,
                                        unit_price             : parseFloat(product.pivot.price).toFixed(4),
                                        warehouse              : parseInt(product.pivot.warehouse_id),
                                        unit                   : parseInt(product.pivot.unit_id),
                                        units                  : product.units,
                                        discount               : product.pivot.discount,
                                        quantity               : product.pivot.quantity,
                                        fromUnit               : product.pivot.unit_id,
                                        baseUnit               : product.base_unit_id,
                                        price                  : 0,
                                        weight                 : product.pivot.weight,
                                        weight_total           : product.pivot.weight_total,
                                        adjustment             : product.pivot.adjustment,
                                        manufacture_part_number: product.manufacture_part_number,
                                        part_number            : []
                                    })
                }
                this.getTotalWeight()
                this.extra_weight = this.purchases.total_weight - this.forms.total_weight
            }
            this.loading = false
        });
        this.forms.payment_status = 3
    },
    methods: {
        findWeight(product_id) {
            let product = this.getProduct(product_id)
            let units   = product.units
            if(units.length > 0) {
                let weight = units.find((unit) => {
                    return unit.id === product.base_unit_id
                }).pivot.weight
                if(weight) {
                    return weight
                } else {
                    return 0;
                }
            } else {
                return 0;
            }
            // console.log(_.find(this.getProduct(product_id).units, { id: this.getProduct(product_id).base_unit_id
            // }));
            // return _.find(this.getProduct(this.product_id).units, { id:
            // this.getProduct(this.product_id).base_unit_id }).pivot.weight
        },
        getTotalWeight() {
            this.forms.total_weight = 0
            this.items.forEach(item => {
                if(!item.weight_total) {
                    item.weight_total = 0
                }
                this.forms.total_weight += item.weight_total
            })
        },
        itemTotalWeight(index) {
            this.items[index].weight_total = this.items[index].quantity
                ? this.items[index].weight * this.items[index].quantity
                : 0

            return this.items[index].weight_total
        },
        postPurchase() {
            this.loading                       = true
            this.forms.items                   = this.items
            this.forms.total                   = this.total
            this.forms.payment_status          = this.payment_status
            this.forms.payment_type            = this.payment_type
            this.forms.purchase_date_formatted = moment(this.forms.purchase_date).format("YYYY-MM-DD")
            this.forms.extra_weight            = this.extra_weight
            // this.forms.purchase_date_formatted = moment(this.forms.purchase_date.format("YYYY-MM-DD HH:mm:ss"))
            // console.log(this.forms.purchase_date_formatted)
            let requestMethod = 'post'
            let url           = '/api/inventory/purchases'
            this.forms.submit(requestMethod, url, false, this.$root)
                .then(data => {
                    if(data.type === 'success') {
                        this.items = []
                    }
                    this.forms.purchase_date = moment(new Date()).format("YYYY-MM-DD")
                    Swal.fire({
                                  timer            : 3000,
                                  icon             : data.type,
                                  text             : data.message,
                                  showCancelButton : false,
                                  showConfirmButton: false,
                              }).then(result => {
                        this.loading = false
                        if(_.isEmpty(this.fromSaleOrder)) {
                            this.$router.push({name: 'purchase.index'})
                        } else {
                            this.$emit('backorderConvert', true)
                            this.$emit('input', true)
                            this.$emit('reloadIndex')
                        }
                    })
                })
                .catch(error => {
                    this.forms.purchase_date = moment(new Date()).format("YYYY-MM-DD")
                    this.loading             = false
                });
        },

        getProducts: _.debounce(function (query) {
            if(query !== '') {
                axios.post('/api/inventory/products/getProducts', {val: query, isPurchase: true})
                     .then(response => {
                         this.products = response.data.products
                         if(this.products.length === 1) {
                             this.product_id = this.products[0].id
                             this.addProduct()
                             this.products = []
                         }
                         this.loadingMessage = false;
                     })
                     .catch(error => {
                         alert(error.message)
                     })
            } else {
                this.loadingMessage = true
                this.products       = []
                return null
            }
        }, 1000),

        // product Purchase section
        addProduct() {
            if(!this.product_id) {
                return null
            }
            // if (this.uniqueItem(this.product_id)) return null;
            this.items.push({
                                product_id             : this.product_id,
                                pname                  : this.getProduct(this.product_id).name,
                                unit_price             : parseFloat(this.getProduct(this.product_id).buying_price).toFixed(4),
                                price                  : 0,
                                fromUnit               : this.getProduct(this.product_id).base_unit_id,
                                baseUnit               : parseFloat(this.getProduct(this.product_id).base_unit_id),
                                units                  : this.getProduct(this.product_id).units,
                                warehouse              : this.$root.getDefault_id(this.warehouses),
                                unit                   : this.$root.getPrimary_id(this.getProduct(this.product_id).units),
                                weight                 : this.findWeight(this.product_id),
                                manufacture_part_number: this.getProduct(this.product_id).manufacture_part_number,
                                part_number            : []
                            })
            if(this.items.length > 0) {
                this.$nextTick(() => {
                    this.product_id = null
                })
            }
        },
        uniqueItem(id) {
            return this.items.find((item) => {
                return item.product_id === id
            })
        },
        getPurchasePivot(product, id) {
            return product.purchases.find((purchase) => {
                return purchase.id === id
            })
        },
        getProduct(id) {
            return this.products.find((product) => {
                return product.id === this.product_id
            })
        },
        removeProduct(index) {
            this.items.splice(index, 1);
        },
        getTotalPrice(item) {
            let totalPrice = 0

            if(item.unit_price && item.quantity) {
                totalPrice = item.unit_price * item.quantity
            }
            if(totalPrice && item.discount) {
                totalPrice -= item.discount
            }
            if(totalPrice && item.adjustment) {
                totalPrice += Number(item.adjustment)
            }
            item.price = Number(parseFloat(totalPrice).toFixed(2))
            return item.price

        },
        calDue() {
            this.$nextTick(() => {
                this.due = (parseFloat(this.total).toFixed(4) - parseFloat(this.forms.paid ? this.forms.paid : 0)).toFixed(4)
                if(this.due <= 0) {
                    this.payment_status = 1 //paid
                }
                if(this.due > 0) {
                    this.payment_status = 2 // partial
                }
                if(this.due == parseFloat(this.total).toFixed(4) && this.due != 0) {
                    this.payment_status = 3 //due
                }
            })
        },
        getPrice(unit, index) {
            let fromUnit               = this.items[index].fromUnit
            this.items[index].fromUnit = unit
            if(fromUnit === unit) {
                return
            }

            let url = '/api/inventory/unitconversions/' + unit + '/' + fromUnit + '/1'
            axios.post(url, {isPurchase: true, productId: this.items[index].product_id})
                 .then(response => {
                     let conversion               = response.data.conversion
                     this.items[index].unit_price = parseFloat(response.data.quantity * this.items[index].unit_price).toFixed(4)
                 })
        },
        onCancel() {
            this.$router.push({name: "purchase.index"})
        },
        closeDatePicker() {
            let currentTime          = moment(new Date()).format("HH:mm:ss")
            let purchase_date        = this.forms.purchase_date
            let selectedDate         = moment(purchase_date).format(this.$root.settings.settings.date_format)
            // console.log(selectedDate + ' ' + currentTime)
            this.computedDate        = moment(selectedDate + ' ' + currentTime, this.$root.settings.settings.date_format).format(this.$root.settings.settings.date_format)
            this.forms.purchase_date = moment(this.forms.purchase_date + ' ' + currentTime).format("YYYY-MM-DD")
            this.menu1               = false
        }
    }
}