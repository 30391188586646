<template>
  <div v-if="!_.isEmpty(item)">
    <v-dialog v-model="showPopup" max-width="800">
      <v-card>
        <v-card-title v-if="!_.isEmpty(items)">
          {{ !_.isEmpty(items.data) ? items.data[0].account.name : null }}
        </v-card-title>
        <v-card-text>
          <v-data-table
              :loading="loading"
              :items="items.data"
              :options.sync="options"
              :headers="data_headers"
              class="elevation-0 ma-5"
              :server-items-length="items.total"
              loading-text="Loading... Please wait"
              :footer-props="{itemsPerPageOptions: $store.state.itemsPerPageOptions}"
          >
            <template v-slot:[`item.date`]="{item}">
              {{ item.date|removeTimeFromDate }}
            </template>
            <template v-slot:[`item.de`]="{item}">
              {{ processDebit(item) }}
            </template>
            <template v-slot:[`item.cr`]="{item}">
              {{ processCredit(item) }}
            </template>

            <template v-slot:[`item.total_balance`]="{item}">
              {{ processTotalBalance(item) }}
            </template>
            <template v-slot:[`item.action`]="{item}">
              <v-btn
                  text
                  icon
                  x-small
                  color="success"
                  :to="{name:'bank.transaction', params:{id:item.id}}"
              >
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-data-table
        item-key="id"
        :items="[totals]"
        :headers="headers"
        class="elevation-0"
        hide-default-footer
    >
      <template v-slot:top>
        <v-toolbar
            flat
        >
          <v-toolbar-title class="text--center">
            {{ item.name + '&#8597;' }}
          </v-toolbar-title>
        </v-toolbar>
      </template>
      <template v-slot:[`item.de`]="{item}">
        <span v-if="!_.isEmpty(item)">
          {{ item.total_debits }}
        </span>
      </template>
      <template v-slot:[`item.cr`]="{item}">
        <span v-if="!_.isEmpty(item)">
          {{ item.total_credits }}
        </span>
      </template>
      <template v-slot:[`item.total_balance`]="{item}">
        <span v-if="!_.isEmpty(item)">
          {{ item.running_balance }}
        </span>
      </template>
      <template v-slot:[`item.action`]="{item}">
        <v-btn
            text
            icon
            x-small
            color="success"
            @click="callDialog(items)"
        >
          <v-icon>mdi-eye</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name   : "comparison",
  props  : {
    columns: {
      type: Array,
      default() {
        return [];
      }
    },
    item   : {
      type: Object,
      default() {
        return {}
      }
    },
    date   : null
  },
  data() {
    return {
      showPopup   : false,
      expanded    : [],
      account     : {},
      children    : [],
      child       : null,
      menu        : false,
      loading     : false,
      totals      : {},
      items       : {},
      options     : {
        itemsPerPage: 10,
        with_total  : 1 //true or false
      },
      data_headers: [
        {text: 'debit', value: 'de', filterable: false, sortable: false, align: ''},
        {text: 'credit', value: 'cr', filterable: false, sortable: false, align: ''},
        // {text: 'balance', value: 'total_balance', filterable: false, sortable: false, align: ''},
        {text: 'action', value: 'action', filterable: false, sortable: false, align: ''},
      ],
      headers     : [
        // {text: 'date', value: 'date', sortable:false, align:''},
        // {text: 'bank', value: 'bank.name'},
        // {text: 'ref no', value: 'ref_no', sortable:false, align:''},
        // {text: 'user-id-type', value: 'user', sortable: false},
        // {text: 'method', value: 'payment_method'},

        // {text: 'name', value: 'account.name', filterable: true, sortable: false, align: ''},
        {text: 'debit', value: 'de', filterable: false, sortable: false, align: ''},
        {text: 'credit', value: 'cr', filterable: false, sortable: false, align: ''},
        // {text: 'balance', value: 'total_balance', filterable: false, sortable: false, align: ''},
        {text: 'action', value: 'action', filterable: false, sortable: false, align: ''},
        // {text: 'action', value: 'action'},
      ],
    }
  },
  watch  : {
    options: {
      deep: true,
      handler() {
        this.getParent(this.item.parent_id)
        if(!_.isEmpty(this.item)) {
          this.getData()
        }
      }
    },
    columns(val) {
      let value     = this.getIndexNumber(val)
      let dontMatch = this.headers.filter((d, i) => {
        return value.indexOf(i) === -1
      })
      let match     = this.headers.filter((d, i) => {
        return value.indexOf(i) !== -1
      })
      if(match.length > 0) {
        match.forEach(m => m.align = 'd-none')
      }

      if(dontMatch.length > 0) {
        dontMatch.forEach(m => m.align = '')
      }
    }
  },
  created() {
    this.getData()
  },
  methods: {
    callDialog(data) {
      this.showPopup = true
    },
    getIndexNumber(val) {
      let value = []
      val.forEach(v => {
        if(v === 'debit') {
          value.push(0)
        }
        if(v === 'credit') {
          value.push(1)
        }
        if(v === 'balance') {
          value.push(2)
        }
      })
      return value
    },
    processTotalBalance(item) {
      if(this.account.name === 'Asset')
        return 0 - item.balance
      if(this.account.name === 'Expense')
        return 0 - item.balance
      if(this.account.name === 'Liability')
        return item.balance
      if(this.account.name === 'Equity')
        return item.balance
      if(this.account.name === 'Income')
        return item.balance

      return 'total balance'
    },
    processDebit(item) {
      if(this.account.name === 'Asset')
        return item.credit
      if(this.account.name === 'Liability')
        return item.credit
      if(this.account.name === 'Equity')
        return item.credit
      if(this.account.name === 'Income')
        return item.credit
      if(this.account.name === 'Expense')
        return item.credit

      return 'debit'
    },
    processCredit(item) {
      if(this.account.name === 'Asset')
        return item.debit
      if(this.account.name === 'Liability')
        return item.debit
      if(this.account.name === 'Equity')
        return item.debit
      if(this.account.name === 'Income')
        return item.debit
      if(this.account.name === 'Expense')
        return item.debit

      return 'credit'
    },
    getParent(account_id) {
      axios
          .get('/api/accounts/' + account_id)
          .then(res => {
            this.account = res.data
          })
    },
    getData() {
      this.loading      = true
      this.options.date = this.date
      axios
          .get('/api/ledger-by-account/' + this.item.id, {
            params: this.options
          })
          .then(res => {
            this.items   = res.data.data
            this.totals  = res.data.total
            this.loading = false
          })
          .catch(error => {
          })
    },
  }
}
</script>

<style scoped>

</style>