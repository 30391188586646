import Vue from 'vue'
import Vuex from 'vuex'
import {
    warehousePickingType,
    warehouseStorageType,
    warehouseType
} from './enums'
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        warehouseType: warehouseType,
        warehousePickingType: warehousePickingType,
        warehouseStorageType: warehouseStorageType,
        permissions: [],
        timePermissions: [],
        appColor: {
            topbar: 'purple',
            sidebar: 'grey lighten-2'
        },
        itemsPerPage: 10,
        itemsPerPageOptions: [5, 10, 15, 20, 25, 30, 40, 50, 60, 70, 80, 90, 100],
        delivery_statuses: [{
                id: 1,
                name: 'Received'
            },
            {
                id: 2,
                name: 'Pending'
            },
        ],
        payment_methods: [{
                id: 1,
                name: 'Cash'
            },
            {
                id: 2,
                name: 'Credit Card'
            },
            {
                id: 3,
                name: 'Cheque'
            },
        ],
        payroll_contact_types: [
            'permanent',
            'temporary',
            'contractual',
            'others'
        ],
        bank_types: [{
                value: null,
                text: 'generic'
            },
            {
                value: 'cr',
                text: 'cash register'
            },
        ],
        settings: {
            measurement: false
        },
        quantity_label: 'Quantity',
        hasTable: false,
        requestedBarcode: '',
    },
    mutations: {
        addPermissions(state, data) {
            state.permissions = data
        },
        addTimePermissions(state, data) {
            state.timePermissions = data
        },
        quantity_label(state, value) {
            state.quantity_label = value
        },
        hasTable(state, value) {
            state.hasTable = value
        },
        barcodeValue(state, value) {
            state.requestedBarcode = value
        },
        measurement(state, value) {
            state.settings.measurement = true
        },
        itemsPerPage(state, value) {
            state.itemsPerPage = Number(value)
        }
    },
    actions: {
        getSettings({
            commit
        }) {
            let settings = document.head.querySelector('meta[name="settings"]')
            if (settings) {
                let content = JSON.parse(settings.content)
                if (content) {
                    if (content.settings) {
                        let settings = content.settings
                        if (settings) {
                            if (settings.inventory) {
                                let inventory = settings.inventory
                                if (inventory) {
                                    // console.log(inventory)
                                    if (inventory.table_order !== undefined) {
                                        commit('hasTable', inventory.table_order)
                                    }
                                    if (inventory.quantity_label !== undefined) {
                                        commit('quantity_label', inventory.quantity_label)
                                    }
                                    if (inventory.measurement !== undefined) {
                                        commit('measurement', inventory.measurement)
                                    }
                                    if (inventory.itemsPerPage !== undefined && inventory.itemsPerPage) {
                                        commit('itemsPerPage', inventory.itemsPerPage)
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
})