<template>
  <v-row>
    <v-col md="6" cols="12">
      <h2>To be Shipped</h2>
      <to-be-shipped ref="shipping" @updateDelivered="updateDelivered"></to-be-shipped>
    </v-col>
    <v-col md="6" cols="12">
      <h2>Delivered</h2>
      <delivered ref="delivered" @updateShipping="updateShipping"></delivered>
    </v-col>
  </v-row>
</template>
<script>
import ToBeShipped from './toBeShipped.vue'
import Delivered   from './delivered.vue'

export default {
  name      : "shipment",
  components: {
    ToBeShipped,
    Delivered
  },
  data() {
    return {
      loading           : false,
      toBeShippedHeaders: [
        {text: '#', value: 'id'},
        {text: 'expected shipping date', value: 'expected_shipping_date'},
        {text: 'action', value: 'action'},
      ],
      toBeShippedOptions: {
        itemsPerPage: this.$store.state.itemsPerPage,
      },
      toBeShipped       : {},
      delivered         : {}
    }
  },
  watch     : {
    toBeShippedOptions: {
      deep: true,
      handler() {
        this.loading = true
        this.getData()
      }
    }
  },
  created() {

  },
  methods   : {
    updateDelivered(val) {
      if(val) {
        this.$refs['delivered'].getData()
      }
    },
    updateShipping(val) {
      if(val) {
        this.$refs['shipping'].getData()
      }
    }
  }
}
</script>