module.exports = [
    {
        path: '/employee/create',
        name: 'payroll.employee.create',
        component: require('../views/payroll/employee/create.vue').default
    },
    {
        path: '/employee/index',
        name: 'payroll.employee.index',
        component: require('../views/payroll/employee/index.vue').default
    },
    {
        path: '/employee/:id/edit',
        name: 'payroll.employee.edit',
        component: require('../views/payroll/employee/create.vue').default
    },
    {
        path: '/employee/:id',
        name: 'payroll.employee.show',
        component: require('../views/payroll/employee/show.vue').default
    },
    {
        path: '/salary/create',
        name: 'payroll.salary.create',
        component: require('../views/payroll/salary/create.vue').default
    },
    {
        path: '/salary/index',
        name: 'payroll.salary.index',
        component: require('../views/payroll/salary/index.vue').default
    },
    {
        path: '/salary/:id/edit',
        name: 'payroll.salary.edit',
        component: require('../views/payroll/salary/create.vue').default
    },
    {
        path: '/salary/:id',
        name: 'payroll.salary.show',
        component: require('../views/payroll/salary/show.vue').default
    },
    {
        path:'/payroll/department',
        name:'payroll.department.index',
        component: require('../views/payroll/department/index.vue').default
    }
];

