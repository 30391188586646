module.exports = [
    {
        path: '/account/chart',
        name: 'account.chart',
        component: require('../views/account/chart-of-account.vue').default
    },
    {
        path:'/account/receivable',
        name:'account.receivable',
        component: require('../views/account/receivable.vue').default
    },
    {
        path:'/account/payable',
        name:'account.payable',
        component: require('../views/account/payable.vue').default
    },
    {
        path:'/account/expense',
        name:'account.expense.other',
        component: require('../views/account/other-expense-ledger.vue').default
    },
    {
        path:'/account/income',
        name:'account.income.other',
        component: require('../views/account/other-income-ledger.vue').default
    },
    {
        path:'/account/chart-account-ledger/:id',
        name:'chartAccountLedger',
        component: require('../views/account/dynamic-ledger.vue').default
    },
    {
        path: '/account/chart-account-comparison',
        name:'chartAccountLedger.ledger',
        component: require('../views/account/dynamic-ledger-comparison.vue').default
    },
    {
        path: '/account/purchase-ledger',
        name:'account.purchase.ledger',
        component: require('../views/account/purchase-ledger.vue').default
    },
    {
        path: '/account/sale-ledger',
        name:'account.sale.ledger',
        component: require('../views/account/sale-ledger.vue').default
    },
    {
        path: '/account/supplier-ledger',
        name:'account.supplier.ledger',
        component: require('../views/account/supplier-ledger.vue').default
    },
    {
        path: '/account/customer-ledger',
        name:'account.customer.ledger',
        component: require('../views/account/customer-ledger.vue').default
    },
    {
        path: '/account/balance-sheet',
        name:'account.balance-sheet',
        component: require('../views/account/balance-sheet.vue').default
    },
    {
        path: '/account/cash-flow',
        name:'account.cash-flow',
        component: require('../views/account/cash-flow.vue').default
    },
    {
        path: '/account/profit-loss',
        name:'account.profit-loss',
        component: require('../views/account/profit-loss.vue').default
    },

]