<template>
<div>
  <h1>You are not authorized to view this page</h1>
</div>
</template>

<script>
export default {
name: "unauthorize-page"
}
</script>

<style scoped>

</style>