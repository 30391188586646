<template>
  <v-card outlined>
    <v-dialog v-model="dialog">
      <v-card>
        <v-card-title primary-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <event-show :item="itemDetail"></event-show>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false">
            close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card-title>
      Build Event list
      <v-spacer></v-spacer>
      <v-btn fab icon @click="options={}, options.itemsPerPage=10">
        <v-icon>mdi-autorenew</v-icon>
      </v-btn>
      <v-btn
          icon
          @click="$htmlToPaper('list_print_bootstrap')"
      >
        <v-icon>mdi-printer</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col md="12" cols="12" id="list_print_div">
          <v-data-table
              :loading="loading"
              :headers="headers"
              :items="items.data"
              class="elevation-0"
              :options.sync="options"
              :server-items-length="items.total"
              :footer-props="{
          itemsPerPageOptions: $store.state.itemsPerPageOptions,
        }"
          >
            <template v-slot:header.created_at="{ header }">
              <v-dialog
                  persistent
                  ref="dialog"
                  width="290px"
                  v-model="modal"
                  :return-value.sync="options.created_at"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      solo
                      dense
                      readonly
                      clearable
                      v-on="on"
                      @click.stop
                      hide-details
                      v-bind="attrs"
                      label="created at"
                      v-model="options.created_at"
                  />
                </template>
                <v-date-picker v-model="options.created_at" scrollable range>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal = false">Cancel</v-btn>
                  <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(options.created_at)"
                  >OK
                  </v-btn
                  >
                </v-date-picker>
              </v-dialog>
            </template>
            <template v-slot:header.product_name="{ header }">
              <v-text-field
                  solo
                  dense
                  @click.stop
                  hide-details
                  name="product_name"
                  label="product name"
                  v-model="options.product_name"
              ></v-text-field>
            </template>
            <template v-slot:[`item.expense_total`]="{ item }">
              <router-link
                  :to="{
              name: 'bank.transaction',
              params: { id: item.transaction_id },
            }"
                  v-if="item.transaction_id"
              >
                {{ item.expense_total }}
              </router-link>
              <template v-else>
                {{ item.expense_total }}
              </template>
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <tooltip-button
                  data-text="view"
                  position="top"
                  @click="showModal(item)"
                  :icon="true"
                  icon-name="mdi-eye"
                  color="primary"
                  size="small"
              ></tooltip-button>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <list-print-bootstrap
        :title="'Build Event list'"
        :columns="headers"
        :rows="items.data"
        style="visibility: collapse"
        :date_fields="['created_at']"
    >
    </list-print-bootstrap>
  </v-card>
</template>

<script>
import EventShow from "./components/eventShow.vue";

export default {
  name      : "bom-event",
  components: {
    "event-show": EventShow,
  },
  data() {
    return {
      menu      : false,
      modal     : false,
      dialog    : false,
      loading   : false,
      itemDetail: null,
      options   : {
        sortBy      : ['created_at'],
        sortDesc    : [true],
        itemsPerPage: this.$store.state.itemsPerPage,
      },
      items     : {},
      headers   : [
        {text: "product name", value: "product_name", sortable: true},
        {text: "Quantity", value: "quantity"},
        {text: "unit", value: "unit_key"},
        {text: "warehouse", value: "warehouse_name"},
        {text: "mc/unit", value: "mcpu"},
        {text: "exp.total", value: "expense_total"},
        {text: "total cost", value: "total_cost"},
        {text: "created at", value: "created_at"},
        {text: "action", value: "action", sortable: false},
      ],
    }
  },
  watch     : {
    dialog(val) {
      if(!val) {
        this.itemDetail = {};
      }
    },
    options: {
      deep: true,
      handler() {
        this.loading = true;
        this.getData();
      },
    },
  },
  created() {
    if(!_.isEmpty(this.$route.params) && this.$route.params.id) {
      this.options.id = this.$route.params.id
    }
  },
  methods   : {
    showModal(item) {
      if(!_.isEmpty(item)) {
        this.itemDetail = item;
        this.dialog     = true;
      }
    },
    getData: _.debounce(function () {
      axios
          .get("/api/inventory/product-composite-build", {
            params: this.options,
          })
          .then((result) => {
            this.items   = result.data.events;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
          });
    }, 800),
  },
};
</script>