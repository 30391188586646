import _ from "lodash";

export default {
    props   : {
        item : {
            type   : Object,
            default: () => {
            }
        },
        model: {
            type   : String,
            default: () => 'sales'
        }
    },
    data() {
        return {
            dateEdit     : true,
            due          : 0,
            paid         : 0,
            total        : 0,
            name         : '',
            data         : [],
            banks        : [],
            list         : {},
            menu         : false,
            items        : [],
            modal        : false,
            dialog       : false,
            model_name   : "",
            computedDate : null,
            model_id_data: 0,
            forms        : {
                bank_id           : null,
                transaction_number: '',
                cheque_number     : '',
                paid              : null,
                payment_type      : 1,
                date              : moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
                errors            : {
                    bank_id     : '',
                    paid        : '',
                    payment_type: '',
                    date        : '',
                }
            },
        }
    },
    computed: {
        calDue() {
            let currentDue = this.total - this.paid
            let payment    = this.forms.paid ? this.forms.paid : 0
            this.due       = (currentDue - payment).toFixed(2)
            return this.due
        }
    },
    watch   : {
        dialog(val) {
            if(val === false) this.resetForm()
            if(val === true)
                axios.get('/api/get-bank', {
                    params:
                        {
                            with_running_balance: true
                        }
                })
                     .then(res => {
                         this.banks = res.data
                     })

        }
    },
    created() {
        this.model_name    = this.model
        this.model_id_data = this.item.id
        this.list          = this.item
        this.getName()
        this.total        = this.list.total ? this.list.total : 0
        this.paid         = this.list.paid ? this.list.paid : 0
        this.computedDate = moment(this.forms.date, "YYYY-MM-DD HH:mm:ss").format(this.$root.settings.settings.date_format + " HH:mm:ss")
        /*for (let i = 0; i < this.list.payments.length; i++) {
         let payment = this.list.payments[i]
         this.paid += payment.paid
         }*/

    },


    methods: {
        resetForm() {
            this.forms.bank_id            = null
            this.forms.transaction_number = ''
            this.forms.cheque_number      = ''
            this.forms.paid               = null
            this.forms.payment_type       = 1
            this.forms.date               = moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
            this.forms.errors             = {
                bank_id     : '',
                paid        : '',
                payment_type: '',
            }
        },
        postPayment() {
            let data          = Object.assign({}, this.forms)
            let requestMethod = 'post'
            let url           = '/api/payments/model/' + this.model_name + '/model_id/' + this.model_id_data + '/payment_id'
            // if (this.method === 'Edit') {
            //     requestMethod = 'patch'
            //     url = '/api/inventory/brands/' + this.forms.id
            // }


            if((this.forms.payment_type == 3 && this.forms.bank_id) && this.type !== 'sales') {
                let bank = _.find(this.banks, {id: this.forms.bank_id})
                if(parseFloat(this.forms.paid) >= parseFloat(bank.running_balance)) {
                    let confirmation = confirm(bank.name + ' has low balance. Do you still want to continue?')
                    if(!confirmation) {
                        return null;
                    }
                }
            }

            axios.post(url, this.forms)
                 .then(res => {
                     Swal.fire({
                                   icon : res.data.type,
                                   timer: 3000,
                                   text : res.data.message,
                               })
                         .then((result) => {
                             this.dialog = false
                             this.resetForm()
                         })
                 })
                 .catch(error => {
                     if(error.response.status === 422) {
                         let err = error.response.data.errors
                         Object.keys(err).forEach(key => {
                             this.forms.errors[key] = err[key][0]
                         })
                     } else {
                         this.dialog = false
                         Swal.fire({
                                       'icon'   : 'error',
                                       'message': error.response.message
                                   })
                     }
                 })

        },
        getName() {
            switch (this.model_name) {
                case "purchases":
                    this.name = this.list.supplier_name
                    break
                case "sales":
                    this.name = this.list.customer.name
                    break
                default:
                    this.name = "default"
            }
        },
        closeDatePicker() {
            let currentTime   = moment(new Date()).format("HH:mm:ss")
            let purchase_date = this.forms.date
            let selectedDate  = moment(purchase_date).format(this.$root.settings.settings.date_format)
            this.computedDate = moment(selectedDate + ' ' + currentTime, this.$root.settings.settings.date_format + " HH:mm:ss").format(this.$root.settings.settings.date_format + " HH:mm:ss")
            this.forms.date   = moment(this.forms.date + ' ' + currentTime).format("YYYY-MM-DD HH:mm:ss")
            this.menu         = false
        }
    }
}