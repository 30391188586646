export default {
    props  : ["forms", "banks", "dialogModal"],
    data() {
        return {
            accounts    : [],
            employees   : [],
            users       : [],
            computedDate: null,
            searchItems : null,
            menu        : false,
            loading     : false,
            dialog      : this.dialogModal,

        };
    },
    watch  : {
        'forms.userable_id'(val) {
            this.forms.userable_model = val.model
        },
        searchItems: {
            immediate: true,
            handler(val) {
                this.getUser(val)
            }
        },
        dialogModal(val) {
            this.dialog = val;
        },
        dialog(val) {
            if(!val) {
                this.$emit("transaction", true);
            }
        },
    },
    created() {
        if(this.forms.id === undefined) {
            this.forms.date = moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
        } else {
            this.forms.date = moment(this.forms.date, this.$root.settings.settings.date_format + " HH:mm:ss").format("YYYY-MM-DD HH:mm:ss")
        }
        this.computedDate = moment(this.forms.date, "YYYY-MM-DD HH:mm:ss").format(this.$root.settings.settings.date_format + " HH:mm:ss")
        this.getAccounts()
        this.getEmployees()
    },
    methods: {
        getEmployees() {
        },
        getAccounts() {
            axios
                .get('/api/transaction/create')
                .then(res => {
                    this.accounts = res.data
                    if(this.forms.account_id && this.forms.id) {
                        this.forms.account = _.find(this.accounts, {id: this.forms.account_id})
                    }
                })
        },
        closeDatePicker() {
            let currentTime   = moment(new Date()).format("HH:mm:ss");
            let date          = this.forms.date;
            let selectedDate  = moment(date).format(
                this.$root.settings.settings.date_format
            );
            // console.log(selectedDate + ' ' + currentTime)
            this.computedDate = moment(
                selectedDate + " " + currentTime,
                this.$root.settings.settings.date_format + " HH:mm:ss"
            ).format(this.$root.settings.settings.date_format + " HH:mm:ss");
            this.forms.date   = moment(this.forms.date + " " + currentTime).format(
                "YYYY-MM-DD HH:mm:ss"
            );
            this.menu         = false;
        },
        getUser: _.debounce(function (query) {
            axios
                .get("/api/get-any-user", {
                    params: {name: query},
                })
                .then((result) => {
                    this.users = result.data;
                })
        }, 400),
        resetForm() {
            this.forms.id             = null
            this.forms.type           = ""
            this.forms.note           = ""
            this.forms.ref_no         = ""
            this.forms.payment        = {}
            this.forms.account        = ""
            this.forms.account_id     = null
            this.forms.amount         = null
            this.forms.bank_id        = null
            this.forms.payment_method = ""
            this.forms.userable_model = ''
            this.forms.userable_id    = null

            this.forms.errors.type           = ""
            this.forms.errors.note           = ""
            this.forms.errors.amount         = ""
            this.forms.errors.ref_no         = ""
            this.forms.errors.bank_id        = ""
            this.forms.errors.account        = ""
            this.forms.errors.payment_method = ""
        },
        postTransaction() {
            this.forms.errors = {}
            if(this.forms.id) {
                this.updateTransaction();
            } else {
                this.storeTransaction();
            }
        },
        updateTransaction() {
            axios
                .patch("/api/transaction/" + this.forms.id, this.forms)
                .then((res) => {
                    Swal.fire({
                                  icon : res.data.type,
                                  timer: 3000,
                                  text : res.data.message,
                              }).then((result) => {
                        this.dialog = false;
                        this.resetForm();
                        this.$emit("transaction", true);
                    });
                })
                .catch((error) => {
                    this.loading = false;
                    if(error.response.status === 422) {
                        let err = error.response.data.errors;
                        Object.keys(err).forEach((key) => {
                            this.forms.errors[key] = err[key][0];
                        });
                    } else {
                        this.dialog = false;
                        Swal.fire({
                                      icon   : "error",
                                      message: error.response.message,
                                  });
                    }
                });
        },
        storeTransaction() {
            let timePermission = this.$options.filters.getTimePermission(['transaction', 'create'])
            if(timePermission && timePermission.hours) {
                let now      = moment(new Date())
                let end      = moment(this.forms.date)
                let duration = moment.duration(now.diff(end))
                let hours    = duration.asHours()
                if(hours >= timePermission.hours && timePermission.hours !== null) {
                    this.forms.errors.date = ['you don\'t have permission to create transaction']
                    return null
                }
            }
            this.loading = true;
            axios
                .post("/api/transaction", this.forms)
                .then((res) => {
                    Swal.fire({
                                  icon : res.data.type,
                                  timer: 3000,
                                  text : res.data.message,
                              }).then((result) => {
                        this.dialog = false;
                        this.resetForm();
                        this.$emit("transaction", true);
                    });
                })
                .catch((error) => {
                    this.loading = false;
                    if(error.response.status === 422) {
                        let err = error.response.data.errors;
                        Object.keys(err).forEach((key) => {
                            this.forms.errors[key] = err[key][0];
                        });
                    } else {
                        this.dialog = false;
                        Swal.fire({
                                      icon   : "error",
                                      message: error.response.message,
                                  });
                    }
                });
        },
    },
};