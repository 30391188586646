<template>
    <v-card max-width="full" outlined>
        <v-card-text class="d-flex justify-center">
            <p class="text-h4">404</p>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        name: "404"
    }
</script>