<template>
  <v-card outlined>
    <v-card-text>
      <v-btn class="float-right"
             icon
             @click="$htmlToPaper('list_print_bootstrap')"
      >
        <v-icon>mdi-printer</v-icon>
      </v-btn>
      <v-row>
        <v-col md="4" cols="12">
          <v-text-field
              solo
              clearable
              name="name"
              label="name or item number"
              v-model="options.product_name"
              prepend-icon="mdi-database-search"
          ></v-text-field>
        </v-col>
        <v-col md="1" cols="12">
          <v-btn fab icon @click="(options = {}), (options.itemsPerPage = 10)">
            <v-icon>mdi-autorenew</v-icon>
          </v-btn>
        </v-col>

      </v-row>
      <v-row>
        <v-col cols="12" md="12" id="list_print_div">
          <v-data-table
              :headers="headers"
              :loading="loading"
              class="elevation-0"
              :items="items.data"
              :options.sync="options"
              :server-items-length="items.total"
              loading-text="Loading... Please wait"
              :footer-props="{
          itemsPerPageOptions: $store.state.itemsPerPageOptions,
        }"
          >
            <template v-slot:top>
              <h2 class="pa-3">Raw Materials</h2>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <list-print-bootstrap
        :title="'Raw Materials'"
        :columns="headers"
        :rows="items.data"
        style="visibility: collapse"
        :date_fields="['created_at']"
    >
    </list-print-bootstrap>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      items  : {},
      loading: false,
      options: {
        itemsPerPage: this.$store.state.itemsPerPage,
      },
      headers: [
        {text: "name", value: "product_name"},
        {text: "item number", value: "product_code"},
        {text: "used in number of products", value: "used_in_count"},
      ],
    }
  },
  watch  : {
    options: {
      deep: true,
      handler() {
        this.loading = true;
        this.getData();
      },
    },
  },
  methods: {
    getData: _.debounce(function () {
      axios
          .get("/api/inventory/raw-material-list", {
            params: this.options,
          })
          .then((result) => {
            this.items   = result.data;
            this.loading = false;
          })
          .catch((err) => {
          });
    }, 400),
  },
};
</script>