module.exports = [
    {
        path: '/inventory/warranty/index',
        name: 'warranty.index',
        component: require('../views/inventory/warranty/index.vue').default
    },
    {
        path:'/inventory/warranty/create',
        name:'warranty.create',
        component: require('../views/inventory/warranty/create.vue').default
    },
    {
        path: '/inventory/warranty/:id/edit',
        name: 'warranty.edit',
        component: require('../views/inventory/warranty/create.vue').default
    },
];