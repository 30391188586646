<template>
  <div id="sale_order_print">
    <h3>Order Ledger of #SO-{{ item.order_no }}</h3>
    <div class="row">
      <div class="col-md-4">
        <p class="text-subtitle-1">Customer Information</p>
        <p>name: {{ item.customer.name }}</p>
        <p>email: {{ item.customer.email }}</p>
        <p v-if="item.customer.phone">phone: {{ item.customer.phone }}</p>
        <p v-if="item.customer.address">address: {{ item.customer.address }}</p>
      </div>
      <div class="col-md-4">
        <p class="text-subtitle-1">Shop Information</p>
        <p>Company name: {{ company.name }}</p>
        <p>Address Detail: {{ company.address1 }}</p>
        <p>
          mobile number:
          <span v-for="(phone, index) in company.contact_phone">
                            {{ phone }}{{ company.contact_phone.length - 1 == index ? '' : ',' }}
                        </span>
        </p>
      </div>
      <div class="col-md-4">
        <p class="text-subtitle-1">Order Information</p>
        <p>Order No: SO-{{ item.order_no }}</p>
        <p>Order Date: {{ item.order_date | removeTimeFromDate }}</p>
        <p v-if="item.expected_shipping_date">Shipping Status: {{ checkShippingStatus(item) }}</p>
        <p>created By: {{ item.user.name }}</p>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <table class="table">
          <thead>
          <tr>
            <th>No.</th>
            <th>Code</th>
            <th>Name</th>
            <th>{{ $store.state.quantity_label }}</th>
            <th>Unit Cost</th>
            <th>Discount</th>
            <th>Total</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(product, index) in item.products" :index="index" v-if="!_.isEmpty(item.products)">
            <td>
              {{ index + 1 }}
            </td>
            <td>{{ product.code }}</td>
            <td>{{ product.name }}</td>
            <td>
              {{ parseFloat(product.pivot.quantity).toFixed(4) }}
              {{ product.units | findFromArrayById(product.pivot.unit_id, 'key') }}
              <!--                  <v-btn-->
              <!--                      x-small-->
              <!--                      color="warning"-->
              <!--                      @click="backorder(product)"-->
              <!--                      v-show="!product.remaining_quantity"-->
              <!--                  >-->
              <!--                    backorder-->
              <!--                  </v-btn>-->
            </td>
            <td>{{ product.pivot.price }}</td>
            <td>{{ product.pivot.discount }}</td>
            <td>{{ product.pivot.subtotal }}</td>
          </tr>
          </tbody>
          <tfoot>
          <tr class="strong">
            <td colspan="6" align="right">
              Product Total
            </td>
            <td colspan="2">
              {{ product_total }}
            </td>
          </tr>
          <tr class="strong">
            <td colspan="6" class="text-right">{{ $root.shippingLabel }}</td>
            <td>{{ item.shipping_cost }}</td>
          </tr>
          <tr>
            <td colspan="6" class="text-right">
              Sub Total
            </td>
            <td>
              {{ sub_total }}
            </td>
          </tr>

          <tr class="strong">
            <td colspan="6" class="text-right">Overall Discount</td>
            <td v-if="item.overall_discount">{{ item.overall_discount }}%({{ discounted_amount }})</td>
            <td v-else>0</td>
          </tr>

          <tr class="strong">
            <td colspan="6" class="text-right">Total</td>
            <td>{{ item.total }}</td>
          </tr>
          </tfoot>
        </table>
      </div>
      <div class="col-md-6" v-if="item.note">
        Note: {{ item.note }}
      </div>
      <div class="col-md-4" v-if="item.orderpurchases!==undefined && item.orderpurchases.length>0">
        <h3>List of CPO</h3>
        <v-simple-table>
          <thead>
          <tr>
            <th>#</th>
            <th>order no</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(backorder, index) in item.orderpurchases">
            <td>{{ index + 1 }}</td>
            <td>
              <router-link :to="{name:'purchase.order.show', params: {id:backorder.id}}">
                {{ backorder.order_no }}
              </router-link>
            </td>
          </tr>
          </tbody>
        </v-simple-table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['item', 'company'],
  computed  : {
    product_total() {
      if(!_.isEmpty(this.item) && !_.isEmpty(this.item.products)) {
        return this.item.products.reduce((acc, obj) => {
          return acc + obj.pivot.subtotal
        }, 0)
      }
      return 0
    },
    sub_total() {
      return this.product_total + this.item.shipping_cost
    },
    discounted_amount() {
      return (this.sub_total * (this.item.overall_discount / 100)).toFixed(2)
    }
  },
}
</script>