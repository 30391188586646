import userForm from './components/user-form.vue'
import roleForm from './components/role-form.vue'

export default {
    components: {
        userForm,
        roleForm
    },
    name      : "user_crud",
    data() {
        return {
            expanded    : [],
            singleExpand: false,
            selectedTab : null,
            headers     : [
                {text: 'name', value: 'name'},
                {text: 'email', value: 'email'},
                {text: 'role', value: 'role'},
                {text: 'action', value: 'action'},
            ],
            options     : {
                itemsPerPage: this.$store.state.itemsPerPage,
            },
            loading     : false,
            users       : [],
            roles       : [],
            forms       : {
                name                 : null,
                email                : null,
                password             : null,
                password_confirmation: null,
                role_id              : null,
                errors               : []
            }
        }
    },
    // created() {
    //     this.loading = true
    //     this.getData()
    // },
    watch     : {
        options: {
            deep: true,
            handler() {
                this.loading = true
                this.getData()
            }
        }
    },
    methods   : {
        labelOnMethod(item) {
            if(item.name === 'create')
                return 'Time range allowed to create transaction'
            if(item.name === 'edit')
                return 'Time range allowed to Edit'
            if(item.name === 'delete')
                return 'Time range allowed to delete'
        },
        checkPermissionLevel(item) {
            if(!_.isEmpty(item)) {
                return 'time'
            }
        },
        toggleSuperUser(user) {

        },
        switchRole(id, user) {
            this.loading = true
            axios.post('/api/rbac/user/switch-role', {role_id: id, id: user})
                 .then(res => {
                     this.loading = false
                     Swal.fire({
                                   timer: 3000,
                                   icon : res.data.type,
                                   text : res.data.message,
                               }).then((result) => {
                         this.getData()
                     })
                 })
                 .catch(error => {
                     this.loading = false
                     Swal.fire({
                                   timer: 3000,
                                   icon : 'error',
                                   text : error.response.data.message,
                               })
                 })
        },
        async getData() {
            await axios.get('/api/rbac/user', {
                params: this.options
            })
                       .then(res => {
                           this.users = res.data.users
                           this.roles = res.data.roles
                           this.roles.push({name: 'administrator', id: 0})
                           this.loading = false
                           if(!this.roles.length) {
                               Swal.fire({
                                             icon : 'warning',
                                             text : 'Please create roles first',
                                             timer: 3000
                                         })
                                   .then((result) => {
                                       this.selectedTab = 1
                                   })
                           }
                       })
                       .catch(error => {
                           this.loading = false
                           Swal.fire({
                                         icon: 'error',
                                         text: error.response.message
                                     })
                       })
        },
        deleteItem(item) {
            this.loading = true
            if(confirm('are you sure?')) {
                axios.delete('/api/rbac/user/' + item.id)
                     .then(res => {
                         Swal.fire({
                                       icon : 'success',
                                       text : res.data.message,
                                       timer: 3000
                                   })
                             .then((result) => {
                                 this.loading = false
                                 this.getData()
                             })
                     })
                     .catch(error => {
                         this.loading = false
                         Swal.fire({
                                       icon : 'error',
                                       text : error.response.message,
                                       timer: 3000
                                   })
                     })
            }
        },
        editItem(item) {
            this.forms.id      = item.id
            this.forms.name    = item.name
            this.forms.email   = item.email
            this.forms.role_id = item.role ? item.role.id : 0
            this.selectedTab   = 0
            this.$nextTick(() => this.$refs.userForm.$refs.userName.focus())
        },
    }
}