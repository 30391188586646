<template>
  <div>
    <v-row>
      <v-col class="d-flex">
        <h1>Overall Report</h1>
        <v-spacer></v-spacer>
        <v-btn @click="$router.back()" icon>
          <v-icon>{{ $root.appIcons.return }}</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" cols="12">
        <v-menu
            offset-y
            v-model="menu"
            min-width="290px"
            :nudge-right="40"
            transition="scale-transition"
            :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                solo
                v-on="on"
                readonly
                v-bind="attrs"
                prepend-icon="event"
                v-model="date_between"
                label="select date between"
            ></v-text-field>
          </template>
          <v-date-picker
              range
              v-model="date_between"
              @input="date_between.length>1?menu = false:null"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row align="stretch">
      <v-col md="3" cols="12">
        <v-card
            outlined
            min-height="150px"
        >
          <v-card-title class="justify-center">
            Purchase Amount
          </v-card-title>
          <v-card-text>
            <h3 class="text-center">{{ report.purchaseTotal.total_amount }}</h3>
            <h3 class="text-center">Purchase Count:{{ report.purchaseTotal.total_count }}</h3>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="3" cols="12">
        <v-card
            outlined
            min-height="150px"
        >
          <v-card-title class="justify-center">
            Sale Amount
          </v-card-title>
          <v-card-text>
            <h3 class="text-center">{{ getTotalSalesAmount(report.saleTotal) }}</h3>
            <h3 class="text-center">Sale Count: {{ report.saleTotal.total_count }}</h3>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="3" cols="12">
        <v-card
            outlined
            min-height="150px"
        >
          <v-card-title class="justify-center">
            Expense Amount
          </v-card-title>
          <v-card-text>
            <h3 class="text-center">
              {{ (parseFloat(report.expenseTotal.total_amount) + parseFloat(report.salaryTotal.total_amount)).toFixed(4) }}</h3>
            <h3 class="text-center">Expense Count:
              {{ parseInt(report.expenseTotal.total_count) + parseInt(report.salaryTotal.total_count) }}</h3>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="3" cols="12">
        <v-card
            outlined
            min-height="150px"
        >
          <v-card-title class="justify-center">
            Income Amount
          </v-card-title>
          <v-card-text>
            <h3 class="text-center">{{ report.incomeTotal.total_amount }}</h3>
            <h3 class="text-center">Income Count: {{ report.incomeTotal.total_count }}</h3>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row align="stretch">
      <v-col md="4" cols="12">
        <v-card
            outlined
            min-height="150px"
        >
          <v-card-title class="justify-center">
            Gross Profit
          </v-card-title>
          <v-card-text>
            <h3 class="text-center">{{
                parseFloat((report.saleTotal.total_amount - report.saleTotal.return_total)
                               - report.purchaseTotal.total_amount).toFixed(4)
              }}</h3>
            <p class="small text-center">Equation: Sale - Purchase</p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="4" cols="12">
        <v-card
            outlined
            min-height="150px"
        >
          <v-card-title class="justify-center">
            Net Profit
          </v-card-title>
          <v-card-text>
            <h3 class="text-center">{{ netProfit }}</h3>
            <p class="small text-center">Equation: (sale+ income) - (purchase + expense)</p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="4" cols="12">
        <v-card
            outlined
            min-height="150px"
        >
          <v-card-title class="justify-center">
            Actual Profit(avg method)
          </v-card-title>
          <v-card-text>
            <h3 class="text-center">{{ actualProfit }}</h3>
            <p class="small text-center">Equation: (sale+ income) - (purchase + expense)</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <br>
    <v-divider></v-divider>
    <br>
    <h1>Transaction Types</h1>
    <v-row align="stretch">
      <v-col>
        <v-card
            outlined
            min-height="150px"
        >

          <v-card-title class="justify-center">
            Purchase
          </v-card-title>
          <v-card-text>
            <p class="text-center">cash: {{ report.purchaseTotal.cash_total }}</p>
            <p class="text-center">cheque: {{ report.purchaseTotal.cheque_total }}</p>
            <p class="text-center">credit card: {{ report.purchaseTotal.credit_total }}</p>
            <p class="text-center">Purchase due: {{ getPurchaseDue }}</p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card
            outlined
            min-height="150px"
        >

          <v-card-title class="justify-center">
            Sale
          </v-card-title>
          <v-card-text>
            <p class="text-center">cash:
              {{ (parseFloat(report.saleTotal.cash_total) - parseFloat(report.saleTotal.return_total)).toFixed(4) }}</p>
            <p class="text-center">cheque: {{ report.saleTotal.cheque_total }}</p>
            <p class="text-center">credit card: {{ report.saleTotal.credit_total }}</p>
            <p class="text-center">due: {{ getSalesDue }}</p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card
            outlined
            height="100%"
        >

          <v-card-title class="justify-center">
            Expense
          </v-card-title>
          <v-card-text>
            <p class="text-center">cash:
              {{ (parseFloat(report.expenseTotal.cash_total) + parseFloat(report.salaryTotal.cash_total)).toFixed(4) }}</p>
            <p class="text-center">cheque:
              {{ (parseFloat(report.expenseTotal.cheque_total) + parseFloat(report.salaryTotal.cheque_total)).toFixed(4) }}</p>
            <p class="text-center">credit card:
              {{ (parseFloat(report.expenseTotal.credit_total) + parseFloat(report.salaryTotal.cheque_total)).toFixed(4) }}</p>
            <p></p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card
            outlined
            height="100%"
        >

          <v-card-title class="justify-center">
            Income
          </v-card-title>
          <v-card-text>
            <p class="text-center">cash: {{ report.incomeTotal.cash_total }}</p>
            <p class="text-center">cheque: {{ report.incomeTotal.cheque_total }}</p>
            <p class="text-center">credit card: {{ report.incomeTotal.credit_total }}</p>
            <p></p>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card
            outlined
            height="100%"
        >

          <v-card-title class="justify-center">
            Standing Balance
          </v-card-title>
          <v-card-text>
            <p class="text-center">cash: {{ parseFloat(cashTotal).toFixed(4) }}</p>
            <p class="text-center">cheque: {{ parseFloat(chequeTotal).toFixed(4) }}</p>
            <p class="text-center">credit card: {{ parseFloat(creditCardTotal).toFixed(4) }}</p>
            <p></p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
  <!--                    <div class="col-1">-->
  <!--                        <div class="card">-->
  <!--                            <div class="header bg-default">-->
  <!--                                Standing Balance-->
  <!--                            </div>-->
  <!--                            <div class="body graph">-->
  <!--                                <Icon type="cash" class="graphIcon"></Icon>-->
  <!--                                <h3 class="dark" style="font-weight:bold!important">Cash balance: <br> {{ parseFloat(cashTotal).toFixed(4) }}</h3>-->
  <!--                                <h3 class="dark">Credit Card balance: <br> {{ parseFloat(creditCardTotal).toFixed(4) }}</h3>-->
  <!--                                <h3 class="dark">Cheque balance: <br> {{ parseFloat(chequeTotal).toFixed(4) }}</h3>-->
  <!--                            </div>-->
  <!--                        </div>-->
  <!--                    </div>-->
  <!--                </div>-->
  <!--            </div>-->

  <!--        </div>-->
  <!--    </div>-->
</template>

<script>
export default {
  data() {
    return {
      queryString    : {},
      date_between   : [],
      menu           : false,
      modal          : false,
      totalPageNumber: 10,
      report         : {
        saleTotal    : {
          total_amount: '',
          total_count : ''
        },
        purchaseTotal: {
          total_amount: '',
          total_count : ''
        },
        expenseTotal : {
          total_amount: '',
          total_count : ''
        },
        incomeTotal  : {
          total_amount: '',
          total_count : ''
        },
        salaryTotal  : {
          total_amount: '',
          total_count : ''
        },
        actualProfit : 0,
      }
    }
  },
  watch: {
    date_between(val) {
      if(_.size(val) > 0) {
        if(!val[0]) {
          delete this.queryString.date_between
        } else {
          this.queryString.date_between = []
          var fromDate                  = moment(val[1]).format("YYYY-MM-DD HH:mm:ss")
          if(moment(val[1]).format('HH:mm:ss') == '00:00:00') {
            fromDate = moment(val[1]).format("YYYY-MM-DD") + ' 23:59:59'
          }

          let date                      = [moment(val[0]).format("YYYY-MM-DD HH:mm:ss"), fromDate];
          this.queryString.date_between = date
        }
        this.getResults()
      }
    }
  },

  computed: {
    cashTotal() {
      let purchase_cash = this.report.purchaseTotal.cash_total ? this.report.purchaseTotal.cash_total : 0
      let sale_cash     = this.report.saleTotal.cash_total ? this.report.saleTotal.cash_total : 0
      let expense_cash  = this.report.expenseTotal.cash_total ? this.report.expenseTotal.cash_total : 0
      let income_cash   = this.report.incomeTotal.cash_total ? this.report.incomeTotal.cash_total : 0
      let salary_cash   = this.report.salaryTotal.cash_total ? this.report.salaryTotal.cash_total : 0
      return (parseFloat(sale_cash) + parseFloat(income_cash)) - (parseFloat(purchase_cash) + parseFloat(expense_cash) + parseFloat(salary_cash))
    },
    creditCardTotal() {
      let purchase_cash = this.report.purchaseTotal.credit_total ? this.report.purchaseTotal.credit_total : 0
      let sale_cash     = this.report.saleTotal.credit_total ? this.report.saleTotal.credit_total : 0
      let expense_cash  = this.report.expenseTotal.credit_total ? this.report.expenseTotal.credit_total : 0
      let income_cash   = this.report.incomeTotal.credit_total ? this.report.incomeTotal.credit_total : 0
      let salary_cash   = this.report.salaryTotal.credit_total ? this.report.salaryTotal.credit_total : 0
      return (parseFloat(sale_cash) + parseFloat(income_cash)) - (parseFloat(purchase_cash) + parseFloat(expense_cash) + parseFloat(salary_cash))
    },

    chequeTotal() {
      let purchase_cash = this.report.purchaseTotal.cheque_total ? this.report.purchaseTotal.cheque_total : 0
      let sale_cash     = this.report.saleTotal.cheque_total ? this.report.saleTotal.cheque_total : 0
      let expense_cash  = this.report.expenseTotal.cheque_total ? this.report.expenseTotal.cheque_total : 0
      let income_cash   = this.report.incomeTotal.cheque_total ? this.report.incomeTotal.cheque_total : 0
      let salary_cash   = this.report.salaryTotal.cheque_total ? this.report.salaryTotal.cheque_total : 0
      return (parseFloat(sale_cash) + parseFloat(income_cash)) - (parseFloat(purchase_cash) + parseFloat(expense_cash) + parseFloat(salary_cash))
    },

    netProfit() {
      let netProfit = (parseFloat(this.report.saleTotal.total_amount) +
          parseFloat(this.report.incomeTotal.total_amount)) -
          (parseFloat(this.report.purchaseTotal.total_amount) +
              parseFloat(this.report.expenseTotal.total_amount) +
              parseFloat(this.report.salaryTotal.total_amount) +
              parseFloat(this.report.saleTotal.return_total));

      return parseFloat(netProfit).toFixed(4)
    },
    actualProfit() {
      let actualProfit = (parseFloat(this.report.actualProfit) +
          parseFloat(this.report.incomeTotal.total_amount)) -
          (parseFloat(this.report.expenseTotal.total_amount) +
              parseFloat(this.report.salaryTotal.total_amount));

      return parseFloat(actualProfit).toFixed(4)
    },
    getPurchaseDue() {
      let cash          = this.report.purchaseTotal.cash_total ? this.report.purchaseTotal.cash_total : 0
      let cheque        = this.report.purchaseTotal.cheque_total ? this.report.purchaseTotal.cheque_total : 0
      let credit        = this.report.purchaseTotal.credit_total ? this.report.purchaseTotal.credit_total : 0
      let purcahseTotal = this.report.purchaseTotal.total_amount ? this.report.purchaseTotal.total_amount : 0

      return (parseFloat(purcahseTotal) - (parseFloat(cash) + parseFloat(cheque) + parseFloat(credit))).toFixed(4)
    },
    getSalesDue() {
      let cash          = this.report.saleTotal.cash_total ? this.report.saleTotal.cash_total : 0
      let cheque        = this.report.saleTotal.cheque_total ? this.report.saleTotal.cheque_total : 0
      let credit        = this.report.saleTotal.credit_total ? this.report.saleTotal.credit_total : 0
      let purcahseTotal = this.report.saleTotal.total_amount ? this.report.purchaseTotal.total_amount : 0

      return (parseFloat(purcahseTotal) - (parseFloat(cash) + parseFloat(cheque) + parseFloat(credit))).toFixed(4)
    }

  },


  created() {
    this.getResults()
  },
  methods: {
    getResults(page) {
      if(typeof page === 'undefined') {
        page = 1
      }
      let url = '/api/report/overall?page=' + page + '&query=' + JSON.stringify(this.queryString) + '&totalPage=' + this.totalPageNumber;

      this.$root.$data.erp.request.get(url, this, (data) => {
        this.report = data
      });
    },
    getTotalSalesAmount(item) {
      return (item.total_amount ? item.total_amount : 0) - (item.return_total ? item.return_total : 0)
    }
  },

}
</script>