<template>
  <date-picker
      v-bind:dense="dense"
      v-model="date"
      :timezone="'UTC'"
      class="custom-date-picker-wrapper"
      :masks="{'input':$root.settings.settings.date_format}"
  >
    <template v-slot="{ inputValue, inputEvents }">
      <label>Date</label>
      <input
          v-on="inputEvents"
          :value="inputValue"
          class="custom-date-picker"
          :style="$root.goDark?{'background':'#1f1f1f', 'color':'white'}:null"
      />
    </template>
  </date-picker>
</template>

<script>
import Calendar from 'v-calendar/lib/components/calendar.umd'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

export default {
  props     : {
    value   : {},
    dense: {
      type   : Boolean,
      default: false
    }
  },
  name      : "component-test",
  data      : () => ({
    date       : new Date(),
    date_format: null,
  }),
  components: {
    Calendar,
    DatePicker
  },
  watch     : {
    date: {
      immediate: true,
      handler(val) {
        if(val) {
          this.$emit('input', val)
        }
      }
    },
  },
  created() {
    this.date = this.value
  }
}
</script>
<style lang="scss" scoped>
.custom-date-picker-wrapper{
  padding-top: 6px;
  font-size: 16px;
  line-height: 1.5;
  position: relative;
  input{
    width: 100%;
    font-size: 1rem;
    outline: none;
    border: 1px solid gray;
    border-radius: 5px;
    padding: 1rem 0.7rem;
    color: gray;
    transition: 0.1s ease-out;
  }
  input:focus {
    border-color: #6200EE;
  }
  input:focus + label {
    color: #6200EE;
    top: 0;
    transform: translateY(-50%) scale(.9);
  }
  input:not(:placeholder-shown) + label {
    top: 0;
    transform: translateY(-50%) scale(.9);
  }
  label{
    position: absolute;
    font-size: 1rem;
    left: 0;
    top: -45%;
    transform: translateY(-50%);
    background-color: white;
    color: gray;
    padding: 0 0.3rem;
    margin: 0 0.5rem;
    transition: .1s ease-out;
    transform-origin: left top;
    pointer-events: none;
  }
}
span[dense="true"]{
  .custom-date-picker{
    padding: 0.4rem 0.7rem !important;
  }
  label{
    top: -19%;
  }
}
</style>