export default {
    props: {
        category: {
            type: Object,
            default: () => {
            }
        },
        categories:{
            type: Array,
            default:()=>[]
        }
    },
    data() {
        return {
            forms: this.$root.$data.forms,
            title: {
                text: 'Update'
            },
            typeItems: [
                {text: 'PRODUCT', value: 'PRODUCT'},
                {text: 'EXPENSE', value: 'EXPENSE'},
                {text: 'INCOME', value: 'INCOME'},
                {text: 'CUSTOMER', value: 'CUSTOMER'},
                {text: 'PRICE', value: 'PRICE'},
            ]
        }
    },
    created() {
        this.forms.id = this.category.id
        this.forms.name = this.category.name
        this.forms.description = this.category.description
        this.forms.type = this.category.type
        this.forms.parent_id = parseInt(this.category.parent_id)
    },
    methods: {
        postCategory() {
            let requestMethod = 'patch'
            let url = '/api/inventory/categories/' + this.forms.id

            this.forms.submit(requestMethod, url, true, this.$root)
                .then(data => {
                    Swal.fire({
                        icon: data.type,
                        timer: 3000,
                        text: data.message,
                        showCancelButton: false,
                        showConfirmButton: false,
                    }).then(result => {
                                if (data.type === "success") {
                                    this.$emit('event', true)
                                }
                            }
                        )


                }).catch(error => {
            });

        }
    }
}