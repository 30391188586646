<template>
  <div>
    <!--    &#45;&#45;max_old_space_size=16384-->
    <v-card v-if="!_.isEmpty(warehouse)">
      <v-card-title>
        {{ warehouse.name }}-{{ warehouse.code }}
        <v-spacer/>
        <tooltip-button
            icon
            dark
            color="primary"
            position="bottom"
            data-text="Config"
            icon-name="mdi-tools"
            @click="warehouseConfigDialog = true"
            v-if="$options.filters.checkPermission(['warehouse', 'create'])"
        />
      </v-card-title>
      <v-card-text>
        <v-data-table
            :headers="headers"
            :loading="loading"
            class="elevation-0"
            :items="items.data"
            :options.sync="options"
            :server-items-length="items.total"
            loading-text="Loading... Please wait"
            :footer-props="{
            itemsPerPageOptions: $store.state.itemsPerPageOptions,
          }"
        >
          <template v-slot:header.type="{ header }">
            <v-select
                solo
                dense
                clearable
                @click.stop
                hide-details
                label="type"
                :items="[
                ...$store.state.warehousePickingType,
                ...$store.state.warehouseStorageType,
              ]"
                v-model="options.type"
            />
          </template>
          <template v-slot:header.isle_name="{ header }">
            <v-text-field
                solo
                dense
                @click.stop
                hide-details
                label="Isle name"
                v-model="options.isle_name"
            />
          </template>
          <template v-slot:header.rack_name="{ header }">
            <v-text-field
                solo
                dense
                @click.stop
                hide-details
                label="Rack name"
                v-model="options.rack_name"
            />
          </template>
          <template v-slot:header.bin_name="{ header }">
            <v-text-field
                solo
                dense
                @click.stop
                hide-details
                label="Bin name"
                v-model="options.bin_name"
            />
          </template>
          <template v-slot:header.picking_location="{ header }">
            <v-text-field
                solo
                dense
                @click.stop
                hide-details
                label="Location"
                v-model="options.picking_location"
            />
          </template>
          <template v-slot:header.is_default="{ header }">
            <v-select
                solo
                dense
                clearable
                @click.stop
                hide-details
                label="Is Default"
                :items="[{text:true, value:1}, {text:false, value:0}]"
                v-model="options.is_default"
            />
          </template>
          <template v-slot:[`item.type`]="{ item }">
            <template v-if="_.has(item, 'storage_type') && item.storage_type">
              {{ item.storage_type }}
            </template>
            <template v-if="_.has(item, 'picking_type') && item.picking_type">
              {{ item.picking_type }}
            </template>
          </template>
          <template v-slot:item.action="{ item, index }">
            <v-menu top :close-on-content-click="closeOnContentClick">
              <template v-slot:activator="{ on }">
                <v-btn dark x-small v-on="on" color="primary"> action</v-btn>
              </template>
              <v-list>
                <v-list-item v-if="item.bin_id && (item.storage_type==='staging' || item.storage_type==='both')">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          icon
                          v-on="on"
                          v-bind="attrs"
                          @click="toggleDefault(item.bin_id)"
                      >
                        <v-icon color="success" v-if="item.is_default">mdi-toggle-switch</v-icon>
                        <v-icon v-else>mdi-toggle-switch-off</v-icon>
                      </v-btn>
                    </template>
                    <span>Toggle Default</span>
                  </v-tooltip>
                </v-list-item>
                <v-list-item>
                  <v-hover>
                    <template v-slot:default="{ hover }">
                      <v-card flat class="mx-auto">
                        <span>{{ item.code }}</span>
                        <v-fade-transition>
                          <v-overlay v-if="hover" absolute color="#036358">
                            <v-btn
                                small
                                fab
                                @click="
                                (printDialog = true), (printValue = item.code)
                              "
                            >
                              <v-icon small> print</v-icon>
                            </v-btn>
                          </v-overlay>
                        </v-fade-transition>
                      </v-card>
                    </template>
                  </v-hover>
                </v-list-item>
                <v-list-item>
                  <tooltip-button
                      color="red"
                      :icon="true"
                      size="small"
                      data-text="Delete"
                      icon-name="delete"
                      @click="deleteConfig(item, index)"
                  />
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <warehouse-configure
        :warehouse_id="warehouse.id"
        v-if="warehouseConfigDialog"
        v-model="warehouseConfigDialog"
    />
    <v-dialog width="450" v-model="printDialog">
      <v-card>
        <v-card-title>
          {{ printValue }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mx-auto">
          <barcode :height="barcodeHeight" :value="printValue"></barcode>
          <v-row>
            <v-col>
              <v-text-field
                  min="0"
                  step="1"
                  type="number"
                  label="Print Quantity"
                  v-model="numberOfPrint"
              >
                <template slot="append">
                  <v-btn
                      text
                      small
                      @click="numberOfPrint ? numberOfPrint-- : null"
                  >
                    <v-icon> mdi-minus</v-icon>
                  </v-btn>
                  <v-btn text small @click="numberOfPrint++">
                    <v-icon> mdi-plus</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                  min="0"
                  step="1"
                  type="number"
                  label="height"
                  v-model="barcodeHeight"
              >
                <template slot="append">
                  <v-btn text small @click="barcodeHeight++">
                    <v-icon> mdi-plus</v-icon>
                  </v-btn>
                  <v-btn
                      text
                      small
                      @click="barcodeHeight ? barcodeHeight-- : null"
                  >
                    <v-icon> mdi-minus</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
              :disabled="!numberOfPrint > 0"
              color="primary"
              @click="$htmlToPaper('barcodePrint')"
          >
            Print
          </v-btn>
          <v-btn text @click="printDialog = false"> Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <barcode-print
        v-if="numberOfPrint"
        style="visibility: collapse"
        :number-of-print="numberOfPrint"
        :print-value="printValue"
        :height="barcodeHeight"
    />
  </div>
</template>
<script>
import WarehouseConfigure from "i#/warehouse/warehouse-configure.vue";

export default {
  name      : "warehouse.show",
  components: {
    WarehouseConfigure,
  },
  data() {
    return {
      closeOnContentClick  : false,
      company_id           : auth.user.company_id,
      warehouse            : null,
      items                : {},
      printDialog          : false,
      printValue           : "",
      numberOfPrint        : 0,
      barcodeHeight        : 50,
      warehouseConfigDialog: false,
      config               : null,
      options              : {
        itemsPerPage: this.$store.state.itemsPerPage,
      },
      loading              : false,
      headers              : [
        {
          text    : "type",
          value   : "type",
          sortable: false,
        },
        {
          text    : "isle name",
          value   : "isle_name",
          sortable: true,
        },
        {
          text    : "rack name",
          value   : "rack_name",
          sortable: true,
        },
        {
          text    : "bin name",
          value   : "bin_name",
          sortable: true,
        },
        {
          text    : "default",
          value   : "is_default",
          sortable: true,
        },
        {
          text    : "location",
          value   : "picking_location",
          sortable: true,
        },
        {
          text    : "action",
          value   : "action",
          sortable: false,
        },
      ],
    };
  },
  created() {
    this.getWarehouse();
  },
  watch     : {
    warehouseConfigDialog(val) {
      if(!val) {
        this.getWarehouse();
      }
    },
    printDialog(val) {
      if(!val) {
        this.printValue    = "";
        this.numberOfPrint = 0;
      }
    },
    options: {
      deep: true,
      handler() {
        this.loading = true;
        this.getWarehouse();
      },
    },
  },
  methods   : {
    toggleDefault(item) {
      console.log(item)
    },
    async deleteConfig(item, index) {
      let confirmation = false;
      confirmation     = await this.$root.confirmation({
                                                         text: "Are you sure you want to delete this item?",
                                                       });
      if(confirmation) {
        if(
            (_.has(item, "isle_id") && item["isle_id"]) ||
            (_.has(item, "picking_id") && item["picking_id"])
        ) {
          axios
              .delete("/api/inventory/warehouse-config/" + item.warehouse_id, {
                params: {
                  value: item,
                },
              })
              .then((res) => {
                this.loading = true;
                Swal.fire({
                            icon             : res.data.type,
                            text             : res.data.message,
                            timer            : 3000,
                            showCancelButton : false,
                            showConfirmButton: false,
                          });
              })
              .finally(() => {
                this.getWarehouse();
                this.loading = false;
                confirmation = false;
              });
        }
      }
    },
    getWarehouse() {
      this.loading = true;
      axios
          .get("/api/inventory/warehouses/" + this.$route.params.id, {
            params: this.options,
          })
          .then((res) => {
            this.warehouse = res.data.warehouse;
            this.items     = res.data.query;
            if(this.items.data.length === undefined && !_.isEmpty(this.items.data)) {
              this.items.data = Object.keys(this.items.data).map(key => {
                return this.items.data[key];
              })
            }
            this.items.data.map((d, i, obj) => {
              if(_.isEmpty(d)) return null
              if(d.isle_id) {
                d.code = this.$setStorageLocation(
                    d.warehouse_id,
                    d.isle_id,
                    d.rack_id,
                    d.bin_id
                );
              }
              if(d.picking_id) {
                d.code = `${this.company_id}-${d.warehouse_id}-${d.picking_id}`;
              }
            });
            if(this.items === undefined) {
              this.items = []
            }
            this.loading = false;
          });
    },
  },
};
</script>
<style scoped>
::v-deep .purchase-table th {
  padding: 0 3px !important;
}
</style>
