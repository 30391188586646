<template>
  <div>
    <div>
      <v-btn
          icon
          class="float-right"
          @click="$htmlToPaper('list_print_bootstrap')"
          v-if="item_detail && item_detail.bank!==undefined"
      >
        <v-icon>mdi-printer</v-icon>
      </v-btn>
      <v-row class="px-3">
        <v-col md="4" cols="12">
          <v-select
              solo
              :items="banks"
              item-value="id"
              item-text="name"
              label="bank name"
              v-model="options.bank_id"
              prepend-icon="mdi-database-search"
          ></v-select>
        </v-col>
        <v-col cols="12" md="4" v-if="options.bank_id">
          <v-dialog
              ref="dialog"
              v-model="modal"
              :return-value.sync="options.created_at"
              persistent
              width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  solo
                  v-model="options.created_at"
                  label="date range"
                  prepend-icon="event"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  clearable
              ></v-text-field>
            </template>
            <v-date-picker v-model="options.created_at" scrollable range>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal = false">Cancel</v-btn>
              <v-btn text color="primary" @click="$refs.dialog.save(options.created_at)">OK</v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
      </v-row>
    </div>
    <div id="list_print_div">
      <v-data-table
          :headers="headers"
          :loading="loading"
          :items="items.data"
          class="elevation-0"
          :options.sync="options"
          :server-items-length="items.total"
          loading-text="Loading... Please wait"
          :footer-props="{itemsPerPageOptions: $store.state.itemsPerPageOptions}"
      >
        <template v-slot:top>
          <v-row v-if="item_detail && item_detail.bank!==undefined">
            <v-col>
              <h1 class="text-center">{{ item_detail.bank.name }}</h1>
              <p class="text-center">Account no: {{ item_detail.bank.account_no }}</p>
              <p class="text-center">Total debit: {{ item_detail.total_debits }}</p>
              <p class="text-center">Total credit: {{ item_detail.total_credits }}</p>
              <p class="text-center">Ending Balance: {{ item_detail.running_balance }}</p>
              <p class="text-center">datetime: {{ from_date }} -- {{ to_date }}</p>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
      <list-print-bootstrap
          :title="[
              `${item_detail.bank.name}`,
              `${'Account No: ' + item_detail.bank.account_no}`,
              `${'Total Debit: '+item_detail.total_debits}`,
              `${'Total Credit: '+item_detail.total_credits}`,
              `${'Ending Balance: '+item_detail.running_balance}`,
              `${'Date time: '+from_date+' -- '+to_date}`
          ]"
          :columns="headers"
          :rows="items.data"
          style="visibility: collapse"
          :date_fields="['created_at']"
          v-if="item_detail && item_detail.bank!==undefined"
      >
      </list-print-bootstrap>
    </div>

  </div>
</template>
<style scoped>
::v-deep th {
  padding: 0 3px !important;
}
</style>
<script>
export default {
  data   : () => ({
    name       : "ledger",
    loading    : false,
    banks      : [],
    options    : {
      itemsPerPage      : 10,
      ledger_per_bank   : true,
      total_debit_credit: true,
      sortBy            : ['created_at'],
      sortDesc          : [true]
    },
    menu       : false,
    modal      : false,
    from_date  : '',
    to_date    : '',
    bank_id    : null,
    items      : {},
    item_detail: {},
    headers    : [
      {text: 'transaction number', value: 'transaction_number', sortable: true},
      {text: 'created at', value: 'created_at', sortable: true},
      {text: 'debit', value: 'debit', sortable: true},
      {text: 'credit', value: 'credit', sortable: true},
      {text: 'running balance', value: 'running_balance', sortable: true},
    ]
  }),
  watch  : {
    options: {
      deep: true,
      handler() {
        if(!this.options.created_at || this.options.created_at.length > 1) {
          this.getData()
        }
      }
    },
  },
  created() {
    if(!_.isEmpty(this.$route.params)) {
      this.options.bank_id = this.$route.params.id
    }
    axios.get('/api/get-bank')
         .then(res => {
           this.banks = res.data
         })
  },
  methods: {
    getData() {
      if(this.options.bank_id) {
        this.loading = true
        axios.get(api_bank + '/' +this.options.bank_id, {params: this.options})
             .then(res => {
               this.items = res.data.ledger_per_bank
               if(this.items.data.length) {
                 this.to_date   = _.maxBy(this.items.data, 'id').created_at
                 this.from_date = _.minBy(this.items.data, 'id').created_at
               }
               this.item_detail = res.data.bankTransactionTotal_debit_credit
               this.loading     = false
             })
      }

    }
  }
}
</script>