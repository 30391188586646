<template>
  <v-autocomplete
      cache-items
      hide-details
      :items="items"
      item-value="id"
      v-model="select"
      item-text="name"
      label="Categories"
      v-bind:solo="solo"
      v-bind:dense="dense"
      v-bind:multiple="multiple"
      :search-input.sync="searchValue"
      clearable
  />
</template>
<script>
export default {
  name : "search-category",
  props: {
    multiple: {
      type   : Boolean,
      default: false
    },
    dense   : {
      type   : Boolean,
      default: false
    },
    solo    : {
      type   : Boolean,
      default: false
    },
  },
  watch: {
    select(val) {
      this.$emit('input', val)
    }
  },
  created() {
    axios.get('/api/inventory/categories', {
      params: {
        type    : 'product',
        dropdown: true
      }
    })
         .then(res => {
           this.items = res.data
         })
  },
  data() {
    return {
      items      : [],
      searchValue: null,
      select     : null,
    }
  },
}
</script>