import Edit from './edit.vue'
import CategoryCreate from 'i#/category/create.vue'
export default {
    components: {
        Edit,
        CategoryCreate
    },
    data() {
        return {
            categories  : [],
            model2      : '',
            list        : [],
            catModal    : false,
            catItem     : {},
            loading     : false,
            selected    : [],
            categoryEdit: [],
            createDialog:false
        }

    },
    watch     : {
        selected(val) {
            if(val.length > 0 && (this.$options.filters.checkPermission(['category', 'edit']) || this.$options.filters.checkPermission(['category', 'delete']))) {
                axios.get('/api/inventory/categories/' + val[0] + '/edit')
                     .then(res => {
                         if(res.data) {
                             this.catItem = res.data
                         }
                     })
            }
        },
        createDialog(val) {
            if(!val) {
                this.getCategoryList()
            }
        }
    },
    computed  : {},
    created() {

        this.getCategoryList()
    },
    methods   : {
        updateEdit(bool = false) {
            if(bool) {
                this.getCategoryList()
                this.catItem = {}
            }
        },
        getCategoryList() {
            this.loading = true
            axios.get('/api/inventory/categories').then(response => {
                this.categories = response.data;
                this.loading    = false
            })
        },
        getData(val) {
            this.catItem  = val[0]
            this.catModal = true
        },

        closeModal() {
            this.catModal = false
        },
        deleteCategory(id) {
            this.loading = true
            this.$deleteWithConfirmation({
                                             text: 'Are you sure you want delete this category?',
                                             url : '/api/inventory/categories/' + id
                                         })
                .then(res=>{
                    this.getCategoryList()
                    this.catItem = {}
                    this.loading = false
                })
                .catch(error=>{
                    this.loading = false
                })
        }
    }
}