import _ from 'lodash'
import SaleShowPrint from './sale-show-print.vue'
import CustomerCreate from 'i#/customer/create.vue'

export default {
    components: {
        SaleShowPrint,
        CustomerCreate
    },
    props     : {
        modelId: {
            type   : Number,
            default: null
        },
        value  : {
            type   : Boolean,
            default: false
        },
    },
    data() {
        return {
            due                  : 0,
            units                : [],
            url                  : this.$getUrl('sales', this.value, this.modelId),
            axiosMethod          : this.$getAxiosMethod(this.value, this.modelId),
            forms                : this.$root.$data.forms,
            items                : [],
            menu1                : false,
            sales                : {status: ''},
            modal                : false,
            banks                : [],
            tables               : [],
            dialog               : false,
            model10              : [],
            loading              : false,
            settings             : [],
            products             : [],
            customers            : [],
            tooltipId            : "conversionTooltip",
            warehouses           : [],
            product_id           : '',
            sale_profit          : false,
            previousDue          : 0,
            initialPaid          : 0,
            extra_weight         : 0,
            payment_type         : 1,
            computedDate         : null,
            salePrintData        : null,
            customerModal        : false,
            searchProduct        : null,
            payment_status       : 1,
            part_number_dialog   : false,
            profitPercentageField: false,
            timeOutValue         : null,
            print_data           : {
                sales: {
                    ref: ''
                }
            }
        }
    },
    beforeCreate() {
        this.$root.$data.forms.fieldReset();
    },
    watch     : {
        searchProduct(val) {
            if(val) {
                this.getProducts(val)
            }
        },
        '$route.fullPath'(val) {
            //reset data
            this.forms.reset()
            this.sales          = []
            this.product_id     = ''
            this.items          = []
            this.due            = 0
            this.payment_status = 1
            this.payment_type   = 1
        },
        total(val) {
            this.calDue()
            if(this.axiosMethod === 'patch') {
                return
            }
            this.forms.paid = parseFloat(parseFloat(val) + parseFloat(this.previousDue)).toFixed(4)
        },
        items:{
            deep: true,
            handler(val){
                val.map(v => {
                    if (v.location) {
                        let arr = v.location.split('-')
                        if (arr.length) v.warehouse = parseInt(arr[1])
                    }
                })
            }
        }
    },

    computed: {
        isLoaded() {
            return !_.isEmpty(this.warehouses) || !_.isEmpty(this.customers) || !_.isEmpty(this.products) || !_.isEmpty(this.units)
        },
        total() {
            let total         = 0
            let shipping_cost = 0
            let discount      = 0
            shipping_cost     = !this.forms.shipping_cost ? 0 : this.forms.shipping_cost
            if(this.items.length > 0) {
                for (let i = 0; i < this.items.length; i++) {
                    total += this.items[i].price
                }
            }
            total    = parseFloat(total) + parseFloat(shipping_cost)
            discount = this.forms.overall_discount ? total * parseFloat(this.forms.overall_discount) / 100 : 0
            total -= discount
            this.getTotalWeight()
            return total.toFixed(4)
        },
    },

    created() {
        this.$set(this.forms, 'total_weight', 0)
        this.forms.extra_weight = 0
        this.loading            = true

        this.$root.$data.erp.request.get(this.url, this, (data) => {
            this.$set(this.forms, 'sales_date', new Date())
            this.settings             = data.settings
            this.customers            = data.customers
            this.products             = data.products
            this.warehouses           = data.warehouses
            this.units                = data.units
            this.forms.payment_status = 3
            this.forms.status         = 1
            this.tables               = data.tables
            this.banks                = data.banks
            this.forms.customer_id    = this.$root.getDefault_id(this.customers)
            this.forms.payment_type   = 1
            if(this.axiosMethod !== 'patch') {
                this.getCustomerDue()
            }
            this.sales.biller = auth.user ? auth.user.name : ''
            this.forms.biller = this.sales.biller
            if(this.axiosMethod === 'patch') {
                this.sales            = data.sales
                let part_numbers      = data.sales.partnumbers
                this.forms.sales_date = moment(this.sales.sales_date_formatted, this.$root.settings.settings.date_format).format("YYYY-MM-DD")
                this.$root.$set(this.forms, 'overall_discount', this.sales.overall_discount);
                this.$root.$set(this.forms, 'shipping_cost', this.sales.shipping_cost);
                this.initialPaid          = !_.isEmpty(this.sales.payments) ? this.sales.payments[0].paid : 0
                this.forms.paid           = !_.isEmpty(this.sales.payments) ? this.sales.payments[0].paid : 0
                this.forms.table          = parseInt(this.sales.table)
                this.forms.status         = parseInt(this.sales.status)
                this.forms.payment_status = parseInt(this.sales.payment_status)
                this.previousDue          = this.sales.previous_due
                this.forms.customer_id    = parseInt(this.sales.customer_id)
                if(this.payment_type == 2 || this.payment_type == 3) {
                    this.forms.bank_id            = this.purchases.payments[0].transaction.bank_id
                    this.forms.transaction_number = this.purchases.payments[0].transaction.transaction_number
                }
                for (let i = 0; i < this.sales.products.length; i++) {
                    let product = this.sales.products[i];
                    this.items.push({
                                        product_id             : product.id,
                                        pname                  : product.name,
                                        unit_price             : parseFloat(product.pivot.price).toFixed(4),
                                        warehouse              : parseInt(product.pivot.warehouse_id),
                                        unit                   : parseInt(product.pivot.unit_id),
                                        units                  : product.units,
                                        discount               : parseFloat(product.pivot.discount).toFixed(4),
                                        quantity               : parseFloat(product.pivot.quantity).toFixed(4),
                                        fromUnit               : product.pivot.unit_id,
                                        baseUnit               : product.base_unit_id,
                                        price                  : 0,
                                        warehouses             : product.warehouses,
                                        productInStock         : this.getAvailableProductCount(product.warehouses),
                                        weight                 : product.pivot.weight,
                                        weight_total           : product.pivot.weight_total,
                                        adjustment             : product.pivot.adjustment,
                                        manufacture_part_number: product.manufacture_part_number,
                                        location               : product.sale_location,
                                        ps_id                  : product.pivot.ps_id,
                                        part_number            : this.$options.filters.sortPartNumber(
                                            product.partnumbers,
                                            product,
                                            parseInt(product.pivot.warehouse_id
                                            )
                                        ),
                                        selected_part_number   : this.getSelectedPartNumbers(part_numbers, product)
                                    })
                }
                this.getTotalWeight()
                this.extra_weight = this.sales.total_weight - this.forms.total_weight
            }
            this.loading = false
        });
    },
    methods: {
        getSelectedPartNumbers(part_numbers, product) {
            let p = []
            part_numbers.forEach(pn => {
                if(pn.product_id === product.id) {
                    p.push(pn.id)
                }
            })
            return p
        },
        sortByWarehouse(part_numbers, warehouse_id) {
            return part_numbers.filter(p => {
                return p.warehouse_id === warehouse_id
            })
        },
        getTotalWeight() {
            this.forms.total_weight = 0
            this.items.forEach(item => {
                if(!item.weight_total) {
                    item.weight_total = 0
                }
                this.forms.total_weight += item.weight_total
            })
        },
        postSales($e) {
            let buttonValue           = $e.submitter.value
            this.loading              = true
            this.forms.items          = this.items
            this.forms.total          = this.total
            this.forms.previous_due   = this.previousDue
            this.forms.payment_status = this.payment_status
            this.forms.payment_type   = this.payment_type
            this.forms.sales_date     = moment(this.forms.sales_date).format("YYYY-MM-DD HH:mm:ss")
            this.forms.extra_weight   = this.extra_weight

            this.forms.submit(
                this.axiosMethod,
                this.$getUrlForm(this.url),
                false,
                this.$root
            )
                .then(data => {
                    if(this.forms.selectedButton == 'pos-print') {
                        this.salePrintData = data.sale.original
                        setTimeout(function () {
                            document.getElementById('posPrintButton').click()
                        }, 2000)
                    }
                    if(this.forms.selectedButton === 'print') {
                        this.salePrintData = data.sale.original
                        setTimeout(function () {
                            document.getElementById('printButton').click()

                        }, 2000)
                    }
                    this.forms.sales_date = moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
                    this.forms.paid       = 0
                    this.items            = []
                    this.loading          = false
                    Swal.fire({
                                  timer            : 3000,
                                  icon             : data.type,
                                  text             : data.message,
                                  showCancelButton : false,
                                  showConfirmButton: false,
                              }).then(result => {
                        if(!this.value) {
                            if(this.method === 'Edit') {
                                this.$router.push({name: 'sale.index'})
                            } else {
                                if(buttonValue === 'print') {
                                    this.call_print_component(data)
                                }
                                if(buttonValue === 'view') {
                                    this.$router.push({name: 'sale.show', params: {id: data.sale.id}})
                                }
                            }
                        }
                        if(this.value) {
                            if(buttonValue === 'submit') {
                                this.$emit('input', false)
                            }
                            if(buttonValue === 'print') {
                                this.call_print_component(data)
                            }
                            if(buttonValue === 'view') {
                                this.$router.push({name: 'sale.show', params: {id: data.sale.id}})
                            }
                        }

                    })
                }).catch(error => {
                this.loading = false
            });

            this.getCustomerDue(this.forms.customer_id)


        },
        call_print_component(data) {
            axios.get('/api/inventory/sales/' + data.sale.id)
                 .then(res => {
                     this.print_data.sales        = res.data.sale
                     this.print_data.company      = res.data.company
                     this.print_data.returns      = res.data.returns
                     this.print_data.totalRAmount = 0.0
                     for (let i = 0; i < this.print_data.returns.length; i++) {
                         this.print_data.totalRAmount += parseFloat(this.print_data.returns[i].amount)
                     }
                     this.$nextTick(next => {
                         this.$htmlToPaper('printSale')
                     })
                 })
        },
        // product Sale section
        addProduct() {
            if(!this.product_id) return null;
            this.items.push({
                                product_id             : this.product_id,
                                pname                  : this.getProduct(this.product_id).name,
                                unit_price             : parseFloat(this.getProduct(this.product_id).price).toFixed(4),
                                price                  : 0,
                                warehouses             : this.getProduct(this.product_id).warehouses,
                                warehouse              : this.select_default_warehouse(this.getProduct(this.product_id).warehouses),
                                productInStock         : this.getAvailableProductCount(this.getProduct(this.product_id).warehouses),
                                fromUnit               : this.getProduct(this.product_id).base_unit_id,
                                baseUnit               : this.getProduct(this.product_id).base_unit_id,
                                units                  : this.getProduct(this.product_id).units,
                                unit                   : this.$root.getPrimary_id(this.getProduct(this.product_id).units),
                                weight                 : this.findWeight(this.product_id),
                                manufacture_part_number: this.getProduct(this.product_id).manufacture_part_number,
                                part_number            : this.getProduct(this.product_id).partnumbers,
                                location               : ''
                            });
            this.forms.paid = 0
            this.calDue()


            // if(this.items.length > 0) {
            //     this.product_id    = null
            //     this.searchProduct = null
            this.$nextTick(() => {
                this.product_id = null
            })
            // }
        },
        itemTotalWeight(index) {
            this.items[index].weight_total = this.items[index].quantity
                ? this.items[index].weight * this.items[index].quantity
                : 0

            return this.items[index].weight_total
        },
        checkSelected(part_numbers, selected, quantity) {
            part_numbers.forEach(p => {
                p.disabled = null
            })
            if(selected) {
                if(selected.length === Number(quantity)) {
                    part_numbers.forEach(p => {
                        if(!selected.includes(p.id)) {
                            p.disabled = true
                        }
                    })
                }
            }
        },
        findWeight(product_id) {
            let product = this.getProduct(product_id)
            let units   = product.units
            if(units.length > 0) {
                let weight = units.find((unit) => {
                    return unit.id === product.base_unit_id
                }).pivot.weight
                return weight ? weight : 0;
            } else {
                return 0;
            }
        },
        select_default_warehouse(warehouses) {
            let datas = null
            warehouses.filter(data => {
                if(data.is_default) {
                    datas = data.id
                }
            })
            if(!datas) {
                return warehouses[0].id
            } else {
                return datas
            }
        },
        uniqueItem(id) {
            return this.items.find((item) => {
                return item.product_id === id
            })
        },

        getProduct(id) {
            return this.products.find((product) => {
                return product.id === this.product_id
            })
        },
        getProductById(id) {
            return this.products.find((product) => {
                return product.id === id
            })
        },
        removeProduct(index) {
            this.items.splice(index, 1);
        },
        getTotalPrice(item) {
            let totalPrice = 0
            if(item.unit_price && item.quantity) {
                totalPrice = Number(item.unit_price) * Number(item.quantity)
            }
            if(totalPrice && item.discount) {
                totalPrice -= Number(item.discount)
            }
            if(totalPrice && item.adjustment) {
                totalPrice += Number(item.adjustment)
            }
            item.price = Number(parseFloat(totalPrice).toFixed(2))
            return item.price
        },
        calDue() {
            this.$nextTick(() => {
                this.due = ((parseFloat(this.total) + parseFloat(this.previousDue)) - parseFloat(this.forms.paid ? this.forms.paid : 0)).toFixed(4)
                if(this.due <= 0) {
                    this.payment_status = 1 //paid
                }
                if(this.due > 0) {
                    this.payment_status = 2 // partial
                }
                if(this.due == (parseFloat(this.total) + parseFloat(this.previousDue)).toFixed(4) && this.due != 0) {
                    this.payment_status = 3 //due
                }
            })


        },
        getPrice(unit, index) {
            let fromUnit               = this.items[index].fromUnit
            this.items[index].fromUnit = unit
            this.items[index].weight   = _.find(this.items[index].units, {id: unit}).pivot.weight
            if(fromUnit === unit) {
                return
            }

            let url = '/api/inventory/unitconversions/' + unit + '/' + fromUnit + '/1'
            axios.post(url)
                 .then(response => {
                     let conversion               = response.data.conversion
                     this.items[index].unit_price = parseFloat(response.data.quantity * this.items[index].unit_price).toFixed(4)
                 })
        },
        getAvailableProductCount(warehouses) {
            let quantity = 0
            for (var i = 0; i < warehouses.length; i++) {
                let warehouse = warehouses[i]
                quantity += parseFloat(warehouse.pivot.quantity)

            }
            return quantity
        },
        getNewCustomer(customerDetails) {
            this.forms.sales_date     = moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
            this.forms.payment_status = 3
            this.forms.status         = 1
            this.customers.push(customerDetails);
            this.forms.customer_id = customerDetails.id;
            this.dialog            = false
        },
        onCancel() {
            this.$router.push({name: "sale.index"})

        },
        getClickedProduct(val) {
            if(val) {
                this.product_id = val
                this.addProduct()
            }
        },
        getProducts: _.debounce(function (query) {
            if(query !== '') {
                axios.post('/api/inventory/products/getProducts', {
                    val             : query,
                    isPurchase      : true,
                    with_part_number: true
                })
                     .then(response => {
                         this.products = response.data.products
                         // console.log( response.data.products, query )
                         // if(this.products.length === 1) {
                         //     this.product_id = this.products[0].id
                         // this.addProduct()
                         // }
                         this.loading = false
                     })
                     .catch(error => {
                         alert(error.message)
                     })
            } else {
                this.loading  = true;
                this.products = []
                return null
            }
        }, 1000),

        getInputField(event) {
            this.profitPercentageField = event.target.checked;
        },
        initPreview() {
            Erp.salePurchase.initialPreviewSetup(this)
        },
        getCustomerDue() {
            axios.get('/api/inventory/customer/get-due/' + this.forms.customer_id)
                 .then(response => {
                     this.previousDue = response.data.due ? response.data.due : 0
                     this.calDue()
                 })
        },
    }
}