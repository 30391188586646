import _ from "lodash";
import IncomeShow from './show.vue'
import IncomeCreate from './create.vue'
import IncomeExport from './income-export.vue'
import IncomePaymentReceived from './income-payment-received.vue'

export default {
    components: {
        IncomeShow,
        IncomeCreate,
        IncomeExport,
        IncomePaymentReceived,
    },
    data() {
        return {
            columnMenu                 : false,
            exportDialog               : false,
            payment_bill_dialog        : false,
            payment_bill_id            : null,
            payment_crud_method        : 'create',
            payment_crud_model_id      : null,
            payment_crud_dialog        : false,
            payment_crud_payment_id    : null,
            payment_list_dialog        : false,
            incomeCreateDialog         : false,
            incomeShowDialog           : false,
            incomePaymentReceivedDialog: false,
            menu                       : false,
            accounts                   : [],
            form_id                    : null,
            items                      : {},
            options                    : {
                sortBy      : ['created_at'],
                sortDesc    : [true],
                itemsPerPage: this.$store.state.itemsPerPage,
            },
            loading                    : false,
            payments                   : [],
            headers                    : [
                {text: 'ref no', value: 'ref', sortable: true, align: ''},
                {text: 'warehouse', value: 'warehouse_name', sortable: false, align: ''},
                {text: 'account', value: 'account_name', sortable: false, align: ''},
                // {text: 'name-id-type', value: 'transaction.userable.name_id_type', sortable: false, align:''},
                {text: 'amount', value: 'amount', sortable: true, align: ''},
                {text: 'paid', value: 'paid_total', sortable: true, align: ''},
                {text: 'due', value: 'due', sortable: true, align: ''},
                {text: 'note', value: 'note', sortable: false, align: ''},
                // {text: 'create at', value: 'created_at', sortable: true, align: ''},
                {text: 'date', value: 'income_date', sortable: true, align: ''},
                {text: 'action', value: 'action', sortable: false, align: ''},
            ],
            paymentHeaders             : [
                {text: 'amount', value: 'paid'},
                {text: 'date', value: 'date'},
                {text: 'action', value: 'action', action: false},
            ],
        }
    },
    watch     : {
        incomeCreateDialog(val) {
            if(!val) {
                this.form_id = null
                this.getData()
            }
        },
        options: {
            deep: true,
            handler() {
                this.loading = true
                this.getData()
            }
        }
    },
    created() {
        if(!_.isEmpty(this.$route.params) && this.$route.params.id) {
            this.options.id = this.$route.params.id
        }
        this.getAccounts()
    },
    methods   : {
        uploadFile() {
            let formData = new FormData()
            let file     = this.$refs.inputUpload.files[0]
            formData.append('file', file)
            axios.post('/api/inventory/income-import', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                 .then(res => {
                     Swal.fire({
                                   icon : 'success',
                                   text : 'file has been uploaded successfully, please update payments as well',
                                   timer: 4000
                               }).then((result) => {
                         this.getData()
                     })
                 })
                 .catch(error => {
                     Swal.fire({
                                   icon: 'error',
                                   html: 'status ' + error.response.status + '.<br>' + error.response.statusText,
                               })
                 })
        },
        closeDialogExport(val) {
            if(val === 200) {
                this.exportDialog = false
            }
        },
        createIncomeBillById(id) {
            this.method                = 'create'
            this.payment_crud_model_id = id
            this.payment_crud_dialog   = true
        },
        showIncome(id) {
            this.form_id          = id
            this.incomeShowDialog = true
        },
        editIncome(id) {
            this.form_id            = id
            this.incomeCreateDialog = true
        },
        editPayment(id) {
            this.payment_crud_payment_id = id
            this.payment_crud_method     = 'edit'
            this.payment_crud_dialog     = true
            this.payment_list_dialog     = false
        },
        getListOfIncomeBills(id) {
            this.payments = []
            axios
                .get('/api/payments/income-payment-received', {
                    params: {model_id: id}
                })
                .then(res => {
                    this.payments = res.data
                    if(this.payments.length > 0) {
                        this.payment_list_dialog = true
                    }
                })
        },
        getAccounts() {
            axios.get('/api/accounts/get-accounts-by-parent/Income')
                 .then(res => {
                     this.accounts = res.data
                 })

        },
        resetPaymentComponent() {
            this.payment_crud_method     = 'create'
            this.payment_crud_model_id   = null
            this.payment_crud_payment_id = null
        },
        deleteItem(id) {
            Swal.fire({
                          icon             : 'warning',
                          text             : 'Are you sure you want to delete this income?',
                          showCancelButton : true,
                          showConfirmButton: true
                      }).then((result) => {
                if(result.isConfirmed){
                    this.loading = true
                    axios.delete('/api/income/incomes/' + id)
                         .then(res => {
                             this.getData()
                             this.loading = false
                             Swal.fire({
                                           icon             : res.data.type,
                                           timer            : 3000,
                                           text             : res.data.message,
                                           showCancelButton : false,
                                           showConfirmButton: false
                                       })
                         })
                }
            })
                .finally(() => {
                    this.loading = false
                })

        },
        getData: _.debounce(function () {
            this.loading = true
            axios.get('/api/income/incomes', {
                params: this.options
            })
                 .then(res => {
                     this.items   = res.data
                     this.loading = false
                 })
        }, 800),

        getExpenseList(query, callback) {
            axios.get('/api/income/incomes?dropdown=true').then(response => {
                this.incomes = response.data;
                callback(query);
            }).catch(error => {

            })
        },
        paymentSuccess(val) {
            if(val) {
                this.getData()
                this.payment_crud_dialog = false
            }
        },
        fromCreate(val) {
            // val.type, val.expense.id
            if(val.type === 'success') {
                if(val.show_payment_component) {
                    this.createIncomeBillById(val.income.id)
                }
                this.incomeCreateDialog = false
                if(!val.show_payment_component) {
                    setTimeout(e => {
                        this.incomeCreateDialog = true
                    }, 200)
                }
            }
        },
        resetQuery() {
            this.options             = {
                sortDesc    : [true],
                sortBy      : ['created_at'],
                itemsPerPage: this.$store.state.itemsPerPage,
            }
            this.options.ref         = null
            this.options.warehouse   = null
            this.options.amount      = null
            this.options.account_id  = null
            this.options.income_date = null
        }
    }
}