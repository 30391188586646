<template>
    <div>
        I am 401
    </div>
</template>

<script>
    export default {
        name: "401"
    }
</script>