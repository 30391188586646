import BankChart from './bank-chart.vue'

export default {
    name      : "index",
    components: {
        BankChart,
    },
    data      : () => ({
        items              : {},
        bank_total         : {},
        chartDatas         : {},
        forms              : {
            id        : null,
            type      : null,
            name      : '',
            account_no: '',
            branch    : '',
            address   : '',
            errors    : {
                type      : '',
                account_no: '',
                name      : '',
                branch    : '',
                address   : ''
            }
        },
        dialog             : false,
        loading            : false,
        options            : {
            itemsPerPage: 10,
        },
        headers            : [
            {text: 'name', value: 'name', sortable: true},
            {text: 'type', value: 'type', sortable: true},
            {text: 'account no', value: 'account_no', sortable: true},
            {text: 'branch', value: 'branch', sortable: true},
            {text: 'address', value: 'address', sortable: true},
            {text: 'total debit', value: 'total_debit', sortable: false},
            {text: 'total credit', value: 'total_credit', sortable: false},
            {text: 'ending balance', value: 'running_balance', sortable: false},
            {text: 'action', value: 'action', sortable: false},
        ],
        closeOnContentClick: false
    }),
    watch     : {
        options: {
            deep: true,
            handler() {
                this.loading = true
                this.getData()
            }
        },
    },
    created() {
        this.bankTotal()
    },
    methods   : {

        bankTotal() {
            axios.get('/api/bank-total')
                 .then(res => {
                     this.bank_total = res.data
                 })
        },
        resetForm() {
            this.forms.id         = null
            this.forms.name       = ''
            this.forms.type       = null
            this.forms.account_no = ''
            this.forms.branch     = ''
            this.forms.address    = ''
            this.resetError()
        },
        resetError() {
            this.forms.errors.account_no = ''
            this.forms.errors.name       = ''
            this.forms.errors.type       = ''
            this.forms.errors.address    = ''
            this.forms.errors.branch     = ''
        },
        editItem(item) {
            this.resetForm()
            this.forms.id         = item.id
            this.forms.name       = item.name
            this.forms.account_no = item.account_no
            this.forms.branch     = item.branch
            this.forms.address    = item.address
            this.forms.type       = item.type
            this.dialog           = true
        },
        deleteItem(id) {
            this.loading = true
            this.$deleteWithConfirmation({
                                             text: 'Are you sure you want delete this bank?',
                                             url : api_bank + '/' +id
                                         })
                .then(data => {
                    this.loading = false
                    this.getData()
                })
                .catch(error => {
                    this.loading = false
                })

            if(confirm('are you sure')) {
                axios.delete()
                     .then(res => {
                         Swal.fire({
                                       icon : res.data.type,
                                       timer: 3000,
                                       text : res.data.message,
                                   })
                             .then((result) => {
                                 this.getData()
                             })
                     })
                     .catch(error => {
                         this.loading = false
                         this.dialog  = false
                         Swal.fire({
                                       'icon'   : 'error',
                                       'message': error.response.message
                                   })
                     })
            }
        },
        postBank() {
            this.resetError()
            if(this.forms.id) {
                this.updateBank()
            } else {
                this.storeBank()
            }
        },
        updateBank() {
            axios.patch(api_bank + '/' +this.forms.id, this.forms)
                 .then(res => {
                     Swal.fire({
                                   icon : res.data.type,
                                   timer: 3000,
                                   text : res.data.message,
                               })
                         .then((result) => {
                             this.dialog = false
                             this.resetForm()
                             this.getData()
                         })
                 })
                 .catch(error => {
                     this.loading = false
                     if(error.response.status === 422) {
                         let err = error.response.data.errors
                         Object.keys(err).forEach(key => {
                             this.forms.errors[key] = err[key][0]
                         })
                     } else {
                         this.dialog = false
                         Swal.fire({
                                       'icon'   : 'error',
                                       'message': error.response.message
                                   })
                     }
                 })
        },
        storeBank() {
            this.loading = true
            axios.post(api_bank, this.forms)
                 .then(res => {
                     Swal.fire({
                                   icon : res.data.type,
                                   timer: 3000,
                                   text : res.data.message,
                               })
                         .then((result) => {
                             this.dialog = false
                             this.resetForm()
                             this.getData()
                         })
                 })
                 .catch(error => {
                     this.loading = false
                     if(error.response.status === 422) {
                         let err = error.response.data.errors
                         Object.keys(err).forEach(key => {
                             this.forms.errors[key] = err[key][0]
                         });
                     } else {
                         this.dialog = false
                         Swal.fire({
                                       'icon'   : 'error',
                                       'message': error.response.message
                                   })
                     }
                 })
        },
        getData() {
            axios.get(api_bank, {params: this.options})
                 .then(res => {
                     this.items   = res.data.banks
                     this.loading = false
                 })
        }
    }
}