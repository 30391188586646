import _ from "lodash";
import BrandCreate from 'i#/brand/create.vue'

export default {
    components: {
        BrandCreate
    },
    data() {
        return {
            brands              : [],
            items               : {},
            loading             : false,
            options             : {
                sortBy      : ['created_at'],
                sortDesc    : [true],
                itemsPerPage: this.$store.state.itemsPerPage,
            },
            createOrUpdateDialog: false,
            edit_id             : null,
            headers             : [
                {text: 'name', value: 'name', sortable: true},
                {text: 'description', value: 'description', sortable: true},
                {text: 'created at', value: 'created_at', sortable: true},
                {text: 'action', value: 'action', sortable: false},
            ],
            closeOnContentClick:false
        }
    },
    watch     : {
        options: {
            deep: true,
            handler() {
                this.loading = true
                this.getDataWithLoading()
            }
        },
        createOrUpdateDialog(val) {
            if(!val) {
                this.getDataWithLoading()
                this.edit_id = null
            }
        }
    },
    methods   : {
        deleteItem(id) {
            this.loading = true
            this.$deleteWithConfirmation({
                                             text: 'Are you sure you want delete this brand?',
                                             url : '/api/inventory/brands/' + id
                                         })
                .then(data => {
                    this.loading = false
                    this.getData()
                })
            this.loading = false
        },
        getData: _.debounce(function () {
            axios.get('/api/inventory/brands', {
                params: this.options
            })
                 .then(res => {
                     this.items   = res.data
                     this.loading = false
                 })
        }, 400),

        getBrandList(query, callback) {
            axios.get('/api/inventory/brands?dropdown=true').then(response => {
                this.brands = response.data;
                callback(query);
            }).catch(error => {

            })
        },
        getDataWithLoading() {
            this.loading = true
            this.getData()
        }

    }
}