export default {
    props   : {
        value       : {
            type    : Boolean,
            default : false,
            required: true,
        },
        warehouse_id: {
            type    : Number,
            default : null,
            required: true,
        },
    },
    data() {
        return {
            forms       : [],
            errors      : {},
            bin_list    : {},
            isle_list   : [],
            rack_list   : {},
            loading     : false,
            is_default  : null,
            warehouse   : null,
            locationType: "",
            model_list  : ["isle", "rack", "bin"],
        };
    },
    watch   : {
        forms: {
            deep: true,
            handler(val) {
                this.formatForm();
            },
        },
    },
    computed: {
        show: {
            get() {
                return this.value;
            },
            set(value) {
                if(!value) {
                    this.$emit("input", false);
                }
            },
        },
    },
    created() {
        this.getWarehouse();
    },
    methods : {
        checkBin(value, index) {
            if(_.isString(value)) {
                let val = _.find(this.bin_list[index], {bin_name: value});
                if(!val) {
                    this.forms[index].bin_name = value;
                }
            }
            if(_.isObject(value)) {
                this.forms[index].bin        = {};
                this.forms[index].bin        = value;
                this.forms[index].bin_name   = value.bin_name;
                this.forms[index].bin_id     = value.bin_id;
                this.forms[index].is_default = value.is_default;
            }
        },
        checkRack(value, index) {
            this.bin_list[index]       = [];
            this.forms[index].bin      = null;
            this.forms[index].bin_name = "";
            this.forms[index].bin_id   = null;
            this.forms[index].is_default = false;
            if(!value) return null;
            if(_.isString(value)) {
                let val = _.find(this.rack_list[index], {rack_name: value});
                if(!_.isEmpty(val)) {
                    this.forms[index].rack      = {};
                    this.forms[index].rack      = val;
                    this.forms[index].rack_name = val.rack_name;
                    this.forms[index].rack_id   = val.rack_id;
                } else {
                    this.forms[index].rack_id   = null;
                    this.forms[index].rack_name = value;
                }
            }
            if(!_.isEmpty(this.forms[index])) {
                this.forms.forEach((m) => {
                    if(m.rack_id) {
                        if(m.rack_id === this.forms[index].rack.rack_id && m.bin_id) {
                            this.bin_list[index].push({
                                                          bin_id  : m.bin_id,
                                                          bin_name: m.bin_name,
                                                      });
                        }
                    }
                });
                // this.bin_list = _.uniqWith(this.bin_list, _.isEqual);
            }
        },
        checkIsle(value, index) {
            this.rack_list[index]       = [];
            this.forms[index].rack      = null;
            this.forms[index].rack_name = "";
            this.forms[index].rack_id   = null;

            this.forms[index].bin      = null;
            this.forms[index].bin_name = "";
            this.forms[index].bin_id   = null;
            if(!value) return null;
            if(_.isString(value)) {
                let val = _.find(this.isle_list, {isle_name: value});
                if(!_.isEmpty(val)) {
                    this.forms[index].isle      = {};
                    this.forms[index].isle      = val;
                    this.forms[index].isle_name = val.isle_name;
                    this.forms[index].isle_id   = val.isle_id;
                } else {
                    this.forms[index].isle_id   = null;
                    this.forms[index].isle_name = value;
                }
            }
            if(!_.isEmpty(this.forms[index])) {
                this.forms.forEach((m) => {
                    if(m.isle_id) {
                        if(m.isle_id === this.forms[index].isle.isle_id && m.rack_id) {
                            this.rack_list[index].push({
                                                           rack_id  : m.rack_id,
                                                           rack_name: m.rack_name,
                                                       });
                        }
                    }
                });
            }
        },
        async deleteRow(index) {
            let confirmation = false;
            confirmation     = await this.$root.confirmation({
                                                                 text: "Are you sure you want to delete this item?",
                                                             });
            if(confirmation) {
                let value = this.forms[index];
                if(
                    (_.has(value, "isle_id") && value["isle_id"]) ||
                    (_.has(value, "picking_id") && value["picking_id"])
                ) {
                    axios
                        .delete("/api/inventory/warehouse-config/" + this.warehouse.id, {
                            params: {
                                value: this.forms[index],
                            },
                        })
                        .then((res) => {
                            this.getWarehouse();
                            this.loading = true;
                            Swal.fire({
                                          icon             : res.data.type,
                                          text             : res.data.message,
                                          timer            : 3000,
                                          showCancelButton : false,
                                          showConfirmButton: false,
                                      });
                        })
                        .catch(err => {
                            if(err.response.status == 406) {
                                Swal.fire({
                                              icon             : err.response.data.type,
                                              text             : err.response.data.message,
                                              timer            : 4000,
                                              showCancelButton : false,
                                              showConfirmButton: false,
                                          });
                            }
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                } else {
                    this.$delete(this.forms, index);
                }
            } else {
                confirmation = false;
            }
        },
        postWarehouse() {
            this.errors  = {};
            this.loading = true;
            // return null;
            axios
                .patch("/api/inventory/warehouse-config/" + this.warehouse_id, {
                    data: this.forms,
                })
                .then((res) => {
                    Swal.fire({
                                  icon : "success",
                                  text : res.data.message,
                                  timer: 3000,
                              }).then((result) => {
                        this.loading = false;
                        this.getWarehouse();
                    });
                })
                .catch((err) => {
                    this.errors  = _.forOwn(err.response.data.errors, function (value) {
                        value[0] = capitalizeAndReplace(value[0]);

                        function capitalizeAndReplace(str) {
                            return str
                                .replace(/the data\.\d+\./gi, "")
                                .replace(/\b\w/g, (c) => c.toUpperCase());
                        }
                    });
                    this.loading = false;
                });
        },

        formatForm() {
            this.forms.map((f) => {
                this.model_list.forEach((m) => {
                    if(_.isObject(f[m])) {
                        f[`${m}_id`]   = f[m][`${m}_id`];
                        f[`${m}_name`] = f[m][`${m}_name`];
                    }
                    if(_.isString(f[m])) {
                        f[`${m}_id`]   = null;
                        f[`${m}_name`] = f[m];
                    }
                });
            });
        },
        addLocation() {
            if(!this.locationType) return null;
            let obj;
            if(this.locationType === "picking") {
                obj = {
                    warehouse_id    : this.warehouse.id,
                    warehouse_name  : this.warehouse.name,
                    picking_type    : "mobile",
                    picking_id      : null,
                    picking_location: "",
                };
            } else {
                obj = {
                    warehouse_id  : this.warehouse.id,
                    warehouse_name: this.warehouse.name,
                    storage_type  : "storage",
                    isle_id       : null,
                    isle_name     : "",
                    rack_id       : null,
                    rack_name     : "",
                    bin_id        : null,
                    bin_name      : "",
                    is_default    : false
                };
            }
            if(!_.isEmpty(obj)) {
                this.forms.push(obj);
                this.$nextTick(() => {
                    let lastIndex = _.lastIndexOf(this.forms)
                    lastIndex     = lastIndex - 1
                    this.$refs[`location-i-${lastIndex}`][0].$el.querySelector('input').focus()
                })
            }
        },
        getWarehouse() {
            this.loading   = true;
            this.isle_list = [];
            axios
                .get("/api/inventory/warehouse-config/" + this.warehouse_id)
                .then((res) => {
                    this.warehouse = res.data.warehouse;
                    res.data.query.map((v) => {
                        if(_.has(v, "isle_id") && v["isle_id"]) {
                            let obj = {
                                isle_id  : v.isle_id,
                                isle_name: v.isle_name,
                            };
                            this.model_list.forEach((m) => {
                                v[m]              = {};
                                v[m][`${m}_id`]   = v[`${m}_id`];
                                v[m][`${m}_name`] = v[`${m}_name`];
                            });
                            if(!_.isEmpty(obj)) {
                                this.isle_list.push(obj);
                            }
                        }
                    });
                    this.isle_list = _.uniqWith(this.isle_list, _.isEqual);
                    this.forms     = res.data.query;
                    this.loading   = false;
                });
        },
    },
};