module.exports = [
    {
        path:'/inventory/raw-material/index',
        name:'raw-material.index',
        component: require('../views/inventory/product/composite/raw-material/index.vue').default
    },
    {
        path:'/inventory/products-composite/create',
        name:'product.composite.create',
        component: require('../views/inventory/product/composite/create.vue').default
    },
    {
        path:'/inventory/products-composite-build/index',
        name:'product.composite.event',
        component: require('../views/inventory/product/composite/event.vue').default
    },
    {
        path:'/inventory/product-composite-unbox/index',
        name:'product.composite.unboxevent',
        component: require('../views/inventory/product/composite/unboxevent.vue').default
    },
    {
        path:'/inventory/products-composite/index',
        name:'product.composite.index',
        component: require('../views/inventory/product/composite/index.vue').default
    },
    {
        path:'/inventory/products-composite/:id',
        name:'product.composite.show',
        component: require('../views/inventory/product/composite/show.vue').default
    },
    {
        path:'/inventory/products-composite/:id/edit',
        name:'product.composite.edit',
        component: require('../views/inventory/product/composite/edit.vue').default
    },
]