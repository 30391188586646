module.exports = [
    {
        path: '/home',
        name: 'home.index',
        component: require('../views/home.vue').default
    },
    {
        path: '*',
        name: '404',
        component: require('../views/404.vue').default
    }, {
        path: '/401',
        name: '401',
        component: require('../views/401.vue').default
    },
    {
        path: '/419',
        name: '419',
        component: require('../views/419.vue').default
    },
    {
        path:'/component-test',
        name:'component.test',
        component: require('../views/component-test.vue').default
    }
];