<template>
    <div class="content-area">
        <div class="formTitle d-flex justify-content-between flex-wrap align-items-center">
            <div>
                Manage Order Table - <Icon type="ios-list-outline"></Icon>
            </div>
        </div>
        <div class="my-table-add row">
            <div class="col-1">
                <input type="text"
                       v-model="table.name"
                       @keyup.enter="table.id?update(table.id):add()"
                       class="input-item"
                       placeholder="Table name"
                       ref="iminput">
            </div>

            <div class="col-1">
                <button @click="table.id?update(table.id):add()"
                        class="info s my-btn" v-if="buttonShow">
                    {{table.id?'update':'add'}}
                </button>
            </div>

        </div>
        <div class="query-area">

            <div class="row">
                <div class="col-1">
                    <input type="text" class="input-item" placeholder="Search by name.." v-model="name"/>
                </div>
            </div>
        </div>
         <div>
                <table class="table ivueTable">
                    <thead>
                    <tr>
                        <td>Name</td>
                        <td>Action</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(table, index) in tables">
                        <td>
                            {{table.name}}
                        </td>
                        <td>
                            <button class="info s" @click="edit(index)">edit</button>
                            <button class="danger s" @click="del(index)">delete</button>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <pagination class="float-right"
                            :data="laravelData"
                            :limit="10"
                            v-on:pagination-change-page="index()">
                </pagination>
            </div>
        </div>
</template>

<style lang="scss">
    .my-table-add{
        display: flex;
        .col-1{
            padding: 0!important;
            width:100%;
            @media (max-width:425px){
                text-align: right;
            }
            .my-btn{
                padding: 3px 10px!important;
                margin: 0 8px!important;
                text-transform: uppercase;
                height:100%;
                @media (max-width:425px){
                    margin: 8px 0!important;
                    height:26px;
                }
            }
        }

    }
</style>

<script>
    export default {
        name:'salesTable',
        data(){
            return{
                name: '',
                tables:[],
                table:{id:'',name:''},
                buttonShow:true,
                laravelData: {},
            }
        },
        created() {
            this.index();

        },
        computed: {
            queryString() {
                return '&name=' + this.name
            }
        },
        methods:{
            index(){
                axios.get('/api/inventory/table')
                    .then(res=>{
                        this.laravelData = res.data
                        this.tables = this.laravelData.data
                    })
            },
            add(){
                this.buttonShow=false
                axios.post('/api/inventory/table',this.table)
                    .then(res=>{
                        this.tables.push({
                            id:res.data.id,
                            name:res.data.name
                        })
                        this.table={}
                        this.buttonShow=true
                    })
            },
            edit(index){
                this.table.id=this.tables[index]['id']
                this.table.name = this.tables[index]['name']
                this.tables.splice(index, 1)
                this.$refs.iminput.focus()
            },
            sortBy(key) {
                this.sortKeyData = key
                this.sortOrderData = !this.sortOrderData
            },
            update(id){
                this.buttonShow=false
                axios.patch('/api/inventory/table/'+this.table.id, this.table)
                    .then(res=>{
                        this.tables.push({
                            id:res.data.id,
                            name:res.data.name
                        })
                        this.table={}
                        this.buttonShow=true
                    })
            },
            del(index){
                axios.delete('/api/inventory/table/' + this.tables[index]['id'])
                    .then(res=>{
                        if(res.data){
                            this.tables.splice(index, 1)
                        }
                    })

            }
        }
    };
</script>
