export default {
    data() {
        return {
            units         : [],
            products      : [],
            unitList      : [],
            loading       : false,
            laravelData   : {},
            searchProduct : null,
            items         : {},
            loadingProduct: false,
            forms         : {
                unitList: [],
                errors  : {}
            },
            options       : {
                itemsPerPage: this.$store.state.itemsPerPage,
            },
            headers       : [
                {text: 'name', value: 'name', sortable: false},
                {text: 'unit name', value: 'unit_name', sortable: false},
                {text: 'unit weight', value: 'unit_weight', sortable: false},
            ],
        }
    },
    watch  : {
        'forms.product'(val) {
            console.log(val)
            if(!_.isEmpty(val)) {
                this.forms.product_id = val.id
                this.forms.unitList   = []
                console.log(val, val.units)
                if(val.units && _.isEmpty(val.units)) return null
                val.units.forEach(element => {
                    this.forms.unitList.push({
                                                 id         : element.id,
                                                 key        : element.key,
                                                 weight     : element.pivot !== undefined ? element.pivot.weight : null,
                                                 conversions: _.find(this.units, {id: element.id}).conversions
                                             })
                });
            }
        },
        options: {
            deep: true,
            handler(val) {
                this.getListData()
            }
        },
        searchProduct(val) {
            if(val !== '') {
                this.loadingProduct = true
                setTimeout(() => {
                    axios.post('/api/inventory/products/getProducts', {val: val})
                         .then(response => {
                             this.products       = response.data.products
                             this.loadingProduct = false
                         })
                         .catch(error => {
                             alert(error.message)
                         })
                }, 200);
            } else {
                this.loading  = true;
                this.products = []
                return null
            }
        }
    },
    created() {
        this.getListData()
        let url = '/api/inventory/productunit/create'
        this.$root.$data.erp.request.get(url, this, (data) => {
            this.units    = data.units
            this.products = data.products
        })
    },
    methods: {
        checkCategory() {
            if(this.forms.product) {
                if(this.forms.product.manufacture_part_number) {
                    if(this.forms.unitList.length > 1) {
                        Swal.fire({
                                      type: 'warning',
                                      icon: 'warning',
                                      text: 'you can not add more than one unit'
                                  })
                            .then((result) => {
                                this.forms.unitList.pop()
                            })
                    }
                }
            }
        },
        getListData() {
            this.loading = true
            axios.get('/api/inventory/product-unit/getProductUnits', {
                params: this.options
            }).then(res => {
                this.items   = res.data
                this.loading = false
            })
        },
        postProductUnit() {
            this.forms.errors = {}
            axios
                .post('/api/inventory/productunit', this.forms)
                .then(res => {
                    Swal.fire({
                                  icon : res.data.type,
                                  timer: 3000,
                                  text : res.data.message
                              })
                        .then((result) => {
                            this.getListData()
                        })
                })
                .catch(error=>{
                    this.forms.errors = error.response.data.errors
                })
        }
    }
}