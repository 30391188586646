module.exports = [
    {
        path: '/bank/index',
        name: 'bank.index',
        component: require('../views/bank/index.vue').default
    },
    {
        path: '/bank/ledger',
        name: 'bank.ledger',
        component: require('../views/bank/ledger.vue').default
    },
    {
        path: '/bank/transaction',
        name: 'bank.transaction',
        component: require('../views/bank/transaction.vue').default
    },
    {
        path: '/bank/reconcile',
        name:'bank.reconcile',
        component: require('../views/bank/reconcile.vue').default
    },
]