import _ from "lodash";

export default {
    data() {
        return {
            total  : 0.0,
            items  : {},
            loading: false,
            options: {
                itemsPerPage: this.$store.state.itemsPerPage,
            },
            headers: [
                {text: 'ref', value: 'ref', sortable: false},
                {text: 'category', value: 'category.name', sortable: false},
                {text: 'warehouse', value: 'warehouse.name', sortable: false},
                {text: 'amount', value: 'amount', sortable: false},
                {text: 'note', value: 'note', sortable: false},
                {text: 'create at', value: 'created_at', sortable: false},
            ],
        }
    },

    watch  : {
        options: {
            deep: true,
            handler() {
                this.loading = true
                this.getData()
            }
        }
    },
    created() {
        axios.get('/api/expense/total-sum')
             .then(res => {
                 this.total = res.data
             })
    },
    methods: {
        deleteItem(id) {
            if(!confirm("are you sure?")) {
                return false;
            }
            this.loading = true
            axios.delete('/api/expense/expenses/' + id)
                 .then(res => {
                     this.getData()
                     this.loading = false
                     Swal.fire({
                                   icon             : res.data.type,
                                   timer            : 3000,
                                   text             : res.data.message,
                                   showCancelButton : false,
                                   showConfirmButton: false
                               })
                 })
        },
        getData: _.debounce(function () {
            this.loading = true
            axios.get('/api/expense/expenses', {
                params: this.options
            })
                 .then(res => {
                     this.items   = res.data.expenses
                     this.loading = false
                 })
        }, 800),
        getExpenseList(query, callback) {
            axios.get('/api/expense/expenses?dropdown=true').then(response => {
                this.expenses = response.data;
                callback(query);
            }).catch(error => {

            })
        },

    }
}