window._               = require('lodash')
window.chart           = require('chart.js')
window.Swal            = require('sweetalert2')
window.Vue             = require('vue')
window.axios           = require('axios')
window.FileSaver       = require('file-saver')
window.moment          = require('moment')

window.api_base_url    = '/api/inventory' //never use this open.
window.api_payroll_url = '/api/payroll'
window.api_accounts    = '/api/accounts'
window.api_bank        = '/api/bank'

window.api_customer    = api_base_url + '/customers'
window.api_supplier    = api_base_url + '/suppliers'
window.api_otheruser   = api_base_url + '/otherusers'

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

let token = document.head.querySelector('meta[name="csrf-token"]');

if(token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

axios.interceptors.response.use((response) => {
    return response
}, error => {
    if(error.response.status === 403) {
        Swal.fire({
                      icon             : 'error',
                      timer            : 2000,
                      text             : 'you are not authorized',
                      showCancelButton : false,
                      showConfirmButton: false,
                  })
            .then((result) => {
                document.getElementById('logout-form').submit();
            })
    }
    // if (error.response.status === 500) {
    //     console.log(error.response)
    //     Swal.fire({
    //         icon: 'error',
    //         timer: 2000,
    //         text: 'you are not authorized',
    //         showCancelButton: false,
    //         showConfirmButton: false,
    //     })
    //         .then((result) => {
    //             Swal.fire({
    //                 icon: 'error',
    //                 timer: 2000,
    //                 text: 'you are not authorized',
    //                 showCancelButton: false,
    //                 showConfirmButton: false,
    //             })
    //         })
    // }
    if(error.response.status === 500) {
        Swal.fire({
                      icon             : 'error',
                      timer            : 8000,
                      text             : error.response.data.message,
                      showCancelButton : false,
                      showConfirmButton: false,
                  })
    }
    if(error.response.status === 401 || error.response.status === 419) {
        Swal.fire({
                      icon             : 'error',
                      timer            : 2000,
                      text             : 'you are not authorized',
                      showCancelButton : false,
                      showConfirmButton: false,
                  })
            .then((result) => {
                window.location.href = '/'
            })
    }
    return Promise.reject(error)
})