import PaymentReceivedExport from './payment-received-export.vue'

export default {
    name      : "payment-received",
    components: {
        PaymentReceivedExport
    },
    data() {
        return {
            paymentReceivedExportDialog: false,
            payment_id                 : null,
            payment_dialog             : false,
            method                     : 'create',
            items                      : {},
            menu                       : false,
            loading                    : false,
            options                    : {
                sortBy      : ['date'],
                sortDesc    : [true],
                itemsPerPage: this.$store.state.itemsPerPage,
            },
            headers                    : [
                {text: 'ref', value: 'sale_ref'},
                {text: 'customer name', value: 'customer_name'},
                {text: 'amount', value: 'paid'},
                {text: 'date', value: 'date'},
                {text: 'action', value: 'action', sortable: false},
            ],
            closeOnContentClick:false
        }
    },
    watch     : {
        payment_dialog(val) {
            if(!val) {
                this.resetPaymentComponent()
            }
        },
        options: {
            deep: true,
            handler() {
                this.loading = true
                this.getData()
            }
        }
    },
    methods   : {
        uploadFile() {
            let formData = new FormData()
            let file     = this.$refs.inputUpload.files[0]
            formData.append('file', file)
            axios.post('/api/inventory/payment-received-import', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                 .then(res => {
                     Swal.fire({
                                   icon: 'success',
                                   text: 'file has been uploaded successfully'
                               }).then((result) => {
                         this.getData()
                     })
                 })
                 .catch(error => {
                     Swal.fire({
                                   icon: 'error',
                                   html: 'status ' + error.response.status + '.<br>' + error.response.statusText,
                               })
                 })
        },
        closeDialogExport(val) {
            if(val === 200) {
                this.paymentReceivedExportDialog = false
            }
        },
        resetPaymentComponent() {
            this.payment_id = null
            this.method     = 'create'
        },
        createPayment() {
            this.resetPaymentComponent()
            this.payment_dialog = true
        },
        editPayment(id) {
            this.payment_id     = id
            this.method         = 'edit'
            this.payment_dialog = true
        },
        paymentSuccess(val) {
            if(val) {
                this.getData()
                this.payment_dialog = false
            }
        },
        getData() {
            axios
                .get('/api/payments/received', {params: this.options})
                .then(res => {
                    this.items   = res.data
                    this.loading = false
                })
        }
    }
}