<template>
  <div id="sale_quotation_print">
    <h3>Quotation Ledger of ref: SQ-{{ quotations.id }}</h3>
    <div class="row">
      <div class="col-md-4">
        <p class="text-subtitle-1">Customer Information</p>
        <p>name: {{ quotations.customer.name }}</p>
        <p>email: {{ quotations.customer.email }}</p>
        <p v-if="quotations.customer.phone">phone: {{ quotations.customer.phone }}</p>
        <p v-if="quotations.customer.address">address: {{ quotations.customer.address }}</p>
      </div>
      <div class="col-md-4">
        <p class="text-subtitle-1">Shop Information</p>
        <p>Company name: {{ company.name }}</p>
        <p>Address Detail: {{ company.address1 }}</p>
        <p>
          mobile number:
          <span v-for="(phone, index) in company.contact_phone">
                            {{ phone }}{{ company.contact_phone.length - 1 == index ? '' : ',' }}
                        </span>
        </p>
      </div>
      <div class="col-md-4">
        <p class="text-subtitle-1">Quotations Information</p>
        <p>Quotation no: SQ-{{ quotations.quotation_no }}</p>
        <p>Quotations Date: {{ quotations.quotation_date }}</p>
        <p>created By: {{ quotations.created_by.name }}</p>
        <barcode
            height="35px"
            font-size="10px"
            :value="`${'SQ' +'-'+quotations.id}`"
        >
        </barcode>
      </div>
    </div>

    <div class="row">
      <v-col md="12" cols="12">
        <table class="table">
          <thead>
          <tr>
            <th>No.</th>
            <th>Code</th>
            <th>Name</th>
            <th>{{ $store.state.quantity_label }}</th>
            <th>Unit Cost</th>
            <th>Discount</th>
            <th>Total</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(product, index) in quotations.products" :index="index">
            <td>
              {{ index + 1 }}
            </td>
            <td>{{ product.code }}</td>
            <td>{{ product.name }}</td>
            <td>{{ product.quantityStr }}({{ parseFloat(product.pivot.quantity).toFixed(4) }})</td>
            <td>{{ product.pivot.price }}</td>
            <td>{{ product.pivot.discount }}</td>
            <td>{{ product.pivot.subtotal }}</td>
          </tr>
          </tbody>
          <tfoot>
          <tr class="strong">
            <td colspan="6" align="right">
              Product Total
            </td>
            <td colspan="2">
              {{ product_total }}
            </td>
          </tr>
          <tr class="strong">
            <td colspan="6" class="text-right">{{ $root.shippingLabel }}</td>
            <td>{{ quotations.shipping_cost }}</td>
          </tr>
          <tr>
            <td colspan="6" class="text-right">
              Sub Total
            </td>
            <td>
              {{ sub_total }}
            </td>
          </tr>

          <tr class="strong">
            <td colspan="6" class="text-right">Overall Discount</td>
            <td v-if="quotations.overall_discount">{{ quotations.overall_discount }}%({{ discounted_amount }})</td>
            <td v-else>0</td>
          </tr>

          <tr class="strong">
            <td colspan="6" class="text-right">Total</td>
            <td>{{ quotations.total }}</td>
          </tr>
          </tfoot>
        </table>
      </v-col>
      <v-col md="6" cols="12" v-if="quotations.note">
        Note: {{ quotations.note }}
      </v-col>
      <v-col md="6" cols="12"></v-col>
    </div>

  </div>
</template>
<script>
export default {
  props:['quotations', 'company'],
  computed  : {
    product_total() {
      if(!_.isEmpty(this.quotations) && !_.isEmpty(this.quotations.products)) {
        return this.quotations.products.reduce((acc, obj) => {
          return acc + obj.pivot.subtotal
        }, 0)
      }
      return 0
    },
    sub_total() {
      return this.product_total + this.quotations.shipping_cost
    },
    discounted_amount() {
      return (this.sub_total * (this.quotations.overall_discount / 100)).toFixed(2)
    }
  },
}
</script>