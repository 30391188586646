import _ from "lodash";
import ProductDamageCreate from 'i#/productdamage/create.vue'

export default {
    components: {
        ProductDamageCreate
    },
    data() {
        return {
            items               : {},
            loading             : false,
            options             : {
                sortBy      : ['created_at'],
                sortDesc    : [true],
                itemsPerPage: this.$store.state.itemsPerPage,
            },
            createOrUpdateDialog: false,
            edit_id             : null,
            headers             : [
                {text: 'product', value: 'product_name'},
                {text: 'quantity', value: 'quantity'},
                {text: 'unit', value: 'unit_name'},
                {text: 'sale value', value: 'sale_value'},
                {text: 'created at', value: 'created_at'},
                {text: 'action', value: 'action', sortable: false},
            ],
            closeOnContentClick:false
        }
    },
    watch     : {
        options: {
            deep: true,
            handler() {
                this.getDataWithLoading()
            }
        },
        createOrUpdateDialog(val) {
            if(!val) {
                this.getDataWithLoading()
                this.edit_id = null
            }
        }
    },
    methods   : {
        deleteItem(id) {
            this.loading = true
            this.$deleteWithConfirmation({
                                             text: 'Are you sure you want delete this warehouse?',
                                             url : '/api/inventory/productdamages/' + id
                                         })
                .then(data => {
                    this.getData()
                })
                .catch(error => {
                    this.loading = false
                })
        },
        getData: _.debounce(function () {
            axios.get('/api/inventory/productdamages', {
                params: this.options
            })
                 .then(res => {
                     this.items   = res.data
                     this.loading = false
                 })
        }, 400),

        getDataWithLoading() {
            this.loading = true
            this.getData()
        }

    }
}