export default {

    data() {
        return {
            forms           : this.$root.$data.forms,
            purchaseSumField: {},
            number          : '',
            salesItem       : {
                value   : '',
                operator: ''
            },
            errors          : []
        }
    },

    computed: {
        dontMount() {
            if(this.forms) {
                if(this.forms.settings) {
                    if(this.forms.settings.site_name) {
                        return true;
                    }
                }
            }
        }
    },

    created() {
        axios.get('/settings').then(response => {
            let settings      = {}
            settings          = response.data.settings
            let companyDetail = response.data.company_detail
            let user          = response.data.user
            if(settings && !settings.settings.inventory.hasOwnProperty('sales')) {
                settings.settings.inventory.sales = {}
                if(!settings.settings.inventory.sales.hasOwnProperty('others')) {
                    settings.settings.inventory.sales.others = []
                }
            }
            if(settings && !settings.settings.inventory.hasOwnProperty('purchase')) {
                settings.settings.inventory.purchase = {}
                if(!settings.settings.inventory.purchase.hasOwnProperty('sum_fields')) {
                    settings.settings.inventory.purchase.sum_fields = []
                }
            }
            if(settings) {
                if(Object.keys(settings).length !== 0) {
                    this.forms = Object.assign({}, companyDetail, settings)
                }
            } else {
                this.forms = Object.assign({}, companyDetail,
                                           {
                                               'settings'     : {
                                                   'site_name'    : 'test site',
                                                   'date_format'  : 'YYYY-MM-DD',
                                                   'currency'     : '$',
                                                   'default_email': user.email,
                                                   'inventory'    : {
                                                       'account_method'     : 'avg',
                                                       'stock_out_sale'     : false,
                                                       'profit_percent'     : false,
                                                       'table_order'        : false,
                                                       'shipping_cost_label': 'shipping cost',
                                                       'quantity_label'     : 'quantity',
                                                       'measurement'        : false,
                                                       'weight_unit'        : 'gram',
                                                       'itemsPerPage'       : 15,
                                                       'sales'              : {
                                                           'others': []
                                                       },
                                                       'purchase'           : {
                                                           'sum_fields'          : [],
                                                           'default_payment_mood': 1
                                                       }
                                                   }
                                               },
                                               'contact_phone': [
                                                   '123123'
                                               ]
                                           }
                )
            }

        })
    },

    methods: {
        addPurchaseSumField() {
            this.forms.settings.inventory.purchase.sum_fields.push({label: '', value: 0.0, active: false})
        },
        deletePurchaseSumField(index) {
            this.forms.settings.inventory.purchase.sum_fields.splice(index, 1)
        },
        addSalesOther(values) {
            if(values.value && values.operator) {
                if(this.forms.settings.inventory.sales.others.length < 1) {
                    this.forms.settings.inventory.sales.others.push({value: values.value, operator: values.operator})
                    this.salesItem.value    = ''
                    this.salesItem.operator = ''
                } else {
                    alert('you can not add more for now')
                }

            }
        },
        deleteNumber(index) {
            if(this.forms.contact_phone.length > 1) {
                this.forms.contact_phone.splice(index, 1)
            } else {
                alert('sorry, you must have one field')
            }
        },
        addNumber() {
            if(this.forms.contact_phone.length < 4) {
                this.forms.contact_phone.push(null)
            }
        },
        addSettings() {
            axios.post('/settings', this.forms).then(res => {
                if(res.status === 200) {
                    Swal.fire(
                        'success',
                        'settings has been updated, please login again'
                    ).then((result) => {
                        location.reload()
                    })
                }
            })
                 .catch(error => {
                     this.errors = []
                     this.errors = error.response.data.errors
                 })
        },
    }

}