import BillPaidExport from './bill-paid-export.vue'

export default {
    components: {
        BillPaidExport
    },
    data() {
        return {
            billPaidDialog: false,
            payment_id    : null,
            payment_dialog: false,
            method        : 'create',
            menu          : false,
            items         : {},
            loading       : false,
            options       : {
                sortBy      : ['date'],
                sortDesc    : [true],
                itemsPerPage: this.$store.state.itemsPerPage,
            },
            headers       : [
                {text: 'bill no', value: 'purchase_bill_no'},
                {text: 'supplier name', value: 'supplier_name'},
                {text: 'amount', value: 'paid'},
                {text: 'date', value: 'date'},
                {text: 'action', value: 'action', sortable: false},
            ],
            closeOnContentClick:false
        }
    },
    watch     : {
        options: {
            deep: true,
            handler() {
                this.loading = true
                this.getData()
            }
        },
        payment_dialog(val) {
            if(!val) {
                this.resetPaymentComponent()
            }
        }
    },
    methods   : {
        uploadFile() {
            let formData = new FormData()
            let file     = this.$refs.inputUpload.files[0]
            let importHeaders = [
                'bill no',
                'paid',
                'date',
                'payment method',
                'bank name',
                'bank account no',
                'note'
            ];
            formData.append('file', file)
            axios.post('/api/inventory/bill-paid-import', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                 .then(res => {
                     Swal.fire({
                                   icon: 'success',
                                   text: 'file has been uploaded successfully'
                               }).then((result) => {
                         this.getData()
                     })
                 })
                 .catch(error => {
                     let errorArr = []
                     if(error.response.status === 422) {
                         // console.log(error.response.data.errors, error.response.status)
                         Object.keys(error.response.data.errors).forEach((e, i) => {
                             let row        = Number(e.split('.')[0]) + 1
                             let column     = Number(e.split('.')[1])
                             let listNumber = i + 1
                             let html       = '<p>' + listNumber + '. Incorrect entry at row: ' + row + ', column: ' + importHeaders[column] + '.</p>'
                             errorArr.push(html)
                         })
                         // console.log(errorArr, errorArr.toString())
                         Swal.fire({
                                       icon: 'error',
                                       html: errorArr.join("")
                                   })
                     } else {
                         Swal.fire({
                                       icon: 'error',
                                       html: 'status ' + error.response.status + '.<br>' + error.response.statusText,
                                   })
                     }
                 })
        },
        closeDialogExport(val) {
            if(val === 200) {
                this.billPaidDialog = false
            }
        },
        resetPaymentComponent() {
            this.model      = 'purchase'
            this.payment_id = null
            this.method     = 'create'
        },
        createBill() {
            this.resetPaymentComponent()
            this.payment_dialog = true
        },
        editBill(id) {
            this.model          = 'purchase'
            this.payment_id     = id
            this.method         = 'edit'
            this.payment_dialog = true
        },
        getData() {
            axios
                .get('/api/payments/bill-paid', {params: this.options})
                .then(res => {
                    this.items   = res.data
                    this.loading = false
                })
        },
        paymentSuccess(val) {
            if(val) {
                this.getData()
                this.payment_dialog = false
            }
        }
    }
}