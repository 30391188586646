<template>
  <div>
    <line-chart :chart-data="datacollection" v-if="loaded" :height="110"></line-chart>
  </div>
</template>

<script>
import LineChart from './LineChart.js'

export default {
  components: {
    LineChart
  },
  data() {
    return {
      loaded        : false,
      datacollection: {
        labels  : [],
        datasets: []
      }
    }
  },
  async mounted() {
    this.loaded = false
    try {
      let dataset1  = {
        data           : [],
        label          : 'running balance',
        backgroundColor: 'transparent',
        borderColor    : 'rgba(248,121,121,0.64)'
      }
      let dataset2  = {
        data           : [],
        label          : 'total debit',
        backgroundColor: 'transparent',
        borderColor    : 'rgba(64,85,248,0.73)'
      }
      let dataset3  = {
        data           : [],
        label          : 'total credit',
        backgroundColor: 'transparent',
        borderColor    : 'rgba(0,248,47,0.73)'
      }
      const data    = await axios.get('/api/charts/banks')
      let labels    = data.data.created_at.reverse()
      dataset1.data = data.data.running_balance.reverse()
      dataset2.data = data.data.total_debit.reverse()
      dataset3.data = data.data.total_credit.reverse()

      this.datacollection.labels = labels
      this.datacollection.datasets.push(dataset1)
      this.datacollection.datasets.push(dataset2)
      this.datacollection.datasets.push(dataset3)
      this.loaded = true

    } catch (e) {

    }
  },
}
</script>