export class Request {

    get(url, apps, func) {

        return axios.get(url)
            .then(response => {
                return response.data
            }).then(data => {
                func(data)
            }).catch(error => {
                if (error.response) {
                    if (error.response.status === 401) {
                        window.location = '/'
                    } else if (error.response.status === 403) {
                        Swal.fire({
                            'icon':'error',
                            'text':error.response.data.message,
                            timer:3000
                        })
                            .then((result) => {
                                document.getElementById('logout-form').submit();
                            })

                    } else {
                        Swal.fire(
                            error.response.data.message
                        )
                    }
                }

            })
    }
}