import _ from 'lodash'
import FormActions from '~/form-actions.vue'

export default {
    components: {
        FormActions
    },
    props     : {
        modelId: {
            type   : Number,
            default: null
        },
        value  : {
            type   : Boolean,
            default: false
        },
    },
    data() {
        return {
            url           : this.$getUrl('warranty', this.value, this.modelId),
            axiosMethod   : this.$getAxiosMethod(this.value, this.modelId),
            forms         : this.$root.$data.forms,
            menu1         : false,
            loading       : false,
            products      : [],
            warranty      : [],
            customers     : [],
            computedDate  : null,
            searchProduct : null,
            loadingMessage: false,

            statuses:
                [
                    'Receive from Customer',
                    'Send to Supplier',
                    'Receive from Supplier',
                    'Delivered to Customer',
                    'Damaged'
                ],
        }
    },
    beforeCreate() {
        this.$root.$data.forms.fieldReset();
    },
    watch     : {
        '$route.fullPath'(val) {
            //reset data
            this.forms.reset();
            this.warranty = []
        },
        searchProduct(val) {
            this.getProducts(val)
        }
    },
    created() {
        this.forms.warranty_date = moment(new Date()).format('YYYY-MM-DD')
        this.computedDate        = moment(this.forms.warranty_date, "YYYY-MM-DD").format(this.$root.settings.settings.date_format)
        this.getData()

    },

    methods: {
        getData() {
            this.loading = true
            this.$root.$data.erp.request.get(this.url, this, (data) => {
                this.customers = data.customers;
                this.products  = data.products;
                if(this.axiosMethod === 'patch') {
                    this.warranty            = data.warranty
                    this.forms.id            = this.warranty.id
                    this.forms.product_id    = this.warranty.product.id
                    this.forms.customer_id   = this.warranty.customer.id
                    this.forms.quantity      = this.warranty.quantity
                    this.forms.status        = this.warranty.status
                    this.forms.note          = this.warranty.note
                    this.forms.warranty_date = moment(this.warranty.warranty_date, this.$root.settings.settings.date_format).format("YYYY-MM-DD")
                    this.computedDate        = moment(this.forms.warranty_date, "YYYY-MM-DD").format(this.$root.settings.settings.date_format)
                }
                this.loading = false
            });
        },
        postWarranty($e) {
            let submitter = $e.submitter.value
            // return null
            this.loading  = true
            this.forms.submit(
                this.axiosMethod,
                this.$getUrlForm(this.url),
                true,
                this.$root
            )
                .then(data => {
                    this.loading = false
                    if(this.value) {
                        if(submitter === 'submit-close' || submitter === 'update-close') {
                            this.$emit('input', false)
                        }
                    }
                    Swal.fire({
                                  icon             : data.type,
                                  timer            : 2000,
                                  text             : data.message,
                                  showCancelButton : false,
                                  showConfirmButton: false,
                              })
                        .then((result) => {
                            this.forms.warranty_date = moment(new Date()).format('YYYY-MM-DD')
                            this.computedDate        = moment(this.forms.warranty_date, "YYYY-MM-DD").format(this.$root.settings.settings.date_format)
                            this.loading             = false
                        })
                })
                .catch(error => {
                    this.loading = false
                });

        },
        onCancel() {
            this.forms.reset();
        },
        getProducts(query) {
            if(query !== '') {
                setTimeout(() => {
                    axios.post('/api/inventory/products/getProducts', {val: query, isPurchase: true})
                         .then(response => {
                             this.products       = response.data.products
                             this.loadingMessage = false;
                         })
                         .catch(error => {
                             alert(error.message)
                         })
                }, 200);
            } else {
                this.loadingMessage = true;
                this.products       = []
                return null
            }
        },
        closeDatePicker() {
            let currentTime          = moment(new Date()).format("HH:mm:ss")
            let warranty_date        = this.forms.warranty_date
            let selectedDate         = moment(warranty_date).format(this.$root.settings.settings.date_format)
            // console.log(selectedDate + ' ' + currentTime)
            this.computedDate        = moment(selectedDate + ' ' + currentTime, this.$root.settings.settings.date_format).format(this.$root.settings.settings.date_format)
            this.forms.warranty_date = moment(this.forms.warranty_date).format("YYYY-MM-DD")
            this.menu1               = false
        }
    }
}