<template>
  <v-card outlined>
    <v-card-title>
      Customer Ledger
      <v-spacer/>
      <user-dropdown-autocomplete
          dense
          returnObject
          module-type="customer"
          v-model="options.customer"
          :columns="['name', 'email']"
      />
      <v-btn @click="$router.back()" icon>
        <v-icon>{{ $root.appIcons.return }}</v-icon>
      </v-btn>
      <v-btn icon @click="$htmlToPaper('list_print_bootstrap')">
        <v-icon>mdi-printer</v-icon>
      </v-btn>
    </v-card-title>
    <v-col cols="12" md="12" id="list_print_div">
      <v-data-table
          dense
          :headers="headers"
          :loading="loading"
          :items="items.data"
          class="elevation-0"
          :options.sync="options"
          :server-items-length="items.total"
          loading-text="Loading... Please wait"
          :footer-props="{itemsPerPageOptions: $store.state.itemsPerPageOptions}"
          item-key="item_id"
      >
        <template v-slot:top>
          <template v-if="options.date">
            <template v-if="options.date.length<2">
              <v-alert
                  elevation="2"
                  border="bottom"
                  colored-border type="warning"
              >
                Please select dates
              </v-alert>
            </template>
          </template>
          <template v-if="!options.date">
            <v-alert
                elevation="2"
                border="bottom"
                colored-border type="warning"
            >
              Please select dates
            </v-alert>
          </template>
        </template>
        <template v-slot:header.date="{ header }">
          <v-menu
              offset-y
              v-model="menu"
              min-width="290px"
              :nudge-right="40"
              transition="scale-transition"
              :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  solo
                  dense
                  readonly
                  clearable
                  v-on="on"
                  hide-details
                  label="Dates"
                  v-bind="attrs"
                  v-model="options.date"
              >
              </v-text-field>
            </template>
            <v-date-picker
                range
                v-model="options.date"
                @input="options.date.length>1?menu = false:null"
            >
            </v-date-picker>
          </v-menu>
        </template>


        <template v-slot:item.date="{item}">
          <template v-if="item.sales_date_formatted">
            {{ item.sales_date_formatted }}
          </template>
          <template v-else>
            {{ item.date_without_time }}
          </template>
        </template>

      </v-data-table>
    </v-col>
    <list-print-bootstrap
        :title="[
            `Customer Ledger ${options.customer? ' - '+options.customer.name:''}`,
            `From ${options.date[0]} To ${options.date[1]}`
        ]"
        :columns="headers"
        :rows="items.data"
        style="visibility: collapse"
        :date_fields="['created_at']"
        v-if="options.user && (options.date && options.date.length>1)"
    />
  </v-card>
</template>
<script>


export default {
  name      : "customer-ledger",
  data() {
    return {
      items      : {},
      user       : null,
      menu       : false,
      loading    : false,
      queryString: {},
      options    : {
        itemsPerPage: this.$store.state.itemsPerPage,
      },
      headers    : [
        {text: 'date', value: 'date', sortable: false},
        {text: 'description', value: 'description', sortable: false},
        {text: 'debit', value: 'debit', sortable: false},
        {text: 'credit', value: 'credit', sortable: false},
        {text: 'balance', value: 'balance', sortable: false},
      ],
    }
  },
  watch     : {
    options: {
      deep: true,
      handler(val) {
        this.options.user = !_.isEmpty(val.customer) ? val.customer.id : null;
        if((this.options.date && this.options.date.length > 1) && this.options.user) {
          this.loading = true
          this.getResults()
        }
      }
    },

  },
  methods   : {
    generateDescription(val) {
      let txt = ''
      if(val.sales_date_formatted && val.sale_id) {
        txt = 'A sale(ref no: '
            + val.ref +
            ') has been made to ' +
            this.options.customer.name
      }
      if(!val.sale_id && val.ledger_type === 'debit') {
        txt = 'A bill has been received from '
            + this.options.customer.name
      }
      if(!val.sale_id && val.ledger_type === 'credit') {
        txt = 'A deposit/advance has been taken from ' + this.options.customer.name
      }
      return txt
    },
    getResults() {
      let url = api_accounts + '/customer-ledger'
      axios.get(url, {params: this.options})
           .then(res => {
             this.items = res.data
             if(this.items.data.length) {
               this.items.data.map((v, i) => {
                 v.date        = v.sales_date_formatted ? v.sales_date_formatted : v.date_without_time
                 v.credit      = v.ledger_type === 'credit' ? v.value : null
                 v.debit       = v.ledger_type === 'debit' ? v.value : null
                 v.description = this.generateDescription(v)
               })
             }
           })
           .catch()
           .finally(r => {
             this.loading = false
           })
    }
  }
}
</script>
