import barcodePrint from '../../../utility/barcode.vue'

export default {
    components: {barcodePrint},
    props     : {
        form_id: null
    },
    data() {
        return {
            buttonName:'new',
            modal1      : false,
            message     : '',
            category_ids: [],
            forms       : this.$root.$data.forms,
            brands      : [],
            units       : [],
            products    : [],
            method      : '',
            categories  : [],
            loading     : false
        }
    },

    beforeCreate() {
        this.$root.$data.forms.fieldReset();
    },

    watch: {
        // 'forms.base_unit_id'(val){
        //     this.forms.weight.unit_id = val
        //     this.forms.weight.value = 0
        // },
        '$route.fullPath'(val) {
            //reset data
            this.forms.reset();
            this.products = []
            this.method   = 'Create'
        }
    },

    created() {
        // this.forms.weight=[
        //     {unit_id:null, value:null}
        // ]
        this.forms.reset()
        this.forms.id = this.form_id
        this.loading  = true
        let url = '/api/inventory/products/create?dropdown=true&withJsonFormat=true';
        if(this.form_id) {
            url = '/api/inventory/products/' + this.forms.id + '/edit'
        }
        this.loading = true
        this.$root.$data.erp.request.get(url, this, (data) => {
            this.categories = data.categories
            this.brands     = data.brands
            this.units      = data.units
            if(this.forms.id) {
                this.products    = data.products
                this.forms.id    = this.products.id
                this.forms.brand = this.products.brands.id
                this.forms.name  = this.products.name
                for (var i = 0; i < this.products.categories.length; i++) {
                    let category = this.products.categories[i]
                    this.category_ids.push(category.id)
                }
                this.forms.categories   = this.category_ids
                this.forms.code         = this.products.code
                this.forms.slug         = this.products.slug
                this.forms.buying_price = this.products.buying_price
                this.forms.price        = this.products.price
                this.forms.base_unit_id = parseInt(this.products.units[0].id)
                this.forms.weight       = this.products.units[0].pivot.weight
                this.forms.measurement  = this.products.measurement
                this.forms.note         = this.products.note
                this.forms.manufacture_part_number = this.products.manufacture_part_number
            }
            this.loading = false
        })
    },
    methods: {
        generateBarcode() {
            this.$store.commit('barcodeValue', this.forms.code)
            this.$router.push({name: 'utility.barcode'})
        },
        postProduct(event) {
            this.buttonName = event.submitter.value
            this.loading      = true
            let requestMethod = 'post'
            let url           = '/api/inventory/products'
            if(this.forms.id) {
                requestMethod = 'patch'
                url           = '/api/inventory/products/' + this.forms.id
            }
            this.forms.submit(requestMethod, url, true, this.$root)
                .then(data => {
                    this.loading = false
                    Swal.fire({
                                  icon             : data.type,
                                  timer            : 3000,
                                  text             : data.message,
                                  showCancelButton : false,
                                  showConfirmButton: false
                              })
                    this.$emit('createProduct', {
                        id        : this.forms.id,
                        buttonName: this.buttonName
                    })
                })
                .catch(err => {
                    this.loading = false
                })
        },
        onCancel() {
            this.forms.reset()
        },
        categoryIds(cat_ids) {
            this.category_ids = cat_ids
        },
    }
}