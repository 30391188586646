import _ from 'lodash'
import FormActions from '~/form-actions.vue'
export default {
    components: {
        FormActions
    },
    props     : {
        modelId: {
            type   : Number,
            default: null
        },
        value  : {
            type   : Boolean,
            default: false
        },
    },
    data() {
        return {
            modal              : false,
            menu2              : false,
            menu3              : false,
            url        : this.$getUrl('employee', this.value, this.modelId, 'payroll'),
            axiosMethod: this.$getAxiosMethod(this.value, this.modelId),
            forms              : this.$root.$data.forms,
            loading            : false,
            employee           : [],
            departments        : [],
            computedBirth      : null,
            computedJoiningDate: null,
            imageRules         : [
                value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
            ],
        }
    },
    beforeCreate() {
        this.$root.$data.forms.fieldReset();
    },
    watch   : {
        '$route.fullPath'(val) {
            //reset data
            this.forms.reset();
            this.employee = []
        }
    },

    created() {
        this.forms.birth   = moment(new Date(), "YYYY-MM-DD").format('YYYY-MM-DD')
        this.computedBirth = moment(this.forms.birth, "YYYY-MM-DD").format(this.$root.settings.settings.date_format)

        this.forms.join_date     = moment(new Date()).format('YYYY-MM-DD')
        this.computedJoiningDate = moment(this.forms.join_date, "YYYY-MM-DD").format(this.$root.settings.settings.date_format)
        this.getData()
    },
    methods: {
        getData(){
            this.loading = true
            this.$root.$data.erp.request.get(this.url, this, (data) => {
                this.departments = data.departments
                if(this.axiosMethod === 'patch') {
                    this.employee = data.employee
                    this.forms.id = this.employee.id
                    this.forms.department_id = this.employee.department_id
                    // this.forms.join_date = this.employee.join_date ? moment(new
                    // Date(this.employee.join_date)).format('YYYY-MM-DD') : ''

                    this.forms.join_date     = moment(this.employee.join_date, this.$root.settings.settings.date_format).format("YYYY-MM-DD")
                    this.computedJoiningDate = moment(this.forms.join_date, "YYYY-MM-DD").format(this.$root.settings.settings.date_format)

                    this.forms.employee_id   = this.employee.employee_id
                    this.forms.name          = this.employee.name
                    this.forms.designation   = this.employee.designation
                    this.forms.contract_type = this.employee.contract_type
                    this.forms.salary        = parseInt(this.employee.salary)
                    this.forms.mobile        = this.employee.mobile
                    // this.forms.birth         = this.employee.birth ? moment(new
                    // Date(this.employee.birth)).format('YYYY-MM-DD') : ''
                    this.forms.birth     = moment(this.employee.birth, this.$root.settings.settings.date_format).format("YYYY-MM-DD")
                    this.computedBirth   = moment(this.forms.birth, "YYYY-MM-DD").format(this.$root.settings.settings.date_format)
                    this.forms.nid       = this.employee.nid
                    this.forms.emergency = this.employee.emergency
                    this.forms.address   = this.employee.address
                    this.forms.note      = this.employee.note
                }
                this.loading = false
            });
        },
        postEmployee($e) {
            this.loading         = true
            this.forms.birth     = this.forms.birth ?
                moment(this.forms.birth).format("YYYY-MM-DD") : ''
            this.forms.join_date = this.forms.join_date ?
                moment(this.forms.join_date).format("YYYY-MM-DD") : ''
            let submitter = $e.submitter.value
            this.forms.submit(
                this.axiosMethod,
                this.$getUrlForm(this.url),
                true,
                this.$root)
                .then(data => {
                    this.loading = false
                    if(this.value) {
                        if(submitter === 'submit-close' || submitter === 'update-close') {
                            this.$emit('input', false)
                        }
                    }
                    this.$axiosPostDialog(data)
                }).catch(err => {
                this.loading = false
            })
        },
        onCancel() {
            this.forms.reset();
            if(this.method === "Edit") {
                this.$router.push({name: "payroll.employee.index"})
            }
        },
        closeDatePickerBirth() {
            let currentTime    = moment(new Date()).format("HH:mm:ss")
            let birth          = this.forms.birth
            let selectedDate   = moment(birth).format(this.$root.settings.settings.date_format)
            this.computedBirth = moment(selectedDate + ' ' + currentTime, this.$root.settings.settings.date_format).format(this.$root.settings.settings.date_format)
            this.forms.birth   = moment(this.forms.birth).format("YYYY-MM-DD")
            this.menu2         = false
        },
        closeDatePickerJoiningDate() {
            let currentTime          = moment(new Date()).format("HH:mm:ss")
            let join_date            = this.forms.join_date
            let selectedDate         = moment(join_date).format(this.$root.settings.settings.date_format)
            this.computedJoiningDate = moment(selectedDate + ' ' + currentTime, this.$root.settings.settings.date_format).format(this.$root.settings.settings.date_format)
            this.forms.join_date     = moment(this.forms.join_date).format("YYYY-MM-DD")
            this.menu3               = false
        }
    }
}