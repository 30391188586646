import _ from "lodash";
import CreateQuotation from './create.vue'
import ConvertQuotation from './convert.vue'

export default {
    components: {
        CreateQuotation,
        ConvertQuotation
    },
    data() {
        return {
            columnMenu          : false,
            items               : {},
            loading             : false,
            options             : {
                sortBy      : ['quotation_date'],
                sortDesc    : [true],
                itemsPerPage: this.$store.state.itemsPerPage,
            },
            createOrUpdateDialog: false,
            convertDialog       : false,
            convert_id:null,
            edit_id             : null,
            headers             : [
                {text: 'quotation no', value: 'quotation_no', sortable: true, align: ''},
                {text: 'created by', value: 'created_by', sortable: false, align: ''},
                {text: 'supplier', value: 'supplier_name', sortable: false, align: ''},
                {text: 'conversion id', value: 'purchase_id', sortable: true, align: ''},
                {text: 'total', value: 'total', sortable: true, align: ''},
                {text: 'quotation date', value: 'quotation_date', sortable: true, align: ''},
                {text: 'action', value: 'action', sortable: false, align: ''},
            ],
            closeOnContentClick : true
        }
    },
    watch     : {
        options: {
            deep: true,
            handler() {
                this.loading = true
                this.getData()
            }
        },
        createOrUpdateDialog(val) {
            if(!val) {
                this.getData()
                this.edit_id = null
            }
        },
        convertDialog(val){
            if(!val){
                this.convert_id = null
            }
        }
    },
    methods   : {
        deleteItem(id) {
            this.loading = true
            this.$deleteWithConfirmation({
                                             text: 'Are you sure you want delete this quotation?',
                                             url : '/api/inventory/purchase-quotations/' + id
                                         })
                .then(data => {
                    this.getData()
                    this.loading = false
                })
                .catch(error => {
                    this.loading = false
                })

        },
        getData: _.debounce(function () {
            this.loading = true
            axios.get('/api/inventory/purchase-quotations', {
                params: this.options
            })
                 .then(res => {
                     this.items = res.data
                     if(!_.isEmpty(this.items) && this.items.data.length > 0) {
                         this.items.data.forEach(i => {
                             i.quotation_no  = 'PQ-' + i.quotation_no
                             i.created_by    = i.created_by.name
                             i.supplier_name = i.supplier.name
                         })
                     }
                     this.loading = false
                 })
                 .catch(err => {
                     this.loading = false
                 })
        }, 800),
    }
}