export default {
    name   : "user-form",
    props  : ['forms', 'roles'],
    data   : () => ({
        loading: false
    }),
    methods: {
        updateUser() {
            this.loading = true
            axios.patch('/api/rbac/user/' + this.forms.id, this.forms)
                 .then(res => {
                     Swal.fire({
                                   icon : res.data.type,
                                   text : res.data.message,
                                   timer: 3000,
                               })
                         .then((result) => {
                             this.loading = false
                             this.resetForm()
                         })
                 })
                 .catch(error => {
                     this.loading = false
                     if(error.response.status === 422) {
                         this.forms.errors = error.response.data.errors
                     } else {
                         Swal.fire({
                                       icon: 'error',
                                       text: error.response.message
                                   })
                     }
                 })
        },
        postUser() {
            if(this.forms.id) {
                this.updateUser()
            } else {
                this.storeUser()
            }
        },
        storeUser() {
            this.loading = true
            axios.post('/api/rbac/user', this.forms)
                 .then(res => {
                     Swal.fire({
                                   icon : res.data.type,
                                   text : res.data.message,
                                   timer: 3000,
                               })
                         .then((result) => {
                             this.loading = false
                             this.resetForm()
                         })
                 })
                 .catch(error => {
                     this.loading = false
                     if(error.response.status === 422) {
                         this.forms.errors = error.response.data.errors
                     } else {
                         Swal.fire({
                                       icon: 'error',
                                       text: error.response.message
                                   })
                     }
                 })
        },
        resetForm() {
            this.forms.id                    = null
            this.forms.name                  = null
            this.forms.email                 = null
            this.forms.password              = null
            this.forms.password_confirmation = null
            this.forms.role_id               = null
            this.forms.errors                = []
            this.$emit('get-data')
        },
    }
}