<template>
  <v-card outlined>
    <v-row justify="end" class="pa-2">
      <v-col md="4">
        <!--        <v-menu-->
        <!--            offset-y-->
        <!--            v-model="menu"-->
        <!--            min-width="290px"-->
        <!--            :nudge-right="40"-->
        <!--            transition="scale-transition"-->
        <!--            :close-on-content-click="false"-->
        <!--        >-->
        <!--          <template v-slot:activator="{ on, attrs }">-->
        <!--            <v-text-field-->
        <!--                solo-->
        <!--                dense-->
        <!--                readonly-->
        <!--                clearable-->
        <!--                v-on="on"-->
        <!--                hide-details-->
        <!--                v-bind="attrs"-->
        <!--                v-model="date"-->
        <!--                label="Date Between"-->
        <!--            >-->
        <!--            </v-text-field>-->
        <!--          </template>-->
        <!--          <v-date-picker-->
        <!--              range-->
        <!--              v-model="date"-->
        <!--              @input="date.length>1?menu = false:null"-->
        <!--          >-->
        <!--          </v-date-picker>-->
        <!--        </v-menu>-->
      </v-col>
    </v-row>
    <v-card-title class="justify-center">
      {{ company_detail.user.company.name }}
    </v-card-title>
    <v-card-subtitle class="d-flex flex-column align-center">
      <span>
        Profit & Loss Statement
      </span>
      <span v-if="triggerReq && !_.isEmpty(response_data.date_between)">
        For Period (<strong>from: {{ response_data.date_between[0] }}</strong>,
        <strong>to: {{ response_data.date_between[1] }}</strong>)
      </span>
      <span>
        Contact:
      <template v-for="(phone, index) in company_detail.user.company.contact_phone">
        {{ phone }}
      </template>
      </span>
      <span>Address: {{ company_detail.user.company.address1 }}</span>
      <br>
      <v-btn outlined text small @click="$htmlToPaper('myPrint')">
        Print
        <v-icon>mdi-printer</v-icon>
      </v-btn>
    </v-card-subtitle>
    <v-card-text>
      <!--        <v-skeleton-loader-->
      <!--            v-if="loading"-->
      <!--            type="table-heading, list-item-two-line, table-tfoot"-->
      <!--        />-->
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
          <tr>
            <th>Particular</th>
            <th>Amount</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td><strong>Income</strong></td>
            <td></td>
          </tr>
          <tr>
            <td>
              <span class="ml-3">Sale Profit(+)</span>
            </td>
            <td>{{ response_data.sale_profit }}</td>
          </tr>
          <tr>
            <td>
              <span class="ml-3">Sale Return(-)</span>
            </td>
            <td>{{ response_data.sale_returns }}</td>
          </tr>
          <tr v-for="(key, income) in response_data.income_accounts">
            <td>
              <span class="ml-3">{{ income }}</span>
            </td>
            <td>{{ key }}</td>
          </tr>
          <tr>
            <td class="text-right">
              Total Income
            </td>
            <td>{{ response_data.summation.total_income }}</td>
          </tr>
          <tr>
            <td class="text-right">Gross Profit</td>
            <td>{{ response_data.summation.gross_profit }}</td>
          </tr>
          <tr>
            <td>
              <strong>Expenses</strong>
            </td>
            <td></td>
          </tr>
          <tr>
            <td><span class="ml-3">Payroll</span></td>
            <td>{{ response_data.salaries }}</td>
          </tr>
          <tr v-for="(key, expense) in response_data.expense_accounts">
            <td>
              <span class="ml-3">{{ expense }}</span>
            </td>
            <td>{{ key }}</td>
          </tr>
          <tr>
            <td class="text-right">
              Total Expense
            </td>
            <td>
              {{ response_data.summation.total_expense }}
            </td>
          </tr>
          <tr>
            <td class="text-right">Net Profit</td>
            <td>{{ response_data.summation.net_profit }}</td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
    <profit-loss-print
        :client-data="{response_data, company_detail}"
        style="visibility: collapse"
    ></profit-loss-print>
  </v-card>
</template>

<script>
import ProfitLossPrint from 'g~/print/profit-loss-print.vue'

export default {
  name      : "profit-loss",
  components: {
    ProfitLossPrint
  },

  data() {
    return {
      menu          : false,
      date          : [],
      company_detail: auth,
      response_data : {
        salaries        : 0,
        sale_profit     : 0,
        sale_returns    : 0,
        income_accounts : [],
        expense_accounts: [],
        summation       : {
          expense      : 0,
          income       : 0,
          total_income : 0,
          gross_profit : 0,
          net_profit   : 0,
          total_expense: 0
        }
      },
    }
  },
  computed: {
    triggerReq() {
      // if(this.date.length < 2) return false
      this.loading = true
      this.getData()
    }
  },
  methods : {
    getData: _.debounce(function () {
      axios.get(api_accounts + '/profit-loss', {
        params: {
          date: this.date
        }
      })
           .then(res => {
             this.response_data = res.data
             this.loading       = false
           })
           .catch(err => {

           })
    }, 200)
  }
}
</script>