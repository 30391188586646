import FormActions from '~/form-actions.vue'

export default {
    components: {
        FormActions
    },
    props     : {
        use_as_modal: {
            type   : Boolean,
            default: false
        },
        modelId     : {
            type   : Number,
            default: null
        },
        value       : {
            type   : Boolean,
            default: false
        },
    },
    data() {
        return {
            url        : this.$getUrl('suppliers', this.value, this.modelId),
            axiosMethod: this.$getAxiosMethod(this.value, this.modelId),
            forms      : this.$root.$data.forms,
            suppliers  : {},
            loading    : false,
        }
    },
    beforeCreate() {
        this.$root.$data.forms.fieldReset();
    },
    watch     : {
        '$route.fullPath'(val) {
            //reset data
            this.forms.reset();
            this.suppliers = []
        }
    },
    created() {
        if(this.axiosMethod === 'patch' || this.modelId)
            this.getData()
    },
    computed  : {},
    methods   : {
        getData() {
            this.loading = true
            this.$root.$data.erp.request.get(this.url, this, (data) => {
                this.suppliers     = data.suppliers
                this.forms.id      = this.suppliers.id
                this.forms.name    = this.suppliers.name
                this.forms.code    = this.suppliers.code
                this.forms.email   = this.suppliers.email
                this.forms.phone   = this.suppliers.phone
                this.forms.address = this.suppliers.address
                this.forms.company = this.suppliers.company
                this.loading       = false
            })
        },
        postSupplier($e) {
            let submitter = $e.submitter.value
            // return null
            this.loading  = true
            this.forms.submit(
                this.axiosMethod,
                this.$getUrlForm(this.url),
                true,
                this.$root
            )
                .then(data => {
                    this.loading = false
                    if(this.value) {
                        if(submitter === 'submit-close' || submitter === 'update-close') {
                            this.$emit('getNewSupplier', data['supplier'])
                            this.$emit('input', false)
                        }
                    }
                    this.$axiosPostDialog(data)
                }).catch(error => {
                this.loading = false
            })
        },
        onCancel() {
            this.forms.reset()
        }
    }
}