import _ from "lodash";

export default {

    data() {
        return {
            payment_crud_method    : 'create',
            payment_crud_model_id  : null,
            payment_crud_dialog    : false,
            payment_crud_payment_id: null,
            payment_list_dialog    : false,
            incomeCreateDialog     : false,
            incomeShowDialog       : false,
            menu                   : false,
            accounts               : [],
            form_id                : null,
            items                  : {},
            total_amount           : 0,
            total_paid             : 0,
            options                : {
                sortBy      : ['created_at'],
                sortDesc    : [true],
                itemsPerPage: this.$store.state.itemsPerPage,
            },
            loading                : false,
            payments               : [],
            headers                : [
                {text: 'date', value: 'income_date', sortable: true},
                {text: 'ref no', value: 'ref', sortable: true},
                {text: 'warehouse', value: 'warehouse.name', sortable: false},
                {text: 'account', value: 'account.name', sortable: false},
                // {text: 'name-id-type', value: 'transaction.userable.name_id_type', sortable: false},
                {text: 'amount', value: 'amount', sortable: true},
                {text: 'paid', value: 'paid_total', sortable: true},
                {text: 'due', value: 'due', sortable: true},
                {text: 'note', value: 'note', sortable: false},
                {text: 'created at', value: 'created_at', sortable: true},
            ],
        }
    },
    watch  : {
        incomeCreateDialog(val) {
            if(!val) {
                this.form_id = null
            }
        },
        options: {
            deep: true,
            handler() {
                this.loading = true
                this.getData()
            }
        }
    },
    created() {
        if(!_.isEmpty(this.$route.params) && this.$route.params.id) {
            this.options.id = this.$route.params.id
        }
        this.getAccounts()
        axios.get('/api/report/income')
            .then(res=>{
                this.total_amount = res.data.total_amount
                this.total_paid   = res.data.paid_total
            })
    },
    methods: {
        createIncomeBillById(id) {
            this.method                = 'create'
            this.payment_crud_model_id = id
            this.payment_crud_dialog   = true
        },
        showIncome(id) {
            this.form_id          = id
            this.incomeShowDialog = true
        },
        editIncome(id) {
            this.form_id            = id
            this.incomeCreateDialog = true
        },
        editPayment(id) {
            this.payment_crud_payment_id = id
            this.payment_crud_method     = 'edit'
            this.payment_crud_dialog     = true
            this.payment_list_dialog     = false
        },
        getListOfIncomeBills(id) {
            this.payments = []
            axios
                .get('/api/payments/income-payment-received', {
                    params: {model_id: id}
                })
                .then(res => {
                    this.payments = res.data
                    if(this.payments.length > 0) {
                        this.payment_list_dialog = true
                    }
                })
        },
        getAccounts() {
            axios.get('/api/accounts/get-accounts-by-parent/Income')
                 .then(res => {
                     this.accounts = res.data
                 })

        },
        resetPaymentComponent() {
            this.payment_crud_method     = 'create'
            this.payment_crud_model_id   = null
            this.payment_crud_payment_id = null
        },
        deleteItem(id) {
            if(!confirm("are you sure?")) {
                return false;
            }
            this.loading = true
            axios.delete('/api/income/incomes/' + id)
                 .then(res => {
                     this.getData()
                     this.loading = false
                     Swal.fire({
                                   icon             : res.data.type,
                                   timer            : 3000,
                                   text             : res.data.message,
                                   showCancelButton : false,
                                   showConfirmButton: false
                               })
                 })
        },
        getData: _.debounce(function () {
            this.loading = true
            axios.get('/api/income/incomes', {
                params: this.options
            })
                 .then(res => {
                     this.items   = res.data
                     this.loading = false
                 })
        }, 800),

        getExpenseList(query, callback) {
            axios.get('/api/income/incomes?dropdown=true').then(response => {
                this.incomes = response.data;
                callback(query);
            }).catch(error => {

            })
        },
        paymentSuccess(val) {
            if(val) {
                this.getData()
                this.payment_crud_dialog = false
            }
        },
        fromCreate(val) {
            if(val === 'create-success') {
                this.getData()
                this.incomeCreateDialog = false
            }
        }
    }
}