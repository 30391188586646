export default {
    name   : "index",
    data(){
        return {
            loading: false,
            dialog : false,
            forms  : {errors: {}},
            items  : {},
            options: {
                itemsPerPage: this.$store.state.itemsPerPage,
                sortBy      : ['created_at']
            },
            headers: [
                {text: 'name', value: 'name'},
                {text: 'id', value: 'code'},
                {text: 'email', value: 'email'},
                {text: 'phone', value: 'phone'},
                {text: 'created at', value: 'created_at'},
                {text: 'action', value: 'action'},
            ],
            closeOnContentClick:false
        }
    },
    watch  : {
        dialog(val) {
            if(!val) {
                this.forms        = {}
                this.forms.errors = {}
            }
        },
        options: {
            deep: true,
            handler() {
                this.loading = true
                this.getData()
            }
        },
    },
    methods: {
        edit(item) {
            this.forms.id    = item.id
            this.forms.name  = item.name
            this.forms.code  = item.code
            this.forms.email = item.email
            this.forms.phone = item.phone
            if(this.forms.id) {
                this.dialog = true
            }
        },
        updateUser() {
            axios
                .patch('/api/inventory/otherusers/' + this.forms.id, this.forms)
                .then(res => {
                    Swal.fire({
                                  icon : res.data.type,
                                  timer: 3000,
                                  text : res.data.message,
                              })
                        .then((result) => {
                            this.dialog = false
                            this.getData()
                        })
                })

        },
        del(item) {
            this.$deleteWithConfirmation({
                                             text: 'Are you sure you want delete this user?',
                                             url : '/api/inventory/otherusers/' + item.id
                                         })
                .then(res=>{
                    this.dialog = false
                    this.getData()
                })
                .catch(error=>{
                    this.loading = false
                })
        },
        getData: _.debounce(function () {
            axios
                .get('/api/inventory/otherusers', {params: this.options})
                .then(res => {
                    this.items   = res.data
                    this.loading = false
                })
        }, 200),
        postUser() {
            this.loading = true
            axios
                .post('/api/inventory/otherusers', this.forms)
                .then(res => {
                    Swal.fire({
                                  icon : res.data.type,
                                  timer: 3000,
                                  text : res.data.message,
                              })
                        .then((result) => {
                            this.dialog = false
                            this.getData()
                        })
                })
                .catch((error) => {
                    this.loading = false;
                    if(error.response.status === 422) {
                        let err = error.response.data.errors;
                        Object.keys(err).forEach((key) => {
                            this.forms.errors[key] = err[key][0];
                        });
                    } else {
                        this.dialog = false;
                        Swal.fire({
                                      icon   : "error",
                                      message: error.response.message,
                                  });
                    }
                });

        }
    }
}