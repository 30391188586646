<template>
  <div>
    <v-card outlined>
      <v-card-title>
        Purchase Return Ledger
        <v-spacer></v-spacer>
        <v-btn icon @click="$htmlToPaper('list_print_bootstrap')">
          <v-icon>mdi-printer</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col md="3" cols="12">
            <v-text-field
                solo
                clearable
                label="search by bill no"
                v-model="options.bill_no"
                prepend-icon="mdi-database-search"
            >
            </v-text-field>
          </v-col>
          <v-col md="12" cols="12">
            <v-data-table
                :headers="headers"
                :loading="loading"
                :items="items.data"
                class="elevation-0"
                :options.sync="options"
                :server-items-length="items.total"
                loading-text="Loading... Please wait"
                :footer-props="{ itemsPerPageOptions: $store.state.itemsPerPageOptions }"
            >
              <template v-slot:footer>
                <div class="px-3 text-right">
                  <p>Total Price: {{ totalPrice }}</p>
                  <p>Total Paid: {{ paid }}</p>
                  <p>
                    Balance: {{ balance }} {{ balance > 0 ? "receivable" : "payable" }}
                  </p>
                </div>
              </template>
              <template v-slot:item.action="{ item }">
                <v-btn
                    fab
                    icon
                    x-small
                    color="primary"
                    :to="{ name: 'purchase.show', params: { id: item.returnable_id } }"
                >
                  <v-icon class="small">mdi-eye</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <list-print-bootstrap
        :columns="headers"
        :rows="items.data"
        style="visibility: collapse"
        :date_fields="['created_at']"
        :title="'Purchase Return List'"
    >
    </list-print-bootstrap>
  </div>
</template>

<script>
export default {
  name   : "purchaseReturnReport",
  data() {
    return {
      items     : {},
      totalPrice: 0.0,
      paid      : 0.0,
      balance   : 0.0,
      loading   : false,
      options   : {
        itemsPerPage: this.$store.state.itemsPerPage,
      },
      headers   : [
        {text: "bill_no", value: "bill_no", sortable: true},
        {text: "total(dr)", value: "total_price", sortable: true},
        {text: "paid(cr)", value: "total_return_amount", sortable: true},
        {text: "balance", value: "balance", sortable: true},
        {text: "created at", value: "created_at", sortable: true},
        {text: "action", value: "action", sortable: false},
      ],
    }
  },
  watch  : {
    options: {
      deep: true,
      handler() {
        this.loading = true;
        this.getData();
      },
    },
  },
  created() {
    if(!_.isEmpty(this.$route.params)) {
      if(this.$route.params.id !== undefined && this.$route.params.id) {
        this.options.id = this.$route.params.id;
      }
    }
  },
  methods: {
    getData() {
      axios
          .get("/api/report/purchase-return", {params: this.options})
          .then((res) => {
            this.items      = res.data.purchaseReturnData;
            this.totalPrice = res.data.totalPrice;
            this.balance    = res.data.balance;
            this.paid       = res.data.paid;
            this.loading    = false;
          });
    },
  },
};
</script>