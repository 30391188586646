import IncomePaymentExport from './income-payment-export.vue'
export default {
    components:{
        IncomePaymentExport
    },
    data(){
        return{
            exportDialog:false,
            payment_bill_dialog:false,
            payment_bill_id:null,
            payment_id    : null,
            payment_dialog: false,
            method        : 'create',
            menu          : false,
            items         : {},
            loading       : false,
            options       : {
                sortBy      : ['date'],
                sortDesc    : [true],
                itemsPerPage: this.$store.state.itemsPerPage,
            },
            headers       : [
                {text: 'ref no', value: 'income_ref'},
                {text: 'user name', value: 'user_name', sortable: false},
                {text: 'amount', value: 'paid'},
                {text: 'date', value: 'date'},
                {text: 'action', value: 'action'},
            ],
        }
    },
    watch  : {
        options: {
            deep: true,
            handler() {
                this.loading = true
                this.getData()
            }
        },
    },
    methods: {
        uploadFile() {
            let formData = new FormData()
            let file     = this.$refs.inputUpload.files[0]
            formData.append('file', file)
            axios.post('/api/inventory/income-payment-import', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                 .then(res => {
                     Swal.fire({
                                   icon: 'success',
                                   text: 'file has been uploaded successfully'
                               }).then((result)=>{
                         this.getData()
                     })
                 })
                 .catch(error => {
                     Swal.fire({
                                   icon: 'error',
                                   html: 'status '+error.response.status+'.<br>'+error.response.statusText,
                               })
                 })
        },
        closeDialogExport(val) {
            if(val === 200) {
                this.exportDialog = false
            }
        },
        getData() {
            axios
                .get('/api/payments/income-payment-received', {params: this.options})
                .then(res => {
                    this.items   = res.data
                    if(!_.isEmpty(this.items.data)){
                        this.items.data.forEach(d=>{
                            if(d.supplier_name){
                                d.user_name = d.supplier_name + '-supplier'
                            }
                            if(d.customer_name){
                                d.user_name = d.customer_name + '-customer'
                            }
                            if(d.employee_name){
                                d.user_name = d.employee_name + '-employee'
                            }
                            if(d.otheruser_name){
                                d.user_name = d.otheruser_name + '-otheruser'
                            }
                        })
                    }
                    this.loading = false
                })
        }
    }
}