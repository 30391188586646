export default {
    data() {
        return {
            purchase  : {},
            value     : null,
            amount    : 0,
            rproduct  : [],
            warehouses: [],
        }
    },
    created() {
        let url = '/api/inventory/purchase-return/' + this.$route.params.id
        this.$root.$data.erp.request.get(url, this, (data) => {
            this.purchase   = data.purchase
            this.rproduct   = this.purchase.products
            this.warehouses = data.warehouses
        })
    },
    methods: {
        submit() {
            let x          = 0;
            let firstCount = 0;
            let obj        = [];
            for (let i = 0, len = this.rproduct.length; i < len; i++) {
                let product = this.rproduct[i]
                for (let j = 0; j < product.units.length; j++) {
                    let unit = product.units[j];
                    // console.log(unit);
                    if(unit.runit === undefined || unit.runit === '') continue
                    if(product.ramount === undefined || product.ramount === '') continue
                    if(product.pivot.warehouse_id === undefined || product.warehouse === '') continue
                    if(firstCount > 0) product.ramount = 0
                    obj[x] = {
                        returnable_id  : parseInt(this.$route.params.id),
                        returnable_type: 'App\\Inventory\\Purchase',
                        product_id     : product.id,
                        unit_id        : unit.id,
                        quantity       : unit.runit,
                        warehouse_id   : product.pivot.warehouse_id,
                        amount         : parseFloat(product.ramount)
                    }
                    firstCount++;
                    x++;
                }
                firstCount = 0;
            }
            if(!obj.length) {
                Swal.fire({
                              icon: 'error',
                              text: 'Data are not valid'
                          })
                return null
            }
            axios.patch('/api/inventory/purchase-return/' + this.$route.params.id, {
                return: obj
            })
                 .then(response => {
                     Swal.fire({
                                   icon             : response.data.type,
                                   timer            : 3000,
                                   text             : response.data.message,
                                   showCancelButton : false,
                                   showConfirmButton: false,
                               })
                         .then(result => {
                             this.$router.push({name: 'purchase.show', params: {id: this.$route.params.id}})
                         })

                 })
                 .catch(error => {
                 });
        }
    }
}