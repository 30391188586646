export default {
    name   : "activitylog",
    data(){
        return {
            searchUser: null,
            items     : {},
            users     : [],
            loading   : false,
            options   : {
                itemsPerPage: this.$store.state.itemsPerPage,
            },
            menu      : false,
            user      : {},
            headers   : [
                {text: 'created at', value: 'created_at'},
                {text: 'created by', value: 'causer.name'},
                {text: 'ip address', value: 'ip'},
                {text: 'description', value: 'description'},
                {text: 'module', value: 'subject_type'},
                {text: 'id', value: 'subject_id'},
                {text: 'changes', value: 'properties'},
            ]
        }
    },
    watch  : {
        searchUser(val) {
            this.getUser(val)
        },
        options: {
            deep: true,
            handler() {
                this.loading = true
                this.getData()
            }
        }
    },
    created() {
        this.authUser = auth.user
        if(!this.authUser.role_id) {
            this.getUser()
        }
    },
    methods: {
        processProperties(data) {
            if(this.authUser.is_super) {
                return data.properties
            }
            if(data.properties.old) {
                return data.properties
            }
            if(data.description === 'deleted') {
                return 'deleted'
            } else {
                return 'new entity'
            }
        },
        getUser: _.debounce(function () {
            let url
            url = this.authUser.is_super ? '/api/admin/get-user' : '/api/get-authable-user';
            axios
                .get(url, {params: {name: this.searchUser}})
                .then(res => {
                    this.users = res.data
                })
        }, 200),
        jsonParse(val) {

        },
        takeLastOne(val) {
            return _.last(val.split('\\'))
        },
        getData: _.debounce(function () {
            let url
            url = this.authUser.is_super ? '/api/admin/activity-log' : '/api/activity-log';
            axios
                .get(url, {params: this.options})
                .then(res => {
                    this.items   = res.data
                    this.loading = false
                })
        }, 200)
    }
}