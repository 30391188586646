<template>
  <div style="max-width: 850px" class="ma-auto">
    <v-card outlined>
      <v-card-title>
        {{ auth.user.company.name }}
        <v-spacer></v-spacer>
        <v-btn
            fab
            icon
            x-small
            @click="$htmlToPaper('myPrint')"
        >
          <v-icon>mdi-printer</v-icon>
        </v-btn>
        <v-btn icon fab x-small>
          <v-icon>mdi-email</v-icon>
        </v-btn>
        <v-btn icon fab x-small>
          <v-icon>mdi-pdf-box</v-icon>
        </v-btn>

      </v-card-title>
      <v-card-subtitle>{{ auth.user.company.address1 }}</v-card-subtitle>
      <v-card-text>
        <h2 class="text-center">Bill Paid</h2>
        <br>
        <v-row>
          <v-col md="8" cols="12">
            <v-simple-table>
              <template v-slot:default>
                <tbody>
                <tr>
                  <td>Payment Date</td>
                  <td>
                    {{ item.date | removeTimeFromDate }}
                  </td>
                </tr>
                <tr>
                  <td>Bill No.</td>
                  <td>
                    <router-link
                        :to="{name:'purchase.show', params:{id: item.paymentable.id}}"
                        v-if="item.paymentable.id && $options.filters.checkPermission(['bill_paid', 'show'])"
                    >
                      {{ item.paymentable.bill_no }}
                    </router-link>
                    <span v-else>{{ item.paymentable.bill_no }}</span>
                  </td>
                </tr>
                <tr>
                  <td>Payment Mode</td>
                  <td>{{ item.payment_type | paymentMethod }}</td>
                </tr>
                <tr>
                  <td>Payment Through</td>
                  <td>{{ item.transaction.bank.name }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
            <div class="pa-5">
              <p>Paid To:</p>
              <div class="px-5">
                <p>{{ item.paymentable.supplier.name }}</p>
                <p>Phone:{{ item.paymentable.supplier.phone }}</p>
                <p>address:{{ item.paymentable.supplier.address }}</p>
              </div>
            </div>
          </v-col>
          <v-col md="4" cols="12">
            <div class="pa-16 success text-center text--white">
              <p class="white--text">
                Payment made
              </p>
              <h1 class="white--text">
                {{ item.paid }}{{ auth.user.setting.settings.currency }}
              </h1>
            </div>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-simple-table>
          <template v-slot:default>
            <thead>
            <tr>
              <th>Bill Number</th>
              <th>Bill Date</th>
              <th>Bill Paid</th>
              <th>Payment Amount</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>
                {{ item.paymentable.bill_no }}
              </td>
              <td>
                {{ item.date | removeTimeFromDate }}
              </td>
              <td>{{ item.paymentable.total }}{{ auth.user.setting.settings.currency }}</td>
              <td>{{ item.paid }}{{ auth.user.setting.settings.currency }}</td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <br>
    <v-divider></v-divider>
    <br>
    <p class="text-center">
      Payment History of Bill No# {{ item.paymentable.bill_no }}
    </p>
    <v-simple-table>
      <template v-slot:default>
        <thead>
        <tr>
          <th>date</th>
          <th>description</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(payment, index) in item.paymentable.payments" :key="index">
          <td>
            {{ payment.date | removeTimeFromDate }}
          </td>
          <td>
            Payment of amount
            <strong>{{ payment.paid }}{{ auth.user.setting.settings.currency }}</strong> made and applied for
            <strong>{{ item.paymentable.bill_no }}</strong> to
            <strong>{{ item.paymentable.supplier.name }}</strong> by
            <strong v-if="!_.isEmpty(item.activities)">{{ item.activities[0].causer.name }}</strong>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
    <bill-paid-show-print
        :client_data="item"
        v-if="!_.isEmpty(item)"
        style="visibility: collapse"
    >
    </bill-paid-show-print>
  </div>
</template>
<script>
import BillPaidShowPrint from './bill-paid-show-print.vue'
export default {
  components:{
    BillPaidShowPrint
  },
  name: "bill-paid-show",
  data: () => ({
    auth: auth,
    item: {
      activities : [],
      paymentable: {
        id      : null,
        supplier: {}
      },
      transaction: {
        bank: {}
      }
    }
  }),

  created() {
    axios
        .get('/api/payments/bill-paid/' + this.$route.params.id)
        .then(res => {
          this.item = res.data
        })
  }
}
</script>