export default {
    name    : "chartAccountLedger",
    data() {
        return {
            account : {},
            children: [],
            child   : null,
            items   : {},
            headers : [
                {text: 'created at', value: 'created_at_without_time'},
                {text: 'bank', value: 'bank.name'},
                {text: 'ref no', value: 'ref_no'},
                {text: 'user-id-type', value: 'user', sortable: false},
                {text: 'method', value: 'payment_method'},
                {text: 'debit', value: 'de'},
                {text: 'credit', value: 'cr'},
                {text: 'balance', value: 'total_balance'},
                {text: 'action', value: 'action'},
            ],
            loading : false,
            options : {
                itemsPerPage: this.$store.state.itemsPerPage,
            },
        }
    },
    computed: {
        loaded() {
            return !_.isEmpty(this.account)
        }
    },
    watch   : {
        options: {
            deep: true,
            handler() {
                this.getData()
            }
        },
        child(val) {
            if(val) {
                this.getData()
            } else {
                this.items = {}
            }
        }
    },
    created() {
        let account_id = null
        if(this.$route.params.id) {
            account_id = this.$route.params.id
        }
        this.getParent(account_id)
    },
    methods : {
        processTotalBalance(item) {
            if(this.account.name === 'Asset')
                return 0 - item.balance
            if(this.account.name === 'Expense')
                return 0 - item.balance
            if(this.account.name === 'Liability')
                return item.balance
            if(this.account.name === 'Equity')
                return item.balance
            if(this.account.name === 'Income')
                return item.balance

            return 'total balance'
        },
        processDebit(item) {
            if(this.account.name === 'Asset')
                return item.credit
            if(this.account.name === 'Liability')
                return item.credit
            if(this.account.name === 'Equity')
                return item.credit
            if(this.account.name === 'Income')
                return item.credit
            if(this.account.name === 'Expense')
                return item.credit

            return 'debit'
        },
        processCredit(item) {
            if(this.account.name === 'Asset')
                return item.debit
            if(this.account.name === 'Liability')
                return item.debit
            if(this.account.name === 'Equity')
                return item.debit
            if(this.account.name === 'Income')
                return item.debit
            if(this.account.name === 'Expense')
                return item.debit

            return 'credit'
        },
        getData: _.debounce(function () {
            this.loading = true
            axios
                .get('/api/ledger-by-account/' + this.child.id, {
                    params: this.options
                })
                .then(res => {
                    this.items   = res.data
                    this.loading = false
                })
                .catch(error => {
                })
        }, 200),
        getParent(account_id) {
            axios
                .get('/api/accounts/' + account_id)
                .then(res => {
                    this.account  = res.data
                    this.children = res.data.children
                })
        }
    }
}