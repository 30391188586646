import SaleOrderPrint from './sale-order-print.vue'
export default {
    components:{
        SaleOrderPrint
    },
    name   : "show",
    data   : () => ({
        item   : {
            customer: {
                name: ''
            },
            user    : {}
        },
        company: {}
    }),
    created() {
        axios.get('/api' + window.location.pathname)
             .then(res => {
                 this.item = res.data.orders
                 this.item.products.forEach(item => {
                     this.checkQuantity(item.pivot.warehouse_id, item)
                 })
                 this.company = res.data.company
             })
    },
    computed  : {
        product_total() {
            if(!_.isEmpty(this.item) && !_.isEmpty(this.item.products)) {
                return this.item.products.reduce((acc, obj) => {
                    return acc + obj.pivot.subtotal
                }, 0)
            }
            return 0
        },
        sub_total() {
            return this.product_total + this.item.shipping_cost
        },
        discounted_amount() {
            return (this.sub_total * (this.item.overall_discount / 100)).toFixed(2)
        }
    },
    methods: {
        checkShippingStatus(item) {
            console.log(item)
        },
        checkQuantity(warehouse_id, item) {
            let value = item.warehouses.filter(val => {
                return val.id === warehouse_id
            })[0]

            if(value === undefined || value.pivot === undefined) {
                item.remaining_quantity = false
                return null
            }
            let warehouseQuantity   = value.pivot.quantity !== undefined ? value.pivot.quantity : 0
            item.lowest_unit        = value.pivot.unit_id
            item.remaining_quantity = warehouseQuantity
            if(item.pivot.unit_id === item.base_unit_id && item.pivot.quantity) {
                item.remaining_quantity = item.remaining_quantity >= item.pivot.quantity
                return null
            }
            if(item.pivot.unit_id !== item.base_unit_id) {
                warehouseQuantity = warehouseQuantity / item.conversion_factor
                if(item.quantity) {
                    item.remaining_quantity = item.remaining_quantity >= item.pivot.quantity
                }
            }
        },
        backorder(product) {
            // product_id, unit_id, warehouse_id, quantity
        },
    }
}