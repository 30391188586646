module.exports = [
    {
        path:'/inventory/warehouse/products/index',
        name:'product.index',
        component: require('../views/inventory/product/index.vue').default
    },
    {
        path:'/inventory/products/import',
        name:'product.import',
        component: require('../views/inventory/product/import.vue').default
    },
    {
        path: '/inventory/productdamages/index',
        name: 'productdamage.index',
        component: require('../views/inventory/productdamage/index.vue').default
    },
    {
        path: '/inventory/productdamages/create',
        name: 'productdamage.create',
        component: require('../views/inventory/productdamage/create.vue').default
    },
    {
        path: '/inventory/productdamages/:id/edit',
        name: 'productdamage.edit',
        component: require('../views/inventory/productdamage/create.vue').default
    },
]