import Vue from 'vue'
import vue2Dropzone from "vue2-dropzone";
import draggable from "vuedraggable";
import VueBarcode from "vue-barcode";

Vue.component('combo-chart', require('~/charts/ComboChartComponent').default)
Vue.component('single-chart', require('~/charts/sinleChartComponent').default)
// Vue.component('barcode-print', require('g~/print/barcodePrint.vue').default)
Vue.component('barcode-print', require('g~/print/barcode-print.vue').default)
Vue.component('barcode-print-for-pos', require('g~/print/barcodePrintPos.vue').default)
// Vue.component('print-component', require('g~/print/print.vue').default)
// Vue.component('pos-print', require('g~/print/pos-print.vue').default)
Vue.component('payment-dialog', require('g~/paymentComponent.vue').default)
Vue.component('payment-crud', require('g~/payment-component-crud.vue').default)
Vue.component('payment-bill-receipt', require('g~/payment-bill-receipt.vue').default)
Vue.component('payment-on-page', require('g~/payment-component-on-page.vue').default)
Vue.component('conversion-component', require('g~/conversionComponent.vue').default)
Vue.component('custom-date-picker', require('g~/custom-datepicker.vue').default)
Vue.component('list-print-bootstrap', require('g~/print/list-print-bootstrap.vue').default)
Vue.component('unauthorize-message', require('g~/unauthorize-message').default)
Vue.component('vueDropzone', vue2Dropzone)
Vue.component('draggable', draggable)
Vue.component('barcode', VueBarcode)
Vue.component('simple-tooltip', require('g~/simple-tooltip').default)

Vue.component('tooltip-button', require('g~/tooltip-button').default)
Vue.component('back-button', require('g~/back-button').default)
Vue.component('user-dropdown-autocomplete', require('g~/user-dropdown-autocomplete').default)

Vue.component('qrcode-scanner', require('g~/qrcode-scanner').default);

Vue.component('warehouse-mapping-select', require('g~/warehouse-mapping-select.vue').default)