import store from "./store";

Vue.filter('obj_string_value', (obj, key)=>{
    const keys = key.split('.');
    let value = obj;
    for (let i = 0; i < keys.length; i++) {
        if (typeof value === 'undefined') {
            return undefined;
        }
        value = value[keys[i]];
        if (typeof value === 'object' && value !== null) {
            value = JSON.stringify(value);
            value = JSON.parse(value);
        }
    }
    return value;
})

Vue.filter('booleanToString', (val)=>{
    return !!val
})

Vue.filter('booleanToNumber', (val)=>{
    return val ? 1 : 0
})

Vue.filter('tofix', (val, arg) => {
    return val ? parseFloat(val).toFixed(arg) : 0;
})

Vue.filter('toUppercase', (val) => {
    if(val) {
        return val.toUpperCase()
    }
})

Vue.filter('firstUpperOnly', (val) => {
    if(val) {
        return _.startCase(_.toLower(val));
    }
})

Vue.filter('delivery_status', (value) => {
    value = parseInt(value)
    if(value === 1)
        return 'received'

    if(value === 2)
        return 'pending'

    if(value === 3)
        return 'ordered'
})

Vue.filter('getTimePermission', (item) => {
    if(store.state.timePermissions.length) {
        let val = store.state.timePermissions.filter(value => {
            return value.model === item[0] && value.permission.method === item[1];
        })
        return val.length ? val[0].permission : 0;
    }
    return true
})

Vue.filter('checkTimeAndPermission', (item, date) => {
    if(!store.state.timePermissions.length)
        return true
    if(store.state.timePermissions.length) {
        let val = store.state.timePermissions.filter(value => {
            return value.model === item[0] && value.permission.method === item[1]
        })
        if(_.isEmpty(val)) {
            return false
        }
        if(!val[0].permission.hours)
            return true
        if(val[0].permission.hours) {
            let now      = moment(new Date())
            let end      = moment(date, `${settings.settings.date_format}`)
            let duration = moment.duration(now.diff(end))
            let hours    = duration.asHours()
            return hours < val[0].permission.hours
        }
    }
    return false

})

Vue.filter('checkPermission', (item) => {
    if(store.state.permissions) {
        if(store.state.permissions.length) {
            let val = store.state.permissions.filter(value => {
                return value.model === item[0] && value.method === item[1];
            })
            return !!val.length
        }
    }
    return true
})


Vue.filter('momentFormatBy', (item, format) => {
    return moment(item).format(format)
})

Vue.filter('momentFormatByWithCurrentFormat', (item, currentFormat = "YYYY-MM-DD", format) => {
    return moment(item, currentFormat).format(format)
})

Vue.filter('totalFromObject', (item, key) => {
    let total = 0.0
    return total += Number(item[key])
})

Vue.filter('multiply', (item, [...arg]) => {
    return item[arg[0]] * item[arg[1]]
})
Vue.filter('totalProductPrice', (item, quantity) => {
    return Number(item.buying_price) * Number(quantity)
})

Vue.filter('find_bom_type_from_product', item => {
    return !!item.bill_of_materials.length
})

Vue.filter('hasWarehouse', val => {

})

Vue.filter('findFromArrayById', (arr, id, returnKey) => {
    let value = _.find(arr, {id: id})
    if(returnKey) {
        return value[returnKey]
    }
})

Vue.filter('paymentMethod', (value) => {
    if(value == 1) {
        return 'cash'
    }
    if(value == 2) {
        return 'credit card'
    }
    if(value == 3) {
        return 'cheque'
    }
})

Vue.filter('removeTimeFromDate', (value) => {
    if(value) {
        let date = moment(value, settings.settings.date_format)
        return date.isValid() ? value.split(' ')[0] : value;
    }
})

Vue.filter('default_payment_mood', (value) => {

})

Vue.filter('uniqueByKeyValue', (arr, key) => {
    return arr.length > 0 ? _.uniqBy(arr, key) : arr;
})

Vue.filter('sortPartNumber', (part_numbers, product, warehouse_id) => {
    if(product.manufacture_part_number) {
        let items = []
        if(part_numbers.length) {
            part_numbers.forEach(v => {
                if(v.warehouse_id === warehouse_id && v.product_id === product.id) {
                    items.push(v)
                }
            })
        }
        return items
    } else {
        return null
    }
})